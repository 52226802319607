/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useRef, useState } from 'react';
import MIW4Page1 from '../../../../../assets/MIW4-1.png';
import MIW4Page2 from '../../../../../assets/MIW4-2.png';
import { Button, Modal } from 'react-bootstrap';
import ControlStateProps from '../../../../../models/State/ControlStateProps';
import { pdf } from '@react-pdf/renderer';
import DocumentWizard from '../../../DocumentWizard';
import DocumentField from '../../../DocumentField';
import SubscriberWrapper from '../../../../../components/SubscriberWrapper';
import DocumentCheckbox from '../../../DocumentCheckbox';
import DocumentSignatureButton from '../../../DocumentSignatureButton';
import { Validation } from '../../../../../validation';
import moment from 'moment';
import MIW4Pdf from './MIW4Pdf';
import DocumentRadioCheck from '../../../DocumentRadioCheck';
import { reactPdfToBase64 } from '../../../../../tools';
import { submitDocument } from '../../../../../services/EmployeeOnboardingService';
import { AppGlobals } from '../../../../../App';
import MIW4 from './MIW4';
import LoadingWrapper from '../../../../../components/LoadingWrapper';

export default function MIW4Form({document, userData, companyData, signature, isEmployer, hideModal, onboardingToken, updateDocumentToCompleted}) {
    const [isLoading, setIsLoading] = useState(false);
    const [miw4, setMIW4] = useState(MIW4.initDefault());
    const [zoom, setZoom] = useState(1);

    const fieldRefs = [
        useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(),
        useRef(), useRef(), useRef(), useRef(), useRef()
    ]

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        setMIW4(await MIW4.initFromData(document, userData, companyData, isEmployer));
        setIsLoading(false);
    }

    const handleSubmit = async () => {
        const pdf = <MIW4Pdf signature={signature} miw4={miw4} isEmployer={isEmployer}/>;
        const base64 = await reactPdfToBase64(pdf);
        const response = await submitDocument(miw4.encode(document, base64), onboardingToken);
        if (response.status === 200) {
            updateDocumentToCompleted('miw4', base64);
            hideModal();
            AppGlobals.alert('success', response.message);
        } else {
            AppGlobals.alert('danger', response.message);
        }
    }

    return (
        <>
            <Modal.Header closeButton>
                
            </Modal.Header>
            <Modal.Body style={{padding: 0, overflowX: 'scroll', backgroundColor: 'lightgray'}}>
                <LoadingWrapper isLoading={isLoading}>
                    <div style={{transform: `scale(${zoom})`, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 12, height: ((1100 * 2) + (12) + 16) * zoom}}>
                        <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                            <img src={isEmployer ? miw4.employeeSubmission[0] ?? MIW4Page1 : MIW4Page1} style={{width: 850, height: 1100}}/>
                            { !isEmployer ?
                                    // EMPLOYEE
                                <>
                                    <DocumentField innerRef={fieldRefs[0]} stateProps={new ControlStateProps('ssn', miw4, Validation.ssn)} x={480} y={179} width={120} fontSize={14} type='number' maxLength={9}/>
                                    <DocumentField innerRef={fieldRefs[1]} type='date' stateProps={new ControlStateProps('dateOfBirth', miw4, Validation.nonEmptyString)} x={665} y={179} width={120} fontSize={14}/>

                                    <DocumentField innerRef={fieldRefs[2]} stateProps={new ControlStateProps('name', miw4, Validation.nonEmptyString)} maxLength={40} x={65} y={213} width={300} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[3]} stateProps={new ControlStateProps('driverOrStateId', miw4, Validation.nonEmptyString)} maxLength={15} x={475} y={213} width={250} fontSize={14}/>

                                    <DocumentField innerRef={fieldRefs[4]} stateProps={new ControlStateProps('homeAddress', miw4, Validation.nonEmptyString)} maxLength={30} x={65} y={247} width={320} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[5]} stateProps={new ControlStateProps('city', miw4, Validation.nonEmptyString)} maxLength={20} x={65} y={281} width={200} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[6]} stateProps={new ControlStateProps('state', miw4, Validation.nonEmptyString)} maxLength={2} x={305} y={281} width={45} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[7]} stateProps={new ControlStateProps('zipCode', miw4, Validation.nonEmptyString)} type='number' maxLength={5} x={372} y={281} width={75} fontSize={14}/>

                                    <DocumentRadioCheck innerRef={fieldRefs[8]} value={0} stateProps={new ControlStateProps('newEmployee', miw4, Validation.notUndefined, 'newEmployee0')} x={467} y={250} width={18} height={18}/>
                                    <DocumentRadioCheck innerRef={fieldRefs[9]} value={1} stateProps={new ControlStateProps('newEmployee', miw4, Validation.notUndefined, 'newEmployee1')} x={467} y={275} width={18} height={18}/>
                                    <DocumentField innerRef={fieldRefs[10]} type='date' stateProps={new ControlStateProps('dateOfHire', miw4)} x={665} y={247} width={120} fontSize={14}/>

                                    <DocumentField innerRef={fieldRefs[11]} stateProps={new ControlStateProps('numberOfExemptions', miw4, Validation.nonEmptyString)} minInt={0} maxInt={255} type='number' x={710} y={310} width={40} fontSize={16}/>
                                    <DocumentField innerRef={fieldRefs[12]} stateProps={new ControlStateProps('additionalWitholding', miw4)} minInt={0} maxInt={9999} type='number' x={670} y={345} width={95} fontSize={16}/>

                                    <DocumentCheckbox innerRef={fieldRefs[13]} stateProps={new ControlStateProps('step8a', miw4)} x={83} y={408} width={18} height={18}/>
                                    <DocumentCheckbox innerRef={fieldRefs[14]} stateProps={new ControlStateProps('step8b', miw4)} x={83} y={433} width={18} height={18}/>
                                    <DocumentCheckbox innerRef={fieldRefs[15]} stateProps={new ControlStateProps('step8c', miw4)} x={83} y={458} width={18} height={18}/>
                                    <DocumentField innerRef={fieldRefs[16]} stateProps={new ControlStateProps('step8bExplanation', miw4)} maxLength={80} x={333} y={432} width={460} fontSize={11}/>
                                    <DocumentField innerRef={fieldRefs[17]} stateProps={new ControlStateProps('step8cResidentZone', miw4)} maxLength={30} x={470} y={457} width={323} fontSize={11}/>

                                    <DocumentSignatureButton innerRef={fieldRefs[18]} signature={signature} stateProps={new ControlStateProps('signed', miw4, Validation.true)} x={80} y={586} width={118} height={26}/>
                                    <SubscriberWrapper stateProps={new ControlStateProps('signed', miw4, null, 'wrapper for date')} render={() => {
                                        return (!miw4.signed ? '' : 
                                        <span style={{position: 'absolute', left: 675, top: 588}}>{moment().format('MM/DD/YYYY')}</span>
                                        )
                                    }}/>
                                </>
                            :
                                    // EMPLOYER
                                <>
                                    <DocumentField innerRef={fieldRefs[0]} stateProps={new ControlStateProps('employerName', miw4, Validation.nonEmptyString)} maxLength={50} x={54} y={688} width={380} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[1]} stateProps={new ControlStateProps('ein', miw4, Validation.nonEmptyString)} maxLength={9} type='number' x={454} y={688} width={180} fontSize={14}/>

                                    <DocumentField innerRef={fieldRefs[2]} stateProps={new ControlStateProps('employerAddress', miw4, Validation.nonEmptyString)} maxLength={40} x={54} y={722} width={380} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[3]} stateProps={new ControlStateProps('employerCity', miw4, Validation.nonEmptyString)} maxLength={20} x={455} y={722} width={190} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[4]} stateProps={new ControlStateProps('employerState', miw4, Validation.nonEmptyString)} maxLength={2} x={653} y={722} width={60} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[5]} stateProps={new ControlStateProps('employerZipCode', miw4, Validation.nonEmptyString)} maxLength={5} type='number' x={720} y={722} width={70} fontSize={14}/>

                                    <DocumentField innerRef={fieldRefs[6]} stateProps={new ControlStateProps('contactName', miw4, Validation.nonEmptyString)} maxLength={30} x={54} y={755} width={380} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[7]} stateProps={new ControlStateProps('contactPhoneNumber', miw4, Validation.phoneNumber)} maxLength={10} x={455} y={755} width={300} fontSize={14}/>
                                </>
                            }
                        </div>
                        <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                            <img src={MIW4Page2} style={{width: 850, height: 1100}}/>
                        </div>
                    </div>
                </LoadingWrapper>
            </Modal.Body>
            <DocumentWizard document={miw4} fieldRefs={fieldRefs} refCount={isEmployer ? 8 : 19} onSubmit={handleSubmit} zoom={zoom} setZoom={setZoom}/>
        </>
    )
}
