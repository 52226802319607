/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React from "react";
import { Form, Modal } from "react-bootstrap";
import { mfa } from "../../services/AuthenticationService";
import { AppGlobals } from "../../App";
import CustomButton from "../../components/CustomButton";


export default class MFA extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mfa: '',
            error: null,
            isSubmitting: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(value) {
        while (value.length > 6 || (value.length > 0 && !'1234567890'.includes(value[value.length - 1]))) {
            value = value.slice(0, value.length - 1);
        }
        this.setState({mfa: value});
    }

    async handleSubmit() {
        this.setState({isSubmitting: true, error: null});
        
        const response = await mfa(this.props.tokenIdentifier, this.state.mfa);
        if (response.status === 200) {
            AppGlobals.CookieMonster.set('token', response.token);
            AppGlobals.checkAuthentication();
            // new Cookies().set('token', response.token, {path: '/'});
            // window.location.reload(false);   
        } else {
            this.setState({error: response.message});
        }        
        
        this.setState({isSubmitting: false});
    }


    render() {
        return (
            <>
                <Modal.Header>
                    <Modal.Title>Multi-Factor Authentication</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={(event) => {event.preventDefault(); if (this.state.mfa.length === 6) {this.handleSubmit()}}}>
                        <p style={{alignSelf: 'center'}}>Please enter the code that has been sent to your mobile device.</p>
                        <div style={{display: 'flex', flexDirection: 'row', maxWidth: 800, gap: 4}}>
                            <Form.Control className="no-arrows" style={{fontSize: '15vmin', textAlign: 'center'}} type='number' maxLength={6} value={this.state.mfa} onChange={(event) => {this.handleChange(event.target.value)}}/>
                        </div>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'column', marginTop: 12}}>
                            <CustomButton disabled={this.state.mfa.length !== 6} isLoading={this.state.isSubmitting} onClick={this.handleSubmit}>
                                Submit
                            </CustomButton>
                        </div>
                        { this.state.error &&
                            <span style={{color: 'var(--bs-danger)', alignSelf: 'center'}}>{this.state.error}</span>
                        }
                    </form>
                </Modal.Body>
            </>
        )
    }
}