/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import { Validation } from "../../../validation";
import CustomQuestion from "../../../models/CustomQuestion";
import StateObject from "../../../models/State/StateObject";
import Decoder from "../../../decoding";
import { JobApplicationMinimal } from "./JobApplicationMinimal";
import { validateDecimal, validateDecimalFixed, validateInteger } from "../../../tools";
import TerminalForApplication from "./TerminalForApplication";

export default class JobPostForApplication extends StateObject {
    uid: number;
    dateActivated: string | undefined;
    title: string;
    description: string;
    employmentType: string;
    shiftType: string;
    shiftDurations: string[];
    compensationMin: number;
    compensationMax: number;
    payType: string;
    questions: CustomQuestion[];
    expirationDate: string | undefined;
    benefits: string | undefined;
    location: string | undefined;
    terminal: TerminalForApplication;

    constructor(
        uid: number,
        dateActivated: string | undefined,
        title: string,
        description: string,
        employmentType: string,
        shiftType: string,
        shiftDurations: string[],
        compensationMin: number,
        compensationMax: number,
        payType: string,
        questions: CustomQuestion[],
        expirationDate: string | undefined,
        benefits: string | undefined,
        location: string | undefined,
        terminal : TerminalForApplication,
    ) {
        super();
        this.uid = uid;
        this.dateActivated = dateActivated;
        this.title = title;
        this.description = description;
        this.employmentType = employmentType;
        this.shiftType = shiftType;
        this.shiftDurations = shiftDurations;
        this.compensationMin = compensationMin;
        this.compensationMax = compensationMax;
        this.payType = payType;
        this.questions = questions;
        this.expirationDate = expirationDate;
        this.benefits = benefits;
        this.location = location;
        this.terminal = terminal;
    }

    static initDefault(): JobPostForApplication {
        return new JobPostForApplication(
            -1,
            moment().format('YYYY-MM-DD'),
            '',
            '',
            'fulltime',
            'weekdays', 
            [],
            10.0, 
            20.0,
            'ph',
            [],
            '',
            '',
            '',
            TerminalForApplication.initDefault()
        );
    }

    static decode(json: {[key: string]: any}): JobPostForApplication {
        const decoder = new Decoder(json);
        const uid: number = decoder.decode('uid', Decoder.Uid);
        const dateActivated: string | undefined = decoder.decode('dateLastModified', Decoder.Date, {defaultValue: undefined, warn: false});
        const title: string = decoder.decode('title', Decoder.NonEmptyString);
        const description: string = decoder.decode('description', Decoder.NonEmptyString);
        const employmentType: string = decoder.decode('employmentType', Decoder.NonEmptyString);
        const shiftType: string = decoder.decode('shiftType', Decoder.NonEmptyString);
        const shiftDurations: string[] = decoder.decode('shiftDurations', Decoder.NonEmptyArrayFromString);
        const compensationMin: number = decoder.decode('compensationMin', Decoder.Decimal);
        const compensationMax: number = decoder.decode('compensationMax', Decoder.Decimal);
        const payType: string = decoder.decode('payType', Decoder.NonEmptyString);
        const questions: CustomQuestion[] = (decoder.decode('questions', Decoder.Array) as any[] ?? []).map(q => CustomQuestion.decode(q));
        const expirationDate: string = decoder.decode('expirationDate', Decoder.Date, {defaultValue: '', warn: false});
        const benefits: string = decoder.decode('benefits', Decoder.StringStrict, {defaultValue: '', warn: false});
        const location: string = decoder.decode('location', Decoder.StringStrict, {defaultValue: '', warn: false});
        const terminal: TerminalForApplication = TerminalForApplication.decode(json.terminal)
        if (decoder.checkForErrors()) {
            return new JobPostForApplication(uid, dateActivated, title, description, employmentType, shiftType, shiftDurations, 
                compensationMin, compensationMax, payType, questions, expirationDate, benefits, location, terminal);
        } else {
            return JobPostForApplication.initDefault();
        }
    }

    encode(): {[key: string]: any} {
        return {
            uid: this.uid, 
            title: this.title, 
            description: this.description, 
            employmentType: this.employmentType, 
            shiftType: this.shiftType, 
            shiftDurations: JSON.stringify(this.shiftDurations), 
            compensationMin: validateDecimalFixed(this.compensationMin as any, 2), 
            compensationMax: validateDecimalFixed(this.compensationMax as any, 2), 
            payType: this.payType,  
            questions: this.questions.map(q=>q.encode()),
            expirationDate: this.expirationDate ? this.expirationDate : null, 
            benefits: this.benefits, 
            location: this.location,
            terminal: this.terminal,
        }
    }
}