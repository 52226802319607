/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment"
import Address from "../../../../models/Address"
import StateObject from "../../../../models/State/StateObject"
import { addressToString, validateDecimal, validateInteger } from "../../../../tools";
import Decoder from "../../../../decoding";

export default class W4 extends StateObject {
    documentType;
    firstNameMiddleInitial;
    lastName;
    ssn;
    address;
    cityStateZip;
    filingStatus;
    has2Jobs;
    step3Children;
    step3OtherDependents;
    step3Total;
    step4OtherIncome;
    step4Deductions;
    step4ExtraWitholding;
    step5Signed;
    employerName;
    employerAddress;
    firstDateOfEmployment;
    ein;
    step2b1;
    step2b2a;
    step2b2b;
    step2b3;
    step4b1;
    step4b2;
    step4b4;
    completed = false;

    constructor(
        firstNameMiddleInitial, 
        lastName, 
        ssn, 
        address, 
        cityStateZip, 
        filingStatus, 
        has2Jobs, 
        step3Children, 
        step3OtherDependents, 
        step3Total, 
        step4OtherIncome, 
        step4Deductions, 
        step4ExtraWitholding, 
        step5Signed,
        employerName,
        employerAddress,
        firstDateOfEmployment,
        ein,
        step2b1, 
        step2b2a, 
        step2b2b, 
        step2b3, 
        step4b1, 
        step4b2, 
        step4b4
        ) {
        super();
        this.firstNameMiddleInitial = firstNameMiddleInitial;
        this.lastName = lastName;
        this.ssn = ssn;
        this.address = address;
        this.cityStateZip = cityStateZip;
        this.filingStatus = filingStatus;
        this.has2Jobs = has2Jobs;
        this.step3Children = step3Children;
        this.step3OtherDependents = step3OtherDependents;
        this.step3Total = step3Total;
        this.step4OtherIncome = step4OtherIncome;
        this.step4Deductions = step4Deductions;
        this.step4ExtraWitholding = step4ExtraWitholding;
        this.step5Signed = step5Signed;
        this.employerName = employerName;
        this.employerAddress = employerAddress;
        this.firstDateOfEmployment = firstDateOfEmployment;
        this.ein = ein
        this.step2b1 = step2b1;
        this.step2b2a = step2b2a;
        this.step2b2b = step2b2b;
        this.step2b3 = step2b3;
        this.step4b1 = step4b1;
        this.step4b2 = step4b2;
        this.step4b4 = step4b4;
        this.initChildren();
    }

    static initDefault() {
        return new W4(
            '',
            '', 
            '', 
            '', 
            '', 
            '', 
            undefined, 
            false, 
            '', 
            '', 
            '', 
            '', 
            '', 
            '', 
            false, 
            '', 
            '', 
            '', 
            '', 
            '', 
            '', 
            ''
        );

    }

    static initFromData(document, userData, companyData, startDate) {
        const firstName = userData.firstName ?? '';
        const middleInitial = userData.middleName?.[0] ?? '';
        const lastName = userData.lastName ?? '';
        const address = Address.decodeNonStrict(userData.address);
        
        return new W4(
            document.firstNameMiddleInitial ?? `${firstName} ${middleInitial}`, 
            document.lastName ?? lastName, 
            document.ssn ?? userData.ssn ?? '', 
            document.address ?? `${address?.thoroughfare} ${address?.premise}`, 
            document.cityStateZip ?? `${address?.locality}, ${address?.administrativeArea} ${address?.postalCode}`, 
            document.filingStatus ?? undefined, 
            document.has2Jobs ?? false, 
            document.step3Children ?? '',
            document.step3OtherDependents ?? '',
            document.step3Total ?? '',
            document.step4OtherIncome ?? '', 
            document.step4Deductions ?? '', 
            document.step4ExtraWitholding ?? '', 
            false,
            companyData.name ?? companyData.companyName ?? '',
            companyData.address ? addressToString(companyData.address) : '',
            startDate ?? '',
            companyData.ein ?? '',
            '', 
            '', 
            '', 
            '', 
            '', 
            '', 
            ''
        );

    }

    step2b2c() {
        return this.step2b2a || this.step2b2b ? (validateDecimal(this.step2b2a) + validateDecimal(this.step2b2b)) : undefined;
    }
    step2b4() {
        return this.step2b1 ? validateDecimal(this.step2b1) / Math.max(1, validateInteger(this.step2b3)) : this.step2b2c() !== undefined ? validateDecimal(this.step2b2c()) / Math.max(1, validateInteger(this.step2b3)) : undefined;
    }
    step4b3() {
        return this.step4b1 || this.step4b2 ? validateDecimal(this.step4b1) > validateDecimal(this.step4b2) ? validateDecimal(this.step4b1) - validateDecimal(this.step4b2) : 0 : undefined;
    }
    step4b5() {
        return this.step4b3() !== undefined || this.step4b4 ? validateDecimal(this.step4b3()) + validateDecimal(this.step4b4) : undefined;
    }

    encode(document, base64){
        return {
            ...document,
            base64: base64, 
            firstNameMiddleInitial: this.firstNameMiddleInitial, 
            lastName: this.lastName, 
            ssn: this.ssn, 
            address: this.address, 
            cityStateZip: this.cityStateZip, 
            filingStatus: this.filingStatus, 
            has2Jobs: this.has2Jobs, 
            step3Children: validateInteger(this.step3Children), 
            step3OtherDependents: validateInteger(this.step3OtherDependents), 
            step3Total: validateDecimal(this.step3Total), 
            step4OtherIncome: this.step4OtherIncome ? validateDecimal(this.step4OtherIncome) : null, 
            step4Deductions: this.step4Deductions ? validateDecimal(this.step4Deductions) : null, 
            step4ExtraWitholding: this.step4ExtraWitholding ? validateDecimal(this.step4ExtraWitholding) : null, 
            step5Signed: this.step5Signed,  
        }
    }
}
