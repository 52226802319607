/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faMagnifyingGlass, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Button, ButtonGroup, Card, Dropdown, Form, ListGroup, Modal, OverlayTrigger, Pagination, Popover } from 'react-bootstrap';
import QuickTable from '../../components/QuickTable';
import { useHistory } from 'react-router-dom';
import './Dashboard.css';
import { getDashBoardInfo } from '../../services/MyCompany';
import LoadingWrapper from '../../components/LoadingWrapper';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import { JobSites } from '../../enums';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, Title, BarElement, LinearScale, PointElement, LineElement } from 'chart.js';
import { Bar, Line, Pie } from 'react-chartjs-2';
import CustomTabButtons from '../../components/CustomTabButtons';
import moment from 'moment';

export default function Dashboard() {

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const jobSites = ['Indeed', 'Monster', 'FlexJobs', 'ZipRecruiter', 'MyTacticalRecruiter'];
    
    const [barsPerPage, setBarsPerPage] = useState(8);
    const [isLoading, setIsLoading] = useState(false);
    const [applicants, setApplicants] = useState([]);
    const [jobPosts, setJobPosts] = useState([]);
    const [enabledJobPosts, setEnabledJobPosts] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const history = useHistory();
    let numberOfNewApplicants = 0;

    async function loadData() {
        setIsLoading(true);
        const response = await getDashBoardInfo();
        if (response.status === 200) {
            setApplicants(response.jobApplications);
            setJobPosts(response.jobPosts);
            setEnabledJobPosts(response.jobPosts.filter(post=> post.isEnabled))
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadData();
    }, [])

    useEffect(()=>{
        function handleBarsPerPage(){
            if(window.innerWidth < 460){
                setBarsPerPage(2);
            }else if(window.innerWidth < 750){
                setBarsPerPage(3)
            }else if(window.innerWidth < 1000){
                setBarsPerPage(4)
            }else if(window.innerWidth < 1200){
                setBarsPerPage(5)
            }else if(window.innerWidth < 1500){
                setBarsPerPage(6)
            }else if(window.innerWidth < 1800){
                setBarsPerPage(8)
            }
            setCurrentPage(1);   
        }
        handleBarsPerPage()
        
        window.addEventListener('resize', handleBarsPerPage);

        return ()=>{
            window.removeEventListener('resize', handleBarsPerPage);
        }
    }, [])

    ///////////////////////
    /// NEW APPLICANT ROWS
    ///////////////////////

    const newApplicantRows = applicants.filter(app => {
        return !app.clientIdentifier && !app.rejected;
    }).map((app, index) => {
        numberOfNewApplicants++;
        return {
            onClick: () => history.push(`/job/${app.jobPostIdentifier}?applicant=${app.uid}`),
            columns: [
                { value: `${app.firstName} ${app.lastName}`},
                { value: app.jobPostTitle },
                { value: app.terminal.name },
                { value: app.dateApplied },
                { value: JobSites[app.source] ? JobSites[app.source] : 'MyTacticalRecruiter'}
            ]
        }
    })

    /////////////////////////
    /// LIST GROUP ITEMS
    /////////////////////////

    const newApplicantElementsMobile = applicants.filter(app => {
        return !app.clientIdentifier && !app.rejected;
    }).map((app, index) => {
        return (
            <ListGroup.Item className='cursor-pointer' action key={app.uid} onClick={() => history.push(`/job/${app.jobPostIdentifier}?applicant=${app.uid}`)}>
                <div style={{display: 'flex', flexDirection: 'column', padding: 8}}>
                        <h4 style={{fontWeight: 'bold', marginBottom: 12}}>{`${app.firstName} ${app.lastName}`}</h4>
                        <h6 style={{marginBottom: 6}}><strong>Job Title: </strong>{app.jobPostTitle}</h6>
                        <h6 style={{marginBottom: 6}}><strong>Terminal: </strong> {app.terminal.name}</h6>
                        <h6 style={{marginBottom: 10}}><strong>Source: </strong> {JobSites[app.source] ? JobSites[app.source] : 'MyTacticalRecruiter'}</h6>
                        <p style={{textAlign: 'right', margin: 0, fontSize: 13}}><strong>Date Applied: </strong>{app.dateApplied}</p>
                </div>
            </ListGroup.Item>
        )
    })

    //////////////////////////////////////////////////////
    /// FITLERED JOB POSTS VARIABLES FOR APPLICANT PIPELINE
    //////////////////////////////////////////////////////


    enabledJobPosts.filter(post=>{ 
        post['totalApplicants'] = post.applicants.length;
        return post['totalApplicants'];    
    })

    const jobPostsApplied = enabledJobPosts.filter(post=>{ 
        post['totalApplied'] = post.applicants.reduce((acc, el)=>{
            if((!el.passedBackground && !el.passedDrugScreen) && !el.interviews.length && !el.hired && !el.rejected){
                return acc+1;
            }
            return acc;
        }, 0)

        return post['totalApplied'];    
    })

    const jobPostsScreened = enabledJobPosts.filter(post=>{ 
        post['totalScreened'] = post.applicants.reduce((acc, el)=>{
            if((el.passedBackground && el.passedDrugScreen) && !el.interviews.length){
                return acc+1;
            }
            return acc;
        }, 0)

        return post['totalScreened'];    
    })
    const jobPostsInterviewed = enabledJobPosts.filter(post=>{ 
        post['totalInterviewed'] = post.applicants.reduce((acc, el)=>{
            if(el.interviews.length && !el.hired){
                return acc+1;
            }
            return acc;
        }, 0)

        return post['totalInterviewed'];    
    })
    const jobPostsHired = enabledJobPosts.filter(post=>{ 
        post['totalHired'] = post.applicants.reduce((acc, el)=>{
            if(el.hired){
                return acc+1;
            }
            return acc;
        }, 0)

        return post['totalHired'];    
    })

    /////////////////////////////
    /// CHART DATA
    ////////////////////////////

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        ArcElement,
        PointElement,
        LineElement,
    );

    ////////////////
    /// PIE CHART
    ////////////////

    // ['Indeed', 'Monster', 'FlexJobs', 'ZipRecruiter', 'MyTacticalRecruiter'];
    let totalIndeed = 0;
    let totalMonster = 0;
    let totalFlexJobs = 0;
    let totalZipRecruiter = 0;
    let totalMyTacticalRecruiter = 0;

    applicants.forEach(app=>{
        switch (app.source){
            case 'indeed':
                totalIndeed++;
                break;
            case 'monster':
                totalMonster++;
                break;
            case 'flexjobs':
                totalFlexJobs++;
                break;
            case 'ziprecruiter':
                totalZipRecruiter++;
                break;
            case null:
                totalMyTacticalRecruiter++;
                break;
            default:
                break;
        }
    })

     const data = {
        labels: jobSites,
        datasets: [
          {
            // label: '%',
            data: [totalIndeed, totalMonster, totalFlexJobs, totalZipRecruiter, totalMyTacticalRecruiter],
            backgroundColor: [
              'rgba(37, 87, 167, 0.7)',
              'rgba(61, 36, 98, 0.7)',
              'rgba(255, 83, 42, 0.7)',
              'rgba(69, 216, 97, 0.7)',
              'rgb(45, 105, 160, 1)'
            ],
            borderColor: [
              'rgba(37, 87, 167, 1)',
              'rgba(61, 36, 98, 1)',
              'rgba(255, 83, 42, 1)',
              'rgba(69, 216, 97, 1)',
              'rgb(45, 105, 160, 1)'
            ],
            borderWidth: 1,
          },
        ],
      };

    ////////////////
    /// LINE CHART
    ////////////////

      const lineOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Applicants Per Month',
          },
        },
      };
      
      const lineLabels = [];
      const applicantsAppliedPerMonth = [];
      const applicantsHiredPerMonth = [];
      let thisMonthIndex = moment().month() + 12;
      for(let i = 11; i >= 0; i--){
        lineLabels[i] = months[thisMonthIndex % 12];
        
        applicantsAppliedPerMonth[i] = applicants.reduce((acc, el) => {
            if(moment(el.dateApplied).month() == thisMonthIndex % 12 && moment().diff(el.dateApplied, 'years') < 1)
                return acc+1;
            return acc;
        }, 0)

        applicantsHiredPerMonth[i] = applicants.reduce((acc, el) => {
            if(el.hired !== null && moment(el.hired).month() == thisMonthIndex % 12 && moment().diff(el.hired, 'years') < 1)
                return acc+1;
            return acc;
        }, 0)

        thisMonthIndex--;
      }
      
      const lineData = {
        labels: lineLabels,
        datasets: [
        {
            label: 'Applied',
            data: applicantsAppliedPerMonth,
            backgroundColor: 'rgb(45, 105, 160, .8)',
        },
        {
            label: 'Hired',
            data: applicantsHiredPerMonth,
            backgroundColor: 'rgba(85, 225, 55, .9',
            },
        ],
      };

    ////////////////
    /// BAR CHART
    ////////////////

    const barOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Applicants Per Job',
          },
        },
    };

      
    const barLabels = enabledJobPosts.map((post, index)=>{
        if(index >= currentPage*barsPerPage || index < (currentPage-1)*barsPerPage )
            return;
        if(post.isDuplicate)
            return `${post.title} (${moment(post.dateActivate).format('MMM DD, YYYY')})`;
        return post.title;
    }).filter(el=>el !== undefined);

    const barJobPostData = enabledJobPosts.map((post, index)=> {
        if(index >= currentPage*barsPerPage || index < (currentPage-1)*barsPerPage)
            return;
        return post.totalApplicants;
    }).filter(el=>el !== undefined);
    

    const barData = {
        labels: barLabels,
        datasets: [
            {
            label: 'Number of Applicants',
            data: barJobPostData,
            backgroundColor: 'rgb(45, 105, 160)',
            },
        ],
    };

    const pages = Math.ceil(enabledJobPosts.length / barsPerPage);
    let paginationItems = [];
    for(let i = 0; i < pages; i++){
        paginationItems.push(i+1);
    }

    return (
        <LoadingWrapper isLoading={isLoading}>
            <div style={{ backgroundColor: 'var(--bs-primary)', padding: 12, textAlign: 'start', display: 'flex', flexDirection: 'column', gap: 12, height: '100%' }}>
                <Card>
                    <Card.Header>
                        <Card.Title>Statistics</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <CustomTabButtons
                            style={{marginBottom: 24}}
                            tabs={['Applicants Per Month', 'Applicants Per Job', 'Applicants Per Source']}
                            tabIndex={tabIndex}
                            setTabIndex={setTabIndex}
                            fontSizeMult={1}
                        />
                        {tabIndex === 0 &&
                            <>
                                <div className='all-time-labels'>
                                    <h6 style={{opacity: .7}}><strong>Total Applicants All Time: </strong>{applicants.length}</h6>
                                    <h6 style={{opacity: .7}}><strong>Total Applicants Hired All Time: </strong>{applicants.reduce((acc, el)=>{if(el.hired !== null){return acc+1} return acc}, 0)}</h6>
                                </div>
                                <div style={{minHeight: 300}}>
                                    <Line options={lineOptions} data={lineData} />
                                </div>
                            </>
                        }
                        {tabIndex === 1 &&
                        <>
                            <div style={{minHeight: 300}}>
                                <Bar options={barOptions} data={barData} />
                            </div>
                            <Pagination style={{marginTop: 16, display: 'flex', flexDirection:'row', padding: 0, justifyContent: 'center'}}>
                                <Pagination.Prev onClick={() => {if(currentPage !== 1){setCurrentPage(cur=>cur-1)}}} />
                                {paginationItems.map((p, index) => {
                                        if(index < currentPage - 2 || index > currentPage )
                                        return;
                                    return <Pagination.Item key={p} active={currentPage === p} onClick={()=>setCurrentPage(p)}>{p}</Pagination.Item>
                                })}
                                <Pagination.Next onClick={() => {if(currentPage !== pages){setCurrentPage(cur=>cur+1)}}}/>
                            </Pagination>
                        </>
                        }
                        {tabIndex === 2 &&
                            <div style={{maxHeight: 600}}>
                                <Pie
                                    style={{margin: 'auto'}}
                                    data={data}
                                />
                            </div>
                        }
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header>
                        <Card.Title>Applicant Pipeline</Card.Title>
                    </Card.Header>
                    <Card.Body className='summary-grid'>
                        <Accordion>
                            <Accordion.Item eventKey='0'>
                                <Accordion.Header>
                                    <p style={{fontWeight: 'bold', textAlign: 'center', margin: 0}}>Total Applied: {jobPostsApplied.reduce((acc, el)=>{ return acc+=el.totalApplied},0)}</p>
                                </Accordion.Header>
                                <Accordion.Body style={{flex: 1, padding: 6, textAlign: 'center', maxHeight: 450, overflowY: 'auto'}}>
                                    <SummaryContainer jobPosts={jobPostsApplied} label='Applied' property='totalApplied'/>    
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion>
                            <Accordion.Item eventKey='1'>
                                <Accordion.Header>
                                    <p style={{fontWeight: 'bold', textAlign: 'center', margin: 0}}>Total Screened: {jobPostsScreened.reduce((acc, el)=>{ return acc+=el.totalScreened},0)}</p>
                                </Accordion.Header>
                                <Accordion.Body style={{flex: 1, padding: 6, textAlign: 'center', maxHeight: 450, overflowY: 'auto'}}>
                                    <SummaryContainer jobPosts={jobPostsScreened} label='Screened' property='totalScreened'/>    
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion>
                            <Accordion.Item eventKey='2'>
                                <Accordion.Header>
                                    <p style={{fontWeight: 'bold', textAlign: 'center', margin: 0}}>Total Interviewed: {jobPostsInterviewed.reduce((acc, el)=>{ return acc+=el.totalInterviewed},0)}</p>
                                </Accordion.Header>
                                <Accordion.Body style={{flex: 1, padding: 6, textAlign: 'center', maxHeight: 450, overflowY: 'auto'}}>
                                    <SummaryContainer jobPosts={jobPostsInterviewed} label='Interviewed' property='totalInterviewed'/>   
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion>
                            <Accordion.Item eventKey='3'>
                                <Accordion.Header>
                                    <p style={{fontWeight: 'bold', textAlign: 'center', margin: 0}}>Total Hired: {jobPostsHired.reduce((acc, el)=>{ return acc+=el.totalHired},0)}</p>
                                </Accordion.Header>
                                <Accordion.Body style={{flex: 1, padding: 6, textAlign: 'center', maxHeight: 450, overflowY: 'auto'}}>
                                    <SummaryContainer jobPosts={jobPostsHired} label='Hired' property='totalHired'/>     
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Card.Body>
                </Card>
                <Card style={{flex: 1}}>
                    <Card.Header>
                        <Card.Title>New Applicants: {numberOfNewApplicants}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <div className='desktop-block'>
                            <QuickTable
                                responsive={false}
                                hover
                                headers={[
                                    { label: 'Name', sortable: true },
                                    { label: 'Job Title', sortable: false },
                                    { label: 'Terminal', sortable: true },
                                    { label: 'Date Applied', sortable: false },
                                    { label: 'Source', sortable: false },
                                ]}
                                rows={newApplicantRows}
                                />
                        </div>
                        <ListGroup className="mobile-block">
                            {newApplicantElementsMobile}
                        </ListGroup>
                    </Card.Body>
                </Card>
            </div>
        </LoadingWrapper>
    )
}

function SummaryContainer({jobPosts, label, property}){
    const history = useHistory();

    return (
        <ListGroup>
            {jobPosts.map((post, index)=>{
                return (
                    <ListGroup.Item action key={index} onClick={()=> history.push(`/job/${post.uid}`)} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap'}}>
                        <h6 style={{fontWeight: 'bold', margin: '8px 12px 8px 0px'}}>{post.title}</h6>
                        <p style={{margin: '8px 0'}}><strong>{label}: </strong>{post[property]}</p>
                    </ListGroup.Item>
                )
            })}
        </ListGroup>
    )
}