/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../decoding";
import Address from "./Address";
import StateObject from "./State/StateObject";

export default class TCEmergencyContacts extends StateObject {
    eContact1FirstName;
    eContact1LastName;
    eContact1PhoneNumber;
    eContact1Email;
    eContact1Address;
    eContact1Relationship

    eContact2FirstName;
    eContact2LastName;
    eContact2PhoneNumber;
    eContact2Email;
    eContact2Address;
    eContact2Relationship;

    constructor(
        eContact1FirstName,
        eContact1LastName,
        eContact1PhoneNumber,
        eContact1Email,
        eContact1Address,
        eContact1Relationship,

        eContact2FirstName,
        eContact2LastName,
        eContact2PhoneNumber,
        eContact2Email,
        eContact2Address,
        eContact2Relationship,
    ){
        super();
        this.eContact1FirstName = eContact1FirstName;
        this.eContact1LastName = eContact1LastName;
        this.eContact1PhoneNumber = eContact1PhoneNumber;
        this.eContact1Email = eContact1Email;
        this.eContact1Address = eContact1Address;
        this.eContact1Relationship = eContact1Relationship;

        this.eContact2FirstName = eContact2FirstName;
        this.eContact2LastName = eContact2LastName;
        this.eContact2PhoneNumber = eContact2PhoneNumber;
        this.eContact2Email = eContact2Email;
        this.eContact2Address = eContact2Address;
        this.eContact2Relationship = eContact2Relationship;
        this.initChildren();
    }

    static initDefault(){
        return new TCEmergencyContacts(
            '',
            '',
            '',
            '',
            undefined,
            '',
            '',
            '',
            '',
            '',
            undefined,
            '',
        )
    }

    static initFromUserData(user){
        return new TCEmergencyContacts(
            user?.eContact1FirstName ?? '',
            user?.eContact1LastName ?? '',
            user?.eContact1PhoneNumber ?? '',
            user?.eContact1Email ?? '',
            user?.eContact1Address ? Address.decodeNonStrict(user?.eContact1Address) : undefined,
            user?.eContact1Relationship ?? '',
            user?.eContact2FirstName ?? '',
            user?.eContact2LastName ?? '',
            user?.eContact2PhoneNumber ?? '',
            user?.eContact2Email ?? '',
            user?.eContact2Address ? Address.decodeNonStrict(user?.eContact2Address) : undefined,
            user?.eContact2Relationship ?? '',
        )
    }

    encode(){
        return {
            eContact1FirstName: this.eContact1FirstName,
            eContact1LastName: this.eContact1LastName,
            eContact1PhoneNumber: this.eContact1PhoneNumber,
            eContact1Email: this.eContact1Email,
            eContact1Address: this.eContact1Address ? this.eContact1Address.encode() : null,
            eContact1Relationship: this.eContact1Relationship,
            eContact2FirstName: this.eContact2FirstName,
            eContact2LastName: this.eContact2LastName,
            eContact2PhoneNumber: this.eContact2PhoneNumber,
            eContact2Email: this.eContact2Email,
            eContact2Address: this.eContact2Address ? this.eContact2Address.encode() : null,
            eContact2Relationship: this.eContact2Relationship
        }
    }
}