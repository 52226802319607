/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import { Card, ListGroup, Form, InputGroup, Button, Modal } from 'react-bootstrap';
import KeyValueRow from '../../components/KeyValueRow';
import LoadingWrapper from '../../components/LoadingWrapper';
import CustomControl from '../../components/controls/CustomControl';
import { AppGlobals } from '../../App';
import EmailTemplate from '../../components/EmailTemplate';
import CustomTextArea from '../../components/controls/CustomTextArea';
import { getClientProfile, updateFaAccountId, updateOfferLetter, updateRejectionMessage } from '../../services/ClientService';
import OfferLetterEditor from './OfferLetterEditor';
import logo from '../../assets/TTA-Long-150.png'
import CustomButton from '../../components/CustomButton';
import { addressToString } from '../../tools';

export default function ClientProfile({selectedClient}) {
    const companyName = AppGlobals.CookieMonster.get('companyName');

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmittingOfferLetter, setIsSubmittingOfferLetter] = useState(false);
    const [isUpdatingToken, setIsUpdatingToken] = useState(false)

   
    const [originalRejectionMessage, setOriginalRejectionMessage] = useState('')
    const [rejectionMessage, setRejectionMessage] = useState('');

    const [originalOfferLetter, setOriginalOfferLetter] = useState('');
    const [offerLetter, setOfferLetter] = useState('');

    const [originalFaAccountId, setOriginalFaAccountId] = useState('')
    const [faAccountId, setFaAccountId] = useState('')
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        loadData();
    }, [selectedClient]);


    const loadData = async () => {
        setIsLoading(true);
        const response = await getClientProfile(selectedClient.uid);
        if (response.status === 200) {
            setRejectionMessage(response.rejectionMessage);
            setOriginalRejectionMessage(response.rejectionMessage);
            setOfferLetter(response.offerLetter);
            setOriginalOfferLetter(response.offerLetter);
            setFaAccountId(selectedClient.faAccountId ?? '')
            setOriginalFaAccountId(selectedClient.faAccountId ?? '')

        }else{
            AppGlobals.alert('danger', response.message);
        }
        setIsLoading(false);
    }

    async function handleSaveChanges(){
        setIsSubmitting(true);
        const response = await updateRejectionMessage(selectedClient.uid, rejectionMessage);
        if(response.status === 200){
            setOriginalRejectionMessage(rejectionMessage);
            AppGlobals.alert('success', response.message)
        } else{
            AppGlobals.alert('danger', response.message)
        }
        setIsSubmitting(false);
    }

    async function handleUpdateFaAccountId(){
        setIsUpdatingToken(true);
        const faAccountIdToSend = faAccountId ? faAccountId : null;
        const response = await updateFaAccountId(selectedClient.uid, faAccountIdToSend);
        if(response.status === 200){
            selectedClient.faAccountId = faAccountId;
            setOriginalFaAccountId(faAccountId)
            AppGlobals.alert('success', response.message)
        } else{
            AppGlobals.alert('danger', response.message)
        }
        setIsUpdatingToken(false);
    }

    async function handleSaveOfferLetter() {
        setIsSubmittingOfferLetter(true);
        const response = await updateOfferLetter(selectedClient.uid, offerLetter);
        if (response.status === 200) {
            setOriginalOfferLetter(offerLetter);
            AppGlobals.alert('success', response.message);
        } else {
            AppGlobals.alert('danger', response.message);
        }
        setIsSubmittingOfferLetter(false);
    }

    return (
        <LoadingWrapper isLoading={isLoading}>
            <Card>
                <Card.Header><Card.Title>Company Information</Card.Title></Card.Header>
                <Card.Body>
                    <ListGroup>
                        <KeyValueRow isListItem title='Name' value={selectedClient.name}/>
                        <KeyValueRow isListItem title='Email Address' value={selectedClient.email}/>
                        <KeyValueRow isListItem title='Address' value={addressToString(selectedClient.address)}/>
                        {!selectedClient.faAccountId 
                        ?  <InputGroup>
                                <CustomControl maxLength={50} floatingLabel title='First Advantage Account ID' value={faAccountId} setValue={setFaAccountId}/>
                                <CustomButton isLoading={isUpdatingToken} onClick={()=>setShowModal(true)} disabled={originalFaAccountId === faAccountId}>Save</CustomButton>
                            </InputGroup>
                        :   <KeyValueRow isListItem title='First Advantage Account ID' value={faAccountId}/>
                        }                   
                    </ListGroup>
                </Card.Body>
            </Card>
            <Card>
                <Card.Header>
                    <Card.Title>Default Rejection Email Message</Card.Title>
                </Card.Header>
                <Card.Body style={{overflowX: 'auto', padding: 6}}>
                    <div style={{margin: 'auto', width: 750, maxWidth: 750}}>
                        <div style={{paddingTop: 48, paddingBottom: 24, border: '1px solid lightgray'}}>
                            <img style={{width: '65%', marginBottom: 60, paddingLeft: 36, paddingRight: 36}} src={logo}/>
                            <h4 style={{fontWeight: 'bold', paddingLeft: 36, paddingRight: 36}}>Dear, [Candidate Name]</h4>
                            <div style={{paddingLeft: 36, paddingRight: 36, marginBottom: 30}}>
                                <Form.Control as='textarea' disabled={isSubmitting} style={{resize: 'none', marginBottom: 8, backgroundColor: originalRejectionMessage != rejectionMessage ? '#faffe3' : 'white'}} maxLength={300} rows={9} value={rejectionMessage} onChange={(e)=>setRejectionMessage(e.target.value)}/>
                                <CustomButton isLoading={isSubmitting} onClick={handleSaveChanges} >Save Changes</CustomButton>
                            </div>
                            <div style={{height: '1px', width: '100%', backgroundColor: 'lightgray', marginBottom: 20}}></div>
                            <h5 style={{fontWeight: 'bold', paddingLeft: 36, paddingRight: 36}}>Best,</h5>
                            <p style={{paddingLeft: 36, paddingRight: 36}}>The My Tactical Recruiter Team on behalf of {companyName}</p>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card>
                <Card.Header>
                    <Card.Title>Company Offer Letter</Card.Title>
                </Card.Header>
                <Card.Body style={{padding: 6}}>
                    <OfferLetterEditor value={offerLetter} setValue={setOfferLetter}/>
                </Card.Body>
                <Card.Footer style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <CustomButton onClick={handleSaveOfferLetter} isLoading={isSubmittingOfferLetter}>
                        {'Save Changes'}
                    </CustomButton>
                </Card.Footer>
            </Card>
            <Modal size='lg' show={showModal} onHide={()=>setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm FA Account ID</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <h6 style={{textAlign: 'center'}}>Warning: Once the First Advantage Account ID is submitted it can not be changed!</h6>
                        <h6 style={{textAlign: 'center'}}>Please ensure this FA ID is accurate before updating.</h6>
                        <p style={{textAlign: 'center'}}>{faAccountId}</p>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton variant='danger' isLoading={isUpdatingToken} onClick={handleUpdateFaAccountId}>Submit</CustomButton>
                </Modal.Footer>
            </Modal>
        </LoadingWrapper>
    )
    
}