/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

export const EmploymentTypes: {[key: string]: string} = {
    fulltime: 'Full-Time',
    parttime: 'Part-Time',
}

export const EmployeeTypes: {[key: string]: string} = {
    '0': 'Full-Time',
    '1': 'Part-Time',
}

export const PayTypes: {[key: string]: string} = {
    py: 'Per Year',
    pd: 'Per Day',
    ph: 'Per Hour',
    ps: 'Per Stop',
    pm: 'Per Mile',
    0: 'Per Hour',
    1: 'Per Year (non-exempt)',
    2: 'Per Year (exempt)',
}

export const BwcCodes: {[key: string]: string} = {
    '7231': '7231 (Driver)',
    '8810': '8810 (Admin)', 
    '7219': '7219 (Mechanic)', 
}

export const ShiftTypes: {[key: string]: string} = {
    weekdays: 'Weekdays',
    weekends: 'Weekends',
    both: 'Both'
}

export const ShiftDurations: {[key: string]: string} = {
    2: '2-Hour',
    4: '4-Hour',
    5: '5-Hour',
    6: '6-Hour',
    8: '8-Hour',
    10: '10-Hour',
    12: '12-Hour',
}

export const JobSites: {[key: string]: string} = {
    indeed: 'Indeed',
    monster: 'Monster',
    ziprecruiter: 'ZipRecruiter',
    flexjobs: 'FlexJobs'
}

export const EducationLevels: {[key: string]: string} = {
    highschool: 'High School',
    college: 'College',
    occupational: 'Occupational/Technical Program',
    other: 'Other'
}

export const DegreeLevels: {[key: string]: string} = {
    associate: 'Associate',
    bachelors: 'Bachelor\'s',
    masters: 'Master\'s',
    doctorate: 'Doctorate'
}

export const MilitaryBranches: {[key: string]: string} = {
    airForce: 'Air Force',
    army: 'Army',
    coastGuard: 'Coast Guard',
    marines: 'Marine Corps',
    nationalGuard: 'National Guard',
    navy: 'Navy',
    spaceForce: 'Space Force',
}

export const States: {[key: string]: string} = {
    AL: 'Alabama', 
    AK: 'Alaska', 
    AZ: 'Arizona', 
    AR: 'Arkansas', 
    CA: 'California', 
    CO: 'Colorado', 
    CT: 'Connecticut', 
    DE: 'Delaware', 
    DC: 'District of Columbia', 
    FL: 'Florida', 
    GA: 'Georgia', 
    HI: 'Hawaii', 
    ID: 'Idaho', 
    IL: 'Illinois', 
    IN: 'Indiana', 
    IA: 'Iowa', 
    KS: 'Kansas', 
    KY: 'Kentucky', 
    LA: 'Louisiana', 
    ME: 'Maine', 
    MD: 'Maryland', 
    MA: 'Massachusetts', 
    MI: 'Michigan', 
    MN: 'Minnesota', 
    MS: 'Mississippi', 
    MO: 'Missouri', 
    MT: 'Montana', 
    NE: 'Nebraska', 
    NV: 'Nevada', 
    NH: 'New Hampshire', 
    NJ: 'New Jersey', 
    NM: 'New Mexico', 
    NY: 'New York', 
    NC: 'North Carolina', 
    ND: 'North Dakota', 
    OH: 'Ohio', 
    OK: 'Oklahoma', 
    OR: 'Oregon', 
    PA: 'Pennsylvania', 
    RI: 'Rhode Island', 
    SC: 'South Carolina', 
    SD: 'South Dakota', 
    TN: 'Tennessee', 
    TX: 'Texas', 
    UT: 'Utah', 
    VT: 'Vermont', 
    VA: 'Virginia', 
    WA: 'Washington', 
    WV: 'West Virginia', 
    WI: 'Wisconsin', 
    WY: 'Wyoming',
}

export const CustomQuestionTypes: {[key: string]: string} = {
    toggle: 'Yes or No',
    picker: 'Pick 1 from Predefined Options',
    multiselect: 'Pick 1+ Predefined Options',
    text: 'Text Field',
    textArea: 'Large Text Field',
    number: 'Number',
    date: 'Date',
    knockout: 'Knockout Question',
}

export const ClientEmployeeTypes: {[key: string]: string} = {
    '0': 'Full-Time',
    '1': 'Part-Time'
}

export enum JSType {
    Undefined = 'undefined',
    Null = 'object',
    Boolean = 'boolean',
    Number = 'number',
    BigInt = 'bigint',
    String = 'string',
    Symbol = 'symbol',
    Function = 'function',
    Object = 'object',
}

export const PtoAccrualTypes: {[key: number]: string} = {
    0: 'Per 40 Hours',
    1: 'Per Pay Period'
}

export const TCTitles: {[key: string]: string} = {
    'Driver': 'Driver',
    'AVP Driver': 'AVP Driver',
    'Jumper': 'Jumper',
    'BC': 'BC',
    'Admin': 'Admin'
}

export const defaultOfferLetterTitles: {[key: string]: string} = {
    'Driver': 'defaultOfferLetterForDriver',
    'AVP Driver': 'defaultOfferLetterForAvpDriver',
    'Jumper': 'defaultOfferLetterForJumper',
    'BC': 'defaultOfferLetterForBc',
    'Admin': 'defaultOfferLetterForAdmin'
}

export const OnboardingDocuments: {[key: string]: string} = {
    'w4': 'W-4 (Employee\'s Withholding Certificate)',
    'i9': 'I-9 (Employment Eligibility Verification)',
    'f8850': 'Form 8850 (for Work Opportunity Credit)',
}

export const OnboardingDocumentsShort = {
    w4: 'W-4',
    i9: 'I-9',
    f8850: 'Form 8850',
}

export const DirectDepositDocuments: {[key: string]: string} = {
    dd: 'Direct Deposit Authorization',
    mdd: 'Multiple Direct Deposit Authorization'
}

export const taxDocumentDictionary: {[key: string]: string} = {
    'a4': 'A4 (Alabama)',
    'wh4': 'WH-4 (Indiana)',
    'k4': 'K-4 (Kentucky)',
    'l4': 'L-4 (Louisiana)',
    'miw4': 'MI-W4 (Michigan)',
    'it2104': 'IT-2104 (New York)',
    'it4': 'IT-4 (Ohio)',
    'rev419': 'REV-419 (Pennsylvania)',
    'va4': 'VA-4 (Virginia)'
}

export const OnboardingDocumentsReadOnly: {[key: string]: string} = {
    // 'w4': 'W-4 (Employee\'s Withholding Certificate)',
    // 'i9': 'I-9 (Employment Eligibility Verification)',
    // 'dd': 'Direct Deposit Authorization',
    // 'mdd': 'Multiple Direct Deposit Authorization',
    // 'ddAtmt': 'Direct Deposit Authorization Attachment',
    // 'f8850': 'Form 8850 (for Work Opportunity Credit)',
    'dl': 'Photo of Driver\'s License',
    'ssn': 'Photo of Social Security Card',
    'offerLetter': 'Offer Letter', 
    ...taxDocumentDictionary,
    ...DirectDepositDocuments,
    ...OnboardingDocuments
}

export const stateTaxDocumens: {[key: string]: string} = {
    'AL': 'a4',
    'IN': 'wh4',
    'KY': 'k4',
    'LA': 'l4',
    'MI': 'miw4',
    'NY': 'it2104',
    'OH': 'it4',
    'PA': 'rev419',
    'VA': 'va4'
}

export const TaxDocumentsShort = {
    a4: 'A4',
    wh4: 'WH-4',
    k4: 'K-4',
    l4: 'L-4',
    miw4: 'MI-W4',
    it2104: 'IT-2104',
    it4: 'IT-4',
    rev419: 'REV-419',
    va4: 'VA-4',
}