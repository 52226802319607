/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import moment from 'moment';
import { addressToString, availabilityFromBinaryString, formatPhoneNumber, formatTime, usdFormatter, validateInteger } from '../../../../tools';
import { EmployeeTypes, PayTypes } from '../../../../enums';
import AbsoluteText from "../AbsoluteText";


export default function OfferLetterPdf({document, userData, companyData, signature}) {

    const parseOfferLetter = (text) => {
        text = text.replaceAll('[CompanyName]', companyData.companyName ?? companyData.name);
        text = text.replaceAll('[CreatedDate]', moment(document.createdDate).format('MMM D, YYYY'));
        text = text.replaceAll('[EmployeeName]', `${userData.firstName} ${userData.lastName}`);
        text = text.replaceAll('[EmployeeType]', EmployeeTypes[document.employeeType]);
        text = text.replaceAll('[StartDate]', moment(document.startDate).format('MMM D, YYYY'));
        text = text.replaceAll('[SupervisorName]', document.supervisorName);
        text = text.replaceAll('[TerminalAddress]', addressToString(document.terminalAddress));
        text = text.replaceAll('[LocationAddress]', addressToString(document.terminalAddress));
        text = text.replaceAll('[StartTime]', formatTime(document.startTime));
        text = text.replaceAll('[Pay]', `${usdFormatter.format(document.payRate)} ${PayTypes[document.payType]}`);
        text = text.replaceAll('[StopBonusThresholds]', document.stopBonusWeekdayThreshold == document.stopBonusWeekendThreshold ? `${validateInteger(document.stopBonusWeekdayThreshold)} stops` : `${validateInteger(document.stopBonusWeekdayThreshold)}/${validateInteger(document.stopBonusWeekendThreshold)} (weekdays/weekends) stops`);
        text = text.replaceAll('[StopBonusAmounts]', document.stopBonusWeekdayAmount == document.stopBonusWeekendAmount ? usdFormatter.format(document.stopBonusWeekdayAmount) : `${usdFormatter.format(document.stopBonusWeekdayAmount)}/${usdFormatter.format(document.stopBonusWeekendAmount)} (weekdays/weekends)`);
        text = text.replaceAll('[ExpectedAvailability]', availabilityFromBinaryString(document.expectedAvailability));
        text = text.replaceAll('[DailyStartTime]', formatTime(document.dailyStartTime));
        text = text.replaceAll('[Benefits]', document.benefits ? document.benefits : 'There are currently no available benefits for this employer');
        text = text.replaceAll('[AOName]', document.aoName);
        text = text.replaceAll('[AOPhoneNumber]', formatPhoneNumber(document.aoPhoneNumber));
        text = text.replaceAll('[AOEmail]', document.aoEmail);
        text = text.replaceAll('[OfferLetterManagerName]', document.aoName);
        text = text.replaceAll('[OfferLetterManagerPhoneNumber]', formatPhoneNumber(document.aoPhoneNumber));
        text = text.replaceAll('[OfferLetterManagerEmail]', document.aoEmail);
        return text;
    }

    return (
        <Document>
            <Page size='LETTER' style={{padding: 50, position: 'relative'}}>
                <Text style={{fontSize: 11.5}}>{parseOfferLetter(document.content)}</Text>

                <Text style={{fontSize: 11.5, position: 'absolute', bottom: 120, left: 50, opacity: 0.65}}>Please sign and date to confirm receipt of this document.</Text>
                <View style={{position: 'absolute', left: 50, bottom: 50, display: 'flex', flexDirection: 'row', gap: 50, fontSize: 11.5}}>
                    <Text style={{borderTop: '1px solid black', paddingLeft: 12, paddingRight: 50, paddingTop: 4}}>Employee's Signature</Text>
                    <Text style={{borderTop: '1px solid black', paddingLeft: 12, paddingRight: 50, paddingTop: 4}}>Date</Text>
                </View>

                { signature && 
                    <>
                        <Image src={signature.image} style={{position: 'absolute', top: 680, left: 55, width: 118, height: 40}}/>
                        <AbsoluteText y={710} x={280} text={moment().format('MM/DD/YYYY')} fontSize={12}/>
                    </>
                }
            </Page>
        </Document>
    )
}