/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../decoding";
import StateObject from "../../../models/State/StateObject";


export default class ClientUser extends StateObject {
    uid: number;
    clientIdentifier: number;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;

    constructor(
        uid: number,
        clientIdentifier: number,
        firstName: string,
        lastName: string,
        phoneNumber: string,
        email: string,
    ) {
        super();
        this.uid = uid;
        this.clientIdentifier = clientIdentifier;
        this.firstName = firstName;
        this.lastName = lastName;
        this.phoneNumber = phoneNumber;
        this.email = email;
    }

    static initDefault(): ClientUser {
        return new ClientUser(-1, -1, '', '', '', '');
    }

    static decode(json: any): ClientUser {
        const decoder = new Decoder(json);
        const uid: number = decoder.decode('uid', Decoder.Uid);
        const clientIdentifier: number = decoder.decode('clientIdentifier', Decoder.Uid)
        const firstName: string = decoder.decode('firstName', Decoder.StringStrict);
        const lastName: string = decoder.decode('lastName', Decoder.StringStrict);
        const phoneNumber: string = decoder.decode('phoneNumber', Decoder.StringStrict);
        const email: string = decoder.decode('email', Decoder.StringStrict);

        if (decoder.checkForErrors()) {
            return new ClientUser(uid, clientIdentifier, firstName, lastName, phoneNumber, email);
        } else {
            return ClientUser.initDefault();
        }
    }

    encode(){
        return{
            uid: this.uid,
            clientIdentifier: this.clientIdentifier,
            firstName: this.firstName,
            lastName: this.lastName,
            phoneNumber: this.phoneNumber,
            email: this.email
        }
    }
}