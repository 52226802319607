/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react"
import { Placement } from "react-bootstrap/esm/types"
import CustomToolTip from "./CustomToolTip"

type JobStatusToolTipProps = {
    placement?: Placement,
    statuses: string[]
}

export default function JobStatusToolTip({placement, statuses}: JobStatusToolTipProps) {

    return (
        <CustomToolTip placement={placement} content={
            <div>
                { statuses.includes('active') &&
                    <>
                        <span style={{fontWeight: 'bold'}}>Active</span>
                        <ul style={{fontSize: 11}}>
                            <li>Posted and visible on all selected job search websites</li>
                            <li>Appears on Job Posts page under active jobs</li>
                            <li>Notifies recruiters of new applicants</li>
                        </ul>
                    </>
                }
                { statuses.includes('inactive') && 
                    <>
                        <span style={{fontWeight: 'bold'}}>Inactive</span>
                        <ul style={{fontSize: 11}}>
                            <li>Removed from all job search websites</li>
                            <li>Appears on Job Posts page under inactive jobs</li>
                            <li>Able to be permanently deleted</li>
                        </ul>
                    </>
                }
            </div>
        }/>
    )
}