/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Card, Dropdown, InputGroup, ListGroup, Modal } from "react-bootstrap";
import { AppGlobals } from "../../../App";
import LoadingWrapper from "../../../components/LoadingWrapper";
import SortableTable, { SortableTableCol, SortableTableRow } from "../../../components/SortableTable";
import { getAllApplications } from "../../../services/ClientUserService"
import { useEffect, useState } from "react";
import KeyValueRow from "../../../components/KeyValueRow";
import JobApplicationViewClient from "./JobApplicationViewClient";
import SearchBar from "../../../components/SearchBar";

export default function Applicants() {
    const [isLoading, setIsLoading] = useState(false);
    const [applicants, setApplicants] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedJobApplication, setSelectedJobApplication] = useState(null);

    const [search, setSearch] = useState('');
    const [searchType, setSearchType] = useState('Job Title');

    const [recruiters, setRecruiters] = useState([]);
    const [recuriterNameFilter, setRecruiterNameFilter] = useState([])
    const [terminals, setTerminals] = useState([]);
    const [terminalNameFilter, setTerminalNameFilter] = useState([])
    const [statusFilter, setStatusFilter] = useState(['Rejected', 'Hired', 'Interviewing', 'Passed BG Check & Drug Screen', 'Awaiting BG Check & Drug Screen']);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(()=>{
        setSearch('')
    }, [searchType])

    const loadData = async () => {
        setIsLoading(true);
        const response = await getAllApplications();
        if (response.status === 200) {
            setApplicants(response.jobApplications);
            setRecruiters([...new Set(response.jobApplications.flatMap(app=>app.companyName))])
            setRecruiterNameFilter([...new Set(response.jobApplications.flatMap(app=>app.companyName))])
            setTerminals([...new Set(response.jobApplications.flatMap(app=>app.terminal.name))])
            setTerminalNameFilter([...new Set(response.jobApplications.flatMap(app=>app.terminal.name))])
        } else {
            AppGlobals.alert('danger', response.message);
        }
        setIsLoading(false);
    }

    function handleSelectApplicant(app){
        setSelectedJobApplication(app);
        setShowModal(true);
    }

    ///////////////////////////////
    /// FILTER DROP DOWN ITEMS
    //////////////////////////////

    const recruiterFilterDropdownItems = recruiters.map((recruiter) => {
        return (
            <Dropdown.Item key={recruiter} active={recuriterNameFilter.includes(recruiter)} onClick={() => {
                if (recuriterNameFilter.includes(recruiter)) {
                    setRecruiterNameFilter(recuriterNameFilter.filter(r => r !== recruiter));
                } else {
                    setRecruiterNameFilter(recuriterNameFilter.concat([recruiter]));
                }
            }}>
                {recruiter}
            </Dropdown.Item>
        )
    })

    const terminalFilterDropdownItems = terminals.map((terminal) => {
        return (
            <Dropdown.Item key={terminal} active={terminalNameFilter.includes(terminal)} onClick={() => {
                if (terminalNameFilter.includes(terminal)) {
                    setTerminalNameFilter(terminalNameFilter.filter(t => t !== terminal));
                } else {
                    setTerminalNameFilter(terminalNameFilter.concat([terminal]));
                }
            }}>
                {terminal}
            </Dropdown.Item>
        )
    })

    const statusfilterDropdownItems = ['Rejected', 'Hired', 'Interviewing', 'Passed BG Check & Drug Screen', 'Awaiting BG Check & Drug Screen'].map((status) => {
        return (
            <Dropdown.Item key={status} active={statusFilter.includes(status)} onClick={() => {
                if (statusFilter.includes(status)) {
                    setStatusFilter(statusFilter.filter(s => s !== status));
                } else {
                    setStatusFilter(statusFilter.concat([status]));
                }
            }}>
                {status}
            </Dropdown.Item>
        )
    })

    ///////////////////////////////
    ///ROWS/COLUMNS SORTABLE TABLE
    //////////////////////////////

    const columns = [
        new SortableTableCol('name', 'Name', null, SortableTableCol.sortAsc),
        new SortableTableCol('companyName', 'Recruiter', null, SortableTableCol.sortAsc),
        new SortableTableCol('jobPostTitle', 'Job Title', null, SortableTableCol.sortAsc),
        new SortableTableCol('terminal', 'Terminal', null, SortableTableCol.sortAsc),
        new SortableTableCol('status', 'Status', null, SortableTableCol.sortAsc),
    ]

    const applicantElementsDesktop = applicants.filter(app=>{
        const status = app.rejected ? 'Rejected' 
            : app.hired ? 'Hired' 
            : app.interviewCount > 0 ? 'Interviewing' 
            : app.passedDrugScreen && app.passedBackground ? 'Passed BG Check & Drug Screen' 
            : 'Awaiting BG Check & Drug Screen'
        ;

         /// Search Filter
         switch (searchType){
            case 'Job Title':
                if(!app.jobPostTitle.toLowerCase().includes(search.toLowerCase()))
                    return false;
                break;
            case 'Applicant Name':
                const name = `${app.firstName} ${app.lastName}`;
                if(!name.toLowerCase().includes(search.toLowerCase()))
                    return false;
                break;
            default:
                break;
         }

         return recuriterNameFilter.includes(app.companyName)
         && terminalNameFilter.includes(app.terminal.name)
         && statusFilter.includes(status);
    }).map((app) => {
        const status = app.rejected ? 'Rejected' 
            : app.hired ? 'Hired' 
            : app.interviewCount > 0 ? 'Interviewing' 
            : app.passedDrugScreen && app.passedBackground ? 'Passed BG Check & Drug Screen' 
            : 'Awaiting BG Check & Drug Screen'
        ;
            return new SortableTableRow({
                name: `${app.firstName} ${app.lastName}`, 
                companyName: app.companyName, 
                jobPostTitle: app.jobPostTitle, 
                terminal: app.terminal.name, 
                status: app.rejected ? 5 : app.hired ? 4 : app.interviewCount > 0 ? 3 : app.passedDrugScreen && app.passedBackground ? 2 : 1
            },
            <tr className="cursor-pointer" key={app.uid} onClick={()=>handleSelectApplicant(app)}>
                <td>{`${app.firstName} ${app.lastName}`}</td>
                <td>{app.companyName}</td>
                <td>{app.jobPostTitle}</td>
                <td>{app.terminal.name}</td>
                <td>{status}</td>
            </tr>
        )
    })

    ///////////////////////////////
    /// LIST GROUP ITEMS
    //////////////////////////////

    const applicantElementsMobile = applicants.filter(app=>{
        const status = app.rejected ? 'Rejected' 
            : app.hired ? 'Hired' 
            : app.interviewCount > 0 ? 'Interviewing' 
            : app.passedDrugScreen && app.passedBackground ? 'Passed BG Check & Drug Screen' 
            : 'Awaiting BG Check & Drug Screen'
        ;

        /// Search Filter
        switch (searchType){
           case 'Job Title':
               if(!app.jobPostTitle.toLowerCase().includes(search.toLowerCase()))
                   return false;
               break;
           case 'Applicant Name':
               const name = `${app.firstName} ${app.lastName}`;
               if(!name.toLowerCase().includes(search.toLowerCase()))
                   return false;
               break;
           default:
               break;
        }

        return recuriterNameFilter.includes(app.companyName)
        && terminalNameFilter.includes(app.terminal.name)
        && statusFilter.includes(status);
    }).map((app) => {
        const status = app.rejected ? 'Rejected' 
            : app.hired ? 'Hired' 
            : app.interviewCount > 0 ? 'Interviewing' 
            : app.passedDrugScreen && app.passedBackground ? 'Passed BG Check & Drug Screen' 
            : 'Awaiting BG Check & Drug Screen'
        ;

        return (
            <ListGroup.Item action className="cursor-pointer" key={app.uid} onClick={()=>handleSelectApplicant(app)}>
                <div style={{ display: 'flex', flexDirection: 'column', padding: 8 }}>
                    <h4 style={{ fontWeight: 'bold', marginBottom: 12 }}>{`${app.firstName} ${app.lastName}`}</h4>
                    <h6 style={{ marginBottom: 6 }}><strong>Job Title: </strong>{app.jobPostTitle}</h6>
                    <h6 style={{ marginBottom: 6 }}><strong>Terminal: </strong> {app.terminal.name}</h6>
                    <h6 style={{ marginBottom: 6 }}><strong>Status: </strong> {status}</h6>
                    <p style={{ textAlign: 'right', margin: 0, fontSize: 14 }}><strong>Recruiter: </strong>{app.companyName}</p>
                </div>
            </ListGroup.Item>
        )
    })

    return (
        <LoadingWrapper isLoading={isLoading}>
            <div style={{padding: 12, display: 'flex', flexDirection: 'column', gap: 12}}>
                <Card>
                    <Card.Body>
                    <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', gap: 8, marginBottom: 12}}>
                            <Dropdown autoClose='outside'>
                                <Dropdown.Toggle style={{minWidth: 240}} variant='outline-primary'>Filter By Recruiter</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {recruiterFilterDropdownItems}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown autoClose='outside'>
                                <Dropdown.Toggle style={{minWidth: 240}} variant='outline-primary'>Filter By Terminal</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {terminalFilterDropdownItems}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown autoClose='outside'>
                                <Dropdown.Toggle style={{minWidth: 240}} variant='outline-primary'>Filter By Status</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {statusfilterDropdownItems}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div style={{marginBottom: 16}}>
                            <SearchBar 
                                typeValues={['Job Title', 'Applicant Name']} 
                                typeNames={['Job Title', 'Applicant Name']} 
                                label={`Search by ${searchType === 'Job Title' ? 'job title' : 'applicant name'}`}
                                type={searchType}
                                setType={setSearchType}
                                value={search} 
                                setValue={(value) => setSearch(value)}
                            />
                        </div>
                        <div className="desktop-block">
                            <SortableTable hover columns={columns} rows={applicantElementsDesktop}/>
                        </div>
                        <ListGroup className="mobile-block">
                            {applicantElementsMobile}
                        </ListGroup>
                    </Card.Body>
                </Card>
            </div>
            <Modal size="xl" show={showModal} onHide={()=>setShowModal(false)}>
                <JobApplicationViewClient selectedJobApplication={selectedJobApplication}/>
            </Modal>
        </LoadingWrapper>
    )
}