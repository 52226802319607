/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { DraftInlineStyleType, EditorState, RawDraftContentBlock, RawDraftContentState, RawDraftInlineStyleRange, convertFromRaw, convertToRaw } from "draft-js";
import Decoder from "../decoding";

export default class RichText extends EditorState {

    static decode(json: any): EditorState {
        const decoder = new Decoder(json);
        const blocks: RawDraftContentBlock[] = (decoder.decode('blocks', Decoder.Array) as any[] ?? []).map(b => RichText.decodeRawDraftContentBlock(b));
        
        if (decoder.checkForErrors()) {
            return EditorState.createWithContent(convertFromRaw({entityMap: {}, blocks: blocks} as RawDraftContentState));
        } else {
            return EditorState.createEmpty();
        }
    }

    static decodeRawDraftContentBlock(json: any): RawDraftContentBlock {
        const decoder = new Decoder(json);
        const text: string = decoder.decode('text', Decoder.StringStrict);
        const type: string = decoder.decode('type', Decoder.StringStrict);
        const inlineStyleRanges: RawDraftInlineStyleRange[] = (decoder.decode('inlineStyleRanges', Decoder.Array) as any[] ?? []).map(b => RichText.decodeRawDraftInlineStyleRange(b));
        
        if (decoder.checkForErrors()) {
            return {
                text: text,
                type: type,
                inlineStyleRanges: inlineStyleRanges
            } as RawDraftContentBlock;
        } else {
            return {
                text: '',
                type: '',
                inlineStyleRanges: [],
                key: '',
                depth: 0,
                entityRanges: [],
            } as RawDraftContentBlock;
        }
    }

    static decodeRawDraftInlineStyleRange(json: any): RawDraftInlineStyleRange {
        const decoder = new Decoder(json);
        const offset: number = decoder.decode('offset', Decoder.Integer);
        const length: number = decoder.decode('length', Decoder.Integer);
        const style: DraftInlineStyleType = decoder.decode('style', Decoder.StringStrict);
        if (decoder.checkForErrors()) {
            return {
                offset: offset,
                length: length,
                style: style
            };
        } else {
            return {
                offset: 0,
                length: 0,
                style: 'BOLD'
            }
        }
    }

    static encode(editorState: EditorState): {[key: string]: any} {
        const json = convertToRaw(editorState.getCurrentContent());
        return {
            blocks: json.blocks.map((block) => {
                return {
                    text: block.text,
                    type: block.type,
                    inlineStyleRanges: block.inlineStyleRanges
                }
            })
        }
    }
}