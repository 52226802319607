/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import StateObject from "../../../../models/State/StateObject"

export default class OfferLetter extends StateObject {
    signed = false;
    signedDate = '';
    completed = false;

    constructor() {
        super();
    }

    static initDefault() {
        return new OfferLetter();
    }

    static initFromData() {
        return new OfferLetter(); 
    }

    encode(document, base64) {
        return {
            ...document,
            base64: base64
        }
    }
}
