/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import { Validation } from "../../../validation";
import CustomQuestion from "../../../models/CustomQuestion";
import StateObject from "../../../models/State/StateObject";
import Decoder from "../../../decoding";
import { JobApplicationMinimal } from "../../JobApplication/models/JobApplicationMinimal";
import { validateDecimal, validateDecimalFixed, validateInteger } from "../../../tools";
import Terminal from "../../../models/Terminal";
import JobPostTerminal from "../../JobCreator/models/JobPostTerminal";
import TerminalForApplication from "../../JobApplication/models/TerminalForApplication";

export default class JobPostMinimal extends StateObject {
    uid: number;
    companyIdentifier: number;
    dateCreated: string;
    dateLastModified: string;
    title: string;
    employmentType: string;
    shiftType: string;
    shiftDurations: string[];
    compensationMin: number;
    compensationMax: number;
    payType: string;
    expirationDate: string | undefined;
    terminals: TerminalForApplication[];

    constructor(
        uid: number,
        companyIdentifier: number,
        dateCreated: string,
        dateLastModified: string,
        title: string,
        employmentType: string,
        shiftType: string,
        shiftDurations: string[],
        compensationMin: number,
        compensationMax: number,
        payType: string,
        expirationDate: string | undefined,
        terminals: TerminalForApplication[],

    ) {
        super();
        this.uid = uid;
        this.companyIdentifier = companyIdentifier;
        this.dateCreated = dateCreated;
        this.dateLastModified = dateLastModified;
        this.title = title;
        this.employmentType = employmentType;
        this.shiftType = shiftType;
        this.shiftDurations = shiftDurations;
        this.compensationMin = compensationMin;
        this.compensationMax = compensationMax;
        this.payType = payType;
        this.shiftDurations = shiftDurations;
        this.expirationDate = expirationDate;
        this.terminals = terminals
        this.initChildren();
    }

    static initDefault(): JobPostMinimal {
        return new JobPostMinimal(
            -1,
            -1,
            moment().format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD'),
            '',
            'fulltime',
            'weekdays', 
            [],
            10.0, 
            20.0,
            'ph',
            '',
            [],
        );
    }

    static decode(json: {[key: string]: any}): JobPostMinimal {
        const decoder = new Decoder(json);
        const uid: number = decoder.decode('uid', Decoder.Uid);
        const companyIdentifier: number = decoder.decode('companyIdentifier', Decoder.Uid);
        const dateCreated: string = decoder.decode('dateCreated', Decoder.Date);
        const dateLastModified: string = decoder.decode('dateLastModified', Decoder.Date);
        const title: string = decoder.decode('title', Decoder.NonEmptyString);
        const employmentType: string = decoder.decode('employmentType', Decoder.NonEmptyString);
        const shiftType: string = decoder.decode('shiftType', Decoder.NonEmptyString);
        const shiftDurations: string[] = decoder.decode('shiftDurations', Decoder.NonEmptyArrayFromString);
        const compensationMin: number = decoder.decode('compensationMin', Decoder.Decimal);
        const compensationMax: number = decoder.decode('compensationMax', Decoder.Decimal);
        const payType: string = decoder.decode('payType', Decoder.NonEmptyString);
        const expirationDate: string = decoder.decode('expirationDate', Decoder.Date, {defaultValue: '', warn: false});
        const terminals: TerminalForApplication[] = (decoder.decode('terminals', Decoder.Array)as any[] ?? []).map(t=>TerminalForApplication.decode(t));
        if (decoder.checkForErrors()) {
            return new JobPostMinimal(uid, companyIdentifier, dateCreated, dateLastModified, title, employmentType, 
                shiftType, shiftDurations, compensationMin, compensationMax, payType, expirationDate, terminals);
        } else {
            return JobPostMinimal.initDefault();
        }
    }
}