/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import APIResponse from "./APIResponse";
import { AppGlobals } from "../App";
import developmentMode from '../developmentMode.js';

export const APIURL = developmentMode ? "http://192.168.1.52/mtr/public/" : "https://api.mytacticalrecruiting.com";

export function getToken() {
    return AppGlobals.CookieMonster.get('token');
}

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
}
function getRequest(data) {
    return {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
    }
}

export function wait(ms = 1000) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export async function getResponse(data) {

    if (developmentMode) {
        console.log(data);
    }

    try {
        const response = await fetch(APIURL, getRequest(data));
        const responseObject = await response.json();
        responseObject.status = response.status;
        if (developmentMode) {
            console.log(responseObject);
        }
        return responseObject;
    } catch (error) {
        console.log(error);
        return {status: 500, message: developmentMode ? error.message : 'Something went wrong. Please contact IT.'};
    }
}


