/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from 'moment'
import IT2104Page1 from '../../../../../assets/it2104-1.png';
import Signature from '../../../Signature/Signature'
import AbsoluteText from '../../AbsoluteText'
import { Document, Image, Page, Text } from '@react-pdf/renderer'
import { formatEin, formatSsn, usdFormatter, validateDecimal } from '../../../../../tools'
import IT2104 from './IT2104'

type IT2104PdfProps = {
    it2104: IT2104
    signature: Signature
    isEmployer: boolean
}

export default function IT2104Pdf({it2104, signature, isEmployer}: IT2104PdfProps) {

    return (
        <Document>
            <Page size='LETTER' style={{fontSize: 10}}>
                <Image src={isEmployer ? it2104.employeeSubmission[0] ?? IT2104Page1 : IT2104Page1}/>
                {!isEmployer ?
                    <>
                        <AbsoluteText y={94} x={40} text={it2104.firstNameAndMiddleInitial}/>
                        <AbsoluteText y={94} x={254} text={it2104.lastName}/>
                        <AbsoluteText y={94} x={440} text={formatSsn(it2104.ssn)}/>
                        
                        <AbsoluteText y={118} x={40} text={it2104.homeAddress}/>
                        <AbsoluteText y={118} x={335} text={it2104.apartmentNumber}/>
                        
                        <AbsoluteText y={141} x={40} text={it2104.city}/>
                        <AbsoluteText y={141} x={270} text={it2104.state}/>
                        <AbsoluteText y={141} x={365} text={it2104.zipCode}/>
                        
                        <AbsoluteText y={107} x={517} text={it2104.singleOrMarried == 0 ? 'X' : ''} fontSize={12}/>
                        <AbsoluteText y={107} x={565} text={it2104.singleOrMarried == 1 ? 'X' : ''} fontSize={12}/>
                        <AbsoluteText y={119} x={565} text={it2104.singleOrMarried == 2 ? 'X' : ''} fontSize={12}/>
                        
                        <AbsoluteText y={155} x={528} text={it2104.residentOfNewYork == 0 ? 'X' : ''} fontSize={12}/>
                        <AbsoluteText y={155} x={567} text={it2104.residentOfNewYork == 1 ? 'X' : ''} fontSize={12}/>
                        
                        <AbsoluteText y={166} x={528} text={it2104.residentOfYonkers == 0 ? 'X' : ''} fontSize={12}/>
                        <AbsoluteText y={166} x={567} text={it2104.residentOfYonkers == 1 ? 'X' : ''} fontSize={12}/>
                        
                        <AbsoluteText y={190} x={536} text={it2104.totalNumberAllowancesState}/>
                        <AbsoluteText y={202} x={536} text={it2104.totalNumberAllowancesCity}/>
                        <AbsoluteText y={238} x={513} text={it2104.stateAdditional ? usdFormatter.format(it2104.stateAdditional) : ''}/>
                        <AbsoluteText y={250} x={513} text={it2104.cityAdditional ? usdFormatter.format(it2104.cityAdditional) : ''}/>
                        <AbsoluteText y={262} x={513} text={it2104.yonkersAdditional ? usdFormatter.format(it2104.yonkersAdditional) : ''}/>
                        
                        <Image src={signature.image} style={{position: 'absolute', top: 322, left: 130, width: 100, height: 18}}/>
                        <AbsoluteText y={326} x={470} text={moment().format('MM/DD/YYYY')} fontSize={13}/>
                    </>
                :
                    <>
                        <AbsoluteText y={449} x={396} text={it2104.employeeMoreThan14Exemptions ? 'X' : ''} fontSize={14}/>
                        <AbsoluteText y={467} x={185} text={it2104.employeeNewOrRehire ? 'X' : ''} fontSize={14}/>
                        <AbsoluteText y={469} x={507} text={it2104.firstDateWorked ? moment(it2104.firstDateWorked).format('MM/DD/YYYY') : ''}/>

                        <AbsoluteText y={529} x={390} text={it2104.healthBenefitsAvailable == 0 ? 'X' : ''} fontSize={14}/>
                        <AbsoluteText y={529} x={452} text={it2104.healthBenefitsAvailable == 1 ? 'X' : ''} fontSize={14}/>
                        <AbsoluteText y={549} x={320} text={it2104.dateQualified ? moment(it2104.dateQualified).format('MM/DD/YYYY') : ''}/>

                        <AbsoluteText y={590} x={40} text={it2104.companyNameAndAddress} fontSize={8}/>
                        <AbsoluteText y={590} x={472} text={formatEin(it2104.ein)} fontSize={12}/>
                    </>
                }
            </Page>
        </Document>
    )
}