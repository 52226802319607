/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {PropsWithChildren} from 'react';
import { Breakpoints } from '../tools';
import { Col } from 'react-bootstrap';

type OptionalColProps = {
    breakpoints?: Breakpoints
}

export default function OptionalCol({breakpoints, children}: PropsWithChildren<OptionalColProps>) {
    if (breakpoints) {
        return (
            <Col style={{marginBottom: 6, position: 'relative'}} xs={breakpoints.xs} sm={breakpoints.sm} md={breakpoints.md} lg={breakpoints.lg} xl={breakpoints.xl} xxl={breakpoints.xxl}>
                {children}
            </Col>
        )
    } else {
        return (
            <>
                {children}
            </>
        )
    }
    
}