/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useReducer, useState } from 'react';
import Form from 'react-bootstrap/Form';
import OptionalCol from '../OptionalCol';
import ValidationFeedback from '../ValidationFeedback';


export default function CustomTextArea({title, style, value, setValue, rows, originalValue, max, breakpoints, disabled, stateProps}) {
    const [showValidation, setShowValidation] = useState(false);
    const [_, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        stateProps?.subscribe((showValidation) => {
            if (showValidation) {
                setShowValidation(true);
            }
            forceUpdate();
        });
        
    }, [stateProps]);

    const handleSetValue = (value) => {
        stateProps?.setValue(value);
        if (setValue) {
            setValue(value);
        }

    }

    const changedFromOriginal = () => {
        return originalValue !== undefined && originalValue !== null && (stateProps ? stateProps.stateObject[stateProps.stateKey] != originalValue : value != originalValue);
    }

    return (
        <OptionalCol breakpoints={breakpoints}>
            <Form.Group style={{position: 'relative'}}>
                {title && <Form.Label>{title}<span style={{color: 'red'}}>{(stateProps?.hasValidation()) ? '*' : ''}</span></Form.Label>}
                <Form.Control 
                    as='textarea' 
                    maxLength={max} 
                    disabled={disabled} 
                    rows={rows} 
                    value={stateProps?.getValue() ?? value ?? ''} 
                    onChange={(event) => {handleSetValue(event.target.value)}} 
                    onBlur={() => setShowValidation(true)} 
                    isInvalid={showValidation ? stateProps?.getIsInvalid() : undefined} 
                    isValid={showValidation ? stateProps?.getIsValid() : undefined} 
                    style={changedFromOriginal() ? {backgroundColor: '#faffe3', ...style} : style}
                />
                <ValidationFeedback stateProps={stateProps} value={value} maxLength={max} showValidation={showValidation}/>
            </Form.Group>
        </OptionalCol>
    )
    
}