/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import L4Page1 from '../../../../../assets/L-4-1.png';
import {Document, Page, Image, } from '@react-pdf/renderer';
import moment from 'moment';
import Signature from "../../../Signature/Signature"
import L4 from "./L4"
import AbsoluteText from '../../AbsoluteText';
import { formatSsn, usdFormatter, validateDecimal } from '../../../../../tools';

type L4PdfProps = {
    l4: L4,
    signature: Signature
    isEmployer: boolean
}

export default function L4Pdf({l4, signature, isEmployer}: L4PdfProps) {
    return (
        <Document>
            <Page size='LETTER' style={{fontSize: 10}}>
                <Image src={isEmployer ? l4.employeeSubmission[0] ?? L4Page1 : L4Page1}/>
                {!isEmployer ?
                    <>
                        <AbsoluteText y={295} x={489} text={l4.blockA} fontSize={18}/>
                        <AbsoluteText y={353} x={489} text={l4.blockB} fontSize={18}/>

                        <AbsoluteText y={487} x={100} text={l4.firstNameAndMiddleInitial}/>
                        <AbsoluteText y={487} x={370} text={l4.lastName}/>
                        <AbsoluteText y={513} x={100} text={formatSsn(l4.ssn)}/>

                        <AbsoluteText y={501} x={233.5} text={l4.singleMarriedOrNone === 0 ? 'X' : ''} fontSize={11}/>
                        <AbsoluteText y={501} x={414} text={l4.singleMarriedOrNone === 1 ? 'X' : ''} fontSize={11}/>
                        <AbsoluteText y={501} x={477} text={l4.singleMarriedOrNone === 2 ? 'X' : ''} fontSize={11}/>

                        <AbsoluteText y={538} x={95} text={l4.homeAddress}/>
                        <AbsoluteText y={564} x={95} text={l4.cityStateZipCode}/>
                        <AbsoluteText y={582} x={465} text={l4.blockA} fontSize={15}/>
                        <AbsoluteText y={607} x={465} text={l4.blockB} fontSize={15}/>
                        <AbsoluteText y={636} x={440} text={l4.additionalWitholdings ? usdFormatter.format(l4.additionalWitholdings) : ''}/>

                        <Image src={signature.image} style={{position: 'absolute', top: 674, left: 165, width: 75, height: 18}}/>
                        <AbsoluteText y={679} x={453} text={moment().format('MM/DD/YYYY')}/>
                    </>
                :
                    <>
                        <AbsoluteText x={73} y={727} fontSize={6} text={l4.companyNameAndAddress} />
                        <AbsoluteText x={345} y={727} text={l4.stateWitholdingAccountNumber} /> 
                    </>
                }
            </Page>
        </Document>
    )
}