/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../decoding";
import Company from "./Company";
import StateObject from "./State/StateObject";

export default class User extends StateObject {
    uid: number;
    company: Company;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    isEnabled: boolean;

    constructor(
        uid: number,
        company: Company,
        firstName: string,
        lastName: string,
        phoneNumber: string,
        email: string,
        isEnabled: boolean,
    ) {
        super();
        this.uid = uid;
        this.company = company;
        this.firstName = firstName;
        this.lastName = lastName;
        this.phoneNumber = phoneNumber;
        this.email = email;
        this.isEnabled = isEnabled;
    }

    static initDefault(): User {
        return new User(-1, Company.initDefault(), 'ERR', 'ERR', '', '', true);
    }

    static decode(json: any): User {
        const decoder = new Decoder(json);
        const uid: number = decoder.decode('uid', Decoder.Uid);
        const company: Company = Company.decode(json.company);
        const firstName: string = decoder.decode('firstName', Decoder.StringStrict);
        const lastName: string = decoder.decode('lastName', Decoder.StringStrict);
        const phoneNumber: string = decoder.decode('phoneNumber', Decoder.StringStrict);
        const email: string = decoder.decode('email', Decoder.StringStrict);
        const isEnabled: boolean = decoder.decode('isEnabled', Decoder.Boolean);

        if (decoder.checkForErrors()) {
            return new User(uid, company, firstName, lastName, phoneNumber, email, isEnabled);
        } else {
            return User.initDefault();
        }
    }  
}