/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import REV419Page1 from '../../../../../assets/REV-419-1.png';
import REV419Page2 from '../../../../../assets/REV-419-2.png';
import REV419Page3 from '../../../../../assets/REV-419-3.png';
import { PDFViewer, Document, Page, Image, View, PDFDownloadLink } from '@react-pdf/renderer';
import Address from '../../../../../models/Address';
import { addressToString, formatEin, formatPhoneNumber, formatSsn, validateDecimal, validateInteger } from '../../../../../tools';
import moment from 'moment';
import AbsoluteText from '../../AbsoluteText';
import REV419 from './REV419';
import { Modal } from 'react-bootstrap';
import Signature from '../../../Signature/Signature';

type REV419PdfProps = {
    rev419: REV419,
    signature: Signature
    isEmployer: boolean
}

export default function REV419Pdf({rev419, signature, isEmployer}: REV419PdfProps) {

    return (
        <Document>
            <Page size='LETTER' style={{fontSize: 10}}>
                <Image src={isEmployer ? rev419.employeeSubmission[0] ?? REV419Page1 : REV419Page1}/>
                {!isEmployer ?
                        //Employee
                    <>
                        <AbsoluteText y={70} x={496} text={rev419.yearLast2} fontSize={17}/>
                        <AbsoluteText y={154} x={57} text={rev419.name}/>
                        <AbsoluteText y={154} x={400} text={formatSsn(rev419.ssn)}/>
                        <AbsoluteText y={154} x={485} text={formatPhoneNumber(rev419.phoneNumber)}/>
                        <AbsoluteText y={178} x={57} text={rev419.streetAddress}/>
                        <AbsoluteText y={178} x={212} text={rev419.city}/>
                        <AbsoluteText y={178} x={310} text={rev419.state}/>
                        <AbsoluteText y={178} x={350} text={rev419.zipCode}/>
                        <AbsoluteText y={178} x={400} text={rev419.taxYear}/>
                        { rev419.section2a &&
                            <div style={{height: 6, width: 15, borderRadius: '100%', backgroundColor: 'black', position: 'absolute', top: 231, left: 68}}></div>
                        }
                        { rev419.section2b &&
                        <>
                            <div style={{height: 6, width: 15, borderRadius: '100%', backgroundColor: 'black', position: 'absolute', top: 260, left: 68}}></div>

                            { rev419.reciprocalState && <div style={{height: 6, width: 15, borderRadius: '100%', backgroundColor: 'black', position: 'absolute', 
                            top: 268.5,
                            left: rev419.reciprocalState == 0 ? 98 : rev419.reciprocalState == 1 ? 172 : rev419.reciprocalState == 2 ? 248 
                            : rev419.reciprocalState == 3 ? 333 : rev419.reciprocalState == 4 ? 397 : rev419.reciprocalState == 5 ? 467 : undefined
                        }}></div>}
                        </>
                        }
                        { rev419.section2c &&
                            <div style={{height: 6, width: 15, borderRadius: '100%', backgroundColor: 'black', position: 'absolute', top: 299, left: 68}}></div>
                        }
                        <AbsoluteText y={294} x={242} text={rev419.residentOfState} fontSize={8}/>
                        <Image src={signature.image} style={{position: 'absolute', top: 365, left: 140, width: 50, height: 15}}/>
                        <AbsoluteText y={369} x={497} text={moment().format('MM/DD/YYYY')}/>
                     </>
                :
                        //Employer
                    <>
                        <AbsoluteText y={395} x={55} text={rev419.employerName}/>
                        <AbsoluteText y={395} x={397} text={formatEin(rev419.ein)}/>

                        <AbsoluteText y={420} x={55} text={rev419.employerAddress}/>
                        <AbsoluteText y={420} x={463} text={formatPhoneNumber(rev419.employerPhoneNumber)}/>

                        <AbsoluteText y={444} x={55} text={rev419.employerCity}/>
                        <AbsoluteText y={444} x={435} text={rev419.employerState}/>
                        <AbsoluteText y={444} x={480} text={rev419.employerZipCode}/>

                        <Image src={signature.image} style={{position: 'absolute', top: 461, left: 130, width: 50, height: 15}}/>       
                        <AbsoluteText y={466} x={275} text={rev419.employeeCompensation}/>
                    </>
                }
            </Page>
            <Page size='LETTER' style={{fontSize: 10}}>
                <Image src={REV419Page3}/>
            </Page>
        </Document>
    )
}