/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import OptionalCol from './OptionalCol';
import { Col, Container, Row } from 'react-bootstrap';

export default function FormGroup({breakpoints, title, children}) {


    return (
        <OptionalCol breakpoints={breakpoints}>
            <Container fluid style={{border: '1px solid lightgray', borderRadius: 6, padding: 12}}>
                { title &&
                    <Row>
                        <Col style={{paddingBottom: 4}}>
                            <span style={{fontSize: 14, opacity: 0.65}}>{title}</span>
                        </Col>
                    </Row>
                }
                <Row>
                    {children}
                </Row>
            </Container>
        </OptionalCol>
    )
}