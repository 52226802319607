/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../decoding";
import Address from "../../../models/Address";
import StateObject from "../../../models/State/StateObject";


export default class Client extends StateObject {
    uid: number;
    ein: number;
    name: string;
    email: string;
    tcIdentifier: number | null;
    address: Address | undefined;
    faAccountId: string;

    constructor(uid: number, ein: number, name: string, email: string, tcIdentifier: number | null, address: Address | undefined, faAccountId: string) {
        super();
        this.uid = uid;
        this.ein = ein;
        this.name = name;
        this.email = email;
        this.tcIdentifier = tcIdentifier;
        this.address = address;
        this.faAccountId = faAccountId;
        this.initChildren();
    }

    static initDefault(): Client {
        return new Client(
            -1,
            0,
            '',
            '',
            null,
            undefined,
            '',
        )
    }

    encode(){
        return {
            uid: this.uid,
            ein: this.ein,
            name: this.name,
            email: this.email,
            tcIdentifier: this.tcIdentifier,
            address: this.address?.encode(),
            faAccountId: this.faAccountId ? this.faAccountId : null
        }
    }

}