/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';


type QuickTableProps = {
    title?: string;
    headers: QuickTableHeader[];
    defaultSort?: [index: number, asc: boolean];
    rows: QuickTableRow[];
    size?: string;
    hover?: boolean;
    fontSize?: number;
    responsive?: boolean;
    bordered?: boolean;
    striped?: boolean; 
    activeIndex?: number;
}


export type QuickTableHeader = {
    label: string;
    sortable?: boolean;
    width?: number
}

export type QuickTableCol = {
    value: any;
    sortMethod?: (a: any, b: any, ascending: boolean) => number;
    content?: React.ReactNode;
}

export type QuickTableRow = {
    columns: QuickTableCol[]
    onClick?: () => void 
}

export default function QuickTable({title, headers, defaultSort, rows, size, hover, responsive = true, bordered = true, striped = true, activeIndex}: QuickTableProps) {
    const [sortOrder, setSortOrder] = useState(defaultSort ? defaultSort : [0, true] as [index: number, asc: boolean])


    const handleSort = (index: number) => {
        if (sortOrder[0] === index) {
            setSortOrder([index, !sortOrder[1]]);
        } else {
            setSortOrder([index, true]);
        }
    }

    const rowsSorted = Array.from(rows).sort((a, b) => {
        const sortMethod = a.columns[sortOrder[0]].sortMethod;
        if (sortMethod) {
            return sortMethod(a.columns[sortOrder[0]].value, b.columns[sortOrder[0]].value, sortOrder[1]);
        } else {
            return 0;
        }
    })



    const headerElements = headers.map((h, index) => {
        return (
            <th onClick={() => {handleSort(index)}} className='cursor-pointer' key={index} style={{width: h.width, padding: 0}}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 8, marginTop: -1, marginBottom: -1, borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray'}}>
                    {h.label}
                    { h.sortable &&
                        <FontAwesomeIcon icon={index === sortOrder[0] ? sortOrder[1] ? faSortUp : faSortDown : faSort}/>
                    }
                </div>
            </th>
        )
    });
    const rowElements = rowsSorted.map((r, index) => {

        const colElements = r.columns.map((c, cIndex) => {
            return (
                <td style={activeIndex === index ? {backgroundColor: 'var(--bs-primary)', color: 'white'} : {}} key={cIndex}>{c.content ?? c.value}</td>
            )
        })
        return (
            <tr style={{verticalAlign: 'middle'}} className={r.onClick ? 'cursor-pointer' : ''}  onClick={r.onClick} key={index}>
                {colElements}
            </tr>
        )
    })

    return (
        <div style={{flex: 1}}>
            <Table responsive={responsive} bordered={bordered} striped={striped} hover={hover} size={size} style={{position: 'relative'}}>
                <thead style={{position: 'sticky', top: 0}}>
                    { title ?
                        <tr>
                            <th colSpan={headers.length} style={{textAlign: 'center'}}>{title}</th>
                        </tr>

                    : ''}
                    <tr>
                        {headerElements}
                    </tr>
                </thead>
                <tbody>
                    {rowElements}
                </tbody>
            </Table>
        </div>
    )
}