/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useEffect, useReducer, useState} from 'react';
import Form from 'react-bootstrap/Form';
import { faPlus, faTrash, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, InputGroup } from 'react-bootstrap';
import OptionalCol from '../OptionalCol';
import CustomRadio from './CustomRadio';
import { BwcCodes } from '../../enums';
import CustomControl from './CustomControl';

export default function BwcCodeControl({stateProps, breakpoints, inline, disabled}) {
    const [useCustom, setUseCustom] = useState(false); 

    const handleToggleUseCustom = () => {
        stateProps.setValue('');
        setUseCustom(!useCustom);
    }

    return (
        <OptionalCol breakpoints={breakpoints}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                { useCustom ?
                    <>
                        <CustomControl maxLength={10} disabled={disabled} type='number' title='BWC Code' floatingLabel stateProps={stateProps} style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}/>
                        <div style={{borderTopRightRadius: 6, borderBottomRightRadius: 6, border: '1px solid lightgray', borderLeft: 'none', display: 'flex', alignItems: 'center', padding: 12, fontSize: 15}}>
                            <Form.Check style={{whiteSpace: 'nowrap'}} label='Custom' type='switch' checked={useCustom} onChange={handleToggleUseCustom}/>
                        </div>
                    </>
                :
                    <CustomRadio
                        title={'BWC Code'} 
                        stateProps={stateProps}
                        setSelection={(value) => {
                            if (value == '-9999999999') {
                                handleToggleUseCustom();
                            }
                        }}
                        items={{...BwcCodes, '-9999999999': 'Custom'}}
                        inline={inline}
                        disabled={disabled} 
                        validation={() => stateProps.getValidationMessage()}
                        
                    />
                }
                
            </div>
        </OptionalCol>
    )
}