/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Document, Page, Image, Text, View} from '@react-pdf/renderer';
import moment from 'moment';
import AbsoluteText from '../AbsoluteText';
import { formatPhoneNumber } from '../../../../tools';

export default function MDDPdf({mdd, signature}) {

    return (
        <Document>
            <Page size='LETTER' style={{position: 'relative', padding: 50}}>
                <View style={{textAlign: 'center', gap: 4, marginBottom: 12}}>
                    <Text style={{fontSize: 20}}>Multiple Direct Deposit Authorization Form</Text>
                    <Text style={{fontSize: 12}}>{mdd.employerName}</Text>
                </View>

                <View style={{borderLeft: '1px solid black', borderTop: '1px solid black', width: '100%', fontSize: 8, color: 'gray', marginBottom: 8}}>
                    <View style={{borderBottom: '1px solid black', width: '100%', flexDirection: 'row', height: 30}}>
                        <View style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                            <Text>Employee Name</Text>
                        </View>
                        <View style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                            <Text>Email Address</Text>
                        </View>
                        <View style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                            <Text>Phone Number</Text>
                        </View>
                    </View>
                </View>
                <Text style={{fontSize: 10}}>Account #1:</Text>
                <View style={{borderLeft: '1px solid black', borderTop: '1px solid black', width: '100%', fontSize: 8, color: 'gray', marginBottom: 8}}>
                    <View style={{borderBottom: '1px solid black', width: '100%', flexDirection: 'row', height: 30}}>
                        <View style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                            <Text>Financial Institution Name</Text>
                        </View>
                        <View style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                            <Text>ABA Routing Number</Text>
                        </View>
                        <View style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                            <Text>Account Number</Text>
                        </View>
                    </View>
                    <View style={{borderBottom: '1px solid black', width: '100%', flexDirection: 'row', height: 30}}>
                        <View style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                            <Text>Account Type</Text>
                            <View style={{display: 'flex', flexDirection: 'row', color: 'black', fontSize: 10, width: '100%', paddingTop: 6}}>
                                <View style={{flexDirection: 'row', alignItems: 'center', gap: 6, flex: 1}}>
                                    <Text>Checking</Text>
                                    <View style={{width: 10, height: 10, border: '1px solid black'}}/>
                                </View>
                                <View style={{flexDirection: 'row', alignItems: 'center', gap: 6, flex: 1}}>
                                    <Text>Savings</Text>
                                    <View style={{width: 10, height: 10, border: '1px solid black'}}/>
                                </View>
                            </View>
                        </View>
                        <View style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                            <Text>Deposit Amount</Text>
                        </View>
                        <View style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                            <Text>Deposit Type</Text>
                            <View style={{display: 'flex', flexDirection: 'row', color: 'black', fontSize: 10, width: '100%', paddingTop: 6}}>
                                <View style={{flexDirection: 'row', alignItems: 'center', gap: 6, flex: 1}}>
                                    <Text>Flat Amount</Text>
                                    <View style={{width: 10, height: 10, border: '1px solid black'}}/>
                                </View>
                                <View style={{flexDirection: 'row', alignItems: 'center', gap: 6, flex: 1}}>
                                    <Text>Percentage</Text>
                                    <View style={{width: 10, height: 10, border: '1px solid black'}}/>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <Text style={{fontSize: 10}}>Account #2:</Text>
                <View style={{borderLeft: '1px solid black', borderTop: '1px solid black', width: '100%', fontSize: 8, color: 'gray'}}>
                    <View style={{borderBottom: '1px solid black', width: '100%', flexDirection: 'row', height: 30}}>
                        <View style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                            <Text>Financial Institution Name</Text>
                        </View>
                        <View style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                            <Text>ABA Routing Number</Text>
                        </View>
                        <View style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                            <Text>Account Number</Text>
                        </View>
                    </View>
                    <View style={{borderBottom: '1px solid black', width: '100%', flexDirection: 'row', height: 30}}>
                        <View style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                            <Text>Account Type</Text>
                            <View style={{display: 'flex', flexDirection: 'row', color: 'black', fontSize: 10, width: '100%', paddingTop: 6}}>
                                <View style={{flexDirection: 'row', alignItems: 'center', gap: 6, flex: 1}}>
                                    <Text>Checking</Text>
                                    <View style={{width: 10, height: 10, border: '1px solid black'}}/>
                                </View>
                                <View style={{flexDirection: 'row', alignItems: 'center', gap: 6, flex: 1}}>
                                    <Text>Savings</Text>
                                    <View style={{width: 10, height: 10, border: '1px solid black'}}/>
                                </View>
                            </View>
                        </View>
                        <View style={{borderRight: '1px solid black', width: '66.66%', paddingLeft: 4}}>
                            <Text>Deposit Remainder Amount</Text>
                        </View>
                    </View>
                </View>
                
                <View style={{gap: 12, marginTop: 12}}>
                    <View style={{display: 'flex', flexDirection: 'column'}}>
                        <Text style={{fontSize: 11, textAlign: 'center', lineHeight: 1}}>A PRINTED BANK DOCUMENT, INCLUDING YOUR NAME, BANK NAME, ROUTING NUMBER</Text>
                        <Text style={{fontSize: 11, textAlign: 'center', lineHeight: 1}}>AND ACCOUNT NUMBER IS REQUIRED FOR VERIFICATION. ACCEPTED BANK DOCS</Text>
                        <Text style={{fontSize: 11, textAlign: 'center', lineHeight: 1}}>INCLUDE VOIDED CHECK, DIRECT DEPOSIT FORM OR BANK APP SCREENSHOT; TWO</Text>
                        <Text style={{fontSize: 11, textAlign: 'center', lineHeight: 1}}>SCREENSHOTS MAY BE NECESSARY TO PROVIDE REQUIRED INFORMATION. FAILURE TO</Text>
                        <Text style={{fontSize: 11, textAlign: 'center', lineHeight: 1}}>PROVIDE REQUIRED BANK DOCUMENT MAY CAUSE DELAY OF PAYROLL PROCESSING</Text>
                    </View>
                    {/* <Text style={{fontSize: 12, textAlign: 'center'}}>A PRINTED BANK DOCUMENT, INCLUDING YOUR NAME, BANK NAME, ROUTING NUMBER AND ACCOUNT NUMBER IS REQUIRED FOR VERIFICATION. ACCEPTED BANK DOCS INCLUDE VOIDED CHECK, DIRECT DEPOSIT FORM OR BANK APP SCREENSHOT; TWO SCREENSHOTS MAY BE NECESSARY TO PROVIDE REQUIRED INFORMATION. FAILURE TO PROVIDE REQUIRED BANK DOCUMENT MAY CAUSE DELAY OF PAYROLL PROCESSING</Text> */}
                    <View>
                        <Text style={{fontSize: 11, textAlign: 'center'}}>NO HAND WRITTEN DOCUMENTS ARE ACCEPTED</Text>
                        <Text style={{fontSize: 11, textAlign: 'center'}}>DO NOT USE A DEPOSIT SLIP TO OBTAIN YOUR ACCOUNT NUMBER!</Text>
                    </View>
                    <View style={{gap: 12, marginTop: 24}}>
                        <Text style={{fontSize: 10}}>{`I hereby authorize ${mdd.employerName} to direct electronic deposits to the accounts noted above for my periodic payroll checks and I authorize the financial institutions designated herein to accept such electronic funds transfers. I have indicated the accounts at the financial institutions to which deposits are to be made and understand that this decision can only be changed by me upon written instruction delivered to ${mdd.employerName}. I understand that ${mdd.employerName} may cancel this direct deposit program at any time.`}</Text>
                        <Text style={{fontSize: 10}}>{`I understand that it is my responsibility to confirm the accuracy of all transfers to my designated accounts and acknowledge that ${mdd.employerName} has received a written termination notification from me in such time to afford ${mdd.employerName} a reasonable opportunity to act on it.`}</Text>
                    </View>

                </View>

                <View style={{display: 'flex', flexDirection: 'row', gap: 50, fontSize: 11.5, marginTop: 48}}>
                    <Text style={{borderTop: '1px solid black', paddingLeft: 12, paddingRight: 50, paddingTop: 4}}>Employee's Signature</Text>
                    <Text style={{borderTop: '1px solid black', paddingLeft: 12, paddingRight: 50, paddingTop: 4}}>Date</Text>
                </View>

                <AbsoluteText fontSize={10} y={118} x={56} text={mdd.employeeName}/>
                <AbsoluteText fontSize={10} y={118} x={226} text={mdd.email}/>
                <AbsoluteText fontSize={10} y={118} x={396} text={formatPhoneNumber(mdd.phoneNumber)}/>
                <AbsoluteText fontSize={10} y={169} x={56} text={mdd.fiName1}/>
                <AbsoluteText fontSize={10} y={169} x={226} text={mdd.routingNumber1}/>
                <AbsoluteText fontSize={10} y={169} x={396} text={mdd.accountNumber1}/>

                <AbsoluteText y={196} x={104} text={mdd.accountType1 == 0 ? 'X' : ''} fontSize={11}/>
                <AbsoluteText y={196} x={181} text={mdd.accountType1 == 1 ? 'X' : ''} fontSize={11}/>
                <AbsoluteText fontSize={10} y={199} x={226} text={mdd.depositAmount1}/>
                <AbsoluteText y={196} x={457} text={mdd.depositType == 0 ? 'X' : ''} fontSize={11}/>
                <AbsoluteText y={196} x={536} text={mdd.depositType == 1 ? 'X' : ''} fontSize={11}/>


                <AbsoluteText fontSize={10} y={248} x={56} text={mdd.fiName2}/>
                <AbsoluteText fontSize={10} y={248} x={226} text={mdd.routingNumber2}/>
                <AbsoluteText fontSize={10} y={248} x={396} text={mdd.accountNumber2}/>

                <AbsoluteText y={276} x={104} text={mdd.accountType2 == 0 ? 'X' : ''} fontSize={11}/>
                <AbsoluteText y={276} x={181} text={mdd.accountType2 == 1 ? 'X' : ''} fontSize={11}/>
                <AbsoluteText fontSize={10} y={278} x={226} text={mdd.depositAmount2}/>

                <Image src={signature.image} style={{position: 'absolute', top: 535, left: 55, width: 118, height: 40}}/>
                <AbsoluteText y={564} x={280} text={moment().format('MM/DD/YYYY')} fontSize={12}/>
            </Page>
            {mdd.attachment1 && 
                <Page size='LETTER' style={{position: 'relative', padding: 50}}>
                    <Text style={{fontSize: 15, textAlign: 'center', marginBottom: 12}}>Uploaded Bank Document (Account #1)</Text>
                    <Image style={{objectFit: 'contain', maxHeight: 600}} src={mdd.attachment1}/>
                </Page>
            }
            {mdd.attachment2 && 
                <Page size='LETTER' style={{position: 'relative', padding: 50}}>
                    <Text style={{fontSize: 15, textAlign: 'center', marginBottom: 12}}>Uploaded Bank Document (Account #2)</Text>
                    <Image style={{objectFit: 'contain', maxHeight: 600}} src={mdd.attachment2}/>
                </Page>
            }
        </Document>
    )
}