import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomControl from "../../components/controls/CustomControl";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Validation } from "../../validation";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomButton from "../../components/CustomButton";
import { AppGlobals } from "../../App";
import { requestReset } from "../../services/ResetPasswordService";


export default function ForgotPassword({hideModal}){

    const [phoneNumber, setPhoneNumber] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleRequestPasswordReset = async() =>{
        setIsLoading(true);
        const response = await requestReset(phoneNumber);
        if(response.status === 200){
            AppGlobals.alert('success', response.message)
            hideModal();
        } else {
            AppGlobals.alert('danger', response.message)
        }
        setIsLoading(false);
    }
    
    return(
        <>
            <Modal.Header closeButton>
                <Modal.Title>Forgot Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <CustomControl title='Phone Number' value={phoneNumber} setValue={setPhoneNumber} validation={Validation.phoneNumber} maxLength={10}/>
            </Modal.Body>
                <Modal.Footer>
                    <div style={{display: 'flex', alignItems: 'center', gap: 12, color: 'gray'}}>
                        <FontAwesomeIcon icon={faInfoCircle}/>
                        <span>After submitting you will receive a text with a link to reset your password</span>
                    </div>
                    <CustomButton label='Submit' isLoading={isLoading} disabled={Validation.phoneNumber(phoneNumber)} variant={'outline-primary'} onClick={()=>handleRequestPasswordReset()}>
                        Submit
                    </CustomButton>
                </Modal.Footer>
        </>
    )
}