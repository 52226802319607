/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import {EditorState,convertFromRaw, convertToRaw } from 'draft-js'
import Decoder from '../../decoding';
import StateObject from '../../models/State/StateObject';

export default class RichText {
    static decode(json) {
        const decoder = new Decoder(json);
        const blocks = (decoder.decode('blocks', Decoder.Array) || []).map(b => RichText.decodeRawDraftContentBlock(b));

        if (decoder.checkForErrors()) {
            return EditorState.createWithContent(convertFromRaw({ entityMap: {}, blocks: blocks }));
        } else {
            return EditorState.createEmpty();
        }
    }

    static decodeRawDraftContentBlock(json) {
        const decoder = new Decoder(json);
        const text = decoder.decode('text', Decoder.StringStrict);
        const type = decoder.decode('type', Decoder.StringStrict);
        const inlineStyleRanges = (decoder.decode('inlineStyleRanges', Decoder.Array) || []).map(b => RichText.decodeRawDraftInlineStyleRange(b));

        if (decoder.checkForErrors()) {
            return {
                text: text,
                type: type,
                inlineStyleRanges: inlineStyleRanges
            };
        } else {
            return {
                text: '',
                type: '',
                inlineStyleRanges: [],
                key: '',
                depth: 0,
                entityRanges: [],
            };
        }
    }

    static decodeRawDraftInlineStyleRange(json) {
        const decoder = new Decoder(json);
        const offset = decoder.decode('offset', Decoder.Integer);
        const length = decoder.decode('length', Decoder.Integer);
        const style = decoder.decode('style', Decoder.StringStrict);
        if (decoder.checkForErrors()) {
            return {
                offset: offset,
                length: length,
                style: style
            };
        } else {
            return {
                offset: 0,
                length: 0,
                style: 'BOLD'
            };
        }
    }

    static encode(editorState) {
        const json = convertToRaw(editorState.getCurrentContent());
        return {
            blocks: json.blocks.map((block) => {
                return {
                    text: block.text,
                    type: block.type,
                    inlineStyleRanges: block.inlineStyleRanges
                };
            })
        };
    }
}