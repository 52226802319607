/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";

export default class Sort {
    static alphaNumeric(a: string | number, b: string | number, ascending: boolean = true) {
        if (a < b) {
            return ascending ? -1 : 1;
        } else if (a > b) {
            return ascending ? 1 : -1;
        } else {
            return 0;
        }
    }
    static date(a: string | undefined, b: string | undefined, ascending: boolean = true) {
        if (!a || !b) {
            if (a === b) {
                return 0;
            } else if (!a) {
                return ascending ? 1 : -1;
            } else {
                return ascending ? -1 : 1;
            }
        } else {
            if (moment(a).isBefore(moment(b), 'days')) {
                return ascending ? -1 : 1;
            } else if (moment(a).isAfter(moment(b), 'days')) {
                return ascending ? 1 : -1;
            } else {
                return 0;
            }
        }
    }
}