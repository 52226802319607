/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import Cookies from 'universal-cookie';
import logo from '../assets/TTA-Long-150.png'
import CustomTextArea from './controls/CustomTextArea';
import { Button, Form } from 'react-bootstrap';
import CustomButton from './CustomButton';

export default function EmailTemplate({message, name, setRejectionMessage, handleSaveChanges, isSubmitting}){

    const cookies = new Cookies();
    const companyName = cookies.get('companyName');

    return (
        <div style={{margin: 'auto', width: 750, maxWidth: 750}}>
            <h6 style={{marginBottom: 8}}>Example Email: </h6>
            <div style={{paddingTop: 48, paddingBottom: 24, border: '1px solid lightgray'}}>
                <img style={{width: '65%', marginBottom: 60, paddingLeft: 36, paddingRight: 36}} src={logo}/>
                <h4 style={{fontWeight: 'bold', paddingLeft: 36, paddingRight: 36}}>Dear, {name ? name : '(Candidate Name)'}</h4>
                {/* <p style={{marginBottom: 30, paddingLeft: 36, paddingRight: 36, whiteSpace: 'pre-line'}}>{message}</p> */}
                <div style={{paddingLeft: 36, paddingRight: 36, marginBottom: 30}}>
                    <Form.Control as='textarea' style={{resize: 'none', marginBottom: 8}} maxLength={300} rows={9} value={message} onChange={(e)=>setRejectionMessage(e.target.value)}/>
                    {handleSaveChanges && <CustomButton isLoading={isSubmitting} onClick={handleSaveChanges} style={{marginLeft: 'auto', display: 'block'}}>Save Changes</CustomButton>}
                </div>
                <div style={{height: '1px', width: '100%', backgroundColor: 'lightgray', marginBottom: 20}}></div>
                <h5 style={{fontWeight: 'bold', paddingLeft: 36, paddingRight: 36}}>Best,</h5>
                <p style={{paddingLeft: 36, paddingRight: 36}}>The My Tactical Recruiter Team on behalf of {companyName}</p>
            </div>
        </div>
    )
}