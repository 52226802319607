/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import { Badge, Card, ListGroup, Modal } from 'react-bootstrap';
import QuickTable, { QuickTableRow } from '../../components/QuickTable';
import moment from 'moment';
import Sort from '../../sorting';
import { getJobPosts } from '../../services/JobPostService';
import LoadingWrapper from '../../components/LoadingWrapper';
import CustomCheck from '../../components/controls/CustomCheck';
import { timeDurationToString, timeSinceDateToString, timeUntilDateToString } from '../../tools';
import JobPostOverview from './models/JobPostOverview';

export default class JobPosts extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            mobileView: window.innerWidth < 992,
            jobs: [],
            showJobModal: false,
            selectedJob: undefined,
        }

        this.handleWindowResize = this.handleWindowResize.bind(this);
        this.handleDeleteJobPost = this.handleDeleteJobPost.bind(this);   
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
        this.loadData();
    }
    componentWillUnmount(){
        window.removeEventListener('resize', this.handleWindowResize);
    }

    handleWindowResize() {
        const newMobileView = window.innerWidth < 992;
        if (this.state.mobileView !== newMobileView) {
            this.setState({mobileView: newMobileView});
        }
    }

    async loadData() {
        this.setState({isLoading: true});

        const response = await getJobPosts();
        if (response.status === 200) {
            const jobsFromResponse = response.jobPosts.map(p => JobPostOverview.decode(p));
            jobsFromResponse.forEach((j, i)=> j.subscribe(`jobPostsModalRender${i}`, null , () => {
                this.forceUpdate();
            }))
            this.setState({jobs: jobsFromResponse});  
        }
        this.setState({isLoading: false});
    }

    handleDeleteJobPost(uid) {
        this.setState({jobs: this.state.jobs.filter(j => j.uid !== uid)});
    }


    render() {
        const activeJobs = this.state.jobs.filter(j => j.active);
        const inactiveJobs = this.state.jobs.filter(j => !j.active);

        const activeJobRows = activeJobs.map((job) => {
            const numberOfNewApplicants = job.applicants.filter(a => a.clientIdentifier === null && !a.rejected).length;
            return {
                onClick: () => this.props.history.push(`/job/${job.uid}`),
                columns: [
                    {value: job.title + `${job.isDuplicate ? ` (Activated ${moment(job.dateActivated).format('MMM D, YYYY')})` : ''}`, sortMethod: Sort.alphaNumeric},
                    {value: job.applicants.length, sortMethod: Sort.alphaNumeric, content: (
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <span>{job.applicants.length}</span>
                            { numberOfNewApplicants > 0 &&
                                <Badge>{numberOfNewApplicants+ ' New!'}</Badge>
                            }
                        </div>
                    )},
                    {value: moment().diff(moment(job.dateActivated), 'days'), sortMethod: Sort.alphaNumeric},
                    {value: job.dateLastModified, sortMethod: Sort.date, content: timeSinceDateToString(job.dateLastModified, 30)},
                    {value: job.expirationDate, sortMethod: Sort.date, content: job.expirationDate ? timeUntilDateToString(job.expirationDate, 30) : 'Never'},
                ]
            } 
        });
        const inactiveJobsRows = inactiveJobs.map((job) => {
            const numberOfHiredApplicants = job.applicants.filter(a => a.hired !== undefined).length;
            return {
                onClick: () => this.props.history.push(`/job/${job.uid}`),
                columns: [
                    {value: job.title + `${job.isDuplicate ? ` (Activated ${moment(job.dateActivated).format('MMM D, YYYY')})` : ''}`, sortMethod: Sort.alphaNumeric},
                    {value: job.applicants.length, sortMethod: Sort.alphaNumeric},
                    {value: numberOfHiredApplicants, sortMethod: Sort.alphaNumeric},
                    {value: job.dateLastModified, sortMethod: Sort.date, content: timeSinceDateToString(job.dateLastModified, 30)},
                    {value: job.dateCreated, sortMethod: Sort.date, content: timeSinceDateToString(job.dateCreated, 7)},
                ]
            }
        });
        const activeJobList = activeJobs.map((job) => {
            const numberOfNewApplicants = job.applicants.filter(a => !a.clientIdentifier && !a.rejected).length;
            return (
                <ListGroup.Item action className='cursor-pointer' key={job.uid} onClick={() => this.props.history.push(`/job/${job.uid}`)}>
                    <div style={{display: 'flex', flexDirection: 'column', padding: 8}}>
                        <div style={{margin: 0, marginBottom: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap-reverse', gap: 8}}>
                            <h4 style={{margin: 0}}>{job.title}</h4>
                            { numberOfNewApplicants > 0 &&
                                <Badge>{numberOfNewApplicants + ' New Applicant' + (numberOfNewApplicants > 1 ? 's!' : '!')}</Badge>
                            }
                        </div>
                        <h6 style={{marginBottom: 14}}>{job.isDuplicate ? `( Activated ${moment().format('MMM D, YYYY')} )` : ''}</h6>
                        <h6 style={{marginBottom: 6}}><strong>Total Applicants: </strong>{job.applicants.length}</h6>
                        <h6 style={{marginBottom: 6}}><strong>Days Active: </strong> {moment().diff(moment(job.dateActivated), 'days')}</h6>
                        <h6 style={{marginBottom: 10}}><strong>Last Modified: </strong> {timeSinceDateToString(job.dateLastModified, 30)}</h6>
                        <p style={{textAlign: 'right', margin: 0, fontSize: 13}}><strong>Expires: </strong>{job.expirationDate ? timeUntilDateToString(job.expirationDate, 30) : 'Never'}</p>
                    </div>
                </ListGroup.Item>
            )
        });
        const inactiveJobList = inactiveJobs.map((job) => {
            const numberOfHiredApplicants = job.applicants.filter(a => a.hired !== undefined).length;
            return (
                <ListGroup.Item action className='cursor-pointer' key={job.uid} onClick={() => this.props.history.push(`/job/${job.uid}`)}>
                     <div style={{display: 'flex', flexDirection: 'column', padding: 8}}>
                        <h4 style={{margin: 0, marginBottom: 2}}>{job.title}</h4>
                        <h6 style={{marginBottom: 14}}>{job.isDuplicate ? `( Activated ${moment().format('MMM D, YYYY')} )` : ''}</h6>
                        <h6 style={{marginBottom: 6}}><strong>Total Applicants: </strong>{job.applicants.length}</h6>
                        <h6 style={{marginBottom: 6}}><strong>Applicants Hired: </strong>{numberOfHiredApplicants}</h6>
                        <h6 style={{marginBottom: 10}}><strong>Last Modified: </strong> {timeSinceDateToString(job.dateLastModified, 30)}</h6>
                        <p style={{textAlign: 'right', margin: 0, fontSize: 13}}><strong>Date Created: </strong>{timeSinceDateToString(job.dateCreated, 7)}</p>
                    </div>
                </ListGroup.Item>
            )
        });

        return (
            <LoadingWrapper isLoading={this.state.isLoading}>
                <div style={{backgroundColor: 'var(--bs-primary)', height: '100%', padding: 12, textAlign: 'start', display: 'flex', flexDirection: 'column', gap: 12}}>
                    <Card style={{flex: 1, minHeight: this.state.mobileView ? '100vh' : undefined, display: 'flex', flexDirection: 'column', overflowY: 'auto'}}>
                        <Card.Body style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                            <Card.Title style={{fontSize: 26, textAlign: 'center', marginBottom: 14}}>Active Job Posts</Card.Title>
                            <div style={{flex: 1, overflowY: 'scroll'}}>
                                { this.state.mobileView ?
                                    <ListGroup>
                                        {activeJobList}
                                    </ListGroup>
                                    :
                                    <QuickTable 
                                        responsive={false}
                                        hover 
                                        defaultSort={[2, true]} 
                                        headers={[{label: 'Job Title', sortable: true}, {label: 'Total Applicants', sortable: true}, {label: 'Days Active', sortable: true}, {label: 'Last Modified', sortable: true}, {label: 'Expires', sortable: true}]} 
                                        rows={activeJobRows}
                                    />
                                }
                            </div>
                        </Card.Body>
                    </Card>
                    <Card style={{flex: 1, minHeight: this.state.mobileView ? '100vh' : undefined, display: 'flex', flexDirection: 'column', overflowY: 'auto'}}>
                        <Card.Body style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                            <Card.Title style={{fontSize: 26, textAlign: 'center', marginBottom: 14}}>Inactive Job Posts</Card.Title>
                            <div style={{flex: 1, overflowY: 'scroll'}}>
                                { this.state.mobileView ?
                                    <ListGroup>
                                        {inactiveJobList}
                                    </ListGroup>
                                    :
                                    <QuickTable 
                                        responsive={false}
                                        hover 
                                        headers={[
                                            {label: 'Job Title', sortable: true}, 
                                            {label: 'Total Applicants', sortable: true}, 
                                            {label: 'Applicants Hired', sortable: true},
                                            {label: 'Last Modified', sortable: true},
                                            {label: 'Date Created', sortable: true}
                                        ]}
                                        rows={inactiveJobsRows}
                                        defaultSort={[4, false]}
                                    />
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </LoadingWrapper>
        )
    }
}