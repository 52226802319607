/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import StateObject from "../../../models/State/StateObject";
import Decoder from "../../../decoding";

export class JobApplicationMinimal extends StateObject {
    uid; //: number;
    jobPostIdentifier; //: number;
    dateApplied; //: string | undefined;
    passedDrugScreen; //: string | undefined;
    passedBackground; //: string | undefined;
    interviews; //: string[];
    hired; //: string | undefined;
    clientIdentifier; //: uid || null;
    rejected; //: boolean;
    firstName; //: string;
    middleName; //: string;
    lastName; //: string;
    terminalIdentifier; // number
    autoRejected; //boolean

    constructor(
        uid,
        jobPostIdentifier,
        dateApplied,
        passedDrugScreen,
        passedBackground,
        interviews,
        hired,
        clientIdentifier,
        rejected,
        firstName,
        middleName,
        lastName,
        terminalIdentifier,
        autoRejected,
    ) {
        super();
        this.uid = uid;
        this.jobPostIdentifier = jobPostIdentifier;
        this.dateApplied = dateApplied;
        this.passedDrugScreen = passedDrugScreen;
        this.passedBackground = passedBackground;
        this.interviews = interviews;
        this.hired = hired;
        this.clientIdentifier= clientIdentifier;
        this.rejected = rejected;
        this.firstName = firstName;
        this.middleName = middleName;
        this.lastName = lastName;
        this.terminalIdentifier = terminalIdentifier;
        this.autoRejected = autoRejected;
        this.initChildren();
    }

    static initFromJobApplication(app) {
        return new JobApplicationMinimal(app.uid, app.jobPostIdentifier, app.dateApplied, app.passedDrugScreen, app.passedBackground, app.interviews, app.hired, app.clientIdentifier, app.rejected, app.firstName, app.middleName, app.lastName, app.terminalIdentifier, false);
    }

    static initDefault() { 
        return new JobApplicationMinimal(
            -1, 
            -1, 
            moment().format('YYYY-MM-DD'),
            undefined,
            undefined,
            [],
            undefined,
            null,
            false,
            '', 
            '',
            '',
            -1,
            false, 
        );
    }

    static decode(json) {
        const decoder = new Decoder(json);
        const uid = decoder.decode('uid', Decoder.Uid);
        const jobPostIdentifier = decoder.decode('jobPostIdentifier', Decoder.Uid);
        const dateApplied = decoder.decode('dateApplied', Decoder.Date, {defaultValue: undefined, warn: false});
        const passedDrugScreen = decoder.decode('passedDrugScreen', Decoder.Date, {defaultValue: undefined, warn: false});
        const passedBackground = decoder.decode('passedBackground', Decoder.Date, {defaultValue: undefined, warn: false});
        const interviews = decoder.decode('interviews', Decoder.Array);
        const hired = decoder.decode('hired', Decoder.Date, {defaultValue: undefined, warn: false});
        const clientIdentifier = decoder.decode('clientIdentifier', Decoder.Uid, {defaultValue: null, warn: false});
        const rejected = decoder.decode('rejected', Decoder.Boolean);
        const firstName = decoder.decode('firstName', Decoder.StringStrict);
        const middleName = decoder.decode('middleName', Decoder.StringStrict);
        const lastName = decoder.decode('lastName', Decoder.StringStrict);
        const terminalIdentifier = decoder.decode('terminalIdentifier', Decoder.Uid);
        const autoRejected = decoder.decode('autoRejected', Decoder.Boolean);
        if (decoder.checkForErrors()) {
            return new JobApplicationMinimal(uid, jobPostIdentifier, dateApplied, passedDrugScreen, passedBackground, 
                interviews, hired, clientIdentifier, rejected, firstName, middleName, lastName, terminalIdentifier, autoRejected);
        } else {
            return JobApplicationMinimal.initDefault();
        }
    }
}