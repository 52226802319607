/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import { Button, Card, ListGroup, ListGroupItem, Modal } from 'react-bootstrap';
import KeyValueRow from '../../components/KeyValueRow';
import { addExistingTerminal, addNewTerminal, getAllUnassociatedTerminals, getClientTerminals } from '../../services/ClientService';
import LoadingWrapper from '../../components/LoadingWrapper';
import SortableTable, { SortableTableCol } from '../../components/SortableTable';
import { addressToString } from '../../tools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faPlus } from '@fortawesome/free-solid-svg-icons';
import CustomButton from '../../components/CustomButton';
import { AppGlobals } from '../../App';
import Address from '../../models/Address';
import Wizard from '../../components/Wizard/Wizard';
import CustomDropDown from '../../components/controls/CustomDropDown';
import { States } from '../../enums';
import CustomControl from '../../components/controls/CustomControl';
import AddressControl from '../../components/controls/AddressControl';
import developmentMode from '../../developmentMode';
import AddressAutoFill from '../../components/controls/AddressAutoFill';
import TerminalForApplication from '../JobApplication/models/TerminalForApplication';
import Terminal from '../../models/Terminal';
import StateObject from '../../models/State/StateObject';
import ControlStateProps from '../../models/State/ControlStateProps';
import { Validation } from '../../validation';

export default function ClientTerminals({selectedClient}) {
    const [isLoading, setIsLoading] = useState(false);
    const [terminals, setTerminals] = useState([]);
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        loadData();
    }, []);


    const loadData = async () => {
        setIsLoading(true);
        
        const response = await getClientTerminals(selectedClient.uid);
        if (response.status === 200) {
            setTerminals(response.terminals);
        }
        setIsLoading(false);
    }

    /////////////////////////
    /// TABLE FOR DESKTOP
    /////////////////////////

    const columns = [
        new SortableTableCol('name', 'Name', null, SortableTableCol.sortAsc),
        new SortableTableCol('address', 'Address', null, SortableTableCol.sortAsc),
    ]

    const terminalElements = terminals.map((terminal) => {
        return {
            values: {name: terminal.name, address: addressToString(terminal.address)},
            content: (
                <tr key={terminal.uid}>
                    <td>{terminal.name}</td>
                    <td>{addressToString(terminal.address)}</td>
                </tr>
            )
        }
    })

    //////////////////////////
    /// LIST ITEMS FOR MOBILE
    //////////////////////////

    const mobileTerminalElements = terminals.map((terminal)=>{
        return (
            <ListGroup.Item key={terminal.uid}>
                <h5 style={{margin: 0, marginBottom: 4}}>{terminal.name}</h5>
                <p style={{margin: 0, fontSize: 14}}>{addressToString(terminal.address)}</p>
            </ListGroup.Item>
        )
    })


    return (
        <LoadingWrapper isLoading={isLoading} color='var(--bs-primary)'>
            <Card style={{flex: 1}}>
                <Card.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                    <Button style={{marginLeft: 'auto'}} onClick={()=>setShowModal(true)}><FontAwesomeIcon style={{marginRight: 6}} icon={faPlus}/>Add Terminal</Button>
                    <div className='desktop-block'>
                        <SortableTable rows={terminalElements} columns={columns}/>
                    </div>
                    <ListGroup className='mobile-block'>
                        {mobileTerminalElements}
                    </ListGroup>
                </Card.Body>
            </Card>
            <Modal size='lg' show={showModal} onHide={()=>setShowModal(false)} backdrop='static'>
                <AddTerminalModal setShowModal={setShowModal} selectedClient={selectedClient} setTerminals={setTerminals}/>
            </Modal>
        </LoadingWrapper>
    )
    
}


function AddTerminalModal({setShowModal, selectedClient, setTerminals}){
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [allTerminals, setAllTerminals] = useState([])

    const [selectedTerminal, setSelectedTerminal] = useState(null)
    const [newTerminal, setNewTerminal] = useState(new StateObject({name: '', address: undefined}))

    const [stepIndex, setStepIndex] = useState(0);
    const [state, setState] = useState('');
    const [city, setCity] = useState('');

    const steps = ['Enter Location', 'Associate Existing Terminal', 'Create New Terminal'];
    

    async function loadData(){
        setIsLoading(true);
        const response = await getAllUnassociatedTerminals(selectedClient.uid);
        if(response.status === 200){
            setAllTerminals(response.terminals);
            setIsLoading(false);
        }else{
            AppGlobals.alert('danger', response.message)
        }
    }

    useEffect(()=>{
        loadData();
    }, [])

    async function handleAddExistingTerminal(){
        setIsSubmitting(true);
        const response = await addExistingTerminal(selectedTerminal.uid, selectedClient.uid);
        if(response.status === 200){
            setTerminals(prev=> [...prev, allTerminals.find(t=>t.uid === selectedTerminal.uid)]);
            setShowModal(false);
        }else{
            AppGlobals.alert('danger', response.message)
        }
        setIsSubmitting(false);
    }

    async function handleAddNewTerminal(){
        setIsSubmitting(true);

        const createdTerminal = TerminalForApplication.initFromTerminalCreation(newTerminal);

        const response = await addNewTerminal(createdTerminal.encode(), selectedClient.uid);
        if(response.status === 200){
            setTerminals(prev=> [...prev, createdTerminal]);
            setShowModal(false);
        }else{
            AppGlobals.alert('danger', response.message)
        }
        setIsSubmitting(false);
    }

    const filteredTerminals = allTerminals.filter(t=>{
        return t.address.locality.toLowerCase() === city.toLowerCase().trim() && t.address.administrativeArea === state;
    });

    return(
        <>
           <Modal.Header closeButton>
                <Modal.Title>Add Terminal</Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
                <LoadingWrapper isLoading={isLoading}>
                    <div style={{marginLeft: 20, marginRight: 20, marginBottom:12}}>
                        <Wizard steps={steps} stepIndex={stepIndex}/>
                    </div>
                    {stepIndex === 0 &&
                        <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                            <CustomDropDown
                                title='State'
                                items={States}
                                selection={state}
                                setSelection={setState}
                            />
                            <CustomControl
                                floatingLabel
                                title='City'
                                value={city}
                                setValue={(value)=>setCity(value)}

                            />
                        </div>
                    }
                    {stepIndex === 1 &&
                    <>
                        <h5 style={{textAlign: 'center', marginTop: 20, marginBottom: 24}}>{filteredTerminals.length > 0 ? `Viewing all terminals located in ${city}, ${state}`: `No terminals located in ${city}, ${state}`}</h5>
                       {filteredTerminals.length > 0 && <p style={{marginBottom: 8, textAlign: 'center'}}>Select a terminal to associate it with {selectedClient.name}:</p>}
                        <ListGroup>
                            {filteredTerminals.map(t=>{
                                return (
                                    <ListGroup.Item key={t.uid} action onClick={()=>setSelectedTerminal(t)} style={{display: 'flex', flexDirection: 'column', gap: 4, justifyContent: 'space-between', 
                                    backgroundColor: selectedTerminal?.uid === t.uid ? 'var(--bs-primary)' : '', color: selectedTerminal?.uid === t.uid ? 'white' : ''}}>
                                        <h5 style={{margin: 0, fontSize: 17}}>{t.name}</h5>
                                        <p style={{margin: 0, fontSize: 13}}>{addressToString(t.address)}</p>
                                    </ListGroup.Item>
                                )
                            })}
                        </ListGroup>
                        {selectedTerminal && <Button style={{display: 'block', marginTop: 12, marginLeft: 'auto'}} onClick={()=> {setStepIndex(stepIndex+1); handleAddExistingTerminal()}}> <FontAwesomeIcon style={{marginRight: 4}} icon={faPlus}/>Associate with {selectedClient.name}</Button>}
                    </>
                    }
                    {stepIndex === 2 &&
                        <>
                            <CustomControl style={{marginTop: 24, marginBottom: 6}} floatingLabel title='Enter Terminal Name' stateProps={new ControlStateProps('name', newTerminal, Validation.nonEmptyString)}/>
                            <AddressAutoFill title={'Terminal Address'} stateProps={new ControlStateProps('address', newTerminal, Validation.notUndefined)}/>
                        </>
                    }
                </LoadingWrapper>
            </Modal.Body>
            {stepIndex === 0 &&
                <Modal.Footer>
                        <Button disabled={!state || !city} onClick={()=>setStepIndex(1)}>Continue</Button>
                </Modal.Footer>
            }
            {stepIndex === 1 &&
                <Modal.Footer>
                    <button onClick={()=>setStepIndex(0)} style={{marginRight: 'auto', border: 'none', boxShadow: 'none', color: 'red', backgroundColor: 'transparent'}}>Back</button>
                    <Button onClick={()=>setStepIndex(2)}>I do not see my terminal</Button>
                </Modal.Footer>
            }
            {stepIndex === 2 &&
                <Modal.Footer>
                    <button onClick={()=>setStepIndex(1)} style={{marginRight: 'auto', border: 'none', boxShadow: 'none', color: 'red', backgroundColor: 'transparent'}}>Back</button>
                    <CustomButton isLoading={isSubmitting} stateObject={newTerminal} onClick={handleAddNewTerminal}>Create New Terminal</CustomButton>
                </Modal.Footer>
            }
        </>
    )
} 