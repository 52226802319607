/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { LegacyRef, MutableRefObject, createRef, useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import Sort from '../../sorting';
import { yearRangeToString } from '../../tools';
import JobApplicationForJobPost from '../JobPosts/models/JobApplicationForJobPost';

export default function JobApplicationTimeline({jobApplication}) {

    const [vertical, setVertical] = useState(false);
    const ref = useRef();

    const timeline = [
        {
            title: 'Applied',
            date: jobApplication.dateApplied
        },
        {
            title: 'Passed Drug Screen',
            date: jobApplication.passedDrugScreen
        },
        {
            title: 'Passed Background Check',
            date: jobApplication.passedBackground
        },
        {
            title: 'Hired',
            date: jobApplication.hired
        },
        ...jobApplication.interviews.map((i) => {return {title: 'Interviewed', date: moment(i.dateTime).format('YYYY-MM-DD')}}),
        {
            title: 'Present Day',
            date: moment().format('YYYY-MM-DD')
        }
    ].filter(i => i.date !== undefined);

    const cleanedTimeline = timeline.map(i => i.date).sort((a, b) => {return a.date < b.date ? -1 : a.date > b.date ? 1 : 0});

    const earliestTime = cleanedTimeline.length === 0 ? moment().format('YYYY-MM-DD') : cleanedTimeline[0];
    const latestTime = cleanedTimeline.length === 0 ? moment().format('YYYY-MM-DD') : cleanedTimeline[cleanedTimeline.length - 1];

    const timeRange = moment(latestTime).diff(moment(earliestTime), 'days');

    const dates = cleanedTimeline.filter((item, index) => cleanedTimeline.indexOf(item) === index);
    const yearDiff = parseInt(latestTime.substring(0, 4)) - parseInt(earliestTime.substring(0, 4));
    for (let i = 0; i < yearDiff; i++) {
        const year = moment(earliestTime).startOf('year').add(1, 'year').format('YYYY-MM-DD');
        if (!dates.includes(year)) {
            dates.push(year);
        }
    }
    dates.sort((a, b) => {return Sort.date(a, b, true)});


    const timelineElements = dates.map((item, index) => {
        const widthPercentage = index === dates.length - 1 ? 0 : Math.max(0.1, moment(dates[index + 1]).diff(moment(item), 'days') / timeRange);

        const items = timeline.filter(i => i.date === item);

        return (
            <div key={index} style={{flexGrow: isNaN(widthPercentage) ? 0 : widthPercentage, display: 'flex', flexDirection: vertical ? 'column' : 'row', alignItems: 'center'}}>
                { vertical ?
                    <>
                        <TimelineNodeVerticalView date={item} items={items.map(i => i.title)}/>
                        <div style={{flex: 1, width: 1, backgroundColor: 'var(--bs-primary)'}}/>
                    </>
                    :
                    <>
                        <TimelineNodeView date={item} items={items.map(i => i.title)} up={index % 2 === 0}/>
                        <div style={{flex: 1, height: 1, backgroundColor: 'var(--bs-primary)'}}/>
                    </>
                }
                
            </div>
        )
    });

    useEffect(()=>{
        function handleVertical(){
            if(ref.current?.clientWidth < timelineElements?.length * 115){
                setVertical(true)
            } else{
                setVertical(false);
            }
        }
        handleVertical();
        
        window.addEventListener('resize', handleVertical);

        return ()=>{
            window.removeEventListener('resize', handleVertical);
        }
    }, [])


    return (
        <div ref={ref} style={{textAlign: 'center', marginBottom: '36px'}}>
            <span style={{fontWeight: 'bold'}}>{yearRangeToString(earliestTime, latestTime)}</span>
            <div style={{display: 'flex', marginTop: 24, flexDirection: vertical ? 'column' : 'row', width: '100%', minHeight: vertical ? timelineElements.length * 80 : undefined, padding: vertical ? 0 : '50px 30px 50px 20px', alignItems: 'flex-start'}}>
                {timelineElements}
            </div>
        </div>

    )
}

function TimelineNodeView({date, items, up = true}) {
    const [show, setShow] = useState(true);

    const wordBubble = items.length > 0 ? (
        <div style={{position: 'absolute', top: up ? -19 + (-19.5 * items.length) : undefined, bottom: up? undefined : -18.5 + (-19.5 * items.length), borderRadius: 6, padding: 4, backgroundColor: 'white', border: '1px solid var(--bs-primary)', display: show ? 'flex' : 'none', flexDirection: 'column'}}>
            {items.map((i, index) => <span style={{whiteSpace: 'nowrap', fontWeight: 'bold'}} key={index}>{i}</span>)}
            <div style={{width: 0, height: 0, borderLeft: '6px solid transparent', borderRight: '6px solid transparent', borderTop: up ? '8px solid var(--bs-primary)' : undefined, borderBottom: up ? undefined : '8px solid var(--bs-primary)', position: 'absolute', top: up ? undefined : -8, bottom: up ? -8 : undefined, left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto'}}/>
        </div>
    ) : (
        <div style={{position: 'absolute', top: up ? -38 : undefined, bottom: up? undefined : -37, borderRadius: 6, padding: 4, backgroundColor: 'white', border: '1px solid var(--bs-primary)', display: show ? 'flex' : 'none', flexDirection: 'column'}}>
            <span style={{whiteSpace: 'nowrap', fontWeight: 'bold'}}>{date.substring(0, 4)}</span>
            <div style={{width: 0, height: 0, borderLeft: '6px solid transparent', borderRight: '6px solid transparent', borderTop: up ? '8px solid var(--bs-primary)' : undefined, borderBottom: up ? undefined : '8px solid var(--bs-primary)', position: 'absolute', top: up ? undefined : -8, bottom: up ? -8 : undefined, left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto'}}/>
        </div>
    )

    const node = items.length > 0 ? (
        <div className='cursor-pointer' onClick={() => {setShow(!show)}} style={{position: 'relative', width: 40, height: 40, backgroundColor: 'white', border: '1px solid var(--bs-primary)', borderRadius: 20, display: 'flex', flexDirection: 'column', fontSize: 13, justifyContent: 'center', alignItems: 'center'}}>
            <span style={{lineHeight: '85%'}}>{moment(date).format('D')}</span>
            <span style={{lineHeight: '85%'}}>{moment(date).format('MMM')}</span>
            {wordBubble}
        </div>
    ) : (
        <div className='cursor-pointer' style={{position: 'relative', display: 'flex', justifyContent: 'center', height: 9, width: 9, backgroundColor: 'var(--bs-primary)', borderRadius: 5, marginTop: 15, marginBottom: 15, fontSize: 13}}>
            {wordBubble}
        </div>
    )

    return node;
}

function TimelineNodeVerticalView({date, items}) {
    const [show, setShow] = useState(true);

    return (
        <div>
            { items.length > 0 ?
                <div className='cursor-pointer' onClick={() => {setShow(!show)}} style={{position: 'relative', width: 40, height: 40, backgroundColor: 'white', border: '1px solid var(--bs-primary)', borderRadius: 20, display: 'flex', flexDirection: 'column', fontSize: 13, justifyContent: 'center', alignItems: 'center'}}>
                    <span style={{lineHeight: '85%'}}>{moment(date).format('D')}</span>
                    <span style={{lineHeight: '85%'}}>{moment(date).format('MMM')}</span>
                    <div style={{position: 'absolute', left: 48, borderRadius: 6, padding: 4, backgroundColor: 'white', border: '1px solid var(--bs-primary)', display: show ? 'flex' : 'none', flexDirection: 'column'}}>
                        {items.map((i, index) => <span style={{whiteSpace: 'nowrap', fontWeight: 'bold'}} key={index}>{i}</span>)}
                        <div style={{width: 0, height: 0, borderBottom: '6px solid transparent', borderRight: '8px solid var(--bs-primary)', borderTop: '6px solid transparent', position: 'absolute', left: -8, top: 0, bottom: 0, marginTop: 'auto', marginBottom: 'auto'}}/>
                    </div>
                </div>
                :
                <div className='cursor-pointer' style={{position: 'relative', display: 'flex', alignItems: 'center', width: 9, marginLeft: 16, marginRight: 16, height: 9, backgroundColor: 'var(--bs-primary)', borderRadius: 5, fontSize: 13}}>
                    
                    <div style={{position: 'absolute', left: 17, borderRadius: 6, padding: 4, backgroundColor: 'white', border: '1px solid var(--bs-primary)', display: show ? 'flex' : 'none', flexDirection: 'column'}}>
                        <span style={{whiteSpace: 'nowrap', fontWeight: 'bold'}}>{date.substring(0, 4)}</span>
                        <div style={{width: 0, height: 0, borderBottom: '6px solid transparent', borderRight: '8px solid var(--bs-primary)', borderTop: '6px solid transparent', position: 'absolute', left: -8, top: 0, bottom: 0, marginTop: 'auto', marginBottom: 'auto'}}/>
                    </div>
                </div>

            }
        </div>
    )
}