/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';


type KeyValueRowProps = {
    title: string;
    value: any;
    titleStyle?: React.CSSProperties;
    valueStyle?: React.CSSProperties;
    vertical?: boolean;
    isListItem?: boolean;
}

class KeyValueRow extends React.Component<KeyValueRowProps, {}> {

    render() {
    
        if (this.props.isListItem) {
            return (
                <ListGroup.Item  style={{display: 'flex', flexDirection: 'row', wordBreak: 'break-word', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                    <p style={{marginBottom: 0, marginRight: 12, ...this.props.titleStyle}}>{this.props.title}</p>
                    <p style={{marginBottom: 0, opacity: 0.5, textAlign: 'left', ...this.props.valueStyle}}>{this.props.value}</p>
                </ListGroup.Item>
            )
        } else {
            return (
                <div style={{display: 'flex', flexDirection: 'row', wordBreak: 'break-word', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                    <p style={{marginBottom: 0, marginRight: 12}}>{this.props.title}</p>
                    <p style={{marginBottom: 0, opacity: 0.5}}>{this.props.value}</p>
                </div>
            )
        }
    }

}



export default KeyValueRow;