/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { Breakpoints } from '../../tools';
import Address from '../../models/Address';
import OptionalCol from '../OptionalCol';
import { States } from '../../enums';
import CustomControl from './CustomControl';
import CustomDropDown from './CustomDropDown';
import ControlStateProps from '../../models/State/ControlStateProps';
import { Validation } from '../../validation';

export default function AddressControl({title, originalValue, containerBreakpoints, breakpoints, address, validate = true}) {
    return (
        <OptionalCol breakpoints={containerBreakpoints}>
            <Form.Group>
                <Form.Label>{title}</Form.Label>
                <Container fluid style={{border: '1px solid lightgray', borderRadius: 6, padding: 12, marginBottom: 8}}>
                    <Row>
                        <CustomControl floatingLabel originalValue={originalValue?.thoroughfare} maxLength={30} title='Street Address 1' stateProps={new ControlStateProps('thoroughfare', address, validate ? Validation.streetAddress1 : null)}  breakpoints={breakpoints}/>
                        <CustomControl floatingLabel originalValue={originalValue?.premise} maxLength={30} title='Street Address 2' stateProps={new ControlStateProps('premise', address)} breakpoints={breakpoints}/>
                        <CustomControl floatingLabel originalValue={originalValue?.locality} maxLength={20} title='City' stateProps={new ControlStateProps('locality', address, validate ? Validation.nonEmptyString : null)} breakpoints={breakpoints}/>
                        <CustomDropDown items={States} title='State' stateProps={new ControlStateProps('administrativeArea', address, validate ? Validation.nonEmptyString : null)} breakpoints={breakpoints}/>
                        <CustomControl floatingLabel originalValue={originalValue?.postalCode} type='number' maxLength={5} title='Postal Code' stateProps={new ControlStateProps('postalCode', address, validate ? Validation.postalCode : null)} breakpoints={breakpoints}/>
                    </Row>
                </Container>
            </Form.Group>
        </OptionalCol>
    )

}