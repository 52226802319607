/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useRef, useState } from 'react';
import A4Page1 from '../../../../../assets/A4-1.png';
import { Button, Modal } from 'react-bootstrap';
import ControlStateProps from '../../../../../models/State/ControlStateProps';
import { pdf } from '@react-pdf/renderer';
import DocumentWizard from '../../../DocumentWizard';
import DocumentField from '../../../DocumentField';
import SubscriberWrapper from '../../../../../components/SubscriberWrapper';
import DocumentCheckbox from '../../../DocumentCheckbox';
import DocumentSignatureButton from '../../../DocumentSignatureButton';
import { Validation } from '../../../../../validation';
import moment from 'moment';
import A4Pdf from './A4Pdf';
import { AppGlobals } from '../../../../../App';
import { reactPdfToBase64 } from '../../../../../tools';
import { submitDocument } from '../../../../../services/EmployeeOnboardingService';
import A4 from './A4';
import LoadingWrapper from '../../../../../components/LoadingWrapper';

export default function A4Form({document, userData, companyData, isEmployer, signature, hideModal, onboardingToken, updateDocumentToCompleted}) {
    const [isLoading, setIsLoading] = useState(false);
    const [a4, setA4] = useState(A4.initDefault());
    const [zoom, setZoom] = useState(1);

    const fieldRefs = [
        useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(),
        useRef(), useRef(), useRef(), useRef(), useRef(), useRef()
    ]

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        setA4(await A4.initFromData(document, userData, companyData, isEmployer));
        setIsLoading(false);
    }

    const handleSubmit = async () => {
        const pdf = <A4Pdf signature={signature} a4={a4} isEmployer={isEmployer}/>;
        const base64 = await reactPdfToBase64(pdf);
        const response = await submitDocument(a4.encode(document, base64), onboardingToken);
        if (response.status === 200) {
            updateDocumentToCompleted('a4', base64);
            hideModal();
            AppGlobals.alert('success', response.message);
        } else {
            AppGlobals.alert('danger', response.message);
        }
    }

    return (
        <>
            <Modal.Header closeButton>
                
            </Modal.Header>
            <Modal.Body style={{padding: 0, overflowX: 'scroll', backgroundColor: 'lightgray'}}>
                <LoadingWrapper isLoading={isLoading}>

                    <div style={{transform: `scale(${zoom})`, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 12, height: (1000 + 16) * zoom}}>
                        <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                            <img src={isEmployer ? a4.employeeSubmission[0] ?? A4Page1 : A4Page1} style={{width: 780, height: 1000}}/>
                            {!isEmployer ? 
                                    ///EMPLOYEE
                                <>
                                    <DocumentField innerRef={fieldRefs[0]} stateProps={new ControlStateProps('employeeName', a4, Validation.nonEmptyString)} maxLength={50} x={51} y={237} width={350} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[1]} stateProps={new ControlStateProps('ssn', a4, Validation.ssn)} type='number' maxLength={9} x={570} y={237} width={150} fontSize={14}/>

                                    <DocumentField innerRef={fieldRefs[2]} stateProps={new ControlStateProps('streetAddress', a4, Validation.nonEmptyString)} maxLength={50} x={51} y={267} width={320} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[3]} stateProps={new ControlStateProps('city', a4, Validation.nonEmptyString)} maxLength={20} x={410} y={267} width={150} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[4]} stateProps={new ControlStateProps('state', a4, Validation.nonEmptyString)} maxLength={2} x={580} y={267} width={40} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[5]} stateProps={new ControlStateProps('zipCode', a4, Validation.nonEmptyString)} maxLength={5} type={'number'} x={642} y={267} width={95} fontSize={14}/>
                                    
                                    <DocumentField innerRef={fieldRefs[6]} stateProps={new ControlStateProps('noPersonalExemptions', a4)} type='number' minInt={0} maxInt={1} x={680} y={337} width={45} fontSize={16}/>
                                    <DocumentField innerRef={fieldRefs[7]} stateProps={new ControlStateProps('singleOrMarriedFilingSeparate', a4)} maxLength={2} x={680} y={367} width={45} fontSize={16}/>
                                    <DocumentField innerRef={fieldRefs[8]} stateProps={new ControlStateProps('marriedOrSingleClaimingHead', a4)} maxLength={1} x={680} y={412} width={45} fontSize={16}/>
                                    <DocumentField innerRef={fieldRefs[9]} stateProps={new ControlStateProps('numberOfDependents', a4)} type='number' minInt={0} maxInt={255} x={680} y={442} width={45} fontSize={16}/>
                                    <DocumentField innerRef={fieldRefs[10]} stateProps={new ControlStateProps('additionalWitholding', a4)} type='number' maxDecimal={9999.99} x={642} y={474} width={100} fontSize={15}/>

                                    <DocumentSignatureButton innerRef={fieldRefs[11]} signature={signature} stateProps={new ControlStateProps('signed', a4, Validation.true)} x={180} y={582} width={148} height={30}/>
                                    <SubscriberWrapper stateProps={new ControlStateProps('signed', a4, null, 'wrapper for date')} render={() => {
                                        return (!a4.signed ? '' : 
                                        <span style={{position: 'absolute', left: 610, top: 590}}>{moment().format('MM/DD/YYYY')}</span>
                                        )
                                    }}/>
                                </>
                            : 
                                    ///EMPLOYER
                                <>               
                                    <DocumentField innerRef={fieldRefs[0]} stateProps={new ControlStateProps('totalExemptions', a4, Validation.nonEmptyString)} maxLength={5} x={665} y={506} width={70} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[1]} stateProps={new ControlStateProps('employerName', a4, Validation.nonEmptyString)} maxLength={50} x={49} y={657} width={450} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[2]} stateProps={new ControlStateProps('ein', a4, Validation.nonEmptyString)} type='number' maxLength={9} x={570} y={657} width={150} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[3]} stateProps={new ControlStateProps('employerAddress', a4, Validation.nonEmptyString)} maxLength={30} x={49} y={688} width={320} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[4]} stateProps={new ControlStateProps('employerCity', a4, Validation.nonEmptyString)} maxLength={20} x={414} y={688} width={150} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[5]} stateProps={new ControlStateProps('employerState', a4, Validation.nonEmptyString)} maxLength={2} x={583} y={688} width={40} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[6]} stateProps={new ControlStateProps('employerZipCode', a4, Validation.nonEmptyString)} type='number' maxLength={5} x={643} y={688} width={70} fontSize={14}/>
                                </>
                            }
                        </div>
                    </div>
                </LoadingWrapper>
            </Modal.Body>
            <DocumentWizard document={a4} fieldRefs={fieldRefs} refCount={isEmployer ? 7 : 12} onSubmit={handleSubmit} zoom={zoom} setZoom={setZoom}/>
        </>
    )
}
