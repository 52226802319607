/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import { Validation } from "../../../validation";
import CustomQuestion from "../../../models/CustomQuestion";
import StateObject from "../../../models/State/StateObject";
import Decoder from "../../../decoding";
import { JobApplicationMinimal } from "../../JobApplication/models/JobApplicationMinimal";
import { validateDecimal, validateDecimalFixed, validateInteger } from "../../../tools";
import Terminal from "../../../models/Terminal";
import JobPostTerminal from "./JobPostTerminal";

export default class JobPost extends StateObject {
    uid: number;
    companyIdentifier: number;
    dateCreated: string;
    dateLastModified: string;
    dateActivated: string | undefined;
    active: boolean;
    title: string;
    description: string;
    employmentType: string;
    shiftType: string;
    shiftDurations: string[];
    compensationMin: number;
    compensationMax: number;
    payType: string;
    jobSites: string[];
    questions: CustomQuestion[];
    expirationDate: string | undefined;
    benefits: string | undefined;
    terminals: JobPostTerminal[];

    constructor(
        uid: number,
        companyIdentifier: number,
        dateCreated: string,
        dateLastModified: string,
        dateActivated: string | undefined,
        active: boolean,
        title: string,
        description: string,
        employmentType: string,
        shiftType: string,
        shiftDurations: string[],
        compensationMin: number,
        compensationMax: number,
        payType: string,
        jobSites: string[],
        questions: CustomQuestion[],
        expirationDate: string | undefined,
        benefits: string | undefined,
        terminals: JobPostTerminal[],
    ) {
        super();
        this.uid = uid;
        this.companyIdentifier = companyIdentifier;
        this.dateCreated = dateCreated;
        this.dateLastModified = dateLastModified;
        this.dateActivated = dateActivated;
        this.active = active;
        this.title = title;
        this.description = description;
        this.employmentType = employmentType;
        this.shiftType = shiftType;
        this.shiftDurations = shiftDurations;
        this.compensationMin = compensationMin;
        this.compensationMax = compensationMax;
        this.payType = payType;
        this.jobSites = jobSites;
        this.questions = questions;
        this.expirationDate = expirationDate;
        this.benefits = benefits;
        this.terminals = terminals;
        this.initChildren();
    }

    static initDefault(): JobPost {
        return new JobPost(
            -1,
            -1,
            moment().format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD'),
            undefined,
            true,
            '',
            '',
            'fulltime',
            'weekdays', 
            [],
            10.0, 
            20.0,
            'ph',
            ['indeed', 'monster', 'ziprecruiter', 'flexjobs'],
            [],
            '',
            '',
            []
        );
    }

    static decode(json: {[key: string]: any}): JobPost {
        const decoder = new Decoder(json);
        const uid: number = decoder.decode('uid', Decoder.Uid);
        const companyIdentifier: number = decoder.decode('companyIdentifier', Decoder.Uid);
        const dateCreated: string = decoder.decode('dateCreated', Decoder.Date);
        const dateLastModified: string = decoder.decode('dateLastModified', Decoder.Date);
        const dateActivated: string | undefined = decoder.decode('dateLastModified', Decoder.Date, {defaultValue: undefined, warn: false});
        const active: boolean = decoder.decode('active', Decoder.Boolean);
        const title: string = decoder.decode('title', Decoder.NonEmptyString);
        const description: string = decoder.decode('description', Decoder.NonEmptyString);
        const employmentType: string = decoder.decode('employmentType', Decoder.NonEmptyString);
        const shiftType: string = decoder.decode('shiftType', Decoder.NonEmptyString);
        const shiftDurations: string[] = decoder.decode('shiftDurations', Decoder.NonEmptyArrayFromString);
        const compensationMin: number = decoder.decode('compensationMin', Decoder.Decimal);
        const compensationMax: number = decoder.decode('compensationMax', Decoder.Decimal);
        const payType: string = decoder.decode('payType', Decoder.NonEmptyString);
        const jobSites: string[] = decoder.decode('jobSites', Decoder.ArrayFromString);
        const questions: CustomQuestion[] = (decoder.decode('questions', Decoder.Array) as any[] ?? []).map(q => CustomQuestion.decode(q));
        const expirationDate: string = decoder.decode('expirationDate', Decoder.Date, {defaultValue: '', warn: false});
        const benefits: string = decoder.decode('benefits', Decoder.StringStrict, {defaultValue: '', warn: false});
        const terminals: JobPostTerminal[] = (decoder.decode('terminals', Decoder.Array) as any[] ?? []).map(t=>JobPostTerminal.decode(t));
        if (decoder.checkForErrors()) {
            return new JobPost(uid, companyIdentifier, dateCreated, dateLastModified, dateActivated, active, title, description, employmentType, shiftType, 
                shiftDurations, compensationMin, compensationMax, payType, jobSites, questions, expirationDate, benefits, terminals);
        } else {
            return JobPost.initDefault();
        }
    }

    encode() {
        return {
            uid: this.uid, 
            companyIdentifier: this.companyIdentifier, 
            dateCreated: this.dateCreated, 
            dateLastModified: this.dateLastModified, 
            dateActivated: this.dateActivated, 
            active: this.active,
            title: this.title, 
            description: this.description, 
            employmentType: this.employmentType, 
            shiftType: this.shiftType, 
            shiftDurations: JSON.stringify(this.shiftDurations), 
            compensationMin: validateDecimalFixed(this.compensationMin as any, 2), 
            compensationMax: validateDecimalFixed(this.compensationMax as any, 2), 
            payType: this.payType, 
            jobSites: JSON.stringify(this.jobSites), 
            questions: this.questions.map(q=>q.encode()),
            expirationDate: this.expirationDate ? this.expirationDate : null, 
            benefits: this.benefits, 
            terminals: this.terminals.map(t=>t.encode())
        }
    }

    // duplicate(): JobPost {
    //     return new JobPost(
    //         this.uid, 
    //         this.companyIdentifier, 
    //         this.dateCreated, 
    //         this.dateLastModified, 
    //         this.dateActivated, 
    //         this.applicants, //
    //         this.status,
    //         this.title,
    //         this.description,
    //         this.employmentType,
    //         this.shiftTypes,
    //         Array.from(this.shiftDurations),
    //         this.compensationMin,
    //         this.compensationMax,
    //         this.payType,
    //         this.availablePositions,
    //         Array.from(this.jobSites),
    //         this.questions, //
    //         this.expirationDate,
    //         this.benefits,
    //         this.location,
    //     )
    // }
}