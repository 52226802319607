/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import IT4Page1 from '../../../../../assets/it4-1.png';
import IT4Page2 from '../../../../../assets/it4-2.png';
import IT4Page3 from '../../../../../assets/it4-3.png';
import {Document, Page, Image, } from '@react-pdf/renderer';
import moment from 'moment';
import AbsoluteText from '../../AbsoluteText';
import Signature from '../../../Signature/Signature';
import IT4 from './IT4';
import { format } from 'path';
import { formatSsn } from '../../../../../tools';


type IT4PdfProps = {
    it4: IT4,
    signature: Signature
}

export default function IT4Pdf({it4, signature}: IT4PdfProps) {

    return (
        <Document>
            <Page size='LETTER' style={{fontSize: 12}}>
                <Image src={IT4Page1}/>
                <AbsoluteText y={211} x={43} text={it4.name}/>
                <AbsoluteText y={211} x={362} text={formatSsn(it4.ssn)}/>
                <AbsoluteText y={246} x={43} text={it4.address}/>
                <AbsoluteText y={283} x={43} text={it4.schoolDistrictOfResidence}/>
                <AbsoluteText y={283} x={362} text={it4.schoolDistrictNumber}/>

                <AbsoluteText y={334} x={525} text={it4.section2Dependent}/>
                <AbsoluteText y={358} x={525} text={it4.section2Single}/>
                <AbsoluteText y={381} x={525} text={it4.section2NumberOfDependents}/>
                <AbsoluteText y={404} x={525} text={`${it4.section2WitholdingExemptions()}`}/>
                <AbsoluteText y={429} x={494} text={it4.section2AdditionalWitholdings}/>

                <AbsoluteText y={500} x={44} text={it4.section3FullYearResident ? 'X' : ''} fontSize={14}/>
                <AbsoluteText y={524} x={44} text={it4.section3ResidentMilitary ? 'X' : ''} fontSize={14}/>
                <AbsoluteText y={548} x={44} text={it4.section3NonResidentMilitary ? 'X' : ''} fontSize={14}/>
                <AbsoluteText y={574} x={44} text={it4.section3NonResidentCivilian ? 'X' : ''} fontSize={14}/>
                <AbsoluteText y={608} x={44} text={it4.section3Exempt ? 'X' : ''} fontSize={14}/>

                <Image src={signature.image} style={{position: 'absolute', top: 691, left: 140, width: 150, height: 33}}/>
                <AbsoluteText y={713} x={435} text={moment().format('MM/DD/YYYY')}/>
            </Page>
            <Page size='LETTER' style={{fontSize: 10}}>
                <Image src={IT4Page2}/>
            </Page>
            <Page size='LETTER' style={{fontSize: 10}}>
                <Image src={IT4Page3}/>
            </Page>
        </Document>
    )
}