/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Validation } from "../../../validation";
import Address from "../../../models/Address";
import Education from "../../../models/JobApplication/Education";
import EmploymentHistory from "../../../models/JobApplication/EmploymentHistory";
import MilitaryService from "../../../models/JobApplication/MilitaryService";
import CustomQuestionAnswer from "../../../models/JobApplication/CustomQuestionAnswer";
import moment from "moment";
import StateObject from "../../../models/State/StateObject";
import Decoder from "../../../decoding";
import JobApplicationNote from "../../../models/JobApplication/JobApplicationNote";
import Interview from "../../../models/JobApplication/Interview";
import Terminal from "../../../models/Terminal";

export default class JobApplicationForJobPost extends StateObject {
    uid; //: number;
    jobPostIdentifier; //: number;
    dateApplied; //: string | undefined;
    passedDrugScreen; //: string | undefined;
    passedBackground; //: string | undefined;
    interviews; //: string[];
    hired; //: string | undefined;
    clientIdentifier; //: uid || null;
    rejected; //: boolean;
    firstName; //: string;
    middleName; //: string;
    lastName; //: string;
    phoneNumber; //: string;
    email; //: string;
    dateOfBirth; //: string;
    address; //: Address;
    dateAvailable; //: string;
    eligibleToWork; //: boolean | undefined;
    convictedOfFelony; //: boolean | undefined;
    isVeteran; //: boolean | undefined;
    education; //: Education[];
    employmentHistory; //: EmploymentHistory[];
    customQuestionAnswers; //: CustomQuestionAnswer[];
    backgroundCheckConsent; //: boolean | undefined;
    militaryService; //: MilitaryService | undefined;
    notes; //: JobApplicationNote[];
    felonyConvictionNotes; //?: string;
    resume; //?: string;
    source; // string | null
    terminal; //Terminal

    constructor(
        uid,
        jobPostIdentifier,
        dateApplied,
        passedDrugScreen,
        passedBackground,
        interviews,
        hired,
        clientIdentifier,
        rejected,
        firstName,
        middleName,
        lastName,
        phoneNumber,
        email,
        dateOfBirth,
        address,
        dateAvailable,
        eligibleToWork,
        convictedOfFelony,
        isVeteran,
        education,
        employmentHistory,
        customQuestionAnswers,
        backgroundCheckConsent,
        militaryService,
        notes,
        felonyConvictionNotes,
        resume,
        source,
        terminal
    ) {
        super();
        this.uid = uid;
        this.jobPostIdentifier = jobPostIdentifier;
        this.dateApplied = dateApplied;
        this.passedDrugScreen = passedDrugScreen;
        this.passedBackground = passedBackground;
        this.interviews = interviews;
        this.hired = hired;
        this.clientIdentifier= clientIdentifier;
        this.rejected = rejected;
        this.firstName = firstName;
        this.middleName = middleName;
        this.lastName = lastName;
        this.phoneNumber = phoneNumber;
        this.email = email;
        this.dateOfBirth = dateOfBirth;
        this.address = address;
        this.dateAvailable = dateAvailable;
        this.eligibleToWork = eligibleToWork;
        this.convictedOfFelony = convictedOfFelony;
        this.isVeteran = isVeteran;
        this.education = education;
        this.employmentHistory = employmentHistory;
        this.customQuestionAnswers = customQuestionAnswers;
        this.backgroundCheckConsent = backgroundCheckConsent;
        this.militaryService = militaryService;
        this.notes = notes;
        this.felonyConvictionNotes = felonyConvictionNotes;
        this.resume = resume;
        this.source = source;
        this.terminal = terminal;
        this.initChildren();
    }

    static initDefault() {
        const jobApplication =  new JobApplicationForJobPost(
            -1, 
            -1, 
            moment().format('YYYY-MM-DD'),
            undefined,
            undefined,
            [],
            undefined,
            null,
            false,
            '', 
            '', 
            '', 
            '', 
            '', 
            '', 
            undefined,
            '', 
            undefined, 
            undefined, 
            undefined, 
            [], 
            [], 
            [], 
            undefined, 
            undefined, 
            [],
            undefined, 
            undefined,
            null,
            Terminal.initDefault()
        );
        jobApplication.address = Address.initDefault('address', jobApplication);
        return jobApplication; 
    }

    static initFromJobPost(jobPost) {
        const jobApplication = this.initDefault();
        this.jobPostIdentifier = jobPost.uid;
        const questionAnswers = jobPost.questions.map(q => CustomQuestionAnswer.initFromQuestion(q));
        jobApplication.customQuestionAnswers = questionAnswers;
        jobApplication.initChildren();
        return jobApplication;
    }

    


    static decode(json) {
        const decoder = new Decoder(json);
        const uid = decoder.decode('uid', Decoder.Uid);
        const jobPostIdentifier = decoder.decode('jobPostIdentifier', Decoder.Uid);
        const dateApplied = decoder.decode('dateApplied', Decoder.Date, {defaultValue: undefined, warn: false});
        const passedDrugScreen = decoder.decode('passedDrugScreen', Decoder.Date, {defaultValue: undefined, warn: false});
        const passedBackground = decoder.decode('passedBackground', Decoder.Date, {defaultValue: undefined, warn: false});
        const interviews = decoder.decode('interviews', Decoder.Array).map(obj => Interview.decode(obj));
        const hired = decoder.decode('hired', Decoder.Date, {defaultValue: undefined, warn: false});
        const clientIdentifier = decoder.decode('clientIdentifier', Decoder.Uid, {defaultValue: null, warn: false});
        const rejected = decoder.decode('rejected', Decoder.Boolean);
        const firstName = decoder.decode('firstName', Decoder.StringStrict);
        const middleName = decoder.decode('middleName', Decoder.StringStrict);
        const lastName = decoder.decode('lastName', Decoder.StringStrict);
        const phoneNumber = decoder.decode('phoneNumber', Decoder.StringStrict);
        const email = decoder.decode('email', Decoder.StringStrict);
        const dateOfBirth = decoder.decode('dateOfBirth', Decoder.Date);
        const address = Address.decode(json.address);
        const dateAvailable = decoder.decode('dateAvailable', Decoder.Date);
        const eligibleToWork = decoder.decode('eligibleToWork', Decoder.Boolean);
        const convictedOfFelony = decoder.decode('convictedOfFelony', Decoder.Boolean);
        const isVeteran = decoder.decode('isVeteran', Decoder.Boolean);
        const education = (decoder.decode('education', Decoder.Array) ?? []).map(e => Education.decode(e));
        const employmentHistory = (decoder.decode('employmentHistory', Decoder.Array)).map(e => EmploymentHistory.decode(e));
        const customQuestionAnswers = (decoder.decode('customQuestionAnswers', Decoder.Array)).map(qa => CustomQuestionAnswer.decode(qa));
        const backgroundCheckConsent = decoder.decode('backgroundCheckConsent', Decoder.Boolean);
        const militaryService = json.militaryService === null ? null : MilitaryService.decode(json.militaryService);
        const notes = (decoder.decode('notes', Decoder.Array) ?? []).map(n => JobApplicationNote.decode(n));
        const felonyConvictionNotes = decoder.decode('felonyConvictionNotes', Decoder.String, {defaultValue: undefined, warn: false});
        const resume = decoder.decode('resume', Decoder.String, {defaultValue: '', warn: false});
        const source = decoder.decode('source', Decoder.String, {defaultValue: null, warn: false});
        const terminal = Terminal.decode(json.terminal);
        
        if (decoder.checkForErrors()) {
            return new JobApplicationForJobPost(uid, jobPostIdentifier, dateApplied, passedDrugScreen, passedBackground, interviews, hired, clientIdentifier, rejected, firstName, middleName, lastName,
                phoneNumber, email, dateOfBirth, address, dateAvailable, eligibleToWork, convictedOfFelony, isVeteran, education, employmentHistory, customQuestionAnswers, backgroundCheckConsent, militaryService, notes, felonyConvictionNotes, resume, source, terminal);
        } else {
            return JobApplicationForJobPost.initDefault();
        }
    }

    // encode() {
    //     return {
    //         uid: this.uid,
    //         jobPostIdentifier: this.jobPostIdentifier,
    //         dateApplied: this.dateApplied,
    //         passedDrugScreen: this.passedDrugScreen,
    //         passedBackground: this.passedBackground,
    //         interviews: this.interviews.map(i => i.encode()), //need encoding
    //         hired: this.hired,
    //         clientIdentifier: this.clientIdentifier,
    //         rejected: this.rejected,
    //         firstName: this.firstName,
    //         middleName: this.middleName,
    //         lastName: this.lastName,
    //         phoneNumber: this.phoneNumber,
    //         email: this.email,
    //         dateOfBirth: this.dateOfBirth,
    //         ssn: this.ssn,
    //         address: this.address.encode(),
    //         dateAvailable: this.dateAvailable,
    //         eligibleToWork: this.eligibleToWork,
    //         convictedOfFelony: this.convictedOfFelony,
    //         isVeteran: this.isVeteran,
    //         education: this.education.map(e => e.encode()),
    //         employmentHistory: this.employmentHistory.map(eh => eh.encode()),
    //         customQuestionAnswers: this.customQuestionAnswers.map(a => a.encode()),
    //         backgroundCheckConsent: this.backgroundCheckConsent,
    //         militaryService: this.militaryService?.encode(),
    //         notes: this.notes,
    //         felonyConvictionNotes: this.felonyConvictionNotes,
    //         resume: this.resume
    //     }
    // }

    // duplicate() {
    //     return new JobApplicationForJobPost(
    //         this.uid, 
    //         this.jobPostIdentifier, 
    //         this.dateApplied, 
    //         this.passedDrugScreen, 
    //         this.passedBackground, 
    //         this.interviews, 
    //         this.hired, 
    //         this.clientIdentifier, 
    //         this.rejected, 
    //         this.firstName, 
    //         this.middleName, 
    //         this.lastName, 
    //         this.phoneNumber, 
    //         this.email, 
    //         this.dateOfBirth,
    //         this.ssn, 
    //         this.address,
    //         this.dateAvailable,
    //         this.eligibleToWork,
    //         this.convictedOfFelony,
    //         this.isVeteran,
    //         this.education,
    //         this.employmentHistory,
    //         this.customQuestionAnswers,
    //         this.backgroundCheckConsent,
    //         this.militaryService,
    //         this.notes,
    //         this.felonyConvictionNotes,
    //         this.resume
    //     )
    // }
}

