/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { ChangeEvent, useEffect, useReducer, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Breakpoints } from '../../tools';
import OptionalCol from '../OptionalCol';
import ValidationFeedback from '../ValidationFeedback';
// import { StateWrapperProtocol } from '../../models/State/StateWrapper/StateWrapper';
// import StateWrapperPrimitive from '../../models/State/StateWrapper/StateWrapperPrimitive';


export default function CustomYesNoControl({value, setValue, title, breakpoints, stateProps}) {
    const [showValidation, setShowValidation] = useState(false);
    const [_, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        stateProps?.subscribe((showValidation) => {
            if (showValidation) {
                setShowValidation(true);
            }
            forceUpdate();
        });
    }, [stateProps]);

    const handleSetValue = (newValue) => {
        if (setValue) {
            setValue(newValue);
        }
        if (stateProps) {
            stateProps.stateObject.setState(stateProps.stateKey, newValue);
        }
    }

    return (
        <OptionalCol breakpoints={breakpoints}>
            <div className={'form-control ' + (stateProps?.getIsInvalid() && showValidation ? 'is-invalid' : stateProps?.getIsValid() && showValidation ? 'is-valid' : '')} style={{position: 'relative', display: 'flex', gap: 12, minHeight: 58, alignItems: 'center', justifyContent: 'space-between', padding: '0px 38px 0px 6px'}} onBlur={() => {setShowValidation(true)}}>
                <div>{title}<span style={{color: 'red'}}>{(stateProps?.hasValidation()) ? '*' : ''}</span></div>
                <div style={{display: 'flex', gap: 12}}>
                    <Form.Check label='Yes' type='radio' checked={stateProps?.stateObject[stateProps.stateKey] === true ?? value === true} onChange={() => {handleSetValue(true)}}/>
                    <Form.Check label='No' type='radio' checked={stateProps?.stateObject[stateProps.stateKey] === false ?? value === false} onChange={() => {handleSetValue(false)}}/>
                </div>
                <ValidationFeedback stateProps={stateProps} showValidation={showValidation}/>
            </div>
        </OptionalCol>
    )
}