/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from 'moment';


export default function DirectDepositFormBackground({dd}) {

    return (
        <div style={{position: 'relative', padding: 75, width: 850, height: 1100, backgroundColor: 'white'}}>
            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', marginBottom: 12}}>
                <span style={{fontSize: 30}}>Direct Deposit Authorization Form</span>
                <span style={{fontSize: 18}}>{dd.employerName}</span>
            </div>

            <div style={{borderLeft: '1px solid black', borderTop: '1px solid black', width: '100%', fontSize: 12, color: 'gray'}}>
                <div style={{display: 'flex', borderBottom: '1px solid black', width: '100%', flexDirection: 'row', height: 45}}>
                    <div style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                        <span>Employee Name</span>
                    </div>
                    <div style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                        <span>Email Address</span>
                    </div>
                    <div style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                        <span>Phone Number</span>
                    </div>
                </div>
                <div style={{display: 'flex', borderBottom: '1px solid black', width: '100%', flexDirection: 'row', height: 45}}>
                    <div style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                        <span>Financial Institution Name</span>
                    </div>
                    <div style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                        <span>ABA Routing Number</span>
                    </div>
                    <div style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                        <span>Account Number</span>
                    </div>
                </div>
            </div>
            <div style={{display: 'flex', border: '1px solid black', borderTop: 'none', width: '100%', flexDirection: 'row', alignItems: 'center', fontSize: 18, gap: 12, padding: 3}}>
                <span>Account Type</span>
                <div style={{borderBottom: '2px dotted gray', flex: 1}}/>

                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 6}}>
                    <span>Checking</span>
                    <div style={{width: 15, height: 15}}/>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 6}}>
                    <span>Savings</span>
                    <div style={{width: 15, height: 15}}/>
                </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', gap: 18, marginTop: 18}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <span style={{fontSize: 17, textAlign: 'center', lineHeight: 1}}>A PRINTED BANK DOCUMENT, INCLUDING YOUR NAME, BANK NAME, ROUTING NUMBER</span>
                    <span style={{fontSize: 17, textAlign: 'center', lineHeight: 1}}>AND ACCOUNT NUMBER IS REQUIRED FOR VERIFICATION. ACCEPTED BANK DOCS</span>
                    <span style={{fontSize: 17, textAlign: 'center', lineHeight: 1}}>INCLUDE VOIDED CHECK, DIRECT DEPOSIT FORM OR BANK APP SCREENSHOT; TWO</span>
                    <span style={{fontSize: 17, textAlign: 'center', lineHeight: 1}}>SCREENSHOTS MAY BE NECESSARY TO PROVIDE REQUIRED INFORMATION. FAILURE TO</span>
                    <span style={{fontSize: 17, textAlign: 'center', lineHeight: 1}}>PROVIDE REQUIRED BANK DOCUMENT MAY CAUSE DELAY OF PAYROLL PROCESSING</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <span style={{fontSize: 17, textAlign: 'center', lineHeight: 1}}>NO HAND WRITTEN DOCUMENTS ARE ACCEPTED</span>
                    <span style={{fontSize: 17, textAlign: 'center', lineHeight: 1}}>DO NOT USE A DEPOSIT SLIP TO OBTAIN YOUR ACCOUNT NUMBER!</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: 18, marginTop: 36}}>
                    <span style={{fontSize: 15, lineHeight: 1}}>{`I hereby authorize ${dd.employerName} to direct electronic deposits to the account noted above for my periodic payroll checks and I authorize the financial institution designated herein to accept such electronic funds transfers. I have indicated the account at the financial institution to which deposits are to be made and understand that this decision can only be changed by me upon written instruction delivered to ${dd.employerName}. I understand that ${dd.employerName} may cancel this direct deposit program at any time.`}</span>
                    <span style={{fontSize: 15, lineHeight: 1}}>{`I understand that it is my responsibility to confirm the accuracy of all transfers to my designated account and acknowledge that ${dd.employerName} has received a written termination notification from me in such time to afford ${dd.employerName} a reasonable opportunity to act on it.`}</span>
                </div>

            </div>

            <div style={{display: 'flex', flexDirection: 'row', gap: 75, fontSize: 18, marginTop: 72}}>
                <span style={{borderTop: '1px solid black', paddingLeft: 18, paddingRight: 75, paddingTop: 6}}>Employee's Signature</span>
                <span style={{borderTop: '1px solid black', paddingLeft: 18, paddingRight: 75, paddingTop: 6}}>Date</span>
            </div>
        </div>
    )
}