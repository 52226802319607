/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './NavigationBar.css';
import React, { CSSProperties, useState } from 'react';
import CustomButton from '../CustomButton';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell, faBriefcase, faBuilding, faBuildingUser, faCog, faHome, faInbox, faPenToSquare, faRightFromBracket, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/TTA_80x80.png';
import WindowResizeDetector from '../WindowResizeDetector';
import { Badge, Button, Dropdown, Modal } from 'react-bootstrap';
import { AppGlobals } from '../../App';
import { logout } from '../../services/AuthenticationService';
import RichTextEditor from '../RichText/RichTextEditor';
import RichText from '../RichText/RichText';
import ApiButton from '../ApiButton';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { markNotificationRead } from '../../services/UserService';
import { stopShadowing } from '../../services/AdminService';

export default function NavigationBar({auth, notifications, setNotifications}) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1030);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);

    const handleLogout = async () => {
        setIsSubmitting(true);
        const response = await logout();
        if (response.status === 200) {
            AppGlobals.checkAuthentication();
        } else {
            AppGlobals.alert('danger', response.message);
        }
        setIsSubmitting(false);
    }

    const handleStopShadowing = async () => {
        setIsSubmitting(true);
        const response = await stopShadowing();
        if (response.status === 200) {
            window.location.reload();
        } else {
            AppGlobals.alert('danger', response.message);
        }
        setIsSubmitting(false);
    }

    const menuItems = auth.userIdentifier ? [
        {title: 'Home', icon: faHome, to: '/home'},
        {title: 'Clients', icon: faUsers, to: '/clients'},
        {title: 'My Company', icon: faBuildingUser, to: '/my-company'},
        {title: 'Job Posts', icon: faBriefcase, to: '/jobs'},
        {title: 'Job Creator', icon: faPenToSquare, to: '/post'}
    ] : [
        {title: 'Recruiters', icon: faUsers, to: '/recruiters'},
        {title: 'Applicants', icon: faInbox, to: '/applicants'},
    ]

    const menuElements = menuItems.map((item) => {
        return (
            <NavLink exact key={item.title} to={item.to} className={isActive => isActive ? 'nav-bar-button nav-bar-button-active' : 'nav-bar-button'}>
                <FontAwesomeIcon icon={item.icon}/>
                <span>{item.title}</span>
            </NavLink>
        )
    });

    const dropdownItems = menuItems.map((item) => {
        return (
            <Dropdown.Item key={item.title} style={{padding: 0}} as={'div'}>
                <NavLink exact  to={item.to} className={isActive => isActive ? 'nav-bar-button-mobile nav-bar-button-mobile-active' : 'nav-bar-button-mobile'}>
                    <FontAwesomeIcon icon={item.icon}/>
                    <span>{item.title}</span>
                </NavLink>
            </Dropdown.Item>
        )
    });

    const dropdownMenu = (
        <Dropdown>
            <Dropdown.Toggle style={{color: 'white'}}>
                <FontAwesomeIcon icon={faBars}/>
            </Dropdown.Toggle>
            <Dropdown.Menu style={{zIndex: 10000}}>
                {dropdownItems}
                { dropdownItems.length > 0 && 
                    <Dropdown.Divider/>
                }
                <Dropdown.Item style={{padding: 0}} as={'div'}>
                    <NavLink exact to={'/settings'} className={isActive => isActive ? 'nav-bar-button-mobile nav-bar-button-mobile-active' : 'nav-bar-button-mobile'}>
                        <FontAwesomeIcon icon={faCog}/>
                        <span>Settings</span>
                    </NavLink>
                </Dropdown.Item>
                <Dropdown.Item style={{padding: 0}} onClick={() => {setShowLogoutModal(true)}}>
                    <div className={'nav-bar-button-mobile'}>
                        <FontAwesomeIcon icon={faRightFromBracket}/>
                        <span>Logout</span>
                    </div>
                </Dropdown.Item>
                { auth.adminUserIdentifier && 
                    <>
                        <Dropdown.Divider/>
                        <Dropdown.Item onClick={handleStopShadowing} style={{color: 'red'}}>
                            <FontAwesomeIcon icon={faRightFromBracket} style={{marginRight: 8}}/>
                            <span>Stop Shadowing</span>
                        </Dropdown.Item>
                    </>
                }
            </Dropdown.Menu>
        </Dropdown>
    )

    

    const notificationElements = notifications.map((not) => {
        return (
            <Modal.Footer key={not.uid} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                <RichTextEditor readOnly editorState={RichText.decode(not.richText)}/>
                { not.read ?
                    <CustomButton disabled variant={'success'} icon={faCheckSquare}>Read</CustomButton>
                :
                    <ApiButton icon={faCheckSquare} api={async () => {return await markNotificationRead(not.uid)}} onSuccess={() => {
                        const newNotifications = Array.from(notifications);
                        newNotifications.find(n => n.uid == not.uid).read = true;
                        setNotifications(newNotifications);
                    }}>
                        Mark Read
                    </ApiButton>
                }
            </Modal.Footer>
        )
    });

    return (
        <div style={{backgroundColor: 'white', height: 64, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 12, padding: '0px 12px 0px 12px', borderBottom: '1px solid lightgray'}}>
            <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                <div style={{display: 'flex', gap: 4, alignItems: 'center', marginRight: 20}} className='cursor-default'>
                    <img src={logo} style={{height: 64}} alt='Logo'/>
                    <span className='logo-secondary' style={{fontSize: 30, fontWeight: 'bold', fontFamily: 'sans-serif'}}>Recruiter</span>
                </div>
                { !isMobile &&
                    <>
                        {menuElements}
                    </>
                }
            </div>
            { isMobile ?
                <>
                    {dropdownMenu}
                </>
                :
                <div style={{display: 'flex', gap: 12}}>
                    { notifications.length > 0 && 
                        <Button variant='secondary' style={{color: 'white'}} onClick={() => {setShowNotifications(true)}}>
                            <FontAwesomeIcon icon={faBell}/>
                                <span style={{marginLeft: 4}}>{notifications.length}</span>
                        </Button>
                    }
                    <Dropdown>
                        <Dropdown.Toggle>
                            <FontAwesomeIcon icon={faUser} style={{marginRight: 8}}/>
                            <span>{AppGlobals.CookieMonster.get('userData')['firstName']}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{zIndex: 10000}}>
                            <Dropdown.Item style={{padding: 0}} as={'div'}>
                                <NavLink exact to={'/settings'} className={isActive => isActive ? 'nav-bar-button-mobile nav-bar-button-mobile-active' : 'nav-bar-button-mobile'}>
                                    <FontAwesomeIcon icon={faCog}/>
                                    <span>Settings</span>
                                </NavLink>
                            </Dropdown.Item>
                            <Dropdown.Divider/>
                            <Dropdown.Item onClick={() => {setShowLogoutModal(true)}} style={{color: 'var(--bs-primary)'}}>
                                <FontAwesomeIcon icon={faRightFromBracket} style={{marginRight: 8}}/>
                                <span>Logout</span>
                            </Dropdown.Item>
                            { auth.adminUserIdentifier && 
                                <>
                                    <Dropdown.Divider/>
                                    <Dropdown.Item onClick={handleStopShadowing} style={{color: 'red'}}>
                                        <FontAwesomeIcon icon={faRightFromBracket} style={{marginRight: 8}}/>
                                        <span>Stop Shadowing</span>
                                    </Dropdown.Item>
                                </>
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

            }
            <Modal show={showNotifications} size='lg' onHide={() => {setShowNotifications(false); setNotifications(notifications.filter(n => !n.read));}}>
                <Modal.Header closeButton style={{borderBottom: 'none'}}>
                    <Modal.Title>Notifications</Modal.Title>
                </Modal.Header>
                {notificationElements}
            </Modal>
            <Modal show={showLogoutModal} onHide={() => {setShowLogoutModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Logout?</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{textAlign: 'end'}}>
                    <CustomButton isLoading={isSubmitting} onClick={handleLogout} forceIsLoading>Logout</CustomButton>
                </Modal.Body>
            </Modal>
            <WindowResizeDetector callback={() => {setIsMobile(window.innerWidth < 1030)}}/>
        </div>
    )
}

