/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, createRef } from 'react';
import { Badge, Button, ButtonGroup } from 'react-bootstrap';

type CustomTabButtonsProps = {
    tabs: string[];
    tabIndex: number;
    setTabIndex: (index: number) => void
    fontSizeMult?: number;
    disableBadgesWhen0: boolean;
    iconBreakpoint?: number;
    automaticDefaultTab?: boolean;
    style?: any;
}
type CustomTabButtonsState = {
    width: number;
}

export default class CustomTabButtons extends React.Component<CustomTabButtonsProps, CustomTabButtonsState> {
    constructor(props: CustomTabButtonsProps) {
        super(props);

        this.state = {
            width: 800
        }
        this.handleWindowResize = this.handleWindowResize.bind(this);
    }

    ref = createRef<HTMLDivElement>();

    componentDidMount() {
        this.handleWindowResize();
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize)
    }

    handleWindowResize() {
        const newWidth = this.ref.current?.clientWidth;
        if (newWidth) {
            this.setState({width: newWidth});
        }
    }

    maxFontSize = 17.0;
    widthMult = 0.06;


    render() {

        const buttons = this.props.tabs.map((tab, index) => {
            const isActive = this.props.tabIndex === index;

            return (
                <Button key={index} style={{display: 'flex', paddingLeft: 4, paddingRight: 4, gap: 8, alignItems: 'center', justifyContent: 'center', backgroundColor: !isActive ? 'white' : 'var(--bs-primary)', color: !isActive ? 'var(--bs-primary)' : 'white'}} onClick={() => {this.props.setTabIndex(index)}}>
                    <span>{tab}</span>
                </Button>
            )
        });


        return (
            <div ref={this.ref} style={{display: 'flex', flexDirection: 'column', gap: 12, ...this.props.style}}>
                <ButtonGroup>
                    {buttons}
                </ButtonGroup>
            </div>
        )
    }
}
