/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from 'moment'
import MIW4Page1 from '../../../../../assets/MIW4-1.png';
import MIW4Page2 from '../../../../../assets/MIW4-2.png';
import Signature from '../../../Signature/Signature'
import AbsoluteText from '../../AbsoluteText'
import { Document, Image, Page, Text } from '@react-pdf/renderer'
import { formatPhoneNumber, formatSsn, usdFormatter, validateDecimal } from '../../../../../tools'
import MIW4 from './MIW4';

type MIW4PdfProps = {
    miw4: MIW4
    signature: Signature
    isEmployer: boolean
}

export default function MIW4Pdf({miw4, signature, isEmployer}: MIW4PdfProps) {

    return (
        <Document>
            <Page size='LETTER' style={{fontSize: 11}}>
                <Image src={isEmployer ? miw4.employeeSubmission[0] ?? MIW4Page1 : MIW4Page1}/>
                {!isEmployer ?
                    <>
                        <AbsoluteText y={129} x={345} text={formatSsn(miw4.ssn)}/>
                        <AbsoluteText y={129} x={485} text={moment(miw4.dateOfBirth).format('MM/DD/YYYY')}/>
                        
                        <AbsoluteText y={152} x={50} text={miw4.name}/>
                        <AbsoluteText y={152} x={345} text={miw4.driverOrStateId}/>
                        
                        <AbsoluteText y={176} x={50} text={miw4.homeAddress}/>
                        <AbsoluteText y={200} x={50} text={miw4.city}/>
                        <AbsoluteText y={200} x={225} text={miw4.state}/>
                        <AbsoluteText y={200} x={275} text={miw4.zipCode}/>
                        
                        <AbsoluteText y={176} x={338.5} text={miw4.newEmployee === 0 ? 'X' : ''} fontSize={14}/>
                        <AbsoluteText y={194} x={338.5} text={miw4.newEmployee === 1 ? 'X' : ''} fontSize={14}/>
                        <AbsoluteText y={176} x={485} text={miw4.dateOfHire ? moment(miw4.dateOfHire).format('MM/DD/YYYY') : ''}/>
                        
                        <AbsoluteText y={220} x={515} text={miw4.numberOfExemptions}/>
                        <AbsoluteText y={247} x={481} text={miw4.additionalWitholding}/>
                        
                        <AbsoluteText y={288} x={62} text={miw4.step8a ? 'X' : ''} fontSize={14}/>
                        <AbsoluteText y={306} x={62} text={miw4.step8b ? 'X' : ''} fontSize={14}/>
                        <AbsoluteText y={324.5} x={62} text={miw4.step8c ? 'X' : ''} fontSize={14}/>
                        <AbsoluteText y={308} x={241} text={miw4.step8bExplanation} fontSize={9}/>
                        <AbsoluteText y={325} x={341} text={miw4.step8cResidentZone} fontSize={9}/>
                        
                        <Image src={signature.image} style={{position: 'absolute', top: 420, left: 70, width: 90, height: 16}}/>
                        <AbsoluteText y={421} x={497} text={moment().format('MM/DD/YYYY')}/>
                    </>
                :
                    <>
                        <AbsoluteText y={490} x={42} text={miw4.employerName}/>
                        <AbsoluteText y={490} x={328} text={miw4.ein}/>

                        <AbsoluteText y={514} x={42} text={miw4.employerAddress}/>
                        <AbsoluteText y={514} x={328} text={miw4.employerCity}/>
                        <AbsoluteText y={514} x={472} text={miw4.employerState}/>
                        <AbsoluteText y={514} x={520} text={miw4.employerZipCode}/>

                        <AbsoluteText y={538} x={42} text={miw4.contactName}/>
                        <AbsoluteText y={538} x={328} text={formatPhoneNumber(miw4.contactPhoneNumber)}/>
                    </>
                }
            </Page>
            <Page size='LETTER' style={{fontSize: 10}}>
                <Image src={MIW4Page2}/>
            </Page>
        </Document>
    )
}