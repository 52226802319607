/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import LoadingWrapper from '../../components/LoadingWrapper';
import { getAllClients } from '../../services/ClientService';
import { Button, Card, Col, Container, Dropdown, ListGroup, ListGroupItem, Modal, Row } from 'react-bootstrap';
import CustomTabs from '../../components/CustomTabs';
import { faBuilding, faInbox, faPeopleRoof, faPlus, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import ClientProfile from './ClientProfile';
import ClientTerminals from './ClientTerminals';
import ClientApplicants from './ClientApplicants';
import ClientOnboardModal from './ClientOnboardModal';
import CustomTabButtons from '../../components/CustomTabButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Clients() {
    const [isLoading, setIsLoading] = useState(false);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(undefined);
    const [showModal, setShowModal] = useState(false);
    const [showInitialModal, setShowInitialModal] = useState(true);
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        loadData();
    }, []);


    const loadData = async () => {
        setIsLoading(true);
        
        const response = await getAllClients();
        if (response.status === 200) {
            setClients(response.clients);
        }
        
        setIsLoading(false);
    }

    function handleAddClient(newClient){
        setClients(oldClients=>[...oldClients, newClient])
    }

    const clientElements = clients.map((c) => {
        return (
            <Dropdown.Item key={c.uid} active={selectedClient?.uid === c.uid} onClick={() => {setSelectedClient(c)}}>{c.name}</Dropdown.Item>
        )
    })

    const tabs = [
        <ClientProfile key={selectedClient?.uid} selectedClient={selectedClient}/>,
        <ClientApplicants key={selectedClient?.uid} selectedClient={selectedClient}/>,
        <ClientTerminals key={selectedClient?.uid} selectedClient={selectedClient}/>
    ]

    return (
        <LoadingWrapper isLoading={isLoading}>
            <div style={{padding: 12, display: 'flex', flexDirection: 'column', gap: 12, height: '100%', overflowY: 'auto'}}>
                <Card>
                    <Card.Body style={{display: 'flex', gap: 12, flexWrap: 'wrap'}}>
                        <Dropdown>
                            <Dropdown.Toggle>{selectedClient ? `Selected: ${selectedClient.name}` : 'Select a Client'}</Dropdown.Toggle>
                            <Dropdown.Menu>
                                {clientElements}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button style={{flexShrink: 0, whiteSpace: 'nowrap'}} onClick={() => {setShowModal(true)}}>Onboard New Client</Button>
                        { selectedClient && 
                            <>
                                <div style={{width: 1, backgroundColor: 'lightgray'}}/>
                                <div style={{flex: 1, marginRight: 12}}>
                                    <CustomTabButtons 
                                        tabs={['Profile / Settings', 'Applicants', 'Terminals']}
                                        tabIndex={tabIndex}
                                        setTabIndex={setTabIndex}
                                        fontSizeMult={1}
                                    />
                                </div>
                            </>
                        }
                    </Card.Body>
                </Card>
                { selectedClient && tabs[tabIndex] }
            </div>
            <Modal show={showModal} onHide={() => {setShowModal(false)}} size='lg'>
                <ClientOnboardModal hideModal={() => {setShowModal(false)}} handleAddClient={handleAddClient}/>
            </Modal>
            <Modal size='xl' show={showInitialModal} onHide={()=>setShowInitialModal(false)} centered>
                <Modal.Header style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 8, gap: 16}}>
                    <h4 style={{textAlign: 'center', margin: 0}}>Select Client</h4>
                    {/* <h5 style={{textAlign: 'center', marginBottom: 18}}>OR</h5> */}
                    <Button style={{}} onClick={() => {setShowModal(true); setShowInitialModal(false)}}><FontAwesomeIcon icon={faPlus} style={{marginRight: 6}}/>Onboard New Client</Button>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup style={{marginBottom: 18, overflow: 'auto', maxHeight: 800}}>
                        {clients.map(client=>{
                            return (
                            <ListGroup.Item key={client.uid} action onClick={()=>{setSelectedClient(client); setShowInitialModal(false)}} style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', flexDirection: 'column'}}>
                                <p style={{margin: 0, marginRight: 24}}><strong>Name: </strong>{client.name}</p>
                                <p style={{margin: 0}}><strong>EIN: </strong>{client.ein}</p>
                            </ListGroup.Item>)
                        })}
                    </ListGroup>
                </Modal.Body>
            </Modal>
        </LoadingWrapper>
    )
    
}