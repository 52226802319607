/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React, { useState } from "react";
import { Button, Col, Form, ListGroup, Row } from "react-bootstrap";
import CustomDropDown from "../../components/controls/CustomDropDown";
import CustomControl from "../../components/controls/CustomControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion, faTrash, faTriangleExclamation, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import JobStatusToolTip from "../../components/JobStatusToolTip";
import CustomToolTip from "../../components/CustomToolTip";
import QuickTable from "../../components/QuickTable";
import ControlStateProps from "../../models/State/ControlStateProps";
import CustomCheck from "../../components/controls/CustomCheck";
import SwitchControl from "../../components/SwitchControl";
import './JobPostTerminalEditor.css'

export default function JobPostTerminalEditor({terminals, jobPost, handleDeleteTerminal, handleAddTerminal}){

    const [clearSearch, setClearSearch] = useState(false);
    const [selectedTerminal, setSelectedTerminal] = useState(null);

    let terminalItems = {};
    terminals.filter(terminal=>!jobPost.terminals.map(ter=>ter.uid).includes(terminal.uid)).forEach(t=>{
        terminalItems[t.uid] = t.name;
    })

    ///////////////////////////
    /// JOB POST TERMINAL ROWS
    ///////////////////////////

    const jobPostTerminalRows = jobPost.terminals.map((terminal) => 
    {
        return {
            columns: [
                { value: terminal.name},
                { value: terminal.availablePositions, content: <CustomControl type={'number'}  maxInt={255} stateProps={new ControlStateProps('availablePositions', terminal)}/>},
                { value: terminal.autoInactivate, content: <SwitchControl style={{border: 'none', backgroundColor: 'transparent'}} stateProps={new ControlStateProps('autoInactivate', terminal)}/>},
                { value: terminal.active, content: <SwitchControl style={{border: 'none', backgroundColor: 'transparent'}} stateProps={new ControlStateProps('active', terminal)}/>},
                { content: <Button variant="danger" onClick={()=>handleDeleteTerminal(terminal.uid)}><FontAwesomeIcon icon={faTrash}/></Button>}

            ]
        }
    })

    ///////////////////////////
    /// LIST ITEMS
    //////////////////////////
    const jobPostTerminalElementsMobile = jobPost.terminals.map((terminal) => 
    {
        return (
            <ListGroup.Item key={terminal.uid}>
                <div style={{display: 'flex', flexDirection: 'column', padding: 8}}>
                    <div style={{display: "flex", justifyContent: 'space-between', alignItems: 'center'}}>
                        <h4 style={{fontWeight: 'bold', marginBottom: 12}}>{`${terminal.name}`}</h4>
                        <Button style={{width: 40, marginLeft: 'auto', marginBottom: 12}} variant="danger" onClick={()=>handleDeleteTerminal(terminal.uid)}><FontAwesomeIcon icon={faTrash}/></Button>
                    </div>
                    <Row>
                        <CustomControl type={'number'}  maxInt={255} floatingLabel title='Available Positions' breakpoints={{lg: 4}} stateProps={new ControlStateProps('availablePositions', terminal)}/>
                        <Col style={{marginBottom: 8}} lg={4}>
                            <SwitchControl style={{height: 58}} title={'Auto-Inactivate'} stateProps={new ControlStateProps('autoInactivate', terminal)}/>
                        </Col>
                        <Col style={{marginBottom: 8}} lg={4}>
                            <SwitchControl style={{height: 58}} title={'Active'} stateProps={new ControlStateProps('active', terminal)}/>
                        </Col>
                    </Row>
                </div>
            </ListGroup.Item>
        )
    })

    return (
        <Row style={{marginTop: 24}}>
            <Col sm={12} style={{marginBottom: 20}}>
                <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                    <h5 style={{textAlign: 'center',  marginTop: 0}}>Add Terminal</h5>
                    <CustomDropDown 
                        title={'Select Terminal'}  
                        clearSearch={clearSearch} 
                        setClearSearch={()=>setClearSearch(false)} items={terminalItems} 
                        selection={selectedTerminal} 
                        setSelection={(value)=>setSelectedTerminal(terminals.find(t=>t.uid == value))} 
                    />
                    {selectedTerminal &&
                        <Button
                            style={{marginBottom: 12}}
                            onClick={()=>{handleAddTerminal(selectedTerminal, 1, true, true); setSelectedTerminal(null); setClearSearch(true);}}>
                            Add
                        </Button>
                    }
                </div>
            </Col>
            <Col sm={12}>
                <h5 style={{textAlign: 'center', marginTop: 0}}>Terminals Selected</h5>
                {jobPost.terminals.length > 0 &&
                <>
                    <div className="desktop-terminals">
                        <QuickTable
                            responsive={false}
                            hover
                            headers={[
                                { label: 'Terminal Name', sortable: false },
                                { label: 'Positions', sortable: false, width: 160},
                                { label: 
                                    <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
                                        <p style={{margin: 0}}>Auto-Inactivate</p>
                                        <CustomToolTip content='Our system will automatically set this job post terminal listing to inactive upon filling the available positions.'/>
                                    </div>, 
                                  sortable: false, 
                                  width: 170
                                },
                                { label: 'Active', sortable: false, width: 1},
                                { label: '', sortable: false, width: 1 },
                            ]}
                            rows={jobPostTerminalRows}
                            /> 
                    </div>
                    <ListGroup className="mobile-terminals">
                        {jobPostTerminalElementsMobile}
                    </ListGroup> 
                </>
                }

            </Col>
        </Row>
    )
}
