/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import StateObject from "../../../models/State/StateObject";
import Decoder from "../../../decoding";

export default class JobPostsForNotificationSettings extends StateObject {
    uid: number;
    companyIdentifier: number;
    dateCreated: string;
    dateLastModified: string;
    dateActivated: string | undefined;
    active: boolean;
    title: string;
    description: string;
    notificationsEnabled: boolean;
    isDuplicate: boolean;

    constructor(
        uid: number,
        companyIdentifier: number,
        dateCreated: string,
        dateLastModified: string,
        dateActivated: string | undefined,
        active: boolean,
        title: string,
        description: string,
        notificationsEnabled: boolean,
        isDuplicate: boolean,

    ) {
        super();
        this.uid = uid;
        this.companyIdentifier = companyIdentifier;
        this.dateCreated = dateCreated;
        this.dateLastModified = dateLastModified;
        this.dateActivated = dateActivated;
        this.active = active;
        this.title = title;
        this.description = description;
        this.notificationsEnabled = notificationsEnabled;
        this.isDuplicate = isDuplicate;
        this.initChildren();
    }

    static initDefault(): JobPostsForNotificationSettings {
        return new JobPostsForNotificationSettings(
            -1,
            -1,
            moment().format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD'),
            undefined,
            false,
            '',
            '',
            true,
            false
        );
    }

    static decode(json: {[key: string]: any}): JobPostsForNotificationSettings {
        const decoder = new Decoder(json);
        const uid: number = decoder.decode('uid', Decoder.Uid);
        const companyIdentifier: number = decoder.decode('companyIdentifier', Decoder.Uid);
        const dateCreated: string = decoder.decode('dateCreated', Decoder.Date);
        const dateLastModified: string = decoder.decode('dateLastModified', Decoder.Date);
        const dateActivated: string | undefined = decoder.decode('dateLastModified', Decoder.Date, {defaultValue: undefined, warn: false});
        const active: boolean = decoder.decode('active', Decoder.Boolean);
        const title: string = decoder.decode('title', Decoder.NonEmptyString);
        const description: string = decoder.decode('description', Decoder.NonEmptyString);
        const notificationsEnabled: boolean = decoder.decode('notificationsEnabled', Decoder.Boolean);
        const isDuplicate: boolean = decoder.decode('isDuplicate', Decoder.Boolean);
        if (decoder.checkForErrors()) {
            return new JobPostsForNotificationSettings(uid, companyIdentifier, dateCreated, dateLastModified, dateActivated, active, title, description, notificationsEnabled, isDuplicate);
        } else {
            return JobPostsForNotificationSettings.initDefault();
        }
    }

    encode(): {[key: string]: any} {
        return {
            uid: this.uid, 
            companyIdentifier: this.companyIdentifier, 
            dateCreated: this.dateCreated, 
            dateLastModified: this.dateLastModified, 
            dateActivated: this.dateActivated, 
            active: this.active, 
            title: this.title,
            description: this.description,
            notificationsEnabled: this.notificationsEnabled,
            isDuplicate: this.isDuplicate
        }
    }
}