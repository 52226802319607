/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../decoding";
import StateObject from "../../../models/State/StateObject";
import Terminal from "../../../models/Terminal";
import { validateInteger } from "../../../tools";



export default class JobPostTerminal extends StateObject {
    uid: number;
    name: string;
    availablePositions: number;
    active: boolean;
    autoInactivate: boolean;

    constructor(uid: number, name: string, availablePositions: number, active: boolean, autoInactivate: boolean) {
        super();
        this.uid = uid;
        this.name = name;
        this.availablePositions = availablePositions;
        this.active = active;
        this.autoInactivate = autoInactivate;
        this.initChildren();
    }

    static initDefault(): JobPostTerminal {
        return new JobPostTerminal(-1, '', 1, true, true);
    }



    static initFromTerminal(terminal: Terminal, availablePositions: number, active: boolean, autoInactivate: boolean): JobPostTerminal {
        const jobPostTerminal = this.initDefault();
        jobPostTerminal.uid = terminal.uid;
        jobPostTerminal.name = terminal.name;
        jobPostTerminal.availablePositions = availablePositions;
        jobPostTerminal.active = active;
        jobPostTerminal.autoInactivate = autoInactivate;
        jobPostTerminal.initChildren();
        return jobPostTerminal;
    }

    static decode(json: any): JobPostTerminal {
        const decoder = new Decoder(json);
        const uid: number = decoder.decode('uid', Decoder.Uid);
        const name: string = decoder.decode('name', Decoder.StringStrict);
        const availablePositions: number = decoder.decode('availablePositions', Decoder.Integer);
        const active: boolean = decoder.decode('active', Decoder.Boolean)
        const autoInactivate: boolean = decoder.decode('autoInactivate', Decoder.Boolean)
        if (decoder.checkForErrors()) {
            return new JobPostTerminal(uid, name, availablePositions, active, autoInactivate);
        } else {
            return JobPostTerminal.initDefault();
        }

    }

    encode(){
        return{
            uid: this.uid,
            name: this.name,
            availablePositions: Math.abs(validateInteger(this.availablePositions)),
            active: this.active,
            autoInactivate: this.autoInactivate
        }
    }

}