/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../decoding";
import StateObject from "../../../models/State/StateObject";


export default class UserForCompany extends StateObject {
    uid: number;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    canEditRecruiters: boolean;
    hasBillingPermissions: boolean;

    constructor(
        uid: number,
        firstName: string,
        lastName: string,
        phoneNumber: string,
        email: string,
        canEditRecruiters: boolean,
        hasBillingPermissions: boolean
    ) {
        super();
        this.uid = uid;
        this.firstName = firstName;
        this.lastName = lastName;
        this.phoneNumber = phoneNumber;
        this.email = email;
        this.canEditRecruiters = canEditRecruiters;
        this.hasBillingPermissions = hasBillingPermissions
    }

    static initDefault(): UserForCompany {
        return new UserForCompany(-1, '', '', '', '', false, false);
    }

    static initFromObject(user: any): UserForCompany {
        return new UserForCompany(user.uid, user.firstName, user.lastName, user.phoneNumber, user.email, user.canEditRecruiters, user.hasBillingPermissions);
    }

    static decode(json: any): UserForCompany {
        const decoder = new Decoder(json);
        const uid: number = decoder.decode('uid', Decoder.Uid);
        const firstName: string = decoder.decode('firstName', Decoder.StringStrict);
        const lastName: string = decoder.decode('lastName', Decoder.StringStrict);
        const phoneNumber: string = decoder.decode('phoneNumber', Decoder.StringStrict);
        const email: string = decoder.decode('email', Decoder.StringStrict);
        const canEditRecruiters: boolean = decoder.decode('canEditRecruiters', Decoder.Boolean)
        const hasBillingPermissions: boolean = decoder.decode('hasBillingPermissions', Decoder.Boolean)

        if (decoder.checkForErrors()) {
            return new UserForCompany(uid, firstName, lastName, phoneNumber, email, canEditRecruiters, hasBillingPermissions);
        } else {
            return UserForCompany.initDefault();
        }
    }  

    encode(){
        return {
            uid: this.uid,
            firstName: this.firstName,
            lastName: this.lastName,
            phoneNumber: this.phoneNumber,
            email: this.email,
            canEditRecruiters: this.canEditRecruiters,
            hasBillingPermissions: this.hasBillingPermissions
        }
    }
}