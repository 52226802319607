/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

export default class StateObject {

    key: string | undefined; //reperesents the property name listed on the parent StateObject for this object
    parent: StateObject | undefined; //represents the parent StateObject
    subscribers: object = {}; //used by subscribe(subscriberKey, key, callback, recursive = false) to allow components to respond to state changes
    validators: Object = {}; //used by setValidation(key, validation) to allow components to assign validation to specific properties
    validationSubscribers = {}; //used by subscribeToValidation(subscriberKey, callback) && updateValidity() to allow components to respond when a model toggles between valid/invalid
    isValid = true; //used to decide whether validationSubscribers or parents need to be notified when validation changes
    requiresValidationUpdate = false; //used by requestValidationUpdate to batch validation update calls for performance

    constructor(properties: any = {}) {
        Object.entries(properties).forEach(([key, value]) => {
            this[key as keyof StateObject] = value as never;
        })
        this.initChildren();
    }

    initChildren() { // call this on models which inherit from this class. Make sure this is called after everything else has been initialized. This function initializes all children with a reference to their parent for state hnadling and validation purposes.
        Object.entries(this).filter(([key, _]) => key !== 'parent').forEach(([key, value]) => {
            if (value instanceof StateObject) {
                value.key = key;
                value.parent = this;
                value.initChildren();
            } else if (Array.isArray(value) && value.length > 0 && value[0] instanceof StateObject) {
                value.forEach((child) => {
                    child.key = key;
                    child.parent = this;
                    child.initChildren();
                })
            }
        });
    }

    setState(key: string, value: any) { //this function must be used when altering the state of a StateObject in order to trigger state management functionality
        let doUpdateValidity = false;
        const oldValue = this[key as keyof StateObject];
        if (value instanceof StateObject) { // If a state object is added to the model, it needs to be connected to its parent
            value.key = key;
            value.parent = this;
        } else if (Array.isArray(value) && value.length > 0 && value[0] instanceof StateObject) { // If a state object is added to the model, it needs to be connected to its parent
            value.forEach((child) => {
                child.key = key;
                child.parent = this;
            })
        } else if (oldValue instanceof StateObject) { // If a state object is removed from the model, validation needs to be updated
            doUpdateValidity = true;
        } else if (Array.isArray(oldValue) && oldValue.length > 0 && oldValue[0] instanceof StateObject) { // If a state object is removed from the model, validation needs to be updated
            doUpdateValidity = true;
        }

        this[key as keyof StateObject] = value as never;

        const validator = this.validators[key as keyof object] as Validator;
        if (validator) { //if property key has validation and that validation state changes, a validation update request is made
            const oldIsValid = validator.validationMessage ? false : true;
            validator.validationMessage = validator.validation(value);
            const newIsValid = validator.validationMessage ? false : true;
            if (oldIsValid !== newIsValid) {
                doUpdateValidity = true;
            }
        }
        if (doUpdateValidity) {
            this.requestValidationUpdate();
        }
        this.publish(key); //notify subscribers
    }

    getChildren() { //helper function to automatically detect properties which are also StateObject which must communicate with their parent
        return Object.entries(this).filter(([key, _]) => key !== 'parent').reduce((prev: StateObject[], curr) => {
            if (curr[1] instanceof StateObject) {
                return prev.concat([curr[1]]);
            } else if (Array.isArray(curr[1]) && curr[1].length > 0 && curr[1][0] instanceof StateObject) {
                return prev.concat(curr[1]);
            } else {
                return prev;
            }
        }, []);
    }


    publish(key: string, fromChild = false) { //notifies subscribers when state changes for a specified property.
        if (this.parent && this.key) {
            this.parent.publish(this.key, true); //recursively notifies parent StateObject
        }
        (Object.values(this.subscribers) as Subscriber[]).filter(s => !s.key || (s.key === key && (!fromChild || s.recursive))).forEach((sub) => { 
            sub.callback(); //only notifies subscribers which are recursive or have subscribed to the entire state object
        })
    }

    subscribe(subscriberKey: string, key: string | undefined, callback: (showValidationMessages?: boolean) => void, recursive: boolean = false) { //allows components to recieve an update when a specified property key changes. Subcribers are stored as keyed objects so that this function can be called repeatedly during rendering
        this.subscribers[subscriberKey as keyof object] = ({
            key: key, //key can be ommited to signify a recursive subscription to the StateObject itself
            callback: callback, //function to call when StateObject publishes
            recursive: recursive //subscriber will also recieve updates if a child StateObject publishes
        }) as never;
    }

    subscribeToValidation(subscriberKey: string, callback: (isValid: boolean) => void) { //allows a component to be notified when this StateObject toggles between valid and invalid
        this.validationSubscribers[subscriberKey as keyof object] = callback as never;
        callback(this.isValid);
    }

    setValidation(key: string, validation: (value: any) => string | undefined) { //used to specify the validaiton for a specific property. validators are stored as keyed objects so that this function can be called repeatedly during rendering
        this.validators[key as keyof object] = {
            validationMessage: validation(this[key as keyof StateObject]),
            validation: validation
        } as never;
        this.requestValidationUpdate();
    }

    removeValidation(key: string) { //useful for edge cases when the validation of one property is conditional based off of something else
        delete this.validators[key as keyof object];
    }

    removeAllValidation() { //useful for edge cases when the validation of one property is conditional based off of something else
        this.validators = {};
    }

    getValidationMessage(key: string) { //convenience function to get validation error message for a property
        const validator = this.validators[key as keyof object] as Validator;
        if (validator) {
            return validator.validation(this[key as keyof StateObject]);
        }
    }

    getIsValid(key: string) { //convenience function to determine if a property is valid
        const validator = this.validators[key as keyof object] as Validator;
        if (validator) {
            return validator.validation(this[key as keyof StateObject]) ? false : true;
        }
    }

    getIsInvalid(key: string) { //convenience function to determine if a property is invalid
        const validator = this.validators[key as keyof object] as Validator;
        if (validator) {
            return validator.validation(this[key as keyof StateObject]) ? true : false;
        }
    }

    hasValidation(key: string){
        const validator = this.validators[key as keyof object] as Validator;
        return validator ? true : false;
    }

    requestValidationUpdate() { //this function batches validation update calls for performance
        if (!this.requiresValidationUpdate) {
            this.requiresValidationUpdate = true;
            setTimeout(() => {
                if (this.requiresValidationUpdate) {
                    this.requiresValidationUpdate = false;
                    this.updateValidity();
                }
            }, 50);
        }
    }

    updateValidity() { //used to update parent and validation subscribers if validity state changes
        const newIsValidPrimitive = Object.keys(this).filter(key => this.validators[key as keyof object]).reduce((prev, curr) => {
            const validator = this.validators[curr as keyof object] as Validator;
            return prev && (validator.validationMessage ? false : true);
        }, true);

        const newIsValidChildren = this.getChildren().reduce((prev, curr) => {
            return prev && curr.isValid;
        }, true);

        const newIsValid = newIsValidPrimitive && newIsValidChildren; //combination of validity states for all properties/children

        if (newIsValid !== this.isValid) { //update parent and validation subscribers if validity state changes
            this.isValid = newIsValid;
            if (this.parent) {
                this.parent.requestValidationUpdate();
            }
            (Object.values(this.validationSubscribers) as [(isValid: boolean) => void]).forEach((callback) => {callback(newIsValid)}); //notify validation subscribers
        }
    }

    showValidationMessages() { //used to instruct all subscribers to show their validation error messages
        Object.values(this.subscribers).filter(s => this.validators[s.key as keyof object]).forEach((sub) => {
            sub.callback(true);
        })
        this.getChildren().forEach(c => c.showValidationMessages()); //recursively calls all descendants
    }

}

type Validator = {
    validationMessage: string | undefined;
    validation: (value: any) => string | undefined;
}

type Subscriber = {
    key: string, //key can be ommited to signify a recursive subscription to the StateObject itself
    callback: (showValidationMessages?: boolean) => void, //function to call when StateObject publishes
    recursive: boolean
}