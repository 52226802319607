/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken} from "./serviceTools";

export async function requestReset(phoneNumber: string){
    const data = {
        type: 'passwordReset',
        target: 'requestReset',
        token: getToken(),
        phoneNumber
    }

    return await getResponse(data);
}

export async function authenticatePasswordReset(passwordToken: string){
    const data = {
        type: 'passwordReset',
        target: 'authenticatePasswordReset',
        token: getToken(),
        passwordToken
    }

    return await getResponse(data);
}

export async function resetPassword(passwordToken: string, newPassword: string){
    const data = {
        type: 'passwordReset',
        target: 'resetPassword',
        token: getToken(),
        passwordToken,
        newPassword
    }

    return await getResponse(data);
}
