/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Terminal from "../models/Terminal";
import Client from "../views/Clients/models/Client";
import ClientUser from "../views/Clients/models/ClientUser";
import { getResponse, getToken } from "./serviceTools";

export async function getAllClients() {
    const data = {
        type: 'client',
        target: 'getAll',
        token: getToken()
    }
    return await getResponse(data);
}

export async function getClientProfile(clientIdentifier: number) {
    const data = {
        type: 'client',
        target: 'getProfile',
        token: getToken(),
        clientIdentifier
    }
    return await getResponse(data);
}

export async function getRejectionMessage(clientIdentifier: number) {
    const data = {
        type: 'client',
        target: 'getRejectionMessage',
        token: getToken(),
        clientIdentifier
    }
    return await getResponse(data);
}

export async function getOfferLetters(clientIdentifier: number) {
    const data = {
        type: 'client',
        target: 'getOfferLetters',
        token: getToken(),
        clientIdentifier
    }
    return await getResponse(data);
}

export async function getClientApplicants(clientIdentifier: number) {
    const data = {
        type: 'client',
        target: 'getApplicants',
        token: getToken(),
        clientIdentifier: clientIdentifier
    }
    return await getResponse(data);
}

export async function getClientTerminals(clientIdentifier: number) {
    const data = {
        type: 'client',
        target: 'getTerminals',
        token: getToken(),
        clientIdentifier: clientIdentifier
    }
    return await getResponse(data);
}

export async function getAllUnassociatedTerminals(clientIdentifier: number) {
    const data = {
        type: 'client',
        target: 'getAllUnassociatedTerminals',
        token: getToken(),
        clientIdentifier: clientIdentifier
    }
    return await getResponse(data);
}

export async function onboardClient(ein: number) {
    const data = {
        type: 'client',
        target: 'onboardClient',
        token: getToken(),
        ein: ein,
    }
    return await getResponse(data);
}

export async function createClient(client: Client, clientUser: ClientUser) {
    const data = {
        type: 'client',
        target: 'createClient',
        token: getToken(),
        client,
        clientUser
    }
    return await getResponse(data);
}

export async function addExistingTerminal(terminalIdentifier: number, clientIdentifier: number){
    const data = {
        type: 'client',
        target: 'addExistingTerminal',
        token: getToken(),
        terminalIdentifier,
        clientIdentifier
    }

    return await getResponse(data)
}

export async function addNewTerminal(newTerminal: Terminal, clientIdentifier: number){
    const data = {
        type: 'client',
        target: 'addNewTerminal',
        token: getToken(),
        newTerminal,
        clientIdentifier
    }

    return await getResponse(data);
}

export async function updateRejectionMessage(clientIdentifier: number, rejectionMessage: string){
    const data = {
        type: 'client',
        target: 'updateRejectionMessage',
        token: getToken(),
        clientIdentifier,
        rejectionMessage
    }

    return await getResponse(data);
}

export async function updateOfferLetter(clientIdentifier: number, offerLetter: string){
    const data = {
        type: 'client',
        target: 'updateOfferLetter',
        token: getToken(),
        clientIdentifier,
        offerLetter: offerLetter
    }

    return await getResponse(data);
}

export async function updateFaAccountId(clientIdentifier: number, faAccountId: string){
    const data = {
        type: 'client',
        target: 'updateFaAccountId',
        token: getToken(),
        clientIdentifier,
        faAccountId
    }

    return await getResponse(data);
}