/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import CustomQuestion from '../../../models/CustomQuestion';
import { Button, ButtonGroup, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTurnDown, faArrowTurnUp, faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import { CustomQuestionTypes } from '../../../enums';
import CustomButton from '../../../components/CustomButton';
import CustomControl from '../../../components/controls/CustomControl';
import ControlStateProps from '../../../models/State/ControlStateProps';
import { Validation } from '../../../validation';
import ValidationFeedback from '../../../components/ValidationFeedback';

// type CustomQuestionPickerViewProps = {
//     index: number;
//     question: CustomQuestion;
//     handleDelete: (uid: number) => void;
//     handleMoveBack: (uid: number) => void;
//     handleMoveForward: (uid: number) => void;
// }

export default class CustomQuestionPickerView extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
                <div>
                    <ButtonGroup vertical>
                        <Button style={{padding: 2}} onClick={() => {this.props.handleMoveBack(this.props.question.uid)}}>
                            <FontAwesomeIcon icon={faArrowTurnUp} flip='horizontal'/>
                        </Button>
                        <Button style={{padding: 2}} onClick={() => {this.props.handleMoveForward(this.props.question.uid)}}>
                            <FontAwesomeIcon icon={faArrowTurnDown} flip='horizontal'/>
                        </Button>
                    </ButtonGroup>
                </div>
                <div style={{border: '1px solid lightgray', backgroundColor: 'var(--background-secondary)',  borderRadius: 6, width:'100%'}}>
                    <div style={{display: 'flex', gap: 12, width: '100%', justifyContent: 'space-between', padding: 6, borderBottom: '1px solid lightgray'}}>
                        <div style={{display: 'flex', flexShrink: 0, justifyContent: 'center', alignItems: 'center', width: 24, height: 24, borderRadius: 12, backgroundColor: 'var(--bs-primary)'}}>
                            <span style={{color: 'white'}}>{this.props.index + 1}</span>
                        </div>
                        <span style={{fontWeight: 'bold'}}>{CustomQuestionTypes[this.props.question.type]}</span>
                        <Button onClick={() => {this.props.handleDelete(this.props.question.uid)}} className='btn-danger' style={{width: 24, height: 24, padding: 0, borderRadius: 12, display: 'flex', justifyContent: 'center', alignItems: 'center', flexShrink: 0}}>
                            <FontAwesomeIcon icon={faX}/>
                        </Button>
                    </div>
                    <div style={{backgroundColor: 'white', width: '100%', padding: 6, borderBottomLeftRadius: 6, borderBottomRightRadius: 6, display: 'flex', flexDirection: 'column', gap: 6}}>
                        <CustomControl floatingLabel title='Question' stateProps={new ControlStateProps('title', this.props.question, Validation.nonEmptyString)}/>
                        <CustomQuestionPickerOptionsControl question={this.props.question}/>
                    </div>
                </div>
            </div>
        )
    }
}


class CustomQuestionPickerOptionsControl extends React.Component {
    constructor(props) {
        super(props);
        this.handleSetOption = this.handleSetOption.bind(this);
        this.handleDeleteOption = this.handleDeleteOption.bind(this);
        this.handleAddOption = this.handleAddOption.bind(this);

        props.question.subscribe('CustomQuestionPickerOptionsControl', 'options', () => this.forceUpdate());
    }

    handleSetOption(index, value) {
        const newOptions = Array.from(this.props.question.options);
        newOptions[index] = value;
        this.props.question.setState('options', newOptions);
    }
    handleDeleteOption(index) {
        const newOptions = Array.from(this.props.question.options);
        newOptions.splice(index, 1);
        this.props.question.setState('options', newOptions);
    }
    handleAddOption() {
        const newOptions = Array.from(this.props.question.options);
        newOptions.push('');
        this.props.question.setState('options', newOptions);
    }

    render() {


        const optionElements = this.props.question.options.map((option, index) => {
            return (
                <div key={index} style={{display: 'flex', gap: 6, alignItems: 'center', width: '100%'}}>
                    <Form.Control 
                        onBlur={this.handleOnBlur} 
                        style={{textAlign: 'center'}} 
                        placeholder={'Option ' + (index + 1)} 
                        value={option} 
                        onChange={(event) => {this.handleSetOption(index, event.target.value)}}/>
                    <Button onClick={() => this.handleDeleteOption(index)} style={{padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'red', border: 'none', width: 20, height: 20, borderRadius: 10, flexShrink: 0}}>
                        <FontAwesomeIcon icon={faX}/>
                    </Button>
                </div>
            )
        });

        const stateProps = new ControlStateProps('options', this.props.question, Validation.validCustomQuestionOptions);

        return (
            <div style={{display: 'flex', position: 'relative', flexDirection: 'column', gap: 6, alignItems: 'center', width: '100%', padding: 12, borderColor: stateProps.getIsInvalid() ? 'red' : 'lightgray', borderWidth: 1, borderStyle: 'solid', borderRadius: 6}}>
                {optionElements}
                <CustomButton onClick={this.handleAddOption} style={{padding: '2px 6px 2px 6px', marginBottom: 10}}>
                    Add Option
                </CustomButton>
                <ValidationFeedback stateProps={stateProps} showValidation={true}/>
            </div>
        )
    }
}