/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useReducer, useState } from 'react';
import { Form } from 'react-bootstrap';
import { validateDecimal, validateInteger } from '../../tools';

export default function DocumentField({type, disabled, stateProps, onChange, maxLength, maxInt, minInt, minDecimal, maxDecimal, min, max, x, y, width, innerRef, fontSize, as, style}) {
    const [showValidation, setShowValidation] = useState(false);
    const [_, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        stateProps?.subscribe((showValidation) => {
            if (showValidation) {
                setShowValidation(true);
            }
            forceUpdate();
        });
    }, [stateProps]);

    const handleSetValue = (value) => {
        if (maxLength) {
            value = value.slice(0, maxLength);
        } else if (maxInt != undefined && validateInteger(value) > maxInt) {
            value = maxInt.toString();
        } else if (maxDecimal != undefined && validateDecimal(value) > maxDecimal) {
            value = maxDecimal.toString();
        }
        if (minInt != undefined && validateInteger(value) < minInt) {
            value = minInt.toString();
        } else if (minDecimal != undefined && validateDecimal(value) < minDecimal) {
            value = minDecimal.toString();
        }
        stateProps.stateObject.setState(stateProps.stateKey, value);
        if (onChange) {
            onChange(value);
        }
    }

    return (
        <Form.Control
            ref={innerRef}
            style={{border: `1px dashed ${stateProps.getIsInvalid() ? 'red' : stateProps.getIsValid() ? 'green' : 'gray'}`, padding: '0px 2px 0px 2px', position: 'absolute', top: y, left: x, width: width, lineHeight: 1, borderRadius: 0, fontSize: fontSize, ...style}}
            onBlur={() => {setShowValidation(true)}}
            isInvalid={stateProps.getIsInvalid()} 
            // isValid={stateProps.getIsValid()} 
            disabled={disabled}
            type={type} 
            min={min}
            max={max}
            as={as}
            value={stateProps.getValue()} 
            onChange={(event) => {handleSetValue(event.target.value)}} 
        />
    )
}

