/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import { RouteComponentProps } from "react-router-dom";
import JobApplicationForm from "../JobApplication/JobApplicationForm";
import LoadingWrapper from "../../components/LoadingWrapper";
import JobPost from "../JobPosts/models/JobPost";
import { getJobPost } from "../../services/JobPostService";
import { Card, Toast } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { getJobPostForApplication } from "../../services/ApplicantService";
import JobPostForApplication from "../JobApplication/models/JobPostForApplication";
import { validateInteger } from "../../tools";
import { AppGlobals } from "../../App";
import { JobSites } from "../../enums";

export default class Apply extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            jobPost: undefined,
            isLoading: false,
            jobPostNotFound: false,
            errorMessage: '',
            source: null
        }
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount(){
        const jobPostIdentifier = validateInteger(this.props.match.params.jobPostIdentifier);
        const terminalIdentifier = validateInteger(this.props.match.params.terminalIdentifier);

        if (jobPostIdentifier && terminalIdentifier) {
            this.loadData(jobPostIdentifier, terminalIdentifier);
        }
    }

    async loadData(jobPostIdentifier, terminalIdentifier) {
        this.setState({isLoading: true});
        const response = await getJobPostForApplication(jobPostIdentifier, terminalIdentifier);
        if (response.status === 200) {
            this.setState({jobPost: JobPostForApplication.decode(response.jobPost)})
            const searchParams = new URLSearchParams(this.props.location.search).get('source');
            if(searchParams !== null){
                this.setState({source: JobSites[searchParams.toLocaleLowerCase()] !== undefined ? searchParams : null});
            }
        } else if (response.status === 400) {
            this.setState({jobPostNotFound: true, errorMessage: response.message})
        } else if (response.status === 500){
            AppGlobals.alert('danger', response.alignItems)
        }

        this.setState({isLoading: false});
    }


    render() {
        return (
            <LoadingWrapper isLoading={this.state.isLoading}>
                { this.state.jobPost !== undefined ?
                    <JobApplicationForm jobPost={this.state.jobPost} source={this.state.source}/>
                    : this.state.jobPostNotFound ?
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                        <Card>
                            <Card.Header>
                                <Card.Title style={{textAlign: 'center'}}>Oops!</Card.Title>
                            </Card.Header>
                            <Card.Body style={{display: 'flex', alignItems: 'center', gap: 12}}>
                                <span style={{margin: 'auto', textAlign: 'center'}}>
                                    <FontAwesomeIcon size="1x" icon={faExclamationTriangle} style={{color: 'var(--bs-danger)', marginRight: 8}}/>
                                    {this.state.errorMessage}
                                </span>
                            </Card.Body>
                        </Card>
                    </div>
                    :
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                        <Card>
                            <Card.Header>
                                <Card.Title style={{textAlign: 'center'}}>Oops!</Card.Title>
                            </Card.Header>
                            <Card.Body style={{display: 'flex', alignItems: 'center', gap: 12}}>
                                <span style={{margin: 'auto', textAlign: 'center'}}>
                                    <FontAwesomeIcon size="1x" icon={faExclamationTriangle} style={{color: 'var(--bs-danger)', marginRight: 8}}/>
                                    {this.state.errorMessage}
                                </span>
                            </Card.Body>
                        </Card>
                     </div>
                }
            </LoadingWrapper>
        )
    }

}