/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from 'moment'
import F8850Page1 from '../../../../assets/F8850-1.png';
import F8850Page2 from '../../../../assets/F8850-2.png';
import { Document, Image, Page, Text } from '@react-pdf/renderer'
import F8850 from './F8850';
import Signature from '../../Signature/Signature';
import AbsoluteText from '../AbsoluteText';
import { formatEin, formatPhoneNumber, formatSsn } from '../../../../tools';


type F8850PdfProps = {
    f8850: F8850
    signature: Signature
    isEmployer: boolean
}

export default function F8850Pdf({f8850, signature, isEmployer}: F8850PdfProps) {

    return (
        <Document>
            <Page size='LETTER' style={{fontSize: 10}}>
                <Image src={isEmployer ? f8850.employeeSubmission[0] ?? F8850Page1 : F8850Page1}/>
                {!isEmployer ?
                    <>
                        <AbsoluteText x={86} y={106} text={f8850.name}/>
                        <AbsoluteText x={470} y={106} text={formatSsn(f8850.ssn)}/>
                        <AbsoluteText x={165} y={131} text={f8850.streetAddress}/>
                        <AbsoluteText x={182} y={155} text={f8850.cityStateZipCode}/>

                        <AbsoluteText x={77} y={178} text={f8850.county}/>
                        <AbsoluteText x={435} y={178} text={formatPhoneNumber(f8850.phoneNumber)}/>
                        <AbsoluteText x={320} y={201} text={f8850.dateOfBirthIfUnder40 ? moment(f8850.dateOfBirthIfUnder40).format('MM/DD/YYYY') : ''}/>

                        <AbsoluteText x={67} y={238} text={f8850.checkbox1 ? 'X' : ''}/>
                        <AbsoluteText x={67} y={273} text={f8850.checkbox2 ? 'X' : ''}/>
                        <AbsoluteText x={67} y={451} text={f8850.checkbox3 ? 'X' : ''}/>
                        <AbsoluteText x={67} y={487} text={f8850.checkbox4 ? 'X' : ''}/>
                        <AbsoluteText x={67} y={522} text={f8850.checkbox5 ? 'X' : ''}/>
                        <AbsoluteText x={67} y={557} text={f8850.checkbox6 ? 'X' : ''}/>
                        <AbsoluteText x={67} y={640} text={f8850.checkbox7 ? 'X' : ''}/>

                        <Image src={signature.image} style={{position: 'absolute', top: 727, left: 165, width: 95, height: 18}}/>
                        <AbsoluteText y={732} x={509} text={moment().format('MM/DD/YYYY')}/>
                    </>
                :   <></>
                }
            </Page>
            <Page size='LETTER' style={{fontSize: 10}}>
                <Image src={F8850Page2}/>
                {isEmployer ?
                    <>
                        <AbsoluteText x={115} y={70} text={f8850.employerName}/>
                        <AbsoluteText x={365} y={70} text={formatPhoneNumber(f8850.employerPhoneNumber)}/>
                        <AbsoluteText x={485} y={70} text={formatEin(f8850.ein)}/>

                        <AbsoluteText x={105} y={95} text={f8850.employerStreetAddress}/>
                        <AbsoluteText x={179} y={119} text={f8850.employerCityStateZipCode}/>

                        <AbsoluteText x={210} y={142} text={f8850.contactPerson}/>
                        <AbsoluteText x={485} y={142} text={formatPhoneNumber(f8850.contactPhoneNumber)}/>
                        <AbsoluteText x={105} y={165} text={f8850.contactStreetAddress}/>
                        <AbsoluteText x={179} y={189} text={f8850.contactCityStateZipCode}/>
                        <AbsoluteText x={556} y={217} text={f8850.groupNumber} fontSize={16}/>

                        <AbsoluteText x={106} y={271} text={f8850.dateGaveInformation ? moment(f8850.dateGaveInformation).format('MM/DD/YYYY') : ''}/>
                        <AbsoluteText x={251} y={271} text={f8850.dateOfferedJob ? moment(f8850.dateOfferedJob).format('MM/DD/YYYY') : ''}/>
                        <AbsoluteText x={386} y={271} text={f8850.dateWasHired ? moment(f8850.dateWasHired).format('MM/DD/YYYY') : ''}/>
                        <AbsoluteText x={516} y={271} text={f8850.dateStartedJob ? moment(f8850.dateStartedJob).format('MM/DD/YYYY') : ''}/>

                        <AbsoluteText x={341} y={354} text={f8850.employerTitle}/>

                        <Image src={signature.image} style={{position: 'absolute', top: 347, left: 158, width: 95, height: 18}}/>
                        <AbsoluteText y={354} x={519} text={moment().format('MM/DD/YYYY')}/>
                    </>
                :   <></>
                }
            </Page>
        </Document>
    )
}