/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment, { duration } from "moment";
import { Validation } from "../../../validation";
import CustomQuestion from "../../../models/CustomQuestion";
import StateObject from "../../../models/State/StateObject";
import Decoder from "../../../decoding";
import { JobApplicationMinimal } from "../../JobApplication/models/JobApplicationMinimal";
import { validateDecimal, validateDecimalFixed, validateInteger } from "../../../tools";
import Terminal from "../../../models/Terminal";
import JobPostTerminal from "../../JobCreator/models/JobPostTerminal";

export default class JobPostOverview extends StateObject {
    uid: number;
    companyIdentifier: number;
    dateCreated: string;
    dateLastModified: string;
    dateActivated: string | undefined;
    applicants: JobApplicationMinimal[];
    active: boolean;
    title: string;
    description: string;
    employmentType: string;
    shiftType: string;
    shiftDurations: string[];
    compensationMin: number;
    compensationMax: number;
    payType: string;
    jobSites: string[];
    expirationDate: string | undefined;
    terminals: JobPostTerminal[];
    benefits: string | undefined;
    isDuplicate: boolean;

    constructor(
        uid: number,
        companyIdentifier: number,
        dateCreated: string,
        dateLastModified: string,
        dateActivated: string | undefined,
        applicants: JobApplicationMinimal[],
        active: boolean,
        title: string,
        description: string,
        employmentType: string,
        shiftType: string,
        shiftDurations: string[],
        compensationMin: number,
        compensationMax: number,
        payType: string,
        jobSites: string[],
        expirationDate: string | undefined,
        terminals: JobPostTerminal[],
        benefits: string | undefined,
        isDuplicate: boolean,
    ) {
        super();
        this.uid = uid;
        this.companyIdentifier = companyIdentifier;
        this.dateCreated = dateCreated;
        this.dateLastModified = dateLastModified;
        this.dateActivated = dateActivated;
        this.applicants = applicants;
        this.active = active;
        this.title = title;
        this.description = description;
        this.employmentType = employmentType;
        this.shiftType = shiftType;
        this.shiftDurations = shiftDurations;
        this.compensationMin = compensationMin;
        this.compensationMax = compensationMax;
        this.payType = payType;
        this.jobSites = jobSites;
        this.shiftDurations = shiftDurations;
        this.expirationDate = expirationDate;
        this.terminals = terminals
        this.benefits = benefits;
        this.isDuplicate = isDuplicate;
        this.initChildren();
    }

    static initDefault(): JobPostOverview {
        return new JobPostOverview(
            -1,
            -1,
            moment().format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD'),
            undefined,
            [],
            false,
            '',
            '',
            'fulltime',
            'weekdays', 
            [],
            10.0, 
            20.0,
            'ph',
            ['indeed', 'monster', 'zipRecruiter', 'flexJobs'],
            '',
            [],
            '',
            false
        );
    }

    static decode(json: {[key: string]: any}): JobPostOverview {
        const decoder = new Decoder(json);
        const uid: number = decoder.decode('uid', Decoder.Uid);
        const companyIdentifier: number = decoder.decode('companyIdentifier', Decoder.Uid);
        const dateCreated: string = decoder.decode('dateCreated', Decoder.Date);
        const dateLastModified: string = decoder.decode('dateLastModified', Decoder.Date);
        const dateActivated: string | undefined = decoder.decode('dateLastModified', Decoder.Date, {defaultValue: undefined, warn: false});
        const applicants: JobApplicationMinimal[] = (decoder.decode('applicants', Decoder.Array) as any[] ?? []).map(obj => JobApplicationMinimal.decode(obj));
        const active: boolean = decoder.decode('active', Decoder.Boolean);
        const title: string = decoder.decode('title', Decoder.NonEmptyString);
        const description: string = decoder.decode('description', Decoder.NonEmptyString);
        const employmentType: string = decoder.decode('employmentType', Decoder.NonEmptyString);
        const shiftType: string = decoder.decode('shiftType', Decoder.NonEmptyString);
        const shiftDurations: string[] = decoder.decode('shiftDurations', Decoder.NonEmptyArrayFromString);
        const compensationMin: number = decoder.decode('compensationMin', Decoder.Decimal);
        const compensationMax: number = decoder.decode('compensationMax', Decoder.Decimal);
        const payType: string = decoder.decode('payType', Decoder.NonEmptyString);
        const jobSites: string[] = decoder.decode('jobSites', Decoder.ArrayFromString);
        const expirationDate: string = decoder.decode('expirationDate', Decoder.Date, {defaultValue: '', warn: false});
        const terminals: JobPostTerminal[] = (decoder.decode('terminals', Decoder.Array)as any[] ?? []).map(t=>JobPostTerminal.decode(t));
        const benefits: string = decoder.decode('benefits', Decoder.StringStrict, {defaultValue: '', warn: false});
        const isDuplicate: boolean = decoder.decode('isDuplicate', Decoder.Boolean);
        if (decoder.checkForErrors()) {
            return new JobPostOverview(uid, companyIdentifier, dateCreated, dateLastModified, dateActivated, applicants, active, title, description, employmentType, 
                shiftType, shiftDurations, compensationMin, compensationMax, payType, jobSites, expirationDate, terminals, benefits, isDuplicate);
        } else {
            return JobPostOverview.initDefault();
        }
    }

    encode(): {[key: string]: any} {
        return {
            uid: this.uid, 
            companyIdentifier: this.companyIdentifier, 
            dateCreated: this.dateCreated, 
            dateLastModified: this.dateLastModified, 
            dateActivated: this.dateActivated,
            applicants: this.applicants, 
            active: this.active, 
            title: this.title,
            description: this.description, 
            employmentType: this.employmentType, 
            shiftType: this.shiftType,
            shiftDurations: JSON.stringify(this.shiftDurations),
            compensationMin: validateDecimalFixed(this.compensationMin as any, 2), 
            compensationMax: validateDecimalFixed(this.compensationMax as any, 2), 
            payType: this.payType,
            jobSites: JSON.stringify(this.jobSites),  
            expirationDate: this.expirationDate ? this.expirationDate : null,
            terminals: this.terminals.map(t=>t.encode),
            benefits: this.benefits,
            isDuplicate: this.isDuplicate  
        }
    }

    // duplicate(): JobPost {
    //     return new JobPost(
    //         this.uid, 
    //         this.companyIdentifier, 
    //         this.dateCreated, 
    //         this.dateLastModified, 
    //         this.dateActivated, 
    //         this.applicants, //
    //         this.status,
    //         this.title,
    //         this.description,
    //         this.employmentType,
    //         this.shiftTypes,
    //         Array.from(this.shiftDurations),
    //         this.compensationMin,
    //         this.compensationMax,
    //         this.payType,
    //         this.availablePositions,
    //         Array.from(this.jobSites),
    //         this.questions, //
    //         this.expirationDate,
    //         this.benefits,
    //         this.location,
    //     )
    // }


}