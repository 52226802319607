/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useRef, useState } from 'react';
import IT2104Page1 from '../../../../../assets/it2104-1.png';
import { Button, Modal } from 'react-bootstrap';
import ControlStateProps from '../../../../../models/State/ControlStateProps';
import { pdf } from '@react-pdf/renderer';
import DocumentWizard from '../../../DocumentWizard';
import DocumentField from '../../../DocumentField';
import IT2104PDF from './IT2104Pdf';
import SubscriberWrapper from '../../../../../components/SubscriberWrapper';
import DocumentCheckbox from '../../../DocumentCheckbox';
import DocumentSignatureButton from '../../../DocumentSignatureButton';
import { Validation } from '../../../../../validation';
import moment from 'moment';
import DocumentRadioCheck from '../../../DocumentRadioCheck';
import IT2104Pdf from './IT2104Pdf';
import { reactPdfToBase64 } from '../../../../../tools';
import { submitDocument } from '../../../../../services/EmployeeOnboardingService';
import { AppGlobals } from '../../../../../App';
import IT2104 from './IT2104';
import LoadingWrapper from '../../../../../components/LoadingWrapper';

export default function IT2104Form({document, userData, companyData, isEmployer, signature, hideModal, onboardingToken, updateDocumentToCompleted}) {
    const [isLoading, setIsLoading] = useState(false);
    const [it2104, setIT2104] = useState(IT2104.initDefault());
    const [zoom, setZoom] = useState(1);

    const fieldRefs = [
        useRef(), useRef(), useRef(), useRef(), useRef(), 
        useRef(), useRef(), useRef(), useRef(), useRef(), 
        useRef(), useRef(), useRef(), useRef(), useRef(), 
        useRef(), useRef(), useRef(), useRef(), useRef(), 
        useRef(),
    ]

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        setIT2104(await IT2104.initFromData(document, userData, companyData, isEmployer));
        setIsLoading(false);
    }

    const handleSubmit = async () => {
        const pdf = <IT2104Pdf signature={signature} it2104={it2104} isEmployer={isEmployer}/>;
        const base64 = await reactPdfToBase64(pdf);
        const response = await submitDocument(it2104.encode(document, base64), onboardingToken);
        if (response.status === 200) {
            updateDocumentToCompleted('it2104', base64);
            hideModal();
            AppGlobals.alert('success', response.message);
        } else {
            AppGlobals.alert('danger', response.message);
        }
    }

    return (
        <>
            <Modal.Header closeButton>
                
            </Modal.Header>
            <Modal.Body style={{padding: 0, overflowX: 'scroll', backgroundColor: 'lightgray'}}>
                <LoadingWrapper isLoading={isLoading}>
                    <div style={{transform: `scale(${zoom})`, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 12, height: (1100 + 16) * zoom}}>
                        <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                            <img src={isEmployer ? it2104.employeeSubmission[0] ?? IT2104Page1 : IT2104Page1} style={{width: 850, height: 1100}}/>
                            {!isEmployer ?
                                <>
                                    <DocumentField innerRef={fieldRefs[0]} stateProps={new ControlStateProps('firstNameAndMiddleInitial', it2104, Validation.nonEmptyString)} maxLength={20} x={55} y={130} width={230} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[1]} stateProps={new ControlStateProps('lastName', it2104, Validation.nonEmptyString)} maxLength={20} x={355} y={130} width={220} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[2]} stateProps={new ControlStateProps('ssn', it2104, Validation.ssn)} x={610} y={130} width={150} fontSize={14} type='number' maxLength={9}/>

                                    <DocumentField innerRef={fieldRefs[3]} stateProps={new ControlStateProps('homeAddress', it2104, Validation.nonEmptyString)} maxLength={30} x={55} y={164} width={320} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[4]} stateProps={new ControlStateProps('apartmentNumber', it2104)} maxLength={10} x={465} y={164} width={90} fontSize={14}/>

                                    <DocumentField innerRef={fieldRefs[5]} stateProps={new ControlStateProps('city', it2104, Validation.nonEmptyString)} maxLength={20} x={55} y={196} width={150} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[6]} stateProps={new ControlStateProps('state', it2104, Validation.nonEmptyString)} maxLength={15} x={373} y={196} width={115} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[7]} stateProps={new ControlStateProps('zipCode', it2104, Validation.nonEmptyString)} type='number' maxLength={5} x={505} y={196} width={85} fontSize={14}/>

                                    <DocumentRadioCheck rhs innerRef={fieldRefs[8]} value={0} stateProps={new ControlStateProps('singleOrMarried', it2104, Validation.notUndefined, 'singleOrMarried0')} x={717} y={153} width={14} height={14}/>
                                    <DocumentRadioCheck rhs innerRef={fieldRefs[9]} value={1} stateProps={new ControlStateProps('singleOrMarried', it2104, Validation.notUndefined, 'singleOrMarried1')} x={783} y={153} width={14} height={14}/>
                                    <DocumentRadioCheck rhs innerRef={fieldRefs[10]} value={2} stateProps={new ControlStateProps('singleOrMarried', it2104, Validation.notUndefined, 'singleOrMarried2')} x={783} y={170} width={14} height={14}/>

                                    <DocumentRadioCheck rhs innerRef={fieldRefs[11]} value={0} stateProps={new ControlStateProps('residentOfNewYork', it2104, Validation.notUndefined, 'residentOfNewYork0')} x={732} y={220} width={14} height={14}/>
                                    <DocumentRadioCheck rhs innerRef={fieldRefs[12]} value={1} stateProps={new ControlStateProps('residentOfNewYork', it2104, Validation.notUndefined, 'residentOfNewYork1')} x={786} y={220} width={14} height={14}/>

                                    <DocumentRadioCheck rhs innerRef={fieldRefs[13]} value={0} stateProps={new ControlStateProps('residentOfYonkers', it2104, Validation.notUndefined, 0)} x={732} y={237} width={14} height={14}/>
                                    <DocumentRadioCheck rhs innerRef={fieldRefs[14]} value={1} stateProps={new ControlStateProps('residentOfYonkers', it2104, Validation.notUndefined, 1)} x={786} y={237} width={14} height={14}/>

                                    <DocumentField innerRef={fieldRefs[15]} stateProps={new ControlStateProps('totalNumberAllowancesState', it2104)} type='number' minInt={0} maxInt={255} x={710} y={267} width={87} fontSize={12}/>
                                    <DocumentField innerRef={fieldRefs[16]} stateProps={new ControlStateProps('totalNumberAllowancesCity', it2104)} type='number' minInt={0} maxInt={255} x={710} y={284} width={87} fontSize={12}/>
                                    <DocumentField innerRef={fieldRefs[17]} stateProps={new ControlStateProps('stateAdditional', it2104)} type='number' minDecimal={0} maxDecimal={9999.99} x={710} y={333} width={87} fontSize={12}/>
                                    <DocumentField innerRef={fieldRefs[18]} stateProps={new ControlStateProps('cityAdditional', it2104)} type='number' minDecimal={0} maxDecimal={9999.99} x={710} y={350} width={87} fontSize={12}/>
                                    <DocumentField innerRef={fieldRefs[19]} stateProps={new ControlStateProps('yonkersAdditional', it2104)} type='number' minDecimal={0} maxDecimal={9999.99} x={710} y={367} width={87} fontSize={12}/>

                                    <DocumentSignatureButton innerRef={fieldRefs[20]} signature={signature} stateProps={new ControlStateProps('signed', it2104, Validation.true)} x={160} y={457} width={80} height={20}/>
                                    <SubscriberWrapper stateProps={new ControlStateProps('signed', it2104, null, 'wrapper for date')} render={() => {
                                        return (!it2104.signed ? '' : 
                                        <span style={{position: 'absolute', left: 640, top: 456}}>{moment().format('MM/DD/YYYY')}</span>
                                        )
                                    }}/>
                                </>
                            :
                                <>
                                    <DocumentCheckbox innerRef={fieldRefs[0]} stateProps={new ControlStateProps('employeeMoreThan14Exemptions', it2104)} x={546} y={632} width={20} height={20}/>
                                    <DocumentCheckbox innerRef={fieldRefs[1]} stateProps={new ControlStateProps('employeeNewOrRehire', it2104)} x={253} y={657} width={20} height={20}/>
                                    <DocumentField innerRef={fieldRefs[2]} type='date' stateProps={new ControlStateProps('firstDateWorked', it2104)} x={682} y={658} width={120} fontSize={14}/>
                                    <DocumentRadioCheck rhs innerRef={fieldRefs[3]} value={0} stateProps={new ControlStateProps('healthBenefitsAvailable', it2104, Validation.notUndefined, 'healthBenefitsAvailable0')} x={539} y={746} width={19} height={19}/>
                                    <DocumentRadioCheck rhs value={1} stateProps={new ControlStateProps('healthBenefitsAvailable', it2104, Validation.notUndefined, 'healthBenefitsAvailable1')} x={625} y={746} width={19} height={19}/>
                                    <DocumentField innerRef={fieldRefs[4]} type='date' stateProps={new ControlStateProps('dateQualified', it2104)} x={418} y={771} width={135} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[5]} stateProps={new ControlStateProps('companyNameAndAddress', it2104)} maxLength={90} x={52} y={829} width={565} fontSize={16}/>
                                    <DocumentField innerRef={fieldRefs[6]} stateProps={new ControlStateProps('ein', it2104, Validation.nonEmptyString)} maxLength={9} type='number' x={640} y={829} width={142} fontSize={16}/>
                                </>
                            }
                        </div>
                    </div>
                </LoadingWrapper>
            </Modal.Body>
            <DocumentWizard document={it2104} fieldRefs={fieldRefs} refCount={isEmployer ? 7 : 21} onSubmit={handleSubmit} zoom={zoom} setZoom={setZoom}/>
        </>
    )
}
