/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import StateObject from "../../../models/State/StateObject";
import Decoder from "../../../decoding";

export default class UserForInterview extends StateObject {
    uid: number;
    firstName: string;
    lastName: string;

    constructor(
        uid: number,
        firstName: string,
        lastName: string,

    ) {
        super();
        this.uid = uid;
        this.firstName = firstName;
        this.lastName = lastName;
    }

    static initDefault(): UserForInterview {
        return new UserForInterview(-1, '', '',);
    }

    static decode(json: any): UserForInterview {
        const decoder = new Decoder(json);
        const uid: number = decoder.decode('uid', Decoder.Uid);
        const firstName: string = decoder.decode('firstName', Decoder.StringStrict);
        const lastName: string = decoder.decode('lastName', Decoder.StringStrict);

        if (decoder.checkForErrors()) {
            return new UserForInterview(uid, firstName, lastName);
        } else {
            return UserForInterview.initDefault();
        }
    }  
}