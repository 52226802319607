/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useReducer, useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import OptionalCol from '../OptionalCol';
import ValidationFeedback from '../ValidationFeedback';

export default function CustomPicker({selection, setSelection, items, title, breakpoints, stateProps}) {
    const [showValidation, setShowValidation] = useState(false);
    const [_, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        stateProps?.subscribe((showValidation) => {
            if (showValidation) {
                setShowValidation(true);
            }
            forceUpdate();
        });
        
    }, [stateProps]);

    const handleSetSelection = (newValue) => {
        setShowValidation(true);
        if (stateProps) {
            stateProps.setValue(newValue);
        }
        if (setSelection) {
            setSelection(newValue);
        }
    }
 
    const dropdownItems = Object.entries(items).map(([value, label]) => {
        return (
            <Dropdown.Item key={value} active={(stateProps?.getValue() ?? selection) === value} onClick={() => {handleSetSelection(value)}}>{label}</Dropdown.Item>
        )
    });

    return (
        <OptionalCol breakpoints={breakpoints}>
            <Dropdown as={InputGroup} onBlur={() => setShowValidation(true)}>
                <FloatingLabel label={<div>{title}<span style={{color: 'red'}}>{(stateProps?.hasValidation()) ? '*' : ''}</span></div>}>
                    <Form.Control 
                        disabled 
                        placeholder=' '
                        value={items[stateProps?.getValue() ?? selection] ?? ''}
                        style={{backgroundColor: 'white'}} 
                        isInvalid={showValidation ? stateProps?.getIsInvalid() : undefined} 
                        isValid={showValidation ? stateProps?.getIsValid() : undefined} 
                    />
                    <ValidationFeedback stateProps={stateProps} showValidation={showValidation}/>
                </FloatingLabel>
                <Dropdown.Toggle style={{color: 'white'}}/>
                <Dropdown.Menu>
                    {dropdownItems}
                </Dropdown.Menu>
            </Dropdown>
        </OptionalCol>
    )

}