/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from 'moment';
import { addressToString, availabilityFromBinaryString, formatPhoneNumber, formatTime, usdFormatter, validateInteger } from '../../../../tools';
import { EmployeeTypes, PayTypes } from '../../../../enums';


export default function OfferLetterFormBackground({document, userData, companyData}) {

    const parseOfferLetter = (text) => {
        text = text.replaceAll('[CompanyName]', companyData.companyName ?? companyData.name);
        text = text.replaceAll('[CreatedDate]', moment(document.createdDate).format('MMM D, YYYY'));
        text = text.replaceAll('[EmployeeName]', `${userData.firstName} ${userData.lastName}`);
        text = text.replaceAll('[EmployeeType]', EmployeeTypes[document.employeeType]);
        text = text.replaceAll('[StartDate]', moment(document.startDate).format('MMM D, YYYY'));
        text = text.replaceAll('[SupervisorName]', document.supervisorName);
        text = text.replaceAll('[TerminalAddress]', addressToString(document.terminalAddress));
        text = text.replaceAll('[LocationAddress]', addressToString(document.terminalAddress));
        text = text.replaceAll('[StartTime]', formatTime(document.startTime));
        text = text.replaceAll('[Pay]', `${usdFormatter.format(document.payRate)} ${PayTypes[document.payType]}`);
        text = text.replaceAll('[StopBonusThresholds]', document.stopBonusWeekdayThreshold == document.stopBonusWeekendThreshold ? `${validateInteger(document.stopBonusWeekdayThreshold)} stops` : `${validateInteger(document.stopBonusWeekdayThreshold)}/${validateInteger(document.stopBonusWeekendThreshold)} (weekdays/weekends) stops`);
        text = text.replaceAll('[StopBonusAmounts]', document.stopBonusWeekdayAmount == document.stopBonusWeekendAmount ? usdFormatter.format(document.stopBonusWeekdayAmount) : `${usdFormatter.format(document.stopBonusWeekdayAmount)}/${usdFormatter.format(document.stopBonusWeekendAmount)} (weekdays/weekends)`);
        text = text.replaceAll('[ExpectedAvailability]', availabilityFromBinaryString(document.expectedAvailability));
        text = text.replaceAll('[DailyStartTime]', formatTime(document.dailyStartTime));
        text = text.replaceAll('[Benefits]', document.benefits ? document.benefits : 'There are currently no available benefits for this employer');
        text = text.replaceAll('[AOName]', document.aoName);
        text = text.replaceAll('[AOPhoneNumber]', formatPhoneNumber(document.aoPhoneNumber));
        text = text.replaceAll('[AOEmail]', document.aoEmail);
        text = text.replaceAll('[OfferLetterManagerName]', document.aoName);
        text = text.replaceAll('[OfferLetterManagerPhoneNumber]', formatPhoneNumber(document.aoPhoneNumber));
        text = text.replaceAll('[OfferLetterManagerEmail]', document.aoEmail);
        return text;
    }

    return (
        <div style={{position: 'relative', padding: 75, width: 850, height: 1100, backgroundColor: 'white', lineHeight: 1}}>
            <span style={{whiteSpace: 'pre-line', fontSize: 17}}>{parseOfferLetter(document.content)}</span>

            <span style={{fontSize: 17, position: 'absolute', bottom: 180, left: 75, opacity: 0.65}}>Please sign and date to confirm receipt of this document.</span>
            <div style={{position: 'absolute', left: 75, bottom: 75, display: 'flex', flexDirection: 'row', gap: 75, fontSize: 17}}>
                <span style={{borderTop: '1px solid black', paddingLeft: 18, paddingRight: 75, paddingTop: 6}}>Employee's Signature</span>
                <span style={{borderTop: '1px solid black', paddingLeft: 18, paddingRight: 75, paddingTop: 6}}>Date</span>
            </div>
        </div>
    )
}