/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import heic2any from "heic2any";

export default function DocumentFileControl({disabled, stateProps, max = 10000000, x, y, width, innerRef, defaultLabel}) {
    const [isLoading, setIsLoading] = useState(false);
    const [_, forceUpdate] = useReducer(x => x + 1, 0);

    const [tooLarge, setTooLarge] = useState(false);
    const [localValue, setLocalValue] = useState('');
    const [fileName, setFileName] = useState(stateProps.getValue() ? defaultLabel : '')

    useEffect(() => {
        stateProps?.subscribe(() => {
            forceUpdate();
        });
        setFileName(stateProps.getValue() ? defaultLabel : '')
    }, [stateProps]);

    const handleSetValue = async (value) => {
        setIsLoading(true);
        const file = innerRef.current.files[0];
        if (max && file?.size > max) {
            setTooLarge(true);
            setLocalValue('');
            setIsLoading(false);
            stateProps.stateObject.setState(stateProps.stateKey, null);
            return;
        } else if (tooLarge) {
            setTooLarge(false);
        }

        if (file) {
            setLocalValue(value);
            let valueToRead = file;

            if (file.type == 'image/heic') {
                valueToRead = await heic2any({blob: file, toType: 'image/jpeg', quality: 0.5});
            }

            const fileReaderResult = await new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                }
                reader.readAsDataURL(valueToRead); 
            })
            stateProps.stateObject.setState(stateProps.stateKey, fileReaderResult);
            setFileName(file.name);
        } else {
            setLocalValue('');
            stateProps.stateObject.setState(stateProps.stateKey, null);
        }
        setIsLoading(false);
    }

    const maxFileSizeToString = () => {
        if (max >= 1000000000) {
            return `${max / 1000000000} GB`;
        } else if (max >= 1000000) {
            return `${max / 1000000} MB`;
        } else if (max >= 1000) {
            return `${max / 1000} KB`;
        } else {
            return `${max} Bytes`;
        }
    }


    return (
        <div style={{position: x & y ? 'absolute' : 'relative', top: y, left: x, width: width, textAlign: 'center'}}>
            <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
                <Form.Control
                    ref={stateProps.getValue() ? null : innerRef}
                    style={{display: stateProps.getValue() ? 'none' : 'block'}}
                    isInvalid={stateProps.getIsInvalid()} 
                    isValid={stateProps.getIsValid()} 
                    disabled={disabled || isLoading}
                    type='file'
                    accept='.heic,.png,.jpg,.jpeg'
                    value={localValue} 
                    onChange={(event) => {handleSetValue(event.target.value)}} 
                />
                <InputGroup ref={stateProps.getValue() ? innerRef : null} style={{display: stateProps.getValue() ? 'flex' : 'none'}}>
                    <Button onClick={() => {stateProps.setValue(null); setLocalValue('')}}>Change File</Button>
                    <Form.Control readOnly isValid value={fileName} style={{fontSize: 15}}/>
                </InputGroup>
                { isLoading && 
                    <Spinner size='sm' style={{color: 'var(--bs-primary)'}}/>
                }
            </div>
            <p style={{margin: 0, marginTop: 4}}>Accepted File Types: .heic, .png, .jpg, .jpeg</p>
            { tooLarge &&
                <p style={{color: 'red', margin: 0}}>{`File size must not exceed ${maxFileSizeToString(max)}`}</p>
            }
        </div>
    )
}

