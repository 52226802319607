/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import { Card, Col, Container, Form, ListGroup, Row } from 'react-bootstrap';
import JobPost from '../JobPosts/models/JobPost';
import JobApplication from './models/JobApplication';
import { Breakpoints, addressToString, cleanUsd, usdFormatter } from '../../tools';
import CustomButton from '../../components/CustomButton';
import { EmploymentTypes, MilitaryBranches, PayTypes, ShiftDurations, ShiftTypes } from '../../enums';
import KeyValueRow from '../../components/KeyValueRow';
import MilitaryService from '../../models/JobApplication/MilitaryService';
import JobApplicationEducationForm from './JobApplicationEducationForm';
import JobApplicationEmploymentHistoryForm from './JobApplicationEmploymentHistoryForm';
import JobApplicationCustomQuestionForm from './JobApplicationCustomQuestionForm';
import CustomQuestionAnswer from '../../models/JobApplication/CustomQuestionAnswer';
import CustomQuestion from '../../models/CustomQuestion';
import CustomControl from '../../components/controls/CustomControl';
import AddressControl from '../../components/controls/AddressControl';
import CustomYesNoControl from '../../components/controls/CustomYesNoControl';
import SubscriberWrapper from '../../components/SubscriberWrapper';
import CustomPicker from '../../components/controls/CustomPicker';
import { Validation } from '../../validation';
import ControlStateProps from '../../models/State/ControlStateProps';
import { applyToJob } from '../../services/ApplicantService';
import { AppGlobals } from '../../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';


export default class JobApplicationForm extends React.Component {
    constructor(props) {
        super(props);
        this.jobApplication = JobApplication.initFromJobPost(props.jobPost);

        this.breakpoints = this.props.breakpoints ?? {xl: 3, lg: 4, md: 6};
        this.breakpointsForYesNo = this.props.breakpointsForYesNo ?? {xxl: 6, xl: 6, lg: 6};

        this.state = {
            isSubmitting: false,
            finishedApplying: false,
        }

        this.handleSubmit = this.handleSubmit.bind(this);

        props.jobPost.subscribe('appPreview', null , () => {
            this.jobApplication.setState('customQuestionAnswers', props.jobPost.questions.map((q) => {
                return CustomQuestionAnswer.initFromQuestion(q.duplicate());
            }));
            this.forceUpdate();
        })
    }

    async handleSubmit() {
        this.setState({isSubmitting: true});
        this.jobApplication.setState('source', this.props.source);
        const response = await applyToJob(this.jobApplication.encode());
        if (response.status === 200) {
            this.setState({finishedApplying: true});
        } else if(response.status === 400){
            AppGlobals.alert('warning', response.message);
        } else {
            AppGlobals.alert('danger', response.message);
        }
        this.setState({isSubmitting: false});
    }

    
    render() {
        return(
            this.state.finishedApplying ? 
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                        <Card>
                            <Card.Header>
                                <Card.Title style={{textAlign: 'center'}}>Thank you!</Card.Title>
                            </Card.Header>
                            <Card.Body style={{display: 'flex', alignItems: 'center', gap: 12}}>
                                <span style={{margin: 'auto', textAlign: 'center'}}>
                                    <FontAwesomeIcon size="1x" icon={faCircleCheck} style={{color: 'var(--bs-success)', marginRight: 8}}/>
                                    Your application was submitted successfully!
                                </span>
                            </Card.Body>
                        </Card>
                    </div> 
                    :(
            <div className='hidden-scrollbar' style={{flex: 1, textAlign: 'center', borderRadius: this.props.jobPost ? 6 : 0, overflowY: 'scroll', display: 'flex', flexDirection: 'column', gap: 12}}>
                <Card style={{backgroundColor: 'var(--background-secondary)', borderRadius: this.props.jobPost ? 6 : 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0}}>
                    <Card.Body>
                        <h1>{this.props.jobPost.title}</h1>
                        {/* <div style={{display: 'flex', gap: 12, justifyContent: 'center', fontWeight: 'bold', marginBottom: 8}}>
                            <span>{EmploymentTypes[this.props.jobPost.employmentType]}</span>
                            <span>|</span>
                            <span>{usdFormatter.format(this.props.jobPost.compensationMin) + ' - ' + usdFormatter.format(this.props.jobPost.compensationMax) + ' ' + PayTypes[this.props.jobPost.payType]}</span>
                        </div> */}
                        <div style={{display: 'block', width: '80%', margin: 'auto'}}>
                            <p style={{whiteSpace: 'break-spaces', textAlign: 'left'}}>{this.props.jobPost.description}</p>
                        </div>
                        <ListGroup>
                        <KeyValueRow isListItem title={EmploymentTypes[this.props.jobPost.employmentType]} value={usdFormatter.format(this.props.jobPost.compensationMin) + ' - ' + usdFormatter.format(this.props.jobPost.compensationMax) + ' ' + PayTypes[this.props.jobPost.payType]}/>
                            <KeyValueRow isListItem title='Shift Type(s)' value={this.props.jobPost.shiftType == 'both' ? 'Weekdays & Weekends' : ShiftTypes[this.props.jobPost.shiftType]}/>
                            <KeyValueRow isListItem title='Shift Durations' value={this.props.jobPost.shiftDurations.map((r) => ShiftDurations[r]).join(', ')}/>
                            { this.props.jobPost.benefits &&
                                <KeyValueRow isListItem title='Benefits' value={this.props.jobPost.benefits}/>
                            }
                                <KeyValueRow isListItem title='Location' value={!this.props.jobPost.terminal ? '(Terminal Address)' : addressToString(this.props.jobPost.terminal.address)}/>
                        </ListGroup>
                    </Card.Body>
                </Card>
                <div style={{padding: 12, display: 'flex', flexDirection: 'column', gap: 12, textAlign: 'start'}}>
                    <Card>
                        <Card.Body>
                            <Card.Title style={{margin: 0}}>Personal Information</Card.Title>
                            <Container fluid style={{paddingTop: 12}}>
                                <Row>
                                    <CustomControl floatingLabel maxLength={30} title='First Name' stateProps={new ControlStateProps('firstName', this.jobApplication, Validation.nonEmptyString)} breakpoints={this.breakpoints}/>
                                    <CustomControl floatingLabel maxLength={30} title='Middle Name' stateProps={new ControlStateProps('middleName', this.jobApplication)} breakpoints={this.breakpoints}/>
                                    <CustomControl floatingLabel maxLength={30} title='Last Name' stateProps={new ControlStateProps('lastName', this.jobApplication, Validation.nonEmptyString)} breakpoints={this.breakpoints}/>
                                    <CustomControl type='number' floatingLabel maxLength={10} title='Phone Number' stateProps={new ControlStateProps('phoneNumber', this.jobApplication, Validation.phoneNumber)} breakpoints={this.breakpoints}/>
                                    <CustomControl floatingLabel maxLength={50} title='Email Address' stateProps={new ControlStateProps('email', this.jobApplication, Validation.email)} breakpoints={this.breakpoints}/>
                                    <CustomControl type='date' max={moment().format('YYYY-MM-DD')} floatingLabel title='Date of Birth' stateProps={new ControlStateProps('dateOfBirth', this.jobApplication, Validation.date)} breakpoints={this.breakpoints}/>
                                    <CustomControl type='date' max={'9999-12-31'} min={moment().format('YYYY-MM-DD')} floatingLabel title='First Date Available to Work' stateProps={new ControlStateProps('dateAvailable', this.jobApplication, Validation.date)} breakpoints={this.breakpoints}/>
                                    <AddressControl title='Address' address={this.jobApplication.address} breakpoints={{lg: 6}}/>
                                    <CustomYesNoControl title='Are you legally eligible to work in the U.S.?' stateProps={new ControlStateProps('eligibleToWork', this.jobApplication, Validation.notUndefined)} breakpoints={this.breakpointsForYesNo}/>
                                    <CustomYesNoControl title='Are you willing to recieve a background check?' stateProps={new ControlStateProps('backgroundCheckConsent', this.jobApplication, Validation.notUndefined)} breakpoints={this.breakpointsForYesNo}/>
                                    <CustomYesNoControl title='Have you ever been convicted of a felony?' stateProps={new ControlStateProps('convictedOfFelony', this.jobApplication, Validation.notUndefined)} setValue={(value) => {this.jobApplication.setState('felonyConvictionNotes', value ? '' : undefined)}} breakpoints={this.breakpointsForYesNo}/>
                                    <SubscriberWrapper stateProps={new ControlStateProps('convictedOfFelony', this.jobApplication, null, 'felonyConvictionNotes wrapper')} render={() => {
                                        return (
                                            <>
                                                { this.jobApplication.convictedOfFelony &&
                                                    <CustomControl title='Explanation of Felony' floatingLabel maxLength={300} stateProps={new ControlStateProps('felonyConvictionNotes', this.jobApplication)} breakpoints={this.breakpointsForYesNo}/>
                                                }
                                            </>
                                        )
                                    }}/>
                                    <CustomYesNoControl title='Are you a veteran?' stateProps={new ControlStateProps('isVeteran', this.jobApplication, Validation.notUndefined)} setValue={(value) => { this.jobApplication.setState('militaryService', value ? MilitaryService.initDefault('militaryService', this.jobApplication) : undefined)}} breakpoints={this.breakpointsForYesNo}/>
                                    <SubscriberWrapper stateProps={new ControlStateProps('isVeteran', this.jobApplication, null, 'militaryService wrapper')} render={() => {
                                        return (
                                            <>
                                                { this.jobApplication.militaryService &&
                                                    <Col xl={12}>
                                                        <Form.Label>{'Military Service'}</Form.Label>
                                                        <Container fluid style={{border: '1px solid lightgray', borderRadius: 6, padding: 12, marginBottom: 8}}>
                                                            <Row>
                                                                <CustomPicker stateProps={new ControlStateProps('branch', this.jobApplication.militaryService, Validation.nonEmptyString)} items={MilitaryBranches} title='Branch' breakpoints={this.breakpoints}/>
                                                                <CustomControl max={'9999-12-31'} floatingLabel type='date' title='Served From Date' stateProps={new ControlStateProps('startDate', this.jobApplication.militaryService, Validation.date)} breakpoints={this.breakpoints}/>
                                                                <CustomControl max={moment().format('YYYY-MM-DD')} floatingLabel type='date' title='Served To Date' stateProps={new ControlStateProps('endDate', this.jobApplication.militaryService)} breakpoints={this.breakpoints}/>
                                                                <CustomControl floatingLabel title='Rank at Discharge' stateProps={new ControlStateProps('rankAtDischarge', this.jobApplication.militaryService, Validation.nonEmptyString)} breakpoints={this.breakpoints}/>
                                                                <CustomControl floatingLabel title='Type of Discharge' stateProps={new ControlStateProps('dischargeType', this.jobApplication.militaryService, Validation.nonEmptyString)} breakpoints={this.breakpoints}/>
                                                                <CustomControl floatingLabel title='If Dishonorable, Explain' stateProps={new ControlStateProps('dishonorableDischargeExplanation', this.jobApplication.militaryService)} breakpoints={this.breakpoints}/>
                                                            </Row>
                                                        </Container>
                                                    </Col>
                                                }
                                            </>
                                        )}
                                    }/>
                                    
                                </Row>
                                
                            </Container>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <JobApplicationEducationForm jobApplication={this.jobApplication} breakpoints={this.breakpoints}/>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <JobApplicationEmploymentHistoryForm jobApplication={this.jobApplication} breakpoints={this.breakpoints}/>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <JobApplicationCustomQuestionForm jobPost={this.props.jobPost} jobApplication={this.jobApplication} breakpoints={this.breakpoints}/>
                        </Card.Body>
                    </Card>

                    { !this.props.isPreview &&
                        <Card>
                            <Card.Body style={{textAlign: 'center'}}>
                                <CustomButton isLoading={this.state.isSubmitting} stateObject={this.jobApplication} uniqueKey={'JobApplicationForm submit'} onClick={this.handleSubmit} style={{maxWidth: 400}}>
                                    Submit
                                </CustomButton>
                            </Card.Body>
                        </Card>
                    }
                </div>
            </div>
        ))
    }
}