/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import { CustomQuestionTypes } from '../../../enums';
import { Card, Col, Container, Dropdown, Row } from 'react-bootstrap';
import JobPost from '../../JobPosts/models/JobPost';
import CustomQuestion from '../../../models/CustomQuestion';
import CustomQuestionFieldView from './CustomQuestionFieldView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import CustomQuestionPickerView from './CustomQuestionPickerView';
import CustomQuestionKnockoutView from './CustomQuestionKnockoutView';

export default class CustomQuestionsView extends React.Component{
    constructor(props) {
        super(props);

        this.handleDeleteQuestion = this.handleDeleteQuestion.bind(this);
        this.handleAddQuestion = this.handleAddQuestion.bind(this);
        this.handleMoveQuestionBack = this.handleMoveQuestionBack.bind(this);
        this.handleMoveQuestionForward = this.handleMoveQuestionForward.bind(this);

        this.props.jobPost.subscribe('CustomQuestionsView', 'questions', () => this.forceUpdate());
    }

    handleAddQuestion(type) {
        let questions= Array.from(this.props.jobPost['questions']);
        let newQuestion = CustomQuestion.initDefault(type);
        questions.push(newQuestion);
        this.props.jobPost.setState('questions', questions);
    }

    handleDeleteQuestion(uid) {
        let questions = Array.from(this.props.jobPost['questions']);
        let filteredQuestions = questions.filter((q) => q.uid != uid);
        this.props.jobPost.setState('questions', filteredQuestions);
    }

    handleMoveQuestionBack(uid) {
        let questions = Array.from(this.props.jobPost['questions']);
        let index = questions.findIndex((q) => q.uid === uid);
        if (index > 0) {
            let question = questions.splice(index, 1)[0];
            questions.splice(index - 1, 0, question);
            this.props.jobPost.setState('questions', questions);
        }
    }

    handleMoveQuestionForward(uid) {
        let questions = Array.from(this.props.jobPost['questions']);
        let index = questions.findIndex((q) => q.uid === uid);
        if (index < questions.length - 1) {
            let question = questions.splice(index, 1)[0];
            questions.splice(index + 1, 0, question);
            this.props.jobPost.setState('questions', questions);
        }
    }

    render() {
        const customQuestionTypeElements = Object.entries(CustomQuestionTypes).map(([value, label]) => {
            return (
                <Dropdown.Item key={value} onClick={() => {this.handleAddQuestion(value)}}>
                    {label}
                </Dropdown.Item>
            )
        })


        const questionElements = this.props.jobPost['questions'].map((question, index) => {
            if (['toggle', 'text', 'textArea', 'number', 'date'].includes(question.type)) {
                return (
                    <CustomQuestionFieldView key={index} index={index} question={question} handleDelete={this.handleDeleteQuestion} handleMoveBack={this.handleMoveQuestionBack} handleMoveForward={this.handleMoveQuestionForward}/>
                )
            } else if (question.type === 'knockout') {
                return (
                    <CustomQuestionKnockoutView key={index} index={index} question={question} handleDelete={this.handleDeleteQuestion} handleMoveBack={this.handleMoveQuestionBack} handleMoveForward={this.handleMoveQuestionForward}/>
                )
            } else {
                return (
                    <CustomQuestionPickerView key={index} index={index} question={question} handleDelete={this.handleDeleteQuestion} handleMoveBack={this.handleMoveQuestionBack} handleMoveForward={this.handleMoveQuestionForward}/>
                )
            }
        })


        return (
            <Card>
                <Card.Body>
                    <div style={{display: 'flex', alignItems: 'center', gap: 12, marginBottom: '0.5rem'}}>
                        <Card.Title style={{marginBottom: 0}}>Application Questions</Card.Title>
                        <Dropdown>
                            <Dropdown.Toggle bsPrefix='p-0' style={{fontSize: 14, height: 24, width: 24, borderRadius: 12}}>
                                <FontAwesomeIcon icon={faPlus}/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Header>Select a Question Type</Dropdown.Header>
                                {customQuestionTypeElements}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                        {questionElements}
                    </div>
                </Card.Body>
            </Card>
        )
    }
}