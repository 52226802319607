/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useRef, useState } from 'react';
import REV419Pdf from './REV419Pdf';
import REV419Page1 from '../../../../../assets/REV-419-1.png';
import REV419Page2 from '../../../../../assets/REV-419-2.png';
import REV419Page3 from '../../../../../assets/REV-419-3.png';
import { Button, Modal } from 'react-bootstrap';
import ControlStateProps from '../../../../../models/State/ControlStateProps';
import { Validation } from '../../../../../validation';
import moment from 'moment';
import SubscriberWrapper from '../../../../../components/SubscriberWrapper';
import { pdf } from '@react-pdf/renderer';
import DocumentWizard from '../../../DocumentWizard';
import DocumentSignatureButton from '../../../DocumentSignatureButton';
import DocumentField from '../../../DocumentField';
import DocumentRadioCheck from '../../../DocumentRadioCheck';
import DocumentCheckbox from '../../../DocumentCheckbox';
import { reactPdfToBase64 } from '../../../../../tools';
import { submitDocument } from '../../../../../services/EmployeeOnboardingService';
import { AppGlobals } from '../../../../../App';
import REV419 from './REV419';
import LoadingWrapper from '../../../../../components/LoadingWrapper';

export default function REV419Form({document, userData, companyData, signature, isEmployer, hideModal, onboardingToken, updateDocumentToCompleted}) {
    const [isLoading, setIsLoading] = useState(false);
    const [rev419, setREV419] = useState(REV419.initDefault());
    const [zoom, setZoom] = useState(1);

    const fieldRefs = [
        useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(),
        useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef()
    ]

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        setREV419(await REV419.initFromData(document, userData, companyData, isEmployer));
        setIsLoading(false);
    }

    const handleSubmit = async () => {
        const pdf = <REV419Pdf signature={signature} rev419={rev419} isEmployer={isEmployer}/>;
        const base64 = await reactPdfToBase64(pdf);
        const response = await submitDocument(rev419.encode(document, base64), onboardingToken);
        if (response.status === 200) {
            updateDocumentToCompleted('rev419', base64);
            hideModal();
            AppGlobals.alert('success', response.message);
        } else {
            AppGlobals.alert('danger', response.message);
        }
    }

    return (
        <>
            <Modal.Header closeButton/>
            <Modal.Body style={{padding: 0, overflowX: 'scroll', backgroundColor: 'lightgray'}}>
                <LoadingWrapper isLoading={isLoading}>
                    <div style={{transform: `scale(${zoom})`, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 12, height: ((1100* 2) + (12) + 16) * zoom}}>
                        <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                            <img src={isEmployer ? rev419.employeeSubmission[0] ?? REV419Page1 : REV419Page1} style={{width: 850, height: 1100}}/>
                            { !isEmployer ?
                                    //EMPLOYEE
                                <>
                                    <DocumentField innerRef={fieldRefs[0]} stateProps={new ControlStateProps('yearLast2', rev419, Validation.nonEmptyString)} maxLength={2} type='number' x={685} y={100} width={55} fontSize={18}/>
                                    <DocumentField innerRef={fieldRefs[1]} stateProps={new ControlStateProps('name', rev419, Validation.nonEmptyString)} maxLength={50} x={82} y={215} width={360} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[2]} stateProps={new ControlStateProps('ssn', rev419, Validation.ssn)} maxLength={9} type='number' x={550} y={215} width={110} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[3]} stateProps={new ControlStateProps('phoneNumber', rev419, Validation.phoneNumber)} type='number' maxLength={10} x={665} y={215} width={110} fontSize={14}/>

                                    <DocumentField innerRef={fieldRefs[4]} stateProps={new ControlStateProps('streetAddress', rev419, Validation.nonEmptyString)} maxLength={35} x={80} y={251} width={200} fontSize={11}/>
                                    <DocumentField innerRef={fieldRefs[5]} stateProps={new ControlStateProps('city', rev419, Validation.nonEmptyString)} maxLength={20} x={291} y={251} width={130} fontSize={11}/>
                                    <DocumentField innerRef={fieldRefs[6]} stateProps={new ControlStateProps('state', rev419, Validation.nonEmptyString)} maxLength={20} x={432} y={248} width={30} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[7]} stateProps={new ControlStateProps('zipCode', rev419, Validation.nonEmptyString)} maxLength={5} type='number' x={478} y={248} width={60} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[8]} stateProps={new ControlStateProps('taxYear', rev419)} type='number' minInt={0} maxLength={4} x={556} y={248} width={50} fontSize={14}/>
                                    <DocumentCheckbox innerRef={fieldRefs[9]} stateProps={new ControlStateProps('section2a', rev419)} x={93} y={318} height={21} width={21}/>
                                    <DocumentCheckbox innerRef={fieldRefs[10]} stateProps={new ControlStateProps('section2b', rev419)} x={93} y={360} height={21} width={21}/>
                                    <DocumentCheckbox innerRef={fieldRefs[11]} stateProps={new ControlStateProps('section2c', rev419)} x={93} y={410} height={21} width={21}/>

                                    <DocumentRadioCheck innerRef={fieldRefs[12]} value={0} stateProps={new ControlStateProps('reciprocalState', rev419, null, '1')} x={140} y={373} width={15} height={15}/>
                                    <DocumentRadioCheck value={1} stateProps={new ControlStateProps('reciprocalState', rev419, null, '2')} x={241} y={373} width={15} height={15}/>
                                    <DocumentRadioCheck value={2} stateProps={new ControlStateProps('reciprocalState', rev419, null, '3')} x={346} y={373} width={14} height={15}/>
                                    <DocumentRadioCheck value={3} stateProps={new ControlStateProps('reciprocalState', rev419, null, '4')} x={467} y={373} width={14} height={15}/>
                                    <DocumentRadioCheck value={4} stateProps={new ControlStateProps('reciprocalState', rev419, null, '5')} x={554} y={373} width={14} height={15}/>
                                    <DocumentRadioCheck value={5} stateProps={new ControlStateProps('reciprocalState', rev419, null, '6')} x={653} y={373} width={14} height={15}/>

                                    <DocumentField innerRef={fieldRefs[13]} stateProps={new ControlStateProps('residentOfState', rev419)} maxLength={15} x={330} y={411} width={120} fontSize={11}/>

                                    <DocumentSignatureButton innerRef={fieldRefs[14]} signature={signature} stateProps={new ControlStateProps('signed', rev419, Validation.true)} x={180} y={520} width={70} height={16}/>
                                    <SubscriberWrapper stateProps={new ControlStateProps('signed', rev419, null, 'wrapper for date')} render={() => {
                                        return (!rev419.signed ? '' : 
                                        <span style={{position: 'absolute', left: 675, top: 515}}>{moment().format('MM/DD/YYYY')}</span>
                                        )
                                    }}/>
                                </>
                            :
                                    //EMPLOYER
                                <>
                                    <DocumentField innerRef={fieldRefs[0]} stateProps={new ControlStateProps('employerName', rev419, Validation.nonEmptyString)} maxLength={50} x={78} y={555} width={450} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[1]} stateProps={new ControlStateProps('ein', rev419, Validation.nonEmptyString)} maxLength={9} type='number' x={550} y={555} width={200} fontSize={14}/>

                                    <DocumentField innerRef={fieldRefs[2]} stateProps={new ControlStateProps('employerAddress', rev419, Validation.nonEmptyString)} maxLength={35} x={78} y={590} width={500} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[3]} stateProps={new ControlStateProps('employerPhoneNumber', rev419, Validation.nonEmptyString)} maxLength={10} type='number' x={640} y={590} width={135} fontSize={14}/>

                                    <DocumentField innerRef={fieldRefs[4]} stateProps={new ControlStateProps('employerCity', rev419, Validation.nonEmptyString)} maxLength={20} x={78} y={623} width={400} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[5]} stateProps={new ControlStateProps('employerState', rev419, Validation.nonEmptyString)} maxLength={2} x={590} y={623} width={50} fontSize={14}/>
                                    <DocumentField innerRef={fieldRefs[6]} stateProps={new ControlStateProps('employerZipCode', rev419, Validation.nonEmptyString)} maxLength={5} type='number' x={660} y={623} width={100} fontSize={14}/>

                                    <DocumentSignatureButton innerRef={fieldRefs[7]} signature={signature} stateProps={new ControlStateProps('signed', rev419, Validation.true)} x={190} y={650} width={80} height={20}/>
                                    <DocumentField innerRef={fieldRefs[8]} stateProps={new ControlStateProps('employeeCompensation', rev419)} type='number' minDecimal={0} maxDecimal={999999.99} x={380} y={656} width={200} fontSize={14}/>
                                </>
                            }
                        </div>
                        <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                            <img src={REV419Page3} style={{width: 850, height: 1100}}/>
                        </div>
                    </div>
                </LoadingWrapper>
            </Modal.Body>
            <DocumentWizard document={rev419} fieldRefs={fieldRefs} refCount={isEmployer ? 9 : 15} onSubmit={handleSubmit} zoom={zoom} setZoom={setZoom}/>
        </>
    )
}
