/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useReducer } from 'react';
import Card from 'react-bootstrap/Card';
import CustomYesNoControl from '../../components/controls/CustomYesNoControl';
import CustomPicker from '../../components/controls/CustomPicker';
import CustomControl from '../../components/controls/CustomControl';
import CustomCheck from '../../components/controls/CustomCheck';
import ControlStateProps from '../../models/State/ControlStateProps';
import { Validation } from '../../validation';
import CustomTextArea from '../../components/controls/CustomTextArea';

export default function JobApplicationCustomQuestionForm({jobApplication, breakpoints}) {

    const customQuestionElements = jobApplication.customQuestionAnswers.map((questionAnswer, index) => {
        const options = {};

        if (['multiselect', 'picker', 'knockout'].includes(questionAnswer.question.type)) {
            (questionAnswer.question.options).forEach((option, index) => {
                options[index] = option;
            });
        }
        const type = questionAnswer.question.type;
        return (
            <div key={questionAnswer.uid} style={{border: '1px solid lightgray', borderRadius: 6, width: '100%'}}>
                <div style={{backgroundColor: 'var(--background-secondary)', marginBottom: 6, padding: 6, borderTopLeftRadius: 6, borderTopRightRadius: 6, borderBottom: '1px solid lightgray', display: 'flex', gap: 12}}>
                    <div style={{display: 'flex', flexShrink: 0, justifyContent: 'center', alignItems: 'center', width: 24, height: 24, borderRadius: 12, backgroundColor: 'var(--bs-primary)'}}>
                        <span style={{color: 'white'}}>{index + 1}</span>
                    </div>
                    <span style={{fontWeight: 'bold', flex: 1, textAlign: 'center'}}>{questionAnswer.question.title}</span>
                </div>
                <div style={{padding: 6}}>
                    { type === 'toggle' ?
                        <CustomYesNoControl title='Select Your Answer' stateProps={new ControlStateProps('answer', questionAnswer, Validation.notUndefined)}/>
                    : type === 'multiselect' ?
                        <CustomCheck title='Select all that apply' stateProps={new ControlStateProps('answer', questionAnswer)} inline items={options}/>
                    : type === 'picker' || type === 'knockout' ?
                        <CustomPicker title='Select 1' stateProps={new ControlStateProps('answer', questionAnswer, Validation.notUndefined)} items={options}/>
                    : type === 'textArea' ?
                        <CustomTextArea rows={5} max={500} stateProps={new ControlStateProps('answer', questionAnswer, Validation.nonEmptyString)}/>
                    :
                        <CustomControl floatingLabel type={type === 'number' ? 'number' : type === 'date' ? 'date' : undefined} max={type === 'date' ? '9999-12-31' : undefined} maxLength={(type === 'text' || type === 'number') ? 500 : undefined}  title='Type Your Answer Here' stateProps={new ControlStateProps('answer', questionAnswer, Validation.nonEmptyString)}/>
                    }
                </div>
                
            </div>
        )
    });

    return (
        <>
            <Card.Title>Questions from the Employer</Card.Title>
            <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                {customQuestionElements}
            </div>
        </>
    )
    
}