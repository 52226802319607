/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../decoding";
import Address from "./Address";
import StateObject from "./State/StateObject";

export default class Terminal extends StateObject {
    uid: number;
    name: string;
    // address: Address;

    constructor(uid: number, name: string) {
        super();
        this.uid = uid;
        this.name = name;
        // this.address = address;
        this.initChildren();
    }

    static initDefault(): Terminal {
        return new Terminal(-1, '');
    }

    static decode(json: any): Terminal {
        const decoder = new Decoder(json);
        const uid: number = decoder.decode('uid', Decoder.Uid);
        const name: string = decoder.decode('name', Decoder.StringStrict);
        // const address: Address = Address.decode(json.address) as Address;
        if (decoder.checkForErrors()) {
            return new Terminal(uid, name);
        } else {
            return Terminal.initDefault();
        }

    }

    encode(){
        return{
            uid: this.uid,
            name: this.name,
            // address: this.address.encode(),
        }
    }

}