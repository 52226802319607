/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { faArrowTurnDown, faArrowTurnUp, faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useReducer } from 'react';
import { Button, ButtonGroup, Card, Container, Row } from 'react-bootstrap';
import JobApplication from './models/JobApplication';
import Education from '../../models/JobApplication/Education';
import { DegreeLevels, EducationLevels, States } from '../../enums';
import { Breakpoints } from '../../tools';
import CustomControl from '../../components/controls/CustomControl';
import CustomPicker from '../../components/controls/CustomPicker';
import CustomDropDown from '../../components/controls/CustomDropDown';
import SubscriberWrapper from '../../components/SubscriberWrapper';
import CustomYesNoControl from '../../components/controls/CustomYesNoControl';
import ControlStateProps from '../../models/State/ControlStateProps';
import { Validation } from '../../validation';


export default function JobApplicationEducationForm({jobApplication, breakpoints}) {
    const [_, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        jobApplication.subscribe('JobApplicationEducationForm', 'education', (_) => forceUpdate());
    }, [jobApplication]);

    const handleAddEducation = () => {
        let educationArray = jobApplication.education;
        let newEducation = Education.initDefault('education', jobApplication);
        educationArray.push(newEducation);
        jobApplication.setState('education', educationArray);
    }

    const handleDeleteEducation = (uid) => {
        jobApplication.setState('education', jobApplication.education.filter((e) => e.uid != uid));
    }

    const handleMoveEducationBack = (uid) => {
        let educationArray = Array.from(jobApplication.education);
        let index = educationArray.findIndex((e) => e.uid === uid);
        if (index > 0) {
            let education = educationArray.splice(index, 1)[0];
            educationArray.splice(index - 1, 0, education);
            jobApplication.setState('education', educationArray);
        }
    }

    const handleMoveEducationForward = (uid) => {
        let educationArray = jobApplication.education;
        let index = educationArray.findIndex((e) => e.uid === uid);
        if (index < educationArray.length - 1) {
            let education = educationArray.splice(index, 1)[0];
            educationArray.splice(index + 1, 0, education);
            jobApplication.setState('education', educationArray);
        }
    }

    const handleSetEducationLevel = (uid, value) => {
        const education = (jobApplication.education).find(e => e.uid === uid);
        if (education) {
            if (value === 'highschool') {
                education.setState('fieldOfStudy', undefined);
                education.removeValidation('fieldOfStudy');
            } else {
                education.setState('fieldOfStudy', '');
            }
            if (value !== 'college') {
                education.setState('degreeLevel', undefined);
                education.removeValidation('degreeLevel');
            } else {
                education.setState('degreeLevel', '');
            }
        }
    }

   

    const educationElements = jobApplication.education.map((education, index) => {
        return (
            <div key={education.uid} style={{display: 'flex', alignItems: 'center', gap: 4}}>
                <div>
                    <ButtonGroup vertical>
                        <Button style={{padding: 2}} onClick={() => {handleMoveEducationBack(education.uid)}}>
                            <FontAwesomeIcon icon={faArrowTurnUp} flip='horizontal'/>
                        </Button>
                        <Button style={{padding: 2}} onClick={() => {handleMoveEducationForward(education.uid)}}>
                            <FontAwesomeIcon icon={faArrowTurnDown} flip='horizontal'/>
                        </Button>
                    </ButtonGroup>
                </div>
                <div style={{border: '1px solid lightgray', borderRadius: 6, flex: 1}}>
                    <div style={{backgroundColor: 'var(--background-secondary)', marginBottom: 6, padding: 6, borderTopLeftRadius: 6, borderTopRightRadius: 6, borderBottom: '1px solid lightgray', display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{display: 'flex', flexShrink: 0, justifyContent: 'center', alignItems: 'center', width: 24, height: 24, borderRadius: 12, backgroundColor: 'var(--bs-primary)'}}>
                            <span style={{color: 'white'}}>{index + 1}</span>
                        </div>
                        <Button onClick={() => {handleDeleteEducation(education.uid)}} className='btn-danger' style={{width: 24, height: 24, padding: 0, borderRadius: 12, display: 'flex', justifyContent: 'center', alignItems: 'center', flexShrink: 0}}>
                            <FontAwesomeIcon icon={faX}/>
                        </Button>
                    </div>
                    <Container fluid >
                        <Row>
                            <CustomControl floatingLabel title='Name of Institution' stateProps={new ControlStateProps('name', education, Validation.nonEmptyString)} breakpoints={breakpoints}/>
                            <CustomPicker title='Education Level' stateProps={new ControlStateProps('level', education, Validation.nonEmptyString)} setSelection={(value) => {handleSetEducationLevel(education.uid, value)}} items={EducationLevels} breakpoints={breakpoints}/>
                            <CustomControl floatingLabel title='City' stateProps={new ControlStateProps('city', education, Validation.nonEmptyString)} breakpoints={breakpoints}/>
                            <CustomDropDown items={States} title='State' stateProps={new ControlStateProps('state', education, Validation.nonEmptyString)} breakpoints={breakpoints}/>
                            <SubscriberWrapper stateProps={new ControlStateProps('level', education, null, 'should startdate exist')} render={()=>{
                                return (
                                    <>
                                    { (!['highschool', ''].includes(education.level)) &&
                                        <CustomControl floatingLabel max={'9999-12-31'} type='date' title='Start Date' stateProps={new ControlStateProps('startDate', education)} breakpoints={breakpoints}/>
                                    }   
                                    </>
                                )
                            }} />
                            <CustomControl floatingLabel max={'9999-12-31'} type='date' title='End Date' stateProps={new ControlStateProps('endDate', education)} breakpoints={breakpoints}/>
                            <SubscriberWrapper stateProps={new ControlStateProps('level', education, null, 'fieldOfStudy & degreeLevel wrapper')} render={() => {
                                return (
                                    <>
                                        { education.fieldOfStudy !== undefined &&
                                            <CustomControl floatingLabel title='Major / Field of Study' stateProps={new ControlStateProps('fieldOfStudy', education, Validation.nonEmptyString)} breakpoints={breakpoints}/>
                                        }
                                        { education.degreeLevel !== undefined &&
                                            <CustomPicker title='Degree Level' stateProps={new ControlStateProps('degreeLevel', education, Validation.nonEmptyString)} items={DegreeLevels} breakpoints={breakpoints}/>
                                        }
                                    </>
                                )
                            }}/>
                            <CustomYesNoControl title='Graduated?' stateProps={new ControlStateProps('graduated', education, Validation.notUndefined)} breakpoints={breakpoints}/>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    })

    return (
        <>
            <div style={{display: 'flex', alignItems: 'center', gap: 12, marginBottom: '0.5rem'}}>
                <Card.Title style={{marginBottom: 0}}>Education</Card.Title>
                <Button onClick={handleAddEducation} style={{fontSize: 14, height: 24, width: 24, padding: 0, borderRadius: 12, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <FontAwesomeIcon icon={faPlus}/>
                </Button>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                {educationElements}
            </div>
        
        </>
    )
    
}