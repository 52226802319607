/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useState } from "react";
import { Button, Container, Dropdown, ListGroup, Modal, Row } from "react-bootstrap";
import CustomControl from "../../components/controls/CustomControl";
import CustomTextArea from "../../components/controls/CustomTextArea";
import SwitchControl from "../../components/SwitchControl";
import CustomButton from "../../components/CustomButton";
import { AppGlobals } from "../../App";
import { getOfferLetters, getRejectionMessage } from "../../services/ClientService";
import { getOfferLetterData, hireApplicant, rejectApplicant } from "../../services/JobApplicationService";
import EmailTemplate from "../../components/EmailTemplate";
import Cookies from "universal-cookie";
import { getAutomaticRejectionMessage } from "../../services/MyCompany";
import { DirectDepositDocuments, EmploymentTypes, OnboardingDocuments, OnboardingDocumentsShort, PayTypes, PtoAccrualTypes, TCTitles, TaxDocumentsShort, defaultOfferLetterTitles, stateTaxDocumens, taxDocumentDictionary } from "../../enums";
import CustomCheck from "../../components/controls/CustomCheck";
import StateObject from "../../models/State/StateObject";
import ControlStateProps from "../../models/State/ControlStateProps";
import CustomRadio from "../../components/controls/CustomRadio";
import OfferLetterEditor from "../Clients/OfferLetterEditor";
import LoadingWrapper from "../../components/LoadingWrapper";
import SubscriberWrapper from "../../components/SubscriberWrapper";
import { addressToString, arraysToObject, availabilityToBinaryString, availabilityToString, base64PdfToBase64Images, downloadReactPdf, formatPhoneNumber, formatTime, reactPdfToBase64, usdFormatter, validateDecimalFixed, validateInteger } from "../../tools";
import { Validation } from "../../validation";
import moment from "moment";
import OfferLetterPdf from "./OfferLetterPdf";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import Wizard from "../../components/Wizard/Wizard";
import Address from "../../models/Address";
import AddressControl from "../../components/controls/AddressControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import AvailabilityControl from "../../components/controls/AvailabilityControl";
import ChildSupportControl from "../../components/controls/ChildSupportControl";
import AutomaticDeductionControl from "../../components/controls/AutomaticDeductionControl";
import AutomaticReimbursementControl from "../../components/controls/AutomaticReimbursementControl";
import FormGroup from "../../components/FormGroup";
import KeyValueRow from "../../components/KeyValueRow";
import { HireData } from "./HireData";
import W4Pdf from "./W4Pdf";
import W4PdfFile from '../../assets/fw4.pdf';
import DirectDepositPdf from "./DirectDepositPdf";
import BwcCodeControl from "../../components/controls/BwcCodeControl";
import DropdownItem from "react-bootstrap/esm/DropdownItem";

export default function HireModal({jobApplication, hideModal, handleSetApplicantHired}){
    const [isLoading, setIsLoading] = useState(true);

    const [wizardIndex, setWizardIndex] = useState(0);
    const [selectedDocuments, setSelectedDocuments] = useState(Object.keys(OnboardingDocuments));
    const [selectedTaxDocument, setSelectedTaxDocument] = useState(undefined);
    const [selectedDirectDepositDocument, setSelectedDirectDepositDocument] = useState(undefined);
    const [includeEContacts, setincludeEContacts] = useState(false);
    const [includeOfferLetter, setIncludeOfferLetter] = useState(true);
    const [selectedOfferLetter, setSelectedOfferLetter] = useState('');
    const [employerSigner, setEmployerSigner] = useState(undefined);

    const [client, setClient] = useState(undefined);
    const [jobPost, setJobPost] = useState(undefined);
    const [recruiterOfferLetter, setRecruiterOfferLetter] = useState('');
    const [clientOfferLetters, setClientOfferLetters] = useState([]);
    const [clientUsers, setClientUsers] = useState([]);
    const [clientSettings, setClientSettings] = useState({});
    const [csas, setCsas] = useState([]);
    const [tcSignerCandidates, setTcSignerCandidates] = useState([]);

    const [hireData, _] = useState(HireData.initDefault());

    useEffect(()=>{
        loadData();
    }, [])

    async function loadData(){
        setIsLoading(true)
        const response = await getOfferLetterData(jobApplication.uid);
        if (response.status === 200) {
            setClient(response.client);
            setRecruiterOfferLetter(response.recruiterOfferLetter);
            setClientUsers(response.clientUsers);
            setJobPost(response.jobPost);
            hireData.setState('payRate', response.jobPost.compensationMin);
            hireData.setState('terminalAddress', Address.decode(response.jobPost.terminal.address));
            hireData.setState('bwcCode', '7231');
            setSelectedTaxDocument(stateTaxDocumens[response.jobPost.terminal.address.administrativeArea]);
            if (response.clientUsers.length === 1) {
                hireData.setState('ao', response.clientUsers[0].uid);
            }
            if (response.client.tcIdentifier) {
                setincludeEContacts(true);
                setCsas(response.csas);
                setClientOfferLetters(response.clientOfferLetters);
                setClientSettings(response.clientSettings)
                setTcSignerCandidates(response.tcSignerCandidates);
            } else {
                setSelectedOfferLetter(response.recruiterOfferLetter);
            }
        } else {
            AppGlobals.alert('danger', response.message);
        }
        setIsLoading(false)
    }

    function handleSetOfferLetter(){
        if(client.tcIdentifier){
            if(clientSettings[defaultOfferLetterTitles[hireData.title]])
                setSelectedOfferLetter(clientOfferLetters.find(ol=>ol.uid == clientSettings[defaultOfferLetterTitles[hireData.title]]).content)
            else{
                setSelectedOfferLetter('');
            }   
        }
    }
    
    const wizardSteps = includeOfferLetter ? ['Step 1', 'Step 2', 'Step 3', 'Step 4'] : client?.tcIdentifier || selectedDocuments.includes('w4') ? ['Step 1', 'Step 2', 'Step 3'] : ['Step 1', 'Step 2'];

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{`Hiring ${jobApplication.firstName} ${jobApplication.lastName}`}</Modal.Title>
            </Modal.Header>
            <Modal.Header>
                <LoadingWrapper isLoading={isLoading} color="var(--bs-primary)">
                    <div style={{width: '100%'}}>
                        <Wizard stepIndex={wizardIndex} steps={wizardSteps}/>
                    </div>
                </LoadingWrapper>
            </Modal.Header>
            { !isLoading && 
                <>
                    { wizardIndex == 0 ? 
                        <Page1 
                            jobApplication={jobApplication}
                            client={client}
                            clientUsers={clientUsers}
                            tcSignerCandidates={tcSignerCandidates}
                            hireData={hireData}
                            selectedDocuments={selectedDocuments} 
                            setSelectedDocuments={setSelectedDocuments} 
                            selectedTaxDocument={selectedTaxDocument}
                            selectedDirectDepositDocument={selectedDirectDepositDocument}
                            setSelectedDirectDepositDocument={setSelectedDirectDepositDocument}
                            setSelectedTaxDocument={setSelectedTaxDocument}
                            includeEContacts={includeEContacts}
                            setincludeEContacts={setincludeEContacts} 
                            includeOfferLetter={includeOfferLetter}
                            setIncludeOfferLetter={setIncludeOfferLetter} 
                            employerSigner={employerSigner}
                            setEmployerSigner={setEmployerSigner}
                            next={() => {setWizardIndex(wizardIndex + 1)}}
                        />
                    : wizardIndex == 1 && (includeOfferLetter || client?.tcIdentifier || selectedDocuments.includes('w4')) ? 
                        <Page2 
                            jobPost={jobPost} 
                            hireData={hireData} 
                            client={client} 
                            clientUsers={clientUsers} 
                            includeOfferLetter={includeOfferLetter} 
                            csas={csas}
                            handleSetOfferLetter={handleSetOfferLetter}
                            back={() => {setWizardIndex(wizardIndex - 1)}} 
                            next={() => {setWizardIndex(wizardIndex + 1)}}
                        />
                    : wizardIndex == 2 && includeOfferLetter ? 
                        <Page3 
                            hireData={hireData} 
                            jobPost={jobPost}
                            jobApplication={jobApplication}
                            client={client}
                            clientUsers={clientUsers}
                            selectedOfferLetter={selectedOfferLetter} 
                            setSelectedOfferLetter={setSelectedOfferLetter} 
                            recruiterOfferLetter={recruiterOfferLetter} 
                            clientOfferLetters={clientOfferLetters} 
                            csas={csas}
                            back={() => {setWizardIndex(wizardIndex - 1)}} next={() => {setWizardIndex(wizardIndex + 1)}}
                        />
                    : 
                        <Page4 
                            jobApplication={jobApplication} 
                            jobPost={jobPost}
                            hireData={hireData} 
                            client={client}
                            clientUsers={clientUsers}
                            csas={csas}
                            includeOfferLetter={includeOfferLetter}
                            selectedDocuments={selectedDocuments}
                            selectedTaxDocument={selectedTaxDocument}
                            selectedDirectDepositDocument={selectedDirectDepositDocument}
                            includeEContacts={includeEContacts}
                            selectedOfferLetter={selectedOfferLetter}
                            employerSigner={employerSigner}
                            back={() => {setWizardIndex(wizardIndex - 1)}} 
                            hideModal={hideModal}
                            handleSetApplicantHired={handleSetApplicantHired}
                        />
                    }
                </>
            }
        </>
    )
}


function Page1({jobApplication, client, clientUsers, tcSignerCandidates, hireData, selectedDocuments, setSelectedDocuments, selectedDirectDepositDocument, setSelectedDirectDepositDocument, selectedTaxDocument, setSelectedTaxDocument, includeEContacts, setincludeEContacts, includeOfferLetter, setIncludeOfferLetter, employerSigner, setEmployerSigner, next}) { //documents to include

    useEffect(() => {
        hireData.removeAllValidation();
    }, []);

    return (
        <>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <h5 style={{margin: 0, textAlign: 'center'}}>Onboarding Documents to Include</h5>
                <CustomCheck inline title='Documents' selection={selectedDocuments} setSelection={setSelectedDocuments} items={OnboardingDocuments}/>
                <SwitchControl title={'Include Direct Deposit Authorization'} value={selectedDirectDepositDocument !== undefined} setValue={(value) => {setSelectedDirectDepositDocument(value ? 'dd' : undefined)}}/>
                { selectedDirectDepositDocument && 
                    <CustomRadio title={'Direct Deposit Form'} selection={selectedDirectDepositDocument} setSelection={setSelectedDirectDepositDocument} items={DirectDepositDocuments}/>                
                }

                <SwitchControl title={'Include State Tax Documents'} value={selectedTaxDocument !== undefined} setValue={(value) => {setSelectedTaxDocument(value ? stateTaxDocumens[jobApplication.address.administrativeArea] ?? 'it4' : undefined)}}/>
                { selectedTaxDocument && 
                    <CustomRadio title={'Tax Witholding Exemption Certificate'} selection={selectedTaxDocument} setSelection={setSelectedTaxDocument} items={taxDocumentDictionary}/>
                }
                { client.tcIdentifier && 
                    <SwitchControl title={'Request Emergency Contacts'} value={includeEContacts} setValue={setincludeEContacts}/>
                }
                <SwitchControl title={'Include Offer Letter'} value={includeOfferLetter} setValue={setIncludeOfferLetter}/>
                { (selectedTaxDocument || selectedDocuments.length > 0) && 
                    <>
                        { client.tcIdentifier ? 
                            <CustomRadio forceValidation validation={Validation.nonEmptyString} title={'Signer on behalf of Employer'} selection={employerSigner} setSelection={setEmployerSigner} items={arraysToObject(tcSignerCandidates.map(c => c.email), tcSignerCandidates.map(c => `${c.firstName} ${c.lastName} (${c.title})`))}/>
                        :
                            <CustomRadio validation={Validation.nonEmptyString} title={'Signer on behalf of Employer'} selection={employerSigner} setSelection={setEmployerSigner} items={arraysToObject(clientUsers.map(c => c.email), clientUsers.map(c => `${c.firstName} ${c.lastName}`))}/>
                        }
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={!employerSigner && (selectedTaxDocument || selectedDocuments.length > 0)} onClick={next}>Next <FontAwesomeIcon icon={faChevronRight}/></Button>
            </Modal.Footer>
        </>
    )
}

function Page2({hireData, jobPost, client, clientUsers, includeOfferLetter, csas, back, next, handleSetOfferLetter}) { //information to gather
    const breakpoints = {lg: 6, xl: 4};

    return (
        <>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                    <h5 style={{margin: 0, textAlign: 'center'}}>Employment Information</h5>
                    <Container fluid>
                        <Row>
                            <CustomControl floatingLabel max={'9999-12-31'} type='date' title='Employment Start Date' stateProps={new ControlStateProps('startDate', hireData, Validation.date)} breakpoints={breakpoints}/>
                            { includeOfferLetter && 
                                <>
                                    <CustomControl floatingLabel title='Name of Immediate Supervisor' stateProps={new ControlStateProps('supervisorName', hireData, Validation.nonEmptyString)} breakpoints={breakpoints}/>
                                    <CustomControl floatingLabel type='time' title='Start Time on First Day' stateProps={new ControlStateProps('startTime', hireData, Validation.nonEmptyString)} breakpoints={breakpoints}/>
                                    <CustomControl floatingLabel type='time' title='Daily Start Time' stateProps={new ControlStateProps('dailyStartTime', hireData, Validation.nonEmptyString)} breakpoints={breakpoints}/>
                                    <CustomRadio title={'AO to Appear on Offer Letter'} stateProps={new ControlStateProps('ao', hireData, Validation.nonEmptyString)} items={arraysToObject(clientUsers.map(c => c.uid), clientUsers.map(c => `${c.firstName} ${c.lastName}`))} breakpoints={breakpoints}/>
                                </>
                            }
                            { (includeOfferLetter || client.tcIdentifier) &&
                                <>
                                    <CustomControl floatingLabel type='number' title={`Pay Rate (${PayTypes[jobPost.payType]})`} stateProps={new ControlStateProps('payRate', hireData, Validation.greaterThanZero)} breakpoints={breakpoints}/>
                                    <AvailabilityControl title='Expected Availability' stateProps={new ControlStateProps('availability', hireData, Validation.availability)} breakpoints={{lg: 12, xl: 8}}/>
                                    { !client.tcIdentifier && 
                                        <AddressControl title='Employment Address' address={hireData.terminalAddress} breakpoints={{xl: 4, lg: 6}} containerBreakpoints={{xxl: 12}}/>
                                    }
                                </>
                            }
                            { client.tcIdentifier && 
                                <>
                                    <CustomRadio title={'Assigned CSA'} stateProps={new ControlStateProps('csaIdentifier', hireData, Validation.notUndefined)} items={arraysToObject(csas.map(c => c.uid), csas.map(c => `${c.csaName} | ${addressToString(c.address)}`))} breakpoints={{xxl: 12}}/>
                                    <CustomRadio title='Title' setSelection={handleSetOfferLetter} stateProps={new ControlStateProps('title', hireData, Validation.nonEmptyString)} items={TCTitles} breakpoints={{lg: 6}}/>  
                                    <CustomControl floatingLabel type='number' title='Hourly Rate' stateProps={new ControlStateProps('hourlyWage', hireData, Validation.greaterThanZero)} breakpoints={{lg: 6}}/>
                                    <BwcCodeControl stateProps={new ControlStateProps('bwcCode', hireData, Validation.nonEmptyString)} breakpoints={{lg: 12}}/>
                                    
                                    <FormGroup title='Over-Threshold Stop Wages' breakpoints={{xxl: 12}}>
                                        <CustomControl floatingLabel type='number' title='Stop Bonus Weekday Threshold' stateProps={new ControlStateProps('stopBonusWeekdayThreshold', hireData)} breakpoints={{lg: 6}}/>
                                        <CustomControl floatingLabel type='number' title='Stop Bonus Weekday Amount' stateProps={new ControlStateProps('stopBonusAmount', hireData)} breakpoints={{lg: 6}}/>
                                        <CustomControl floatingLabel type='number' title='Stop Bonus Weekend Threshold' stateProps={new ControlStateProps('stopBonusWeekendThreshold', hireData)} breakpoints={{lg: 6}}/>
                                        <CustomControl floatingLabel type='number' title='Stop Bonus Weekend Amount' stateProps={new ControlStateProps('stopBonusWeekendAmount', hireData)} breakpoints={{lg: 6}}/>
                                    </FormGroup>
                                    { jobPost.payType !== 'py' &&
                                        <FormGroup title='PTO' breakpoints={{xxl: 12}}>
                                            <CustomRadio title='PTO Accrual Type' stateProps={new ControlStateProps('ptoAccrualType', hireData, Validation.nonEmptyString)} items={PtoAccrualTypes} breakpoints={{lg: 6}}/>
                                            <CustomControl floatingLabel type='number' title='PTO Hours Accrued' stateProps={new ControlStateProps('ptoAccrual', hireData, Validation.nonEmptyString)} breakpoints={{lg: 6}}/>
                                        </FormGroup>
                                    }
                                    <FormGroup title='Deductions' breakpoints={{xxl: 12}}>
                                        <CustomControl floatingLabel type='number' title='Medical Insurance' stateProps={new ControlStateProps('medical', hireData)} breakpoints={breakpoints}/>
                                        <CustomControl floatingLabel type='number' title='Dental Insurance' stateProps={new ControlStateProps('dental', hireData)} breakpoints={breakpoints}/>
                                        <CustomControl floatingLabel type='number' title='Vision Insurance' stateProps={new ControlStateProps('vision', hireData)} breakpoints={breakpoints}/>
                                        <ChildSupportControl stateProps={new ControlStateProps('childSupport', hireData)} breakpoints={{lg: 6}}/>
                                        <AutomaticDeductionControl stateProps={new ControlStateProps('automaticDeductions', hireData)} breakpoints={{lg: 6}}/>
                                    </FormGroup>
                                    <AutomaticReimbursementControl stateProps={new ControlStateProps('automaticReimbursements', hireData)} breakpoints={{xxl: 12}}/>

                                </>

                            }
                        </Row>
                    </Container>

                </div>

            </Modal.Body>
            <Modal.Footer style={{display: 'flex', justifyContent: 'space-between'}}>
                <Button onClick={back}><FontAwesomeIcon icon={faChevronLeft}/> Back</Button>
                <CustomButton stateObject={hireData} onClick={next}>Next<FontAwesomeIcon icon={faChevronRight}/></CustomButton>
            </Modal.Footer>
        </>
    )
}

function Page3({hireData, jobPost, jobApplication, client, clientUsers, selectedOfferLetter, setSelectedOfferLetter, recruiterOfferLetter, clientOfferLetters, csas, back, next}) { //offer letter

    const ao = clientUsers.find(c => c.uid == hireData.ao);
    const csa = csas.find(c => c.uid == hireData.csaIdentifier);

    return (
        <>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                    <h5 style={{margin: 0, textAlign: 'center'}}>Review Offer Letter</h5>
                    <div style={{display: 'flex', justifyContent: 'flex-end', gap: 12, flexWrap: 'wrap'}}>
                        <Dropdown>
                                <Dropdown.Toggle>Import Offer Letter</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item  onClick={() => {setSelectedOfferLetter(recruiterOfferLetter)}}>Defined by Recruiter</Dropdown.Item>
                                    {clientOfferLetters.length > 0 && 
                                    <>
                                        <Dropdown.Divider/>
                                        <Dropdown.Header>Defined by Client</Dropdown.Header>
                                        {clientOfferLetters.map(col=>{
                                            return <Dropdown.Item key={col.uid} onClick={()=>setSelectedOfferLetter(col.content)}>{col.name}</Dropdown.Item>
                                        })}
                                    </>}
                                </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <OfferLetterEditor value={selectedOfferLetter} setValue={setSelectedOfferLetter} offerLetterData={{
                        companyName: client.name,
                        employeeName: `${jobApplication.firstName} ${jobApplication.lastName}`,
                        employeeType: EmploymentTypes[jobPost.employmentType],
                        startDate: moment(hireData.startDate).format('MMM D, YYYY'),
                        supervisorName: hireData.supervisorName,
                        terminalAddress: csa ? addressToString(csa.address) : addressToString(hireData.terminalAddress),
                        startTime: formatTime(hireData.startTime),
                        pay: `${usdFormatter.format(hireData.payRate)} ${PayTypes[jobPost.payType]}`,
                        stopBonusWeekdayThreshold: hireData.stopBonusWeekdayThreshold,
                        stopBonusWeekdayAmount: hireData.stopBonusAmount,
                        stopBonusWeekendThreshold: hireData.stopBonusWeekendThreshold,
                        stopBonusWeekendAmount: hireData.stopBonusWeekendAmount,
                        expectedAvailability: availabilityToString(hireData.availability),
                        dailyStartTime: formatTime(hireData.dailyStartTime),
                        benefits: jobPost.benefits,
                        aoName: `${ao.firstName} ${ao.lastName}`,
                        aoPhoneNumber: formatPhoneNumber(ao.phoneNumber),
                        aoEmail: ao.email
                    }}/>
                    
                </div>
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', justifyContent: 'space-between'}}>
                <Button onClick={back}><FontAwesomeIcon icon={faChevronLeft}/> Back</Button>
                <Button onClick={next}>Next <FontAwesomeIcon icon={faChevronRight}/></Button>
            </Modal.Footer>
        </>
    )
}

function Page4({jobApplication, jobPost, hireData, client, clientUsers, csas, includeOfferLetter, selectedDocuments, selectedOfferLetter, selectedTaxDocument, selectedDirectDepositDocument, includeEContacts, employerSigner, back, hideModal, handleSetApplicantHired}) { //review
    const [isSubmitting, setIsSubmitting] = useState(false);
    const ao = clientUsers.find(c => c.uid == hireData.ao);
    const csa = csas.find(c => c.uid == hireData.csaIdentifier);

    const handleSubmit = async () => {
        setIsSubmitting(true);

        const documents = {};
        selectedDocuments.forEach((type) => {
            const document = {title: `${jobApplication.firstName} ${jobApplication.lastName} ${OnboardingDocumentsShort[type]}`};
            if (type == 'w4') {
                document.startDate = hireData.startDate;
            }
            documents[type] = document;
        })
        if (selectedTaxDocument) {
            documents[selectedTaxDocument] = {title: `${jobApplication.firstName} ${jobApplication.lastName} ${TaxDocumentsShort[selectedTaxDocument]}`};
        }

        if (selectedDirectDepositDocument) {
            documents[selectedDirectDepositDocument] = {title: `${jobApplication.firstName} ${jobApplication.lastName} ${DirectDepositDocuments[selectedTaxDocument]}`};
        }

        if (includeOfferLetter) {
            // const ao = signers.find(s => s.uid == selectedAo);
            documents.offerLetter = {
                title: `${jobApplication.firstName} ${jobApplication.lastName} Offer Letter`,
                content: selectedOfferLetter,
                companyName: client.name,
                employeeType: jobPost.employmentType == 'fulltime' ? 0 : 1,
                startDate: hireData.startDate,
                supervisorName: hireData.supervisorName,
                terminalAddress: csa ? csa.address : hireData.terminalAddress,
                startTime: hireData.startTime,
                payType: jobPost.payType,
                payRate: hireData.payRate,
                stopBonusWeekdayThreshold: validateInteger(hireData.stopBonusWeekdayThreshold),
                stopBonusWeekendThreshold: validateInteger(hireData.stopBonusWeekendThreshold),
                stopBonusWeekdayAmount: validateDecimalFixed(hireData.stopBonusAmount, 2),
                stopBonusWeekendAmount: validateDecimalFixed(hireData.stopBonusWeekendAmount, 2),
                expectedAvailability: availabilityToBinaryString(hireData.availability),
                dailyStartTime: hireData.dailyStartTime,
                benefits: jobPost.benefits,
                aoName: `${ao.firstName} ${ao.lastName}`,
                aoPhoneNumber: ao.phoneNumber,
                aoEmail: ao.email
            };
        }

        const response = await hireApplicant(jobApplication.uid, hireData.encode(), documents, includeEContacts, employerSigner);
        if (response.status === 200) {
            hideModal();
            handleSetApplicantHired();
            AppGlobals.alert('success', response.message);
        } else {
            AppGlobals.alert('danger', response.message);
        }
        setIsSubmitting(false);
    }

    

    const csElements = hireData.childSupport.map((cs, index) => {
        return (
            <KeyValueRow key={index} isListItem title={`Child Support #${index + 1}`} value={usdFormatter.format(cs)}/>
        )
    })

    const adElements = hireData.automaticDeductions.map((ad, index) => {
        return (
            <KeyValueRow key={index} isListItem title={ad.label} value={usdFormatter.format(ad.amount)}/>
        )
    })

    const arElements = hireData.automaticReimbursements.map((ar, index) => {
        return (
            <KeyValueRow key={index} isListItem title={ar.label} value={usdFormatter.format(ar.amount)}/>
        )
    })

    
    return (
        <>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <ListGroup>
                    <KeyValueRow isListItem title='Included Documents' value={selectedDocuments.length == 0 ? 'None' : selectedDocuments.map(d => OnboardingDocuments[d]).join(', ')}/>
                    <KeyValueRow isListItem title='Tax Witholding Exemption Certificate' value={taxDocumentDictionary[selectedTaxDocument] ?? 'None'}/>
                    <KeyValueRow isListItem title='Request Emergency Contacts?' value={includeEContacts ? 'Yes' : 'No'}/>
                </ListGroup>
                { (includeOfferLetter || client.tcIdentifier) && 
                    <ListGroup>

                        { includeOfferLetter && 
                            <>
                                <KeyValueRow isListItem title='Name of Immediate Supervisor' value={hireData.supervisorName}/>
                                <KeyValueRow isListItem title='Start Time on First Day' value={formatTime(hireData.startTime)}/>
                                <KeyValueRow isListItem title='Daily Start Time' value={formatTime(hireData.dailyStartTime)}/>
                                <KeyValueRow isListItem title='AO to Appear on Offer Letter' value={`${ao.firstName} ${ao.lastName}`}/>
                            </>
                        }
                        <KeyValueRow isListItem title='Pay Rate' value={`${usdFormatter.format(hireData.payRate)} ${PayTypes[jobPost.payType]}`}/>
                        <KeyValueRow isListItem title='Availability' value={availabilityToString(hireData.availability)}/>
                        <KeyValueRow isListItem title='Employment Address' value={addressToString(hireData.terminalAddress)}/>
                        { client.tcIdentifier && 
                            <>
                                <KeyValueRow isListItem title='Assigned CSA' value={`${csa.csaName} | ${addressToString(csa.address)}`}/>
                                <KeyValueRow isListItem title='Title' value={hireData.title}/>
                                <KeyValueRow isListItem title='Hourly Rate' value={usdFormatter.format(hireData.hourlyWage)}/>
                                <KeyValueRow isListItem title='Stop Bonus Weekday Threshold' value={hireData.stopBonusWeekdayThreshold}/>
                                <KeyValueRow isListItem title='Stop Bonus Weekday Amount' value={hireData.stopBonusAmount}/>
                                <KeyValueRow isListItem title='Stop Bonus Weekend Threshold' value={hireData.stopBonusWeekendThreshold}/>
                                <KeyValueRow isListItem title='Stop Bonus Weekend Amount' value={hireData.stopBonusWeekendAmount}/>
                                { jobPost.payType !== 'py' &&
                                    <KeyValueRow isListItem title='PTO Accrual' value={`${hireData.ptoAccrual} PTO Hours Accrued ${PtoAccrualTypes[hireData.ptoAccrualType]}`}/>
                                }
                                <KeyValueRow isListItem title='Medical Insurance' value={hireData.medical}/>
                                <KeyValueRow isListItem title='Dental Insurance' value={hireData.dental}/>
                                <KeyValueRow isListItem title='Vision Insurance' value={hireData.vision}/>
                                {csElements}
                                {adElements}
                                {arElements}
                            </>
                        }
                    </ListGroup>
                }
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', justifyContent: 'space-between'}}>
                <Button onClick={back}><FontAwesomeIcon icon={faChevronLeft}/> Back</Button>
                <CustomButton variant={'success'} isLoading={isSubmitting} onClick={handleSubmit}>
                    {`Hire ${jobApplication.firstName} ${jobApplication.lastName}`}
                </CustomButton>
            </Modal.Footer>
        </>
    )
}