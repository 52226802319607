/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import JobPostTerminal from "../views/JobCreator/models/JobPostTerminal";
import JobPost from "../views/JobPosts/models/JobPost";
import { getResponse, getToken } from "./serviceTools";

export async function getJobPost(uid: number) {
    const data = {
        type: 'jobPost',
        target: 'get',
        token: getToken(),
        uid
    }
    return await getResponse(data);
}

export async function getJobPostForEdit(uid: number) {
    const data = {
        type: 'jobPost',
        target: 'getEdit',
        token: getToken(),
        uid
    }
    return await getResponse(data);
}

export async function getJobPostDetailed(uid: number) {
    const data = {
        type: 'jobPost',
        target: 'getDetailed',
        token: getToken(),
        uid
    }
    return await getResponse(data);
}

export async function getJobPosts() {
    const data = {
        type: 'jobPost',
        target: 'getAll',
        token: getToken()
    }

    return await getResponse(data);
}

export async function getJobPostTerminals() {
    const data = {
        type: 'jobPost',
        target: 'getTerminals',
        token: getToken()
    }

    return await getResponse(data);
}

export async function createJobPost(jobPost: JobPost) {
    const data = {
        type: 'jobPost',
        target: 'create',
        token: getToken(),
        jobPost
    }

    return await getResponse(data);
}

export async function updateJobPost(jobPost: JobPost) {
    const data = {
        type: 'jobPost',
        target: 'update',
        token: getToken(),
        jobPost
    }

    return await getResponse(data);
}

export async function updateJobPostTerminals(terminals: JobPostTerminal[], jobPostIdentifier: number){
    const data = {
        type: 'jobPost',
        target: 'updateJobPostTerminals',
        token: getToken(),
        terminals,
        jobPostIdentifier
    }

    return await getResponse(data);
}

export async function activateJobPost(uid: number, cloneJobPost: boolean, jobSites: string) {
    const data = {
        type: 'jobPost',
        target: 'activate',
        token: getToken(),
        uid,
        cloneJobPost,
        jobSites
    }

    return await getResponse(data);
}

export async function deactivateJobPost(uid: number) {
    const data = {
        type: 'jobPost',
        target: 'deactivate',
        token: getToken(),
        uid,
    }

    return await getResponse(data);
}

export async function deleteJobPost(uid: number) {
    const data = {
        type: 'jobPost',
        target: 'delete',
        token: getToken(),
        uid
    }
    return await getResponse(data);
}