/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { PropsWithChildren } from 'react';
import { Spinner } from 'react-bootstrap';

type LoadingWrapperProps = {
    isLoading: boolean;
    color?: string | undefined;
    flex?: boolean
}

export default function LoadingWrapper({isLoading, children, color = 'white', flex = false}: PropsWithChildren<LoadingWrapperProps>) {
    
    if (isLoading) {
        return (
            <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 12}}>
                <Spinner style={{color: color}}/>
            </div>
        )
    } else {
        return (
            <>
                {children}
            </>
        );
    }
}