/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import LoadingWrapper from '../../components/LoadingWrapper';
import { getAllUsers, shadow } from '../../services/AdminService';
import { AppGlobals } from '../../App';
import SortableTable, { SortableTableCol } from '../../components/SortableTable';
import { Modal } from 'react-bootstrap';
import SearchBar from '../../components/SearchBar';
import { filterArrayByObjectProperty } from '../../tools';

export default function ShadowUser() {
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [searchType, setSearchType] = useState('firstName');

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        const response = await getAllUsers();
        if (response.status === 200) {
            setUsers(response.users);
        } else {
            AppGlobals.alert('danger', response.message);
        }
        setIsLoading(false);
    }

    const handleShadowUser = async (userIdentifier) => {
        setIsLoading(true);
        const response = await shadow(userIdentifier, null);
        if (response.status === 200) {
            window.location.reload();
        } else {
            AppGlobals.alert('danger', response.message);
        }
        setIsLoading(false);
    }

    const columns = [
        new SortableTableCol('firstName', 'First Name', null, SortableTableCol.sortAsc),
        new SortableTableCol('lastName', 'Last Name', null, SortableTableCol.sortAsc),
        new SortableTableCol('companyName', 'Company Name', null, SortableTableCol.sortAsc),
    ]


    const userElements = filterArrayByObjectProperty(users, searchType, search).map((user) => {
        return {
            values: {firstName: user.firstName, lastName: user.lastName, companyName: user.companyName},
            content: (
                <tr className='cursor-pointer' onClick={() => {handleShadowUser(user.uid)}} key={user.uid}>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>{user.companyName}</td>
                </tr>
            )
        }
    })


    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Shadow Recruiter</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <LoadingWrapper isLoading={isLoading} color='var(--bs-primary)'>
                    <SearchBar value={search} setValue={setSearch} typeValues={['firstName', 'lastName', 'companyName']} typeNames={['First Name', 'Last Name', 'Company Name']} type={searchType} setType={setSearchType}/>
                    <SortableTable rows={userElements} columns={columns}/>
                </LoadingWrapper>
            </Modal.Body>
        </>
    )
}