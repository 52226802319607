/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import ShadowClientUser from './ShadowClientUser';
import ShadowUser from './ShadowUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBinoculars } from '@fortawesome/free-solid-svg-icons';

export default function Admin() {
    const [modalSwitch, setModalSwitch] = useState('');

    return (
        <div style={{padding: 12, height: '100%', display: 'flex', flexDirection: 'column', gap: 12}}>
            <Card>
                <Card.Body style={{textAlign: 'center'}}>
                    <h3 style={{color: 'var(--bs-primary)', fontWeight: 'bold'}}>My Tactical Recruiting</h3>
                    <h4 style={{margin: 0, opacity: 0.65}}>~ Admin Dashboard ~</h4>
                </Card.Body>
            </Card>
            <Container fluid style={{padding: 0}}>
                <Row>
                    <Col lg={6} style={{marginBottom: 12}}>
                        <Card>
                            <Card.Header style={{display: 'flex', alignItems: 'center', gap: 12}}>
                                <FontAwesomeIcon icon={faBinoculars} size='2x'/>
                                <h4 style={{margin: 0}}>Shadow User</h4>
                            </Card.Header>
                            <Card.Body>
                                <span style={{opacity: 0.65}}>Login as a <b>Recruiter</b> or <b>Client</b> to view the website from their perspective. Stop shadowing at any time by pressing the <b>Stop Shadowing</b> button in the dropdown menu on the navbar</span>
                            </Card.Body>
                            <Card.Footer style={{display: 'flex', justifyContent: 'flex-end', gap: 12}}>
                                <Button onClick={() => {setModalSwitch('shadowUser')}}>Shadow Recruiter</Button>
                                <Button onClick={() => {setModalSwitch('shadowClientUser')}}>Shadow Client</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal show={modalSwitch == 'shadowUser'} onHide={() => {setModalSwitch('')}} centered size='xl'>
                <ShadowUser/>
            </Modal>
            <Modal show={modalSwitch == 'shadowClientUser'} onHide={() => {setModalSwitch('')}} centered size='xl'>
                <ShadowClientUser/>
            </Modal>
        </div>
    )
}