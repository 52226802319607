/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Address from "../../models/Address";
import StateObject from "../../models/State/StateObject";
import { encodeAvailability, validateDecimal, validateInteger } from "../../tools";

export class HireData extends StateObject {
    startDate;
    ao;
    supervisorName;
    startTime;
    dailyStartTime;
    payRate;
    availability;
    terminalAddress;
    csaIdentifier;
    title;
    hourlyWage;
    bwcCode;
    childSupport;
    medical;
    dental;
    vision;
    ptoAccrual;
    ptoAccrualType;
    stopBonusWeekdayThreshold;
    stopBonusAmount;
    stopBonusWeekendThreshold;
    stopBonusWeekendAmount;
    automaticDeductions;
    automaticReimbursements;

    constructor(startDate, ao, supervisorName, startTime, dailyStartTime, payRate, availability, terminalAddress, csaIdentifier, title, hourlyWage, bwcCode, childSupport, medical, dental, vision, ptoAccrual, ptoAccrualType, stopBonusWeekdayThreshold, stopBonusAmount, stopBonusWeekendThreshold, stopBonusWeekendAmount, automaticDeductions, automaticReimbursements) {
        super();
        this.startDate = startDate;
        this.ao = ao;
        this.supervisorName = supervisorName;
        this.startTime = startTime;
        this.dailyStartTime = dailyStartTime;
        this.payRate = payRate;
        this.availability = availability;
        this.terminalAddress = terminalAddress;
        this.csaIdentifier = csaIdentifier;
        this.title = title;
        this.hourlyWage = hourlyWage;
        this.bwcCode = bwcCode;
        this.childSupport = childSupport;
        this.medical = medical;
        this.dental = dental;
        this.vision = vision;
        this.ptoAccrual = ptoAccrual;
        this.ptoAccrualType = ptoAccrualType;
        this.stopBonusWeekdayThreshold = stopBonusWeekdayThreshold;
        this.stopBonusAmount = stopBonusAmount;
        this.stopBonusWeekendThreshold = stopBonusWeekendThreshold;
        this.stopBonusWeekendAmount = stopBonusWeekendAmount;
        this.automaticDeductions = automaticDeductions;
        this.automaticReimbursements = automaticReimbursements;
    }

    static initDefault() {
        const hireData = new HireData(
            '',
            undefined,
            '',
            '08:00',
            '08:00',
            '',
            [true, true, true, true, true, true, true],
            Address.initDefault(),
            undefined,
            '',
            '',
            '',
            [],
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            [],
            []
        )
        hireData.initChildren();
        return hireData;
    }

    encode() {
        return {
            startDate: this.startDate,
            dailyStartTime: this.dailyStartTime,
            payRate: validateDecimal(this.payRate),
            availability: encodeAvailability(this.availability),
            terminalAddress: this.terminalAddress.encode(),
            csaIdentifier: this.csaIdentifier,
            title: this.title,
            hourlyWage: validateDecimal(this.hourlyWage),
            bwcCode: this.bwcCode,
            childSupport: JSON.stringify(this.childSupport),
            medical: validateDecimal(this.medical),
            dental: validateDecimal(this.dental),
            vision: validateDecimal(this.vision),
            ptoAccrual: validateDecimal(this.ptoAccrual),
            ptoAccrualType: this.ptoAccrualType,
            stopBonusWeekdayThreshold: validateInteger(this.stopBonusWeekdayThreshold),
            stopBonusAmount: validateDecimal(this.stopBonusAmount),
            stopBonusWeekendThreshold: validateInteger(this.stopBonusWeekendThreshold),
            stopBonusWeekendAmount: validateDecimal(this.stopBonusWeekendAmount),
            automaticDeductions: JSON.stringify(this.automaticDeductions),
            automaticReimbursements: JSON.stringify(this.automaticReimbursements),
        }
    }
}