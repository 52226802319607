/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Validation } from "../../validation";
import { UidGenerator } from "../../tools";
import StateObject from "../State/StateObject";
import Decoder from "../../decoding";
import moment from "moment";

export default class MilitaryService extends StateObject {
    uid; //: number;
    branch; //: string;
    startDate; //: string;
    endDate; //: string;
    rankAtDischarge; //: string;
    dischargeType; //: string;
    dishonorableDischargeExplanation; //: string | undefined;

    constructor(
        branch,
        startDate,
        endDate,
        rankAtDischarge,
        dischargeType,
        dishonorableDischargeExplanation,
        key,
        parent
    ) {
        super(key, parent);
        this.uid = UidGenerator.getUid();
        this.branch = branch;
        this.startDate = startDate;
        this.endDate = endDate;
        this.rankAtDischarge = rankAtDischarge;
        this.dischargeType = dischargeType;
        this.dishonorableDischargeExplanation = dishonorableDischargeExplanation;
    }

    static initDefault(key = undefined, parent = undefined) {
        return new MilitaryService(
            '',
            '',
            '',
            '',
            '',
            '',
            key,
            parent
        );
    }

    static decode(json, optional) {
        const decoder = new Decoder(json, optional);
        const branch = decoder.decode('branch', Decoder.StringStrict);
        const startDate = decoder.decode('startDate', Decoder.Date);
        const endDate = decoder.decode('endDate', Decoder.Date, {defaultValue: null, warn: false});
        const rankAtDischarge = decoder.decode('rankAtDischarge', Decoder.StringStrict);
        const dischargeType = decoder.decode('dischargeType', Decoder.StringStrict);
        const dishonorableDischargeExplanation = decoder.decode('dishonorableDischargeExplanation', Decoder.StringStrict, {defaultValue: undefined, warn: false});
        
        if (decoder.checkForErrors()) {
            return new MilitaryService(branch, startDate, endDate, rankAtDischarge, dischargeType, dishonorableDischargeExplanation);
        } else if (optional) {
            return undefined;
        } else {
            return MilitaryService.initDefault();
        }
    }

    encode() {
        return {
            branch: this.branch,
            startDate: this.startDate,
            endDate: this.endDate ? this.endDate : null,
            rankAtDischarge: this.rankAtDischarge,
            dischargeType: this.dischargeType,
            dishonorableDischargeExplanation: this.dishonorableDischargeExplanation ? this.dishonorableDischargeExplanation : null
        }
    }


}

