/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "./serviceTools";

export async function getAllRecruiters() {
    const data = {
        type: 'clientUser',
        target: 'getAllRecruiters',
        token: getToken()
    }
    return await getResponse(data);
}

export async function getAllApplications() {
    const data = {
        type: 'clientUser',
        target: 'getAllApplications',
        token: getToken()
    }
    return await getResponse(data);
}

export async function getApplication(uid: number) {
    const data = {
        type: 'clientUser',
        target: 'getApplication',
        token: getToken(),
        uid: uid
    }
    return await getResponse(data);
}

export async function changeClientUserPassword(clientUserIdentifier: number, currentPassword: string, newPassword: string){
    const data = {
        type: 'clientUser',
        target: 'changePassword',
        token: getToken(),
        clientUserIdentifier,
        currentPassword,
        newPassword
    }

    return await getResponse(data);
}