/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../decoding";


export default class Signature {
    strokes: Array<SignatureStroke>
    image: string | undefined

    constructor(strokes: Array<SignatureStroke>, image: string | undefined) {
        this.strokes = strokes;
        this.image = image;
    }

    static initFromCanvas(pointGroupArray: Array<Array<any>>, image: string) {
        return new Signature(
            pointGroupArray.map((pga) => {
                return new SignatureStroke(
                    pga.map((p, index) => {
                        return new SignatureStrokePoint(p.x, p.y, index == 0 ? 0 : Math.min(100, Math.max(0, p.time - pga[index - 1].time)));
                    })
                )
            }),
            image
        )
    }

    static decode(json: any) {
        const decoder = new Decoder(json);
        const strokes = decoder.decode('strokes', Decoder.Array).map((s: any) => SignatureStroke.decode(s));
        if (decoder.checkForErrors()) {
            return new Signature(strokes, undefined);
        } else {
            return new Signature([], undefined);
        }
    }

    toPointGroupArray() {
        return this.strokes.map((s) => {
            let timeAcc = 0;
            return s.points.map((p, index) => {
                timeAcc += p.delta;
                return {...p, time: index == 0 ? 0 : timeAcc}
            });
        })
    }

}

class SignatureStroke {
    points: Array<SignatureStrokePoint>;

    constructor(points: Array<SignatureStrokePoint>) {
        this.points = points;
    }

    static decode(json: any) {
        const decoder = new Decoder(json);
        const points = decoder.decode('points', Decoder.Array).map((p: any) => SignatureStrokePoint.decode(p));
        if (decoder.checkForErrors()) {
            return new SignatureStroke(points);
        } else {
            return new SignatureStroke([]);
        }
    }
}

class SignatureStrokePoint {
    x: number;
    y: number;
    delta: number;

    constructor(x: number, y: number, delta: number) {
        this.x = x;
        this.y = y;
        this.delta = delta;
    }

    static decode(json: any) {
        const decoder = new Decoder(json);
        const x = decoder.decode('x', Decoder.Integer);
        const y = decoder.decode('y', Decoder.Integer);
        const delta = decoder.decode('delta', Decoder.Integer);

        if (decoder.checkForErrors()) {
            return new SignatureStrokePoint(x, y, delta);
        } else {
            return new SignatureStrokePoint(0, 0, 0);
        }
    }
}