/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../decoding";
import { UidGenerator } from "../tools";
import { Validation } from "../validation";
import StateObject from "./State/StateObject";

export default class CustomQuestion extends StateObject {
    uid: number;
    type: string;
    title: string;
    options?: string[] | undefined;
    requiredAnswer?: number | undefined;

    constructor(
        type: string,
        title: string,
        options?: string[] | undefined,
        requiredAnswer?: number | undefined,
    ) {
        super();
        this.uid = UidGenerator.getUid();
        this.type = type;
        this.title = title;
        this.options = options;
        this.requiredAnswer = requiredAnswer;
    }

    static initDefault(type: string): CustomQuestion {
        if (['toggle', 'text', 'textArea', 'integer', 'decimal', 'date'].includes(type)) {
            return new CustomQuestion(type, '');
        } else if (type === 'knockout') {
            return new CustomQuestion(type, '', ['', '']);
        } else {
            return new CustomQuestion(type, '', ['', '', '']);
        }
    }

    static decode(json: any): CustomQuestion {
        const decoder = new Decoder(json);
        const type: string = decoder.decode('type', Decoder.String);
        const title: string = decoder.decode('title', Decoder.String);
        let options: string[] | undefined;
        if (['knockout', 'picker', 'multiselect'].includes(type)) {
            options = decoder.decode('options', Decoder.Array);
        }
        let requiredAnswer: number | undefined 
        if (type === 'knockout') {
            requiredAnswer = decoder.decode('requiredAnswer', Decoder.Integer);
        }

        if (decoder.checkForErrors()) {
            return new CustomQuestion(type, title, options, requiredAnswer);
        } else {
            return CustomQuestion.initDefault(type ?? 'text');
        }
    }

    encode() {
        return {
            type: this.type,
            title: this.title,
            options: this.options,
            requiredAnswer: this.requiredAnswer
        }
    }

    duplicate() {
        return new CustomQuestion(this.type, this.title, this.options, this.requiredAnswer);
    }

}