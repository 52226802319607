/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useRef, useState } from 'react';
import VA4Page1 from '../../../../../assets/VA-4-1.png'
import VA4Page2 from '../../../../../assets/VA-4-2.png'
import { Button, Modal } from 'react-bootstrap';
import ControlStateProps from '../../../../../models/State/ControlStateProps';
import { pdf } from '@react-pdf/renderer';
import DocumentWizard from '../../../DocumentWizard';
import DocumentField from '../../../DocumentField';
import VA4PDF from './VA4Pdf';
import SubscriberWrapper from '../../../../../components/SubscriberWrapper';
import DocumentCheckbox from '../../../DocumentCheckbox';
import DocumentSignatureButton from '../../../DocumentSignatureButton';
import { Validation } from '../../../../../validation';
import moment from 'moment';
import VA4Pdf from './VA4Pdf';
import { reactPdfToBase64 } from '../../../../../tools';
import { submitDocument } from '../../../../../services/EmployeeOnboardingService';
import { AppGlobals } from '../../../../../App';
import VA4 from './VA4';

export default function VA4Form({document, userData, signature, hideModal, onboardingToken, updateDocumentToCompleted}) {
    const [zoom, setZoom] = useState(1);
    const [va4, _] = useState(VA4.initFromData(document, userData));

    const fieldRefs = [
        useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(),
        useRef(), useRef()
    ]

    const handleSubmit = async () => {
        const pdf = <VA4Pdf signature={signature} va4={va4}/>;
        const base64 = await reactPdfToBase64(pdf);
        const response = await submitDocument(va4.encode(document, base64), onboardingToken);
        if (response.status === 200) {
            updateDocumentToCompleted('va4', base64);
            hideModal();
            AppGlobals.alert('success', response.message);
        } else {
            AppGlobals.alert('danger', response.message);
        }
    }

    return (
        <>
            <Modal.Header closeButton>
                
            </Modal.Header>
            <Modal.Body style={{padding: 0, overflowX: 'scroll', backgroundColor: 'lightgray'}}>
                <div style={{transform: `scale(${zoom})`, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 12, height: ((1100 * 2) + (12) + 16) * zoom}}>
                    <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                        <img src={VA4Page1} style={{width: 850, height: 1100}}/>
                        <DocumentField innerRef={fieldRefs[0]} stateProps={new ControlStateProps('claimYourself', va4)} type='number' minInt={0} maxInt={1} x={601} y={145} width={30} fontSize={16}/>
                        <DocumentField innerRef={fieldRefs[1]} stateProps={new ControlStateProps('marriedSpouseNotClaimed', va4)} type='number' minInt={0} maxInt={1} x={601} y={178} width={30} fontSize={16}/>
                        <DocumentField innerRef={fieldRefs[2]} stateProps={new ControlStateProps('numberOfDependents', va4)} type='number' minInt={0} maxInt={255} x={601} y={211} width={30} fontSize={16}/>
                        <DocumentField innerRef={fieldRefs[3]} stateProps={new ControlStateProps('olderThan65', va4)} type='number' minInt={0} maxInt={1} x={601} y={279} width={30} fontSize={16}/>
                        <DocumentField innerRef={fieldRefs[4]} stateProps={new ControlStateProps('spouseOlderThan65', va4)} minInt={0} maxInt={1} x={601} y={313} width={30} fontSize={16}/>
                        <DocumentField innerRef={fieldRefs[5]} stateProps={new ControlStateProps('legallyBlind', va4)} type='number' minInt={0} maxInt={1} x={601} y={346} width={30} fontSize={16}/>
                        <DocumentField innerRef={fieldRefs[6]} stateProps={new ControlStateProps('spouseLegallyBlind', va4)} type='number' minInt={0} maxInt={1} x={601} y={379} width={30} fontSize={16}/>

                        <DocumentField innerRef={fieldRefs[7]} stateProps={new ControlStateProps('ssn', va4, Validation.ssn)} x={61} y={590} width={130} fontSize={16} type='number' maxLength={9}/>
                        <DocumentField innerRef={fieldRefs[8]} stateProps={new ControlStateProps('name', va4, Validation.nonEmptyString)} maxLength={50} x={245} y={590} width={550} fontSize={16}/>
                        <DocumentField innerRef={fieldRefs[9]} stateProps={new ControlStateProps('streetAddress', va4, Validation.nonEmptyString)} maxLength={30} x={61} y={630} width={734} fontSize={16}/>
                        <DocumentField innerRef={fieldRefs[6]} stateProps={new ControlStateProps('city', va4, Validation.nonEmptyString)} maxLength={20} x={61} y={670} width={358} fontSize={16}/>
                        <DocumentField innerRef={fieldRefs[10]} stateProps={new ControlStateProps('state', va4, Validation.nonEmptyString)} maxLength={20} x={431} y={670} width={176} fontSize={16}/>
                        <DocumentField innerRef={fieldRefs[11]} stateProps={new ControlStateProps('zipCode', va4, Validation.nonEmptyString)} maxLength={5} type='number' x={619} y={670} width={174} fontSize={16}/>

                        <DocumentField innerRef={fieldRefs[12]} stateProps={new ControlStateProps('additionalWitholdings', va4)} type='number' minDecimal={0} maxDecimal={9999.99} x={681} y={838} width={120} fontSize={16}/>
                        <DocumentCheckbox innerRef={fieldRefs[13]} value={2} stateProps={new ControlStateProps('notSubjectToWitholdingFromInstructions', va4)} x={675} y={879} height={26} width={26}/>
                        <DocumentCheckbox innerRef={fieldRefs[14]} value={2} stateProps={new ControlStateProps('notSubjectToWitholdingServiceMember', va4)} x={675} y={940} height={26} width={26}/>
                        <div style={{position: 'absolute', backgroundColor: 'white', left: 770, top: 855, height: 3, width: 3}}></div>

                        <SubscriberWrapper stateProps={new ControlStateProps(null, va4, null, 'va4fields')} render={() => {
                            return (
                                <>
                                    <span style={{position: 'absolute', left: 605, top: 238}}>{va4.subtotalPersonalExemptions()}</span>
                                    <span style={{position: 'absolute', left: 733, top: 409}}>{va4.subtotalAgeBlind()}</span>
                                    <span style={{position: 'absolute', left: 733, top: 439}}>{va4.totalExemptions()}</span>
                                    <span style={{position: 'absolute', left: 733, top: 739}}>{va4.subtotalPersonalExemptions()}</span>
                                    <span style={{position: 'absolute', left: 733, top: 780}}>{va4.subtotalAgeBlind()}</span>
                                    <span style={{position: 'absolute', left: 733, top: 809}}>{va4.totalExemptions()}</span>  
                                </>
                            )
                        }}/>

                        <DocumentSignatureButton innerRef={fieldRefs[15]} signature={signature} stateProps={new ControlStateProps('signed', va4, Validation.true)} x={80} y={964} width={148} height={32}/>
                        <SubscriberWrapper stateProps={new ControlStateProps('signed', va4, null, 'wrapper for date')} render={() => {
                            return (!va4.signed ? '' : 
                            <span style={{position: 'absolute', left: 660, top: 976}}>{moment().format('MM/DD/YYYY')}</span>
                            )
                        }}/>
                    </div>
                    <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                        <img src={VA4Page2} style={{width: 850, height: 1100}}/>
                    </div>
                </div>
            </Modal.Body>
            <DocumentWizard document={va4} fieldRefs={fieldRefs} refCount={16} hideModal={hideModal} onSubmit={handleSubmit} zoom={zoom} setZoom={setZoom}/>
        </>
    )
}
