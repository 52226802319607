/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import UserForCompany from "../views/MyCompany/models/UserForCompany";
import { getResponse, getToken} from "./serviceTools";

export async function getCompanyInfo(){
    const data = {
        type: 'myCompany',
        target: 'getInfo',
        token: getToken(),
    }

    return await getResponse(data);
}

export async function getDashBoardInfo(){
    const data = {
        type: 'myCompany',
        target: 'getDashboard',
        token: getToken()
    }

    return await getResponse(data);
}

export async function getAutomaticRejectionMessage(){
    const data = {
        type: 'myCompany',
        target: 'getAutomaticRejectionMessage',
        token: getToken(),
    }

    return await getResponse(data);
}

export async function updateCompanySettings(sendAutomaticRejectionEmail: boolean, automaticRejectionMessage: string) {
    const data = {
        type: 'myCompany',
        target: 'updateCompanySettings',
        token: getToken(),
        sendAutomaticRejectionEmail: sendAutomaticRejectionEmail,
        automaticRejectionMessage: automaticRejectionMessage
    }

    return await getResponse(data);
}

export async function createUser(newUser: UserForCompany){
    const data = {
        type: 'myCompany',
        target: 'createUser',
        token: getToken(),
        newUser
    }

    return await getResponse(data);
}

export async function updateUser(user: UserForCompany){
    const data = {
        type: 'myCompany',
        target: 'updateUser',
        token: getToken(),
        user
    }
    
    return await getResponse(data);
}

export async function deleteUser(userIdentifier: number){
    const data = {
        type: 'myCompany',
        target: 'deleteUser',
        token: getToken(),
        userIdentifier
    }

    return await getResponse(data);
}