/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment"
import Address from "../../../../models/Address"
import StateObject from "../../../../models/State/StateObject"
import Decoder from "../../../../decoding";

export default class MDD extends StateObject {
    employerName;
    employeeName;
    email;
    phoneNumber;
    fiName1;
    routingNumber1;
    accountNumber1;
    accountType1;
    depositAmount1;
    depositType;
    fiName2;
    routingNumber2;
    accountNumber2;
    accountType2;
    depositAmount2;
    attachment1;
    attachment2;
    signed = false;
    completed = false;

    constructor(employerName, employeeName, email, phoneNumber, fiName1, routingNumber1, accountNumber1, accountType1, depositAmount1, depositType, fiName2, routingNumber2, accountNumber2, accountType2, depositAmount2, attachment1, attachment2) {
        super();
        this.employerName = employerName;
        this.employeeName = employeeName;
        this.email = email;
        this.phoneNumber = phoneNumber;
        this.fiName1 = fiName1;
        this.routingNumber1 = routingNumber1;
        this.accountNumber1 = accountNumber1;
        this.accountType1 = accountType1;
        this.depositAmount1 = depositAmount1;
        this.depositType = depositType;
        this.fiName2 = fiName2;
        this.routingNumber2 = routingNumber2;
        this.accountNumber2 = accountNumber2;
        this.accountType2 = accountType2;
        this.depositAmount2 = depositAmount2;
        this.attachment1 = attachment1;
        this.attachment2 = attachment2;
    }

    static initDefault() {
        return new MDD('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', null, null);
    }

    static initFromData(document, userData, companyData) {
        const firstName = userData.firstName ?? '';
        const lastName = userData.lastName ?? '';

        return new MDD(
            companyData.companyName ?? companyData.name ?? '',
            document.employeeName ?? `${firstName} ${lastName}`,
            document.email ?? userData.email ?? '',
            document.phoneNumber ?? userData.phoneNumber ?? '',
            document.fiName1 ?? '',
            document.routingNumber1 ?? '',
            document.accountNumber1 ?? '',
            document.accountType1,
            document.depositAmount1 ?? '',
            document.depositType,
            document.fiName2 ?? '',
            document.routingNumber2 ?? '',
            document.accountNumber2 ?? '',
            document.accountType2,
            document.depositAmount2 ?? '',
            document.attachment1,
            document.attachment2,
        )
    }

    encode(document, base64) {
        return {
            ...document,
            base64: base64,
            employerName: this.employerName,
            employeeName: this.employeeName,
            email: this.email,
            phoneNumber: this.phoneNumber,
            fiName1: this.fiName1,
            routingNumber1: this.routingNumber1,
            accountNumber1: this.accountNumber1,
            accountType1: this.accountType1,
            depositAmount1: this.depositAmount1,
            depositType: this.depositType,
            fiName2: this.fiName2,
            routingNumber2: this.routingNumber2,
            accountNumber2: this.accountNumber2,
            accountType2: this.accountType2,
            depositAmount2: this.depositAmount2,
            attachment1: this.attachment1,
            attachment2: this.attachment2
        }
    }
}