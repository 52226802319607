/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment"
import Address from "../../../../models/Address"
import StateObject from "../../../../models/State/StateObject"
import { addressToString, base64PdfToBase64Images, formatPhoneNumber, validateDecimal, validateInteger } from "../../../../tools";
import Decoder from "../../../../decoding";

export default class I9 extends StateObject {
    employeeSubmission;
    lastName;
    firstName;
    middleInitial;
    otherLastNamesUsed;
    streetAddress;
    aptNumber;
    city;
    state;
    zipCode;
    dateOfBirth;
    ssn;
    emailAddress;
    phoneNumber;
    status;
    authorizedToWorkUntil;
    uscisANumber;
    uscisANumberForSection4;
    formI94Number;
    foreignPassportNumberAndCountry;
    signed;
    // Employer
    aDocument;
    aDocumentTitle;
    aIssuingAuthority;
    aDocumentNumber;
    aExpirationDate;
    // aDocumentTitle2;
    // aIssuingAuthority2;
    // aDocumentNumber2;
    // aExpirationDate2;
    // aDocumentTitle3;
    // aIssuingAuthority3;
    // aDocumentNumber3;
    // aExpirationDate3;
    bDocument;
    bDocumentTitle;
    bIssuingAuthority;
    bDocumentNumber;
    bExpirationDate;
    cDocument;
    cDocumentTitle;
    cIssuingAuthority;
    cDocumentNumber;
    cExpirationDate;
    additionalInformation;
    alternateProcedure;
    firstDayOfEmployment;
    nameAndTitle;
    employerName;
    fullAddress;
    uploadType;

    constructor(
        employeeSubmission,
        lastName, 
        firstName, 
        middleInitial, 
        otherLastNamesUsed, 
        streetAddress, 
        aptNumber, 
        city, 
        state, 
        zipCode, 
        dateOfBirth, 
        ssn, 
        emailAddress, 
        phoneNumber, 
        status, 
        authorizedToWorkUntil, 
        uscisANumber, 
        uscisANumberForSection4, 
        formI94Number, 
        foreignPassportNumberAndCountry, 
        signed,
        //Employer
        aDocument,
        aDocumentTitle,
        aIssuingAuthority,
        aDocumentNumber,
        aExpirationDate,
        bDocument,
        bDocumentTitle,
        bIssuingAuthority,
        bDocumentNumber,
        bExpirationDate,
        cDocument,
        cDocumentTitle,
        cIssuingAuthority,
        cDocumentNumber,
        cExpirationDate,
        additionalInformation,
        alternateProcedure,
        firstDayOfEmployment,
        nameAndTitle,
        employerName,
        fullAddress,
        uploadType,
        ) {
        super();
        this.employeeSubmission = employeeSubmission;
        this.lastName = lastName;
        this.firstName = firstName;
        this.middleInitial = middleInitial;
        this.otherLastNamesUsed = otherLastNamesUsed;
        this.streetAddress = streetAddress;
        this.aptNumber = aptNumber;
        this.city = city;
        this.state = state;
        this.zipCode = zipCode;
        this.dateOfBirth = dateOfBirth;
        this.ssn = ssn;
        this.emailAddress = emailAddress;
        this.phoneNumber = phoneNumber;
        this.status = status;
        this.authorizedToWorkUntil = authorizedToWorkUntil;
        this.uscisANumber = uscisANumber;
        this.uscisANumberForSection4 = uscisANumberForSection4;
        this.formI94Number = formI94Number;
        this.foreignPassportNumberAndCountry = foreignPassportNumberAndCountry;
        this.signed = signed;
        this.aDocument = aDocument;
        this.aDocumentTitle = aDocumentTitle;
        this.aIssuingAuthority = aIssuingAuthority;
        this.aDocumentNumber = aDocumentNumber;
        this.aExpirationDate = aExpirationDate;
        this.bDocument = bDocument;
        this.bDocumentTitle = bDocumentTitle;
        this.bIssuingAuthority = bIssuingAuthority;
        this.bDocumentNumber = bDocumentNumber;
        this.bExpirationDate = bExpirationDate;
        this.cDocument = cDocument;
        this.cDocumentTitle = cDocumentTitle;
        this.cIssuingAuthority = cIssuingAuthority;
        this.cDocumentNumber = cDocumentNumber;
        this.cExpirationDate = cExpirationDate;
        this.additionalInformation = additionalInformation;
        this.alternateProcedure = alternateProcedure;
        this.firstDayOfEmployment = firstDayOfEmployment;
        this.nameAndTitle = nameAndTitle;
        this.employerName = employerName;
        this.fullAddress = fullAddress;
        this.uploadType = uploadType;
        this.initChildren();
    }

    static initDefault() {
        return new I9(
            [],
            '',
            '', 
            '', 
            '', 
            '', 
            '', 
            '', 
            '', 
            '', 
            '', 
            '', 
            '', 
            '', 
            '', 
            '', 
            '', 
            '',
            '', 
            '', 
            false,
            '', 
            '', 
            '', 
            '', 
            '', 
            '', 
            '', 
            '',
            '',
            '',
            '',
            '', 
            '', 
            '', 
            '', 
            '', 
            false, 
            '', 
            '', 
            '', 
            '',
            ''
        );
    }

    static async initFromData(document, userData, companyData, startDate, isEmployer){
        const lastName = userData.lastName ?? ''
        const firstName = userData.firstName ?? ''
        const middleInitial = userData.middleName[0] ?? '';
        const userAddress = Address.decodeNonStrict(userData.address);
        const dateOfBirth = userData.dateOfBirth ?? '';
        const email = userData.email ?? '';
        const phoneNumber = userData.phoneNumber ?? '';
        const ssn = userData.ssn ?? '';

        const companyName = companyData.companyName ?? companyData.name ?? '';
        const companyAddress = Address.decodeNonStrict(companyData.address);

        let employeeSubmission = [];
        if (isEmployer) {
            employeeSubmission = await base64PdfToBase64Images(document.employeeSubmission, 1173);
        }

        return new I9(
            employeeSubmission,
            document.lastName ?? lastName,
            document.firstName ?? firstName,
            document.middleInitial ?? middleInitial,
            document.otherLastNamesUsed ?? '',
            document.streetAddress ?? userAddress.thoroughfare,
            document.aptNumber ?? userAddress.premise,
            document.city ?? userAddress.locality,
            document.state ?? userAddress.administrativeArea,
            document.zipCode ?? userAddress.postalCode,
            document.dateOfBirth ?? moment(dateOfBirth).format('YYYY-MM-DD'),
            document.ssn?.toString() ?? ssn,
            document.email ?? email,
            document.phoneNumber ?? phoneNumber,
            document.status ?? '',
            document.authorizedToWorkUntil ?? '',
            document.uscisANumber ?? '',
            document.uscisANumberForSection4 ?? '',
            document.formI94Number ?? '',
            document.foreignPassportNumberAndCountry ?? '',
            false,
            document.aDocument ?? null,
            document.aDocumentTitle ?? '',
            document.aIssuingAuthority ?? '',
            document.aDocumentNumber ?? '',
            document.aExpirationDate ?? '',
            document.bDocument ?? null,
            document.bDocumentTitle ?? '',
            document.bIssuingAuthority ?? '',
            document.bDocumentNumber ?? '',
            document.bExpirationDate ?? '',
            document.cDocument ?? null,
            document.cDocumentTitle ?? '',
            document.cIssuingAuthority ?? '',
            document.cDocumentNumber ?? '',
            document.cExpirationDate ?? '',
            document.additionalInformation ?? '',
            document.alternateProcedure ?? false,
            document.firstDayOfEmployment ?? startDate ?? '',
            document.nameAndTitle ?? '',
            document.employerName ?? companyName ?? '',
            document.fullAddress ?? addressToString(companyAddress) ?? '',
            document.aDocument ? 'a' : (document.bDocument || document.cDocument) ? 'bc' : ''
        )
    }

    encode(document, base64){
       return {
            ...document,
            base64: base64,
            lastName: this.lastName,
            firstName: this.firstName,
            middleInitial: this.middleInitial ? this.middleInitial : null,
            otherLastNamesUsed: this.otherLastNamesUsed ? this.otherLastNamesUsed : null,
            streetAddress: this.streetAddress,
            aptNumber: this.aptNumber ? this.aptNumber : null,
            city: this.city,
            state: this.state,
            zipCode: validateInteger(this.zipCode),
            dateOfBirth: this.dateOfBirth,
            ssn: validateInteger(this.ssn),
            emailAddress: this.emailAddress,
            phoneNumber: this.phoneNumber,
            status: this.status,
            authorizedToWorkUntil: this.authorizedToWorkUntil ? this.authorizedToWorkUntil : null,
            uscisANumber: this.uscisANumber ? this.uscisANumber : null,
            uscisANumberForSection4: this.uscisANumberForSection4 ? this.uscisANumberForSection4 : null,
            formI94Number: this.formI94Number ? validateInteger(this.formI94Number) : null,
            foreignPassportNumberAndCountry: this.foreignPassportNumberAndCountry ? this.foreignPassportNumberAndCountry : null,
            aDocument: this.aDocument,
            aDocumentTitle: this.aDocumentTitle,
            aIssuingAuthority: this.aIssuingAuthority,
            aDocumentNumber: this.aDocumentNumber ? this.aDocumentNumber : null,
            aExpirationDate: this.aExpirationDate ? this.aExpirationDate : null,
            bDocument: this.bDocument,
            bDocumentTitle: this.bDocumentTitle,
            bIssuingAuthority: this.bIssuingAuthority,
            bDocumentNumber: this.bDocumentNumber ? this.bDocumentNumber : null,
            bExpirationDate: this.bExpirationDate ? this.bExpirationDate : null,
            cDocument: this.cDocument,
            cDocumentTitle: this.cDocumentTitle,
            cIssuingAuthority: this.cIssuingAuthority,
            cDocumentNumber: this.cDocumentNumber ? this.cDocumentNumber : null,
            cExpirationDate: this.cExpirationDate ? this.cExpirationDate : null,
            additionalInformation: this.additionalInformation ? this.additionalInformation : null,
            alternateProcedure: this.alternateProcedure,
            firstDayOfEmployment: this.firstDayOfEmployment,
            nameAndTitle: this.nameAndTitle,
            employerName: this.employerName,
            fullAddress: this.fullAddress,
        }
    }

}
