/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import CustomButton from '../../components/CustomButton';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';

export default function DocumentWizard({document, fieldRefs, refCount, zoom, setZoom, onSubmit}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [fieldIndex, setFieldIndex] = useState(-1);
    const [showConfirmation, setShowConfirmation] = useState(false);

    useEffect(() => {
        document?.subscribeToValidation('Document Wizard', (isValid) => setTimeout(() => {
            setIsValid(isValid);
        }));
    }, [document]);

    const handleSubmit = async () => {
        setIsSubmitting(true);
        await onSubmit();
        setIsSubmitting(false);
    }

    const overlayMode = fieldIndex === -1;

    return (
        <div style={{position: 'fixed', top: overlayMode ? 0 : 4, left: overlayMode ? 0 : '50%', right: overlayMode ? 0 : '50%', bottom: overlayMode ? 0 : undefined, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: overlayMode ? 'rgba(0, 0, 0, 0.2)' : undefined}}>
            <div style={{backgroundColor: 'white', padding: 4, borderRadius: 6, border: '1px solid lightgray', display: 'flex', flexDirection: 'column', gap: 4, flexShrink: 0}}>
                <InputGroup style={{width: 'auto', marginLeft: 'auto', marginRight: 'auto'}}>
                    { fieldIndex >= 0 && 
                        <>
                            <Button style={{padding: '0px 6px 0px 6px', fontSize: 14}} onClick={() => {
                                const newIndex = fieldIndex - 1 < 0 ? refCount - 1 : fieldIndex - 1;
                                setFieldIndex(newIndex);
                                fieldRefs[newIndex]?.current?.focus({preventScroll: true});
                                fieldRefs[newIndex]?.current?.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
                            }}>Go Back</Button>
                            <InputGroup.Text style={{padding: '0px 6px 0px 6px', fontSize: 14}}>{`${fieldIndex + 1} / ${refCount}`}</InputGroup.Text>
                        </>
                    }
                    <Button style={fieldIndex > -1 ? {padding: '0px 6px 0px 6px', fontSize: 14} : {}} onClick={() => {
                        const newIndex = (fieldIndex + 1) % refCount;
                        setFieldIndex(newIndex);
                        fieldRefs[newIndex]?.current?.focus({preventScroll: true});
                        fieldRefs[newIndex]?.current?.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
                    }}>{fieldIndex == -1 ? 'Press To Begin' : 'Proceed'}</Button>
                </InputGroup>
                { fieldIndex >= 0 && 
                    <div style={{display: 'flex', fontSize: 14, gap: 4}}>
                        <span>Zoom:</span>
                        <Form.Range value={zoom} onChange={(event) => {setZoom(event.target.value)}} min={0.5} max={1.0} step={0.05} style={{backgroundColor: 'rgb(220, 230, 250)', padding: 4, borderRadius: 6, width: 100}}/>
                        <span style={{width: 37}}>{`${parseInt(zoom * 100)}%`}</span>
                    </div>
                }

                { fieldIndex == -1 ?
                    ''
                : isValid ? 
                    <CustomButton onClick={() => [setShowConfirmation(true)]} variant={'success'}>
                        <span>Submit Document</span>
                        <FontAwesomeIcon icon={faCheckSquare}/>
                    </CustomButton>
                :
                    <span style={{opacity: 0.5, fontStyle: 'italic', fontSize: 14, textAlign: 'center'}}>Complete 1+ required fields</span>
                }
                
            </div>
            
            <Modal show={showConfirmation} onHide={() => {setShowConfirmation(false)}} centered backdrop={isSubmitting ? 'static' : undefined}>
                <Modal.Header closeButton>
                    <Modal.Title>Submit Document?</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{fontStyle: 'italic', color: 'gray'}}>
                    Please ensure all entered information is accurate before submitting.
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton isLoading={isSubmitting} onClick={handleSubmit}>
                        Submit Document
                    </CustomButton>
                </Modal.Footer>
            </Modal>
        </div>
    )

}