/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { faArrowTurnDown, faArrowTurnUp, faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useReducer } from 'react';
import { Button, ButtonGroup, Card, Container, Row } from 'react-bootstrap';
import JobApplication from './models/JobApplication';
import { PayTypes } from '../../enums';
import { Breakpoints } from '../../tools';
import EmploymentHistory from '../../models/JobApplication/EmploymentHistory';
import CustomControl from '../../components/controls/CustomControl';
import CustomPicker from '../../components/controls/CustomPicker';
import AddressControl from '../../components/controls/AddressControl';
import CustomTextArea from '../../components/controls/CustomTextArea';
import ControlStateProps from '../../models/State/ControlStateProps';
import { Validation } from '../../validation';

export default function JobApplicationEmploymentHistoryForm({jobApplication, breakpoints}) {
    const [_, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        jobApplication.subscribe('JobApplicationEmploymentHistoryForm', 'employmentHistory', (_) => forceUpdate());
    }, [jobApplication]);

    const handleAddEmploymentHistory = () => {
        const ehArray = Array.from(jobApplication.employmentHistory);
        const newEh = EmploymentHistory.initDefault('employmentHistory', jobApplication);
        ehArray.push(newEh);
        jobApplication.setState('employmentHistory', ehArray);
    }

    const handleDeleteEmploymentHistory = (uid) => {
        jobApplication.setState('employmentHistory', jobApplication.employmentHistory.filter((e) => e.uid != uid));
    }

    const handleMoveEmploymentHistoryBack = (uid) => {
        let ehArray = Array.from(jobApplication.employmentHistory);
        let index = ehArray.findIndex((e) => e.uid === uid);
        if (index > 0) {
            let eh = ehArray.splice(index, 1)[0];
            ehArray.splice(index - 1, 0, eh);
            jobApplication.setState('employmentHistory', eh);
        }
    }

    const handleMoveEmploymentHistoryForward = (uid) => {
        let ehArray = Array.from(jobApplication.employmentHistory);
        let index = ehArray.findIndex((e) => e.uid === uid);
        if (index < ehArray.length - 1) {
            let eh = ehArray.splice(index, 1)[0];
            ehArray.splice(index + 1, 0, eh);
            jobApplication.setState('employmentHistory', eh);
        }
    }


    const employmentHistoryElements = jobApplication.employmentHistory.map((eh, index) => {

        return (
            <div key={eh.uid} style={{display: 'flex', alignItems: 'center', gap: 4}}>
                <div>
                    <ButtonGroup vertical>
                        <Button style={{padding: 2}} onClick={() => {handleMoveEmploymentHistoryBack(eh.uid)}}>
                            <FontAwesomeIcon icon={faArrowTurnUp} flip='horizontal'/>
                        </Button>
                        <Button style={{padding: 2}} onClick={() => {handleMoveEmploymentHistoryForward(eh.uid)}}>
                            <FontAwesomeIcon icon={faArrowTurnDown} flip='horizontal'/>
                        </Button>
                    </ButtonGroup>
                </div>
                <div style={{border: '1px solid lightgray', borderRadius: 6}}>
                    <div style={{backgroundColor: 'var(--background-secondary)', marginBottom: 6, padding: 6, borderTopLeftRadius: 6, borderTopRightRadius: 6, borderBottom: '1px solid lightgray', display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{display: 'flex', flexShrink: 0, justifyContent: 'center', alignItems: 'center', width: 24, height: 24, borderRadius: 12, backgroundColor: 'var(--bs-primary)'}}>
                            <span style={{color: 'white'}}>{index + 1}</span>
                        </div>
                        <Button onClick={() => {handleDeleteEmploymentHistory(eh.uid)}} className='btn-danger' style={{width: 24, height: 24, padding: 0, borderRadius: 12, display: 'flex', justifyContent: 'center', alignItems: 'center', flexShrink: 0}}>
                            <FontAwesomeIcon icon={faX}/>
                        </Button>
                    </div>
                    <Container fluid>
                        <Row>
                            <CustomControl floatingLabel title='Name of Employer' stateProps={new ControlStateProps('employerName', eh, Validation.nonEmptyString)} breakpoints={breakpoints}/>
                            <CustomControl floatingLabel title='Job Title' stateProps={new ControlStateProps('jobTitle', eh, Validation.nonEmptyString)} breakpoints={breakpoints}/>
                            <CustomControl floatingLabel type='number' title='Starting Pay Amount' stateProps={new ControlStateProps('startingPayAmount', eh, Validation.greaterThanZero)} breakpoints={breakpoints}/>
                            <CustomPicker title='Starting Pay Type' stateProps={new ControlStateProps('startingPayType', eh, Validation.nonEmptyString)} items={PayTypes} breakpoints={breakpoints}/>
                            <CustomControl floatingLabel type='number' title='Ending Pay Amount' stateProps={new ControlStateProps('endingPayAmount', eh, Validation.greaterThanZero)} breakpoints={breakpoints}/>
                            <CustomPicker title='Ending Pay Type' stateProps={new ControlStateProps('endingPayType', eh, Validation.nonEmptyString)} items={PayTypes} breakpoints={breakpoints}/>
                            <CustomControl floatingLabel max={'9999-12-31'} type='date' title='Start Date' stateProps={new ControlStateProps('startDate', eh, Validation.date)} breakpoints={breakpoints}/>
                            <CustomControl floatingLabel max={'9999-12-31'} type='date' title='End Date' stateProps={new ControlStateProps('endDate', eh)} breakpoints={breakpoints}/>
                            <CustomControl floatingLabel title='Reason for Leaving' stateProps={new ControlStateProps('reasonForLeaving', eh)} breakpoints={breakpoints}/>
                            <CustomControl floatingLabel maxLength={10} title='Employer Phone Number' stateProps={new ControlStateProps('employerPhoneNumber', eh)} breakpoints={breakpoints}/>
                            <CustomControl floatingLabel title='Employer Email Address' stateProps={new ControlStateProps('employerEmail', eh)} breakpoints={breakpoints}/>
                            <AddressControl validate={false} title='Employer Address' address={eh.employerAddress} containerBreakpoints={{xxl: 12}} breakpoints={breakpoints}/>
                            <CustomTextArea rows={3} title='Job Responsibilities' stateProps={new ControlStateProps('responsibilities', eh, Validation.nonEmptyString)} breakpoints={{xxl: 12}}/>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    })

    return (
        <>
            <div style={{display: 'flex', alignItems: 'center', gap: 12, marginBottom: '0.5rem'}}>
                <Card.Title style={{marginBottom: 0}}>Employment History</Card.Title>
                <Button onClick={handleAddEmploymentHistory} style={{fontSize: 14, height: 24, width: 24, padding: 0, borderRadius: 12, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <FontAwesomeIcon icon={faPlus}/>
                </Button>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                {employmentHistoryElements}
            </div>
        
        </>
    )
    
}