/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, InputGroup, ListGroup, ListGroupItem, Modal, Row } from "react-bootstrap";
import CustomCheck from "../../components/controls/CustomCheck";
import StateObject from "../../models/State/StateObject";
import ControlStateProps from "../../models/State/ControlStateProps";
import CustomButton from "../../components/CustomButton";
import KeyValueRow from "../../components/KeyValueRow";
import Cookies from "universal-cookie";
import { formatPhoneNumber } from "../../tools";
import CustomControl from "../../components/controls/CustomControl";
import { Validation } from "../../validation";
import { AppGlobals } from "../../App";
import { changePassword, changeUserPassword, getJobPostsForNotificationSettings, getNotificationSettings, updateNotificationSettings } from "../../services/UserService";
import { getJobPosts } from "../../services/JobPostService";
import LoadingWrapper from "../../components/LoadingWrapper";
import moment from "moment";
import JobPostsForNotificationSettings from "./models/JobPostsForNotificationSettings";
import SwitchControl from "../../components/SwitchControl";
import NotificationSetting from "./models/NotificationSetting";
import './Settings.css'
import { changeClientPassword, changeClientUserPassword } from "../../services/ClientUserService";

const cookies = new Cookies();
const userData = cookies.get('userData');

export default function Settings() {
    const nameOfSettings = ['newApplicant', 'applicantScreenResults', 'jobApplicationMessage'];

    const [isCLientUser] = useState(userData?.clientIdentifier)
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [jobs, setJobs] = useState([])
    const [receiveNotificationsForNewJobPosts, setReceiveNotificationsForNewJobPosts] = useState(true);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [notificationSettings, setNotificationSettings] = useState(new StateObject({
        'newApplicant': [],
        'applicantScreenResults': [],
        'jobApplicationMessage': []
    }));


    useEffect(() => {
        !isCLientUser && loadData();
    }, [])

    const loadData = async () => {
        setIsLoading(true);
        const response = await getNotificationSettings();
        if(response.status === 200){
            setJobs(response.jobPosts.map(j=>JobPostsForNotificationSettings.decode(j)));
            setReceiveNotificationsForNewJobPosts(response.receiveNotificationsForNewJobPosts);

            response.notificationSettings.forEach(setting=>{
                notificationSettings.setState(setting.name, NotificationSetting.decode(response.notificationSettings.find(ns=>ns.name === setting.name)).convertToArrayOfStrings())
            })
            
        }
        setIsLoading(false);
    }

    async function handleUpdateNotificationSettings(){
        setIsSubmitting(true);
        const notificationSettingsToSend = nameOfSettings.map(name=>{return new NotificationSetting(name, notificationSettings[name].includes('textEnabled'), notificationSettings[name].includes('emailEnabled'))});
        const response = await updateNotificationSettings(notificationSettingsToSend, jobs);
        if(response.status === 200){
            AppGlobals.alert('success', response.message);
        } else{
            AppGlobals.alert('danger', response.message);
        }

        setIsSubmitting(false);
    }

    function handleUpdateJobNotification(index){
        const newJobs = Array.from(jobs);
        newJobs[index]['notificationsEnabled'] = !newJobs[index]['notificationsEnabled'];
        setJobs(newJobs);
    }
    

    return (
        <LoadingWrapper isLoading={isLoading}>
            <Container fluid style={{height: '100%', padding: 12, paddingBottom: 0}}>
                <Row style={{height: '100%'}}>
                    <Col xl={!isCLientUser ? 6 : 12} style={{marginBottom: 12}}>
                        <Card style={{height: isCLientUser ? '' : '100%'}}>
                            <Card.Header>
                                <Card.Title>Account</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <ListGroup>
                                    <KeyValueRow isListItem title={'First Name'} value={userData.firstName}/>
                                    <KeyValueRow isListItem title={'Last Name'} value={userData.lastName}/>
                                    {userData.phoneNumber && <KeyValueRow isListItem title={'Phone Number'} value={formatPhoneNumber(userData.phoneNumber)}/>}
                                    <KeyValueRow isListItem title={'Email Address'} value={userData.email}/>  
                                </ListGroup>
                            </Card.Body>
                            <Card.Footer style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <CustomButton style={{minWidth: 150}} onClick={() => setShowPasswordModal(true)}>Change Password</CustomButton>
                            </Card.Footer>
                        </Card>
                    </Col>
                    {!isCLientUser && <Col xl={6} style={{marginBottom: 12}}>
                        <Card style={{height: '100%'}}>
                            <Card.Header>
                                <Card.Title>Notification Settings</Card.Title>
                            </Card.Header>
                            <Card.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                                <CustomCheck title='New Applicant' inline stateProps={new ControlStateProps('newApplicant', notificationSettings)} items={{'textEnabled': 'Send Text', 'emailEnabled': 'Send Email'}}/>
                                <CustomCheck title='Applicant Background Check and Drug Screen Results' inline stateProps={new ControlStateProps('applicantScreenResults', notificationSettings)} items={{'textEnabled': 'Send Text', 'emailEnabled': 'Send Email'}}/>
                                <CustomCheck title='New Job Application Note' inline stateProps={new ControlStateProps('jobApplicationMessage', notificationSettings)} items={{'textEnabled': 'Send Text', 'emailEnabled': 'Send Email'}}/>
                                <Row style={{alignItems: 'center', marginTop: 24}}>
                                    <Col sm={6} style={{marginBottom: 12}}>
                                        <h5 style={{margin: 0, paddingLeft: 4}}>Job Post Notification Settings</h5>
                                    </Col>
                                    <Col sm={12}>
                                        <SwitchControl noWrap={false} value={receiveNotificationsForNewJobPosts} setValue={setReceiveNotificationsForNewJobPosts} title='Default setting for new job posts'/>
                                    </Col>
                                </Row>
                                <ListGroup className="job-post-notifications" style={{border: '1px solid lightgray'}}>
                                    {jobs.map((j, index)=>{
                                        return (
                                            <ListGroup.Item key={j.uid} style={{display: 'flex', flexDirection: 'column'}}>
                                                <div style={{display: 'flex', justifyContent: 'space-between', gap: 12, flexWrap: 'wrap'}}>
                                                    <p style={{margin: 0, whiteSpace: 'nowrap'}}>{j.title}</p>
                                                    <Form.Check style={{marginLeft: 'auto'}} checked={j.notificationsEnabled} onChange={()=>handleUpdateJobNotification(index)} type="switch"/>
                                                </div>
                                                <p style={{margin: 0, color: 'rgb(45, 105, 160)'}}>{j.isDuplicate ? `Activated ${moment(j.dateActivated).format('MMM D, YYYY')}` : ''}</p>
                                                <p style={{color: 'red', margin: 0, marginBottom: 12}}>{j.active ? '' : ' Inactive'}</p>
                                                <p style={{margin: 0, opacity: .5, overflow: "hidden", whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{j.description}</p>
                                            </ListGroup.Item>
                                        )
                                    })}
                                </ListGroup>
                            </Card.Body>
                            <Card.Footer style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <CustomButton isLoading={isSubmitting} style={{minWidth: 150}} onClick={handleUpdateNotificationSettings}>Save Changes</CustomButton>
                            </Card.Footer>
                        </Card>
                    </Col>
                    }
                </Row>
            </Container>
            <Modal show={showPasswordModal} backdrop='static' onHide={()=>setShowPasswordModal(false)}>
              <PasswordModal isCLientUser={isCLientUser} setShowPasswordModal={setShowPasswordModal}/>
            </Modal>
        </LoadingWrapper>
    )
}

function PasswordModal({setShowPasswordModal, isCLientUser}){
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    async function handleChangePassword(){
        setIsSubmitting(true);
        const response = isCLientUser ? await changeClientUserPassword(userData.uid, currentPassword, newPassword) : await changeUserPassword(currentPassword, newPassword);
        if(response.status === 200){
            AppGlobals.alert('success', response.message)
            setShowPasswordModal(false);
        }else{
            AppGlobals.alert('danger', response.message)
        }
        setIsSubmitting(false);
    }

    return(
        <>
           <Modal.Header closeButton>
                    <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                    <CustomControl type='password' title='Current Password' value={currentPassword} setValue={(value)=>setCurrentPassword(value)} floatingLabel validation={Validation.nonEmptyString}/>
                    <CustomControl type='password' title='New Password' value={newPassword} setValue={(value)=>setNewPassword(value)}  floatingLabel  validation={Validation.nonEmptyString}/>
                    <CustomControl type='password' title='Confirm Password'value={confirmPassword} setValue={(value)=>setConfirmPassword(value)} floatingLabel validation={(value)=>{if(value!==newPassword){return 'Passwords do not match'}}}/>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton isLoading={isSubmitting} onClick={handleChangePassword}>Submit</CustomButton>
            </Modal.Footer>
        </>
    )
} 