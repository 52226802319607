/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import { Card, ListGroup, Modal } from 'react-bootstrap';
import KeyValueRow from '../../components/KeyValueRow';
import { getClientApplicants, getClientTerminals } from '../../services/ClientService';
import LoadingWrapper from '../../components/LoadingWrapper';
import SortableTable, { SortableTableCol } from '../../components/SortableTable';
import { addressToString } from '../../tools';
import moment from 'moment';
import JobApplicationView from '../JobPost/JobApplicationView';
import SearchBar from '../../components/SearchBar';

export default function ClientApplicants({ selectedClient }) {
    const [isLoading, setIsLoading] = useState(false);
    const [applicants, setApplicants] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedApplicant, setSelectedApplicant] = useState(false);
    const [applicantSearch, setApplicantSearch] = useState('');

    useEffect(() => {
        loadData();
    }, []);


    const loadData = async () => {
        setIsLoading(true);

        const response = await getClientApplicants(selectedClient.uid);
        if (response.status === 200) {
            setApplicants(response.applicants);
        }
        setIsLoading(false);
    }

    function handleUpdateReject() {
        const newApplicants = Array.from(applicants);
        newApplicants.find(app=>app.uid === selectedApplicant.uid).rejected = true;
        setApplicants(newApplicants); 
    }

    function handleSetApplicant(uid, key, value){
        const newApplicants = Array.from(applicants);
        newApplicants.find(app=>app.uid === uid)[key] = value;
        setApplicants(newApplicants); 
    }

    function handleClick(app) {
        setSelectedApplicant(app);
        setShowModal(true);
    }

    /////////////////////////
    /// SORTABLE TABLE
    /////////////////////////

    const columns = [
        new SortableTableCol('dateApplied', 'Date Applied', null, SortableTableCol.sortAsc),
        new SortableTableCol('name', 'Name', null, SortableTableCol.sortAsc),
        new SortableTableCol('jobPost', 'Job Post', null, SortableTableCol.sortAsc),
        new SortableTableCol('terminal', 'Terminal', null, SortableTableCol.sortAsc),
        new SortableTableCol('status', 'Status', null, SortableTableCol.sortAsc),
    ]

    const applicantRows = applicants.filter(app => {
        const fullName = `${app.lastName}, ${app.firstName} ${app.middleName}`.toLowerCase();
        if (!fullName.includes(applicantSearch.toLowerCase()))
            return;

        return true;
    }).map((app) => {
        const status = app.rejected ? 'Rejected' 
            : app.hired ? 'Hired' 
            : app.interviewCount > 0 ? 'Interviewing' 
            : app.passedDrugScreen && app.passedBackground ? 'Passed BG Check & Drug Screen' 
            : 'Assigned To Client'
        ;

        return {
            values: {
                dateApplied: app.dateApplied,
                name: `${app.lastName}, ${app.firstName} ${app.middleName}`,
                jobPost: app.jobPostTitle,
                terminal: app.terminal.name,
                status: app.rejected ? 5 : app.hired ? 4 : app.interviewCount > 0 ? 3 : app.passedDrugScreen && app.passedBackground ? 2 : 1
            },
            content: (
                <tr key={app.uid} onClick={() => handleClick(app)} style={{ cursor: 'pointer' }}>
                    <td>{moment(app.dateApplied).format('MMM D, YYYY')}</td>
                    <td>{`${app.lastName}, ${app.firstName} ${app.middleName}`}</td>
                    <td>{app.jobPostTitle}</td>
                    <td>{app.terminal.name}</td>
                    <td>{status}</td>
                </tr>
            )
        }
    })

    /////////////////////////
    /// LIST GROUP ITEMS
    /////////////////////////
    const applicantElementsMobile = applicants.filter(app => {
        const fullName = `${app.lastName}, ${app.firstName} ${app.middleName}`.toLowerCase();
        if (!fullName.includes(applicantSearch.toLowerCase()))
            return;

        return true;
    }).map((app, index) => {
        const status = app.rejected ? 'Rejected' 
            : app.hired ? 'Hired' 
            : app.interviewCount > 0 ? 'Interviewing' 
            : app.passedDrugScreen && app.passedBackground ? 'Passed BG Check & Drug Screen' 
            : 'Assigned To Client'
        ;
        return (
            <ListGroup.Item className='cursor-pointer' action key={app.uid} onClick={() => handleClick(app)}>
                <div style={{ display: 'flex', flexDirection: 'column', padding: 8 }}>
                    <h4 style={{ fontWeight: 'bold', marginBottom: 12 }}>{`${app.lastName}, ${app.firstName} ${app.middleName}`}</h4>
                    <h6 style={{ marginBottom: 6 }}><strong>Job Title: </strong>{app.jobPostTitle}</h6>
                    <h6 style={{ marginBottom: 6 }}><strong>Terminal: </strong> {app.terminal.name}</h6>
                    <h6 style={{ marginBottom: 6 }}><strong>Status: </strong> {status}</h6>
                    <p style={{ textAlign: 'right', margin: 0, fontSize: 13 }}><strong>Date Applied: </strong>{app.dateApplied}</p>
                </div>
            </ListGroup.Item>
        )
    })

    return (
        <LoadingWrapper isLoading={isLoading}>
            <Card style={{ flex: 1 }}>
                <Card.Body style={{ display: 'flex', flexDirection: 'column', gap: 12, }}>
                    <SearchBar label='Search by applicant name' value={applicantSearch} setValue={(value) => setApplicantSearch(value)} />
                    <div className='desktop-block'>
                        <SortableTable hover rows={applicantRows} columns={columns} />
                    </div>
                    <ListGroup className='mobile-block'>
                        {applicantElementsMobile}
                    </ListGroup>
                    <Modal size='xl' show={showModal} onHide={() => { setShowModal(false); setSelectedApplicant(null) }}>
                        <Modal.Header closeButton>
                            <Modal.Title>Viewing Applicant</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <JobApplicationView selectedJobApplication={selectedApplicant?.uid} handleUpdateReject={handleUpdateReject} handleSetApplicant={handleSetApplicant} />
                        </Modal.Body>
                    </Modal>
                </Card.Body>
            </Card>
        </LoadingWrapper>
    )

}