/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../decoding";
import Address from "./Address";
import StateObject from "./State/StateObject";

export default class EmergencyContactLh extends StateObject{
    uid;
    userIdentifier;
    firstName;
    lastName;
    phoneNumber;
    email;
    address;
    relationship;

    constructor(
        uid,
        userIdentifier,
        firstName,
        lastName,
        phoneNumber,
        email,
        address,
        relationship,
    ){
        super();
        this.uid = uid;
        this.userIdentifier = userIdentifier;
        this.firstName = firstName;
        this.lastName = lastName;
        this.phoneNumber = phoneNumber;
        this.email = email;
        this.address = address;
        this.relationship = relationship;
        this.initChildren();
    }

    static initDefault(){
        return new EmergencyContactLh(
            -1,
            -1,
            '',
            '',
            '',
            '',
            undefined,
            '',
        )
    }

    static decode(json){
        const decoder = new Decoder(json);

        const uid = decoder.decode('uid', Decoder.Uid);
        const userIdentifier = decoder.decode('userIdentifier', Decoder.Uid);
        const firstName = decoder.decode('firstName', Decoder.String);
        const lastName = decoder.decode('lastName', Decoder.String);
        const phoneNumber = decoder.decode('phoneNumber', Decoder.String);
        const email = decoder.decode('email', Decoder.String);
        const address = Address.decodeValidated(json.address, true);
        const relationship = decoder.decode('relationship', Decoder.String);

        if(decoder.checkForErrors()){
            return new EmergencyContactLh(uid, userIdentifier, firstName, lastName, phoneNumber, email, address, relationship)
        } else {
            return EmergencyContactLh.initDefault();
        }
    }

    encode(){
        return {
            uid: this.uid,
            userIdentifier: this.userIdentifier,
            firstName: this.firstName,
            lastName: this.lastName,
            phoneNumber: this.phoneNumber,
            email: this.email,
            address: this.address ? this.address.encode() : null,
            relationship: this.relationship,
        }
    }
}