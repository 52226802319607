/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../../../decoding";
import Address from "../../../../../models/Address";
import StateObject from "../../../../../models/State/StateObject";
import { addressToString, base64PdfToBase64Images, validateDecimal, validateInteger } from "../../../../../tools";

export default class L4 extends StateObject{
    employeeSubmission;
    blockA;
    blockB;
    firstNameAndMiddleInitial;
    lastName;
    ssn;
    singleMarriedOrNone;
    homeAddress;
    cityStateZipCode;
    additionalWitholdings;
    signed;
    // Employer
    companyNameAndAddress;
    stateWitholdingAccountNumber;

    constructor(
        employeeSubmission,
        blockA,
        blockB,
        firstNameAndMiddleInitial,
        lastName,
        ssn,
        singleMarriedOrNone,
        homeAddress,
        cityStateZipCode,
        additionalWitholdings,
        signed,
        companyNameAndAddress,
        stateWitholdingAccountNumber,
    ){
        super();
        this.employeeSubmission = employeeSubmission;
        this.blockA = blockA;
        this.blockB = blockB;
        this.firstNameAndMiddleInitial = firstNameAndMiddleInitial;
        this.lastName = lastName;
        this.ssn = ssn;
        this.singleMarriedOrNone = singleMarriedOrNone;
        this.homeAddress = homeAddress;
        this.cityStateZipCode = cityStateZipCode;
        this.additionalWitholdings = additionalWitholdings;
        this.signed = signed;
        this.companyNameAndAddress = companyNameAndAddress;
        this.stateWitholdingAccountNumber = stateWitholdingAccountNumber;
    }

    static initDefault(){
        return new L4(
            [],
            '',
            '',
            '',
            '',
            '',
            undefined,
            '',
            '',
            '',
            false,
            '',
            ''
        )
    }

    static async initFromData(document, userData, companyData, isEmployer){
        const firstName = userData.firstName ?? '';
        const middleInitial = userData.middleName?.[0] ?? '';
        const lastName = userData.lastName ?? '';
        const ssn = userData.ssn ?? '';
        const userAddress = Address.decodeNonStrict(userData.address);

        const companyAddress = Address.decodeNonStrict(companyData.address);
        const companyName = companyData.companyName ?? companyData.name ?? '';
        const companyNameAndAddress = `${companyName} ${addressToString(companyAddress)}`;

        let employeeSubmission = [];
        if (isEmployer) {
            employeeSubmission = await base64PdfToBase64Images(document.employeeSubmission, 1173);
        }

        return new L4(
            employeeSubmission,
            document.blockA ?? '',
            document.blockB ?? '',
            document.firstNameAndMiddleInitial ?? `${firstName} ${middleInitial}`,
            document.lastName ?? lastName,
            document.ssn ?? ssn,
            document.singleMarriedOrNone ?? undefined,
            document.homeAddress ?? `${userAddress?.thoroughfare} ${userAddress?.premise}`,
            document.cityStateZipCode ?? `${userAddress?.locality}, ${userAddress?.administrativeArea}, ${userAddress?.postalCode}`,
            document.additionalWitholdings ?? '',
            false,
            document.companyNameAndAddress ?? companyNameAndAddress,
            document.stateWitholdingAccountNumber ?? '',
        )
    }

    getExemptionsBoolean(){
        return validateInteger(this.blockA) + validateInteger(this.blockB);
    }

    encode(document, base64){
        return {
            ...document,
            base64: base64, 
            firstNameAndMiddleInitial: this.firstNameAndMiddleInitial,
            lastName: this.lastName,
            ssn: this.ssn,
            blockA: validateInteger(this.blockA),
            blockB: validateInteger(this.blockB),
            singleMarriedOrNone: this.singleMarriedOrNone,
            homeAddress: this.homeAddress,
            cityStateZipCode: this.cityStateZipCode,
            additionalWitholdings: this.additionalWitholdings ? validateDecimal(this.additionalWitholdings) : null,
            companyNameAndAddress: this.companyNameAndAddress,
            stateWitholdingAccountNumber: this.stateWitholdingAccountNumber,
        }
    }

}