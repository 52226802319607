/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { React, useEffect, useState } from "react";
import { Card, Form, Button, Container, Row, Col } from "react-bootstrap";
import logo from '../../assets/TTA-Long-white.png';
import { authenticatePasswordReset, resetPassword } from "../../services/ResetPasswordService";
import CustomControl from "../../components/controls/CustomControl";
import CustomButton from "../../components/CustomButton";
import { AppGlobals } from "../../App";
import LoadingWrapper from "../../components/LoadingWrapper";
import { useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

export default function PasswordReset() {
   
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { passwordToken } = useParams();
    const history = useHistory();

    useEffect(() => {
        loadData();
    }, []);

    async function loadData(){
        setIsLoading(true);
        const response = await authenticatePasswordReset(passwordToken);
        if(response.status !== 200){
            setErrorMessage(response.message)
        }
        setIsLoading(false);
    }

    async function handleSubmit(){
        setIsSubmitting(true);
        const response = await resetPassword(passwordToken, confirmPassword);
        if(response.status === 200){
            AppGlobals.alert('success', response.message)
            history.push(`/`);
        } else{
            AppGlobals.alert('danger', response.message)
        }
        setIsSubmitting(false);
    }

    return(
        <LoadingWrapper isLoading={isLoading}>
            {!errorMessage ?
            <div style={{display: 'flex', flexDirection: 'column', position: 'relative', justifyContent: 'center', alignItems: 'center', height: '100vh', padding: 8}}>
                <div className='login-background' style={{position: 'absolute', width: '100%', height: '100%', opacity: 0.03}}>
                </div>
                <Card style={{maxWidth: '100%', display: 'flex', flexDirection: 'row', width: 800, minHeight: 600, border: '1px solid var(--bs-primary)', borderRadius: 8}}>
                    <Container fluid>
                        <Row style={{height: '100%'}}>
                            <Col md={6} style={{padding: 0}}>
                                <Card.Body className='login-lhs-card' style={{padding: 32, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: 'var(--bs-primary)', textAlign: 'center', borderRadius: 6}}>
                                    <div>
                                        <img src={logo} style={{padding: 8, maxWidth: '100%'}}/>
                                        <h2 style={{color: 'white', fontWeight: 'bolder'}}>Recruiter</h2>
                                    </div>
                                    <span style={{color: 'white', opacity: 0.9, fontWeight: 'bold'}}>A comprehensive solution to your recruiting needs</span>
                                </Card.Body>
                            </Col>
                            <Col md={6} style={{padding: 0}}> 
                                <Card.Body style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', gap: 12, height: '100%', padding: 32}}>
                                    <div style={{textAlign: 'center'}}>
                                        <h1>Password Reset</h1>
                                        <span style={{color: 'gray'}}>Please enter and confirm your new password:</span>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                                        <CustomControl floatingLabel type='password' title='Enter new password'  value={newPassword} setValue={setNewPassword}/>
                                        <CustomControl floatingLabel type='password'  title='Confirm Password' value={confirmPassword} setValue={setConfirmPassword}/>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', gap: 6, textAlign: 'center'}}>
                                    {newPassword !== confirmPassword && <p style={{color: 'red'}}>Passwords must match</p>}
                                        <CustomButton disabled={newPassword !== confirmPassword} isLoading={isLoading} onClick={handleSubmit}>
                                            Reset Password
                                        </CustomButton>
                                    </div>
                                </Card.Body>
                            </Col>
                        </Row>
                    </Container>
                </Card>
            </div>
            :
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
            <Card>
                <Card.Header>
                    <Card.Title style={{textAlign: 'center'}}>Oops!</Card.Title>
                </Card.Header>
                <Card.Body style={{display: 'flex', alignItems: 'center', gap: 12}}>
                    <span style={{margin: 'auto', textAlign: 'center'}}>
                        <FontAwesomeIcon size="1x" icon={faExclamationTriangle} style={{color: 'var(--bs-warning)', marginRight: 8}}/>
                        {errorMessage}
                    </span>
                </Card.Body>
            </Card>
         </div>
         }
        </LoadingWrapper>
    )
}