/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import StateObject from "../State/StateObject";
import Decoder from "../../decoding";
import User from "../User";
import { EditorState } from "draft-js";
import RichText from "../RichText";

export default class JobApplicationNote extends StateObject {
    uid: number;
    jobApplicationIdentifier: number;
    author: User;
    dateTime: string;
    richText: EditorState;
    lastEdited?: string;

    constructor(uid: number, jobApplicationIdentifier: number, author: User, dateTime: string, richText: EditorState, lastEdited?: string) {
        super();
        this.uid = uid;
        this.jobApplicationIdentifier = jobApplicationIdentifier;
        this.author = author;
        this.dateTime = dateTime;
        this.richText = richText;
        this.lastEdited = lastEdited
    }

    static initDefault(): JobApplicationNote {
        return new JobApplicationNote(
            -1, 
            -1, 
            User.initDefault(), 
            moment().format('YYYY-MM-DD HH:mm:ss'), 
            EditorState.createEmpty(),
            undefined
        )
    }

    static decode(json: any): JobApplicationNote {
        const decoder = new Decoder(json);
        const uid: number = decoder.decode('uid', Decoder.Uid);
        const jobApplicationIdentifier: number = decoder.decode('jobApplicationIdentifier', Decoder.Uid);
        const author: User = User.decode(json['author']);
        const dateTime: string = decoder.decode('dateTime', Decoder.DateTime);
        const richText: EditorState = RichText.decode(json.richText);
        const lastEdited: string = decoder.decode('lastEdited', Decoder.String, {defaultValue: undefined, warn: false})

        if (decoder.checkForErrors()) {
            return new JobApplicationNote(uid, jobApplicationIdentifier, author, dateTime, richText, lastEdited);
        } else {
            return JobApplicationNote.initDefault();
        }
    }

    // encode() {
    //     return {
    //         uid: this.uid,
    //         jobApplicationIdentifier: this.jobApplicationIdentifier,
    //         author: User,
    //         dateTime: this.dateTime,
    //         richText: RichText.encode(this.richText)
            
    //     }
    // }
}