/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useRef, useState } from 'react';
import L4Page1 from '../../../../../assets/L-4-1.png';
import { Button, Modal } from 'react-bootstrap';
import ControlStateProps from '../../../../../models/State/ControlStateProps';
import { pdf } from '@react-pdf/renderer';
import DocumentWizard from '../../../DocumentWizard';
import DocumentField from '../../../DocumentField';
import L4Pdf from './L4Pdf';
import { Validation } from '../../../../../validation';
import DocumentRadioCheck from '../../../DocumentRadioCheck';
import DocumentCheckbox from '../../../DocumentCheckbox';
import SubscriberWrapper from '../../../../../components/SubscriberWrapper';
import DocumentSignatureButton from '../../../DocumentSignatureButton';
import moment from 'moment';
import { submitDocument } from '../../../../../services/EmployeeOnboardingService';
import { reactPdfToBase64 } from '../../../../../tools';
import { AppGlobals } from '../../../../../App';
import L4 from './L4';
import LoadingWrapper from '../../../../../components/LoadingWrapper';

export default function L4Form({document, userData, companyData, signature, isEmployer, hideModal, onboardingToken, updateDocumentToCompleted}) {
    const [isLoading, setIsLoading] = useState(false);
    const [l4, setL4] = useState(L4.initDefault());
    const [zoom, setZoom] = useState(1);

    const fieldRefs = [
        useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef()
    ]

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        setL4(await L4.initFromData(document, userData, companyData, isEmployer));
        setIsLoading(false);
    }

    const handleSubmit = async () => {
        const pdf = <L4Pdf signature={signature} l4={l4} isEmployer={isEmployer}/>;
        const base64 = await reactPdfToBase64(pdf);
        const response = await submitDocument(l4.encode(document, base64), onboardingToken);
        if (response.status === 200) {
            updateDocumentToCompleted('l4', base64)
            hideModal();
            AppGlobals.alert('success', response.message);
        } else {
            AppGlobals.alert('danger', response.message);
        }
    }

    return (
        <>
            <Modal.Header closeButton>
                
            </Modal.Header>
            <Modal.Body style={{padding: 0, overflowX: 'scroll', backgroundColor: 'lightgray'}}>
                <LoadingWrapper isLoading={isLoading}>
                    <div style={{transform: `scale(${zoom})`, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 12, height: (1100 + 16) * zoom}}>
                        <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                            <img src={isEmployer ? l4.employeeSubmission[0] ?? L4Page1 : L4Page1} style={{width: 850, height: 1100}}/>
                            {!isEmployer ?
                                <>
                                    <DocumentField innerRef={fieldRefs[0]} stateProps={new ControlStateProps('blockA', l4, Validation.nonEmptyString)} minInt={0} maxInt={2} type='number' x={660} y={418} width={70} fontSize={18}/>
                                    <DocumentField innerRef={fieldRefs[1]} stateProps={new ControlStateProps('blockB', l4, Validation.nonEmptyString)} minInt={0} maxInt={255} type='number' x={660} y={498} width={70} fontSize={18}/>

                                    <DocumentField innerRef={fieldRefs[2]} stateProps={new ControlStateProps('firstNameAndMiddleInitial', l4, Validation.nonEmptyString)} maxLength={20} x={140} y={685} width={200} fontSize={12}/>
                                    <DocumentField innerRef={fieldRefs[3]} stateProps={new ControlStateProps('lastName', l4, Validation.nonEmptyString)} maxLength={20} x={520} y={685} width={170} fontSize={12}/>
                                    <DocumentField innerRef={fieldRefs[4]} stateProps={new ControlStateProps('ssn', l4, Validation.ssn)} x={135} y={721} width={110} fontSize={12} type='number' maxLength={9}/>
                                
                                    <DocumentRadioCheck innerRef={fieldRefs[5]} value={0} stateProps={new ControlStateProps('singleMarriedOrNone', l4, Validation.notUndefined, 'singleMarriedOrNone0')} x={320} y={704} width={18} height={18}/>
                                    <DocumentRadioCheck value={1} stateProps={new ControlStateProps('singleMarriedOrNone', l4, Validation.notUndefined, 'singleMarriedOrNone1')} x={570} y={704} width={18} height={18}/>
                                    <DocumentRadioCheck value={2} stateProps={new ControlStateProps('singleMarriedOrNone', l4, Validation.notUndefined, 'singleMarriedOrNone2')} x={659} y={704} width={18} height={18}/>


                                    <DocumentField innerRef={fieldRefs[6]} stateProps={new ControlStateProps('homeAddress', l4, Validation.nonEmptyString)} maxLength={40} x={130} y={755} width={400} fontSize={12}/>
                                    <DocumentField innerRef={fieldRefs[7]} stateProps={new ControlStateProps('cityStateZipCode', l4, Validation.nonEmptyString)} maxLength={50} x={130} y={791} width={400} fontSize={12}/>
                                    <SubscriberWrapper stateProps={new ControlStateProps('blockA', l4, null, 'blockA ro')} render={() => {
                                        return (<span style={{position: 'absolute', left: 650, top: 816}}>{l4.blockA}</span>)
                                    }}/>
                                    <SubscriberWrapper stateProps={new ControlStateProps('blockB', l4, null, 'blockB ro')} render={() => {
                                        return (<span style={{position: 'absolute', left: 650, top: 854}}>{l4.blockB}</span>)
                                    }}/>
                                    {/* <span style={{position: 'absolute', left: 650, top: 816}}>{l4.blockA}</span>
                                    <span style={{position: 'absolute', left: 650, top: 854}}>{l4.blockB}</span> */}
                                    <DocumentField innerRef={fieldRefs[8]} stateProps={new ControlStateProps('additionalWitholdings', l4)} type='number' minDecimal={0} maxDecimal={9999.99} x={593} y={896} width={140} fontSize={12}/>

                                    <DocumentSignatureButton innerRef={fieldRefs[9]} signature={signature} stateProps={new ControlStateProps('signed', l4, Validation.true)} x={240} y={949} width={110} height={24}/>
                                    <SubscriberWrapper stateProps={new ControlStateProps('signed', l4, null, 'wrapper for date')} render={() => {
                                        return (!l4.signed ? '' : 
                                        <span style={{position: 'absolute', left: 625, top: 951}}>{moment().format('MM/DD/YYYY')}</span>
                                        )
                                    }}/>
                                </>
                            :
                                <>
                                    <DocumentField innerRef={fieldRefs[0]} stateProps={new ControlStateProps('companyNameAndAddress', l4, Validation.nonEmptyString)} maxLength={90} x={105} y={1020} width={360} fontSize={11}/>
                                    <DocumentField innerRef={fieldRefs[1]} stateProps={new ControlStateProps('stateWitholdingAccountNumber', l4, Validation.nonEmptyString)} maxLength={15} x={480} y={1020} width={260} fontSize={11}/>
                                </>
                            }
                        </div>
                    </div>
                </LoadingWrapper>
            </Modal.Body>
            <DocumentWizard document={l4} fieldRefs={fieldRefs} refCount={isEmployer ? 2 : 10} onSubmit={handleSubmit} zoom={zoom} setZoom={setZoom}/>
        </>
    )
}
