/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useRef, useState } from 'react';
import IT4Page1 from '../../../../../assets/it4-1.png';
import IT4Page2 from '../../../../../assets/it4-2.png';
import IT4Page3 from '../../../../../assets/it4-3.png';
import { Button, Modal } from 'react-bootstrap';
import ControlStateProps from '../../../../../models/State/ControlStateProps';
import { Validation } from '../../../../../validation';
import moment from 'moment';
import SubscriberWrapper from '../../../../../components/SubscriberWrapper';
import { pdf } from '@react-pdf/renderer';
import DocumentWizard from '../../../DocumentWizard';
import DocumentSignatureButton from '../../../DocumentSignatureButton';
import DocumentField from '../../../DocumentField';
import DocumentCheckbox from '../../../DocumentCheckbox';
import IT4PDF from './IT4Pdf';
import AbsoluteText from '../../AbsoluteText';
import IT4Pdf from './IT4Pdf';
import { AppGlobals } from '../../../../../App';
import { reactPdfToBase64 } from '../../../../../tools';
import { submitDocument } from '../../../../../services/EmployeeOnboardingService';
import IT4 from './IT4';

export default function IT4Form({document, userData, signature, hideModal, onboardingToken, updateDocumentToCompleted}) {
    const [zoom, setZoom] = useState(1);
    const [it4, _] = useState(IT4.initFromData(document, userData));

    const fieldRefs = [
        useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(),
        useRef()
    ]

    const handleSubmit = async () => {
        const pdf = <IT4Pdf signature={signature} it4={it4}/>;
        const base64 = await reactPdfToBase64(pdf)
        const response = await submitDocument(it4.encode(document, base64), onboardingToken);
        if (response.status === 200) {
            updateDocumentToCompleted('it4', base64)
            hideModal();
            AppGlobals.alert('success', response.message);
        } else {
            AppGlobals.alert('danger', response.message);
        }
    }

    return (
        <>
            <Modal.Header closeButton>
                
            </Modal.Header>
            <Modal.Body style={{padding: 0, overflowX: 'scroll', backgroundColor: 'lightgray'}}>
                <div style={{transform: `scale(${zoom})`, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 12, height: ((1100*3) + (12 * 2) + 16) * zoom}}>
                    <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                        <img src={IT4Page1} style={{width: 850, height: 1100}}/>
                        <DocumentField innerRef={fieldRefs[0]} stateProps={new ControlStateProps('name', it4, Validation.nonEmptyString)} maxLength={50} x={54} y={295} width={400} fontSize={16}/>
                        <DocumentField innerRef={fieldRefs[1]} stateProps={new ControlStateProps('ssn', it4, Validation.ssn)} maxLength={9} type='number' x={500} y={295} width={200} fontSize={16}/>
                        <DocumentField innerRef={fieldRefs[2]} stateProps={new ControlStateProps('address', it4, Validation.nonEmptyString)} maxLength={80} x={54} y={344} width={730} fontSize={16}/>
                        <DocumentField innerRef={fieldRefs[3]} stateProps={new ControlStateProps('schoolDistrictOfResidence', it4)} maxLength={40} x={54} y={397} width={400} fontSize={16}/>
                        <DocumentField innerRef={fieldRefs[4]} stateProps={new ControlStateProps('schoolDistrictNumber', it4)} maxLength={4} type='number' x={500} y={397} width={200} fontSize={16}/>
                        <DocumentField innerRef={fieldRefs[5]} stateProps={new ControlStateProps('section2Dependent', it4, Validation.nonEmptyString)} minInt={0} maxInt={1} type='number' x={725} y={465} width={50} fontSize={18}/>
                        <DocumentField innerRef={fieldRefs[6]} stateProps={new ControlStateProps('section2Single', it4, Validation.nonEmptyString)} minInt={0} maxInt={1} type='number' x={725} y={498} width={50} fontSize={18}/>
                        <DocumentField innerRef={fieldRefs[7]} stateProps={new ControlStateProps('section2NumberOfDependents', it4, Validation.nonEmptyString)} minInt={0} maxInt={255} type='number' x={725} y={531} width={50} fontSize={18}/>
                        
                        <SubscriberWrapper stateProps={new ControlStateProps(null, it4, null, 'it4Section2WitholdingExemptions')} render={() => {
                            return (
                                <>
                                    <span style={{position: 'absolute', left: 725, top: 567}}>{it4.section2WitholdingExemptions()}</span>
                                </>
                            )
                        }}/>
                        <DocumentField innerRef={fieldRefs[8]} stateProps={new ControlStateProps('section2AdditionalWitholdings', it4)} type='number' maxDecimal={9999.99} x={682} y={600} width={110} fontSize={16}/>

                        <DocumentCheckbox innerRef={fieldRefs[9]} value={2} stateProps={new ControlStateProps('section3FullYearResident', it4)} x={56} y={704} height={24} width={24}/>
                        <DocumentCheckbox innerRef={fieldRefs[10]} value={2} stateProps={new ControlStateProps('section3ResidentMilitary', it4)} x={56} y={736} height={24} width={24}/>
                        <DocumentCheckbox innerRef={fieldRefs[11]} value={2} stateProps={new ControlStateProps('section3NonResidentMilitary', it4)} x={56} y={770} height={24} width={24}/>
                        <DocumentCheckbox innerRef={fieldRefs[12]} value={2} stateProps={new ControlStateProps('section3NonResidentCivilian', it4)} x={56} y={806} height={24} width={24}/>
                        <DocumentCheckbox innerRef={fieldRefs[13]} value={2} stateProps={new ControlStateProps('section3Exempt', it4)} x={55} y={854} height={24} width={24}/>
                    
                        <DocumentSignatureButton innerRef={fieldRefs[14]} signature={signature} stateProps={new ControlStateProps('signed', it4, Validation.true)} x={160} y={983} width={148} height={40}/>
                        <SubscriberWrapper stateProps={new ControlStateProps('signed', it4, null, 'wrapper for date')} render={() => {
                            return (!it4.signed ? '' : 
                                <span style={{position: 'absolute', left: 620, top: 999}}>{moment().format('MM/DD/YYYY')}</span>
                            )
                        }}/>

                    </div>
                    <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                        <img src={IT4Page2} style={{width: 850, height: 1100}}/>
                    </div>
                    <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                        <img src={IT4Page3} style={{width: 850, height: 1100}}/>
                    </div>
                </div>
            </Modal.Body>
            <DocumentWizard document={it4} fieldRefs={fieldRefs} refCount={15} onSubmit={handleSubmit} zoom={zoom} setZoom={setZoom}/>
        </>
    )
}
