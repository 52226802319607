/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import LoadingWrapper from '../../components/LoadingWrapper';
import { getAllClientUsers, getAllUsers, shadow } from '../../services/AdminService';
import { AppGlobals } from '../../App';
import SortableTable, { SortableTableCol } from '../../components/SortableTable';
import { Modal } from 'react-bootstrap';
import { filterArrayByObjectProperty } from '../../tools';
import SearchBar from '../../components/SearchBar';

export default function ShadowClientUser() {
    const [isLoading, setIsLoading] = useState(false);
    const [clientUsers, setClientUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [searchType, setSearchType] = useState('firstName');

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        const response = await getAllClientUsers();
        if (response.status === 200) {
            setClientUsers(response.clientUsers);
        } else {
            AppGlobals.alert('danger', response.message);
        }
        setIsLoading(false);
    }

    const handleShadowClientUser = async (clientUserIdentifier) => {
        setIsLoading(true);
        const response = await shadow(null, clientUserIdentifier);
        if (response.status === 200) {
            window.location.reload();
        } else {
            AppGlobals.alert('danger', response.message);
        }
        setIsLoading(false);
    }

    const columns = [
        new SortableTableCol('firstName', 'First Name', null, SortableTableCol.sortAsc),
        new SortableTableCol('lastName', 'Last Name', null, SortableTableCol.sortAsc),
        new SortableTableCol('clientName', 'Company Name', null, SortableTableCol.sortAsc),
    ]

    const userElements = filterArrayByObjectProperty(clientUsers, searchType, search).map((user) => {
        return {
            values: {firstName: user.firstName, lastName: user.lastName, clientName: user.clientName},
            content: (
                <tr className='cursor-pointer' onClick={() => {handleShadowClientUser(user.uid)}} key={user.uid}>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>{user.clientName}</td>
                </tr>
            )
        }
    })

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Shadow Client</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <LoadingWrapper isLoading={isLoading} color='var(--bs-primary)'>
                    <SearchBar value={search} setValue={setSearch} typeValues={['firstName', 'lastName', 'clientName']} typeNames={['First Name', 'Last Name', 'Company Name']} type={searchType} setType={setSearchType}/>
                    <SortableTable rows={userElements} columns={columns}/>
                </LoadingWrapper>
            </Modal.Body>
        </>
    )
}