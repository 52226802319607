/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { CSSProperties } from 'react';
import backgroundTiles from '../assets/truck-tiled-white.png';

type TTABackgroundProps = {
    style?: CSSProperties
}
export default function TTABackground({style}: TTABackgroundProps) {
    
    return (
        <div style={{position: 'absolute', backgroundImage: `url(${backgroundTiles})`, height: '100%', width: '100%', opacity: 0.03, ...style}}/>
    )
}