/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import I9Page1 from '../../../../assets/i-9-1.png';
import I9Page2 from '../../../../assets/i-9-2.png';
import I9Page3 from '../../../../assets/i-9-3.png';
import I9Page4 from '../../../../assets/i-9-4.png';

import { PDFViewer, Document, Page, Image, View, PDFDownloadLink, Text } from '@react-pdf/renderer';
import Address from '../../../../models/Address';
import { addressToString, formatEin, formatSsn, validateDecimal, validateInteger } from '../../../../tools';
import moment from 'moment';
import AbsoluteText from '../AbsoluteText';
import I9 from './I9';
import { Modal } from 'react-bootstrap';
import Signature from '../../Signature/Signature';

type I9PdfProps = {
    i9: I9,
    signature: Signature
    isEmployer: boolean
    ssn: string | undefined
    dl: string | undefined
}

export default function I9Pdf({i9, signature, isEmployer, ssn, dl}: I9PdfProps) {
    
    return (
        <Document>
            <Page size='LETTER' style={{fontSize: 10}}>
                <Image src={isEmployer ? i9.employeeSubmission[0] ?? I9Page1 :I9Page1}/>
                {!isEmployer ?
                    //Employee
                    <>
                        <AbsoluteText y={171} x={42} text={i9.lastName}/>
                        <AbsoluteText y={171} x={205} text={i9.firstName}/>
                        <AbsoluteText y={171} x={370} text={i9.middleInitial}/>
                        <AbsoluteText y={171} x={420} text={i9.otherLastNamesUsed}/>

                        <AbsoluteText y={197} x={42} text={i9.streetAddress}/>
                        <AbsoluteText y={197} x={233} text={i9.aptNumber}/>
                        <AbsoluteText y={197} x={305} text={i9.city}/>
                        <AbsoluteText y={197} x={461} text={i9.state}/>
                        <AbsoluteText y={197} x={510} text={i9.zipCode}/>

                        <AbsoluteText y={224} x={42} text={moment(i9.dateOfBirth).format('MM/DD/YYYY')}/>
                        <AbsoluteText y={224} x={153} text={i9.ssn[0]}/>
                        <AbsoluteText y={224} x={164} text={i9.ssn[1]}/>
                        <AbsoluteText y={224} x={175} text={i9.ssn[2]}/>
                        <AbsoluteText y={224} x={187} text={i9.ssn[3]}/>
                        <AbsoluteText y={224} x={198} text={i9.ssn[4]}/>
                        <AbsoluteText y={224} x={209} text={i9.ssn[5]}/>
                        <AbsoluteText y={224} x={222} text={i9.ssn[6]}/>
                        <AbsoluteText y={224} x={234} text={i9.ssn[7]}/>
                        <AbsoluteText y={224} x={246} text={i9.ssn[8]}/>
                        <AbsoluteText y={224} x={264} text={i9.emailAddress}/>
                        <AbsoluteText y={224} x={456} text={i9.phoneNumber}/>

                        <AbsoluteText y={255} x={184} text={i9.status == 0 ? 'X' : ''}/>
                        <AbsoluteText y={267} x={184} text={i9.status == 1 ? 'X' : ''}/>
                        <AbsoluteText y={279} x={184} text={i9.status == 2 ? 'X' : ''}/>
                        <AbsoluteText y={291} x={184} text={i9.status == 3 ? 'X' : ''}/>
                        <AbsoluteText y={279} x={396} text={i9.uscisANumber}/>
                        <AbsoluteText y={293} x={520} text={i9.authorizedToWorkUntil ? moment(i9.authorizedToWorkUntil).format('MM/DD/YYYY') : ''}/>

                        <AbsoluteText y={332} x={192} text={i9.uscisANumberForSection4}/>
                        <AbsoluteText y={332} x={290} text={i9.formI94Number}/>
                        <AbsoluteText y={332} x={403} text={i9.foreignPassportNumberAndCountry}/>

                        <Image src={signature.image} style={{position: 'absolute', top: 349, left: 130, width: 80, height: 16}}/>
                        <AbsoluteText y={351} x={485} text={moment().format('MM/DD/YYYY')}/>
                     </>
            :
                    //Employer
                 <>
                    <AbsoluteText y={431} x={130} text={i9.aDocumentTitle}/>
                    <AbsoluteText y={449} x={130} text={i9.aIssuingAuthority}/>
                    <AbsoluteText y={467} x={130} text={i9.aDocumentNumber}/>
                    <AbsoluteText y={485} x={130} text={i9.aExpirationDate ? moment(i9.aExpirationDate).format('MM/DD/YYYY') : ''}/>
                    <AbsoluteText y={431} x={280} text={i9.bDocumentTitle}/>
                    <AbsoluteText y={449} x={280} text={i9.bIssuingAuthority}/>
                    <AbsoluteText y={467} x={280} text={i9.bDocumentNumber}/>
                    <AbsoluteText y={485} x={280} text={i9.bExpirationDate ? moment(i9.bExpirationDate).format('MM/DD/YYYY') : ''}/>
                    <AbsoluteText y={431} x={425} text={i9.cDocumentTitle}/>
                    <AbsoluteText y={449} x={425} text={i9.cIssuingAuthority}/>
                    <AbsoluteText y={467} x={425} text={i9.cDocumentNumber}/>
                    <AbsoluteText y={485} x={425} text={i9.cExpirationDate ? moment(i9.cExpirationDate).format('MM/DD/YYYY') : ''}/>

                    <AbsoluteText y={512} x={270} text={i9.additionalInformation} style={{whiteSpace: 'break-spaces', width: 300, height: 110}}/>
                    <AbsoluteText y={627} x={269} text={i9.alternateProcedure ? 'X' : ''}/>

                    <AbsoluteText y={659} x={490} text={i9.firstDayOfEmployment}/>
                    <AbsoluteText y={690} x={40} text={i9.nameAndTitle}/>
                    <AbsoluteText y={718} x={40} text={i9.employerName}/>
                    <AbsoluteText y={718} x={243} text={i9.fullAddress}/>
                    <Image src={signature.image} style={{position: 'absolute', top: 686, left: 345, width: 80, height: 16}}/>
                    <AbsoluteText y={688} x={505} text={moment().format('MM/DD/YYYY')}/>
                 </>
            }
            </Page>
            <Page size='LETTER' style={{fontSize: 10}}>
                <Image src={I9Page2}/>
            </Page>
            {dl && 
                <Page size='LETTER' style={{position: 'relative', padding: 50}}>
                    <Text style={{fontSize: 15, textAlign: 'center', marginBottom: 12}}>Driver's License</Text>
                    <Image style={{objectFit: 'contain', maxHeight: 600}} src={dl}/>
                </Page>
            }
            {ssn && 
                <Page size='LETTER' style={{position: 'relative', padding: 50}}>
                    <Text style={{fontSize: 15, textAlign: 'center', marginBottom: 12}}>Social Security Card</Text>
                    <Image style={{objectFit: 'contain', maxHeight: 600}} src={ssn}/>
                </Page>
            }
            {i9.aDocument && 
                <Page size='LETTER' style={{position: 'relative', padding: 50}}>
                    <Text style={{fontSize: 15, textAlign: 'center', marginBottom: 12}}>Uploaded List A Document</Text>
                    <Image style={{objectFit: 'contain', maxHeight: 600}} src={i9.aDocument}/>
                </Page>
            }
            {i9.bDocument && 
                <Page size='LETTER' style={{position: 'relative', padding: 50}}>
                    <Text style={{fontSize: 15, textAlign: 'center', marginBottom: 12}}>Uploaded List B Document</Text>
                    <Image style={{objectFit: 'contain', maxHeight: 600}} src={i9.bDocument}/>
                </Page>
}
            {i9.cDocument && 
                <Page size='LETTER' style={{position: 'relative', padding: 50}}>
                    <Text style={{fontSize: 15, textAlign: 'center', marginBottom: 12}}>Uploaded List C Document</Text>
                    <Image style={{objectFit: 'contain', maxHeight: 600}} src={i9.cDocument}/>
                </Page>
            }
        </Document>
    )
}