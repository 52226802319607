/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Document, Page, Image, Text, View} from '@react-pdf/renderer';
import moment from 'moment';
import AbsoluteText from '../AbsoluteText';
import { formatPhoneNumber } from '../../../../tools';

export default function DirectDepositPdf({dd, signature}) {

    return (
        <Document>
            <Page size='LETTER' style={{position: 'relative', padding: 50}}>
                <View style={{textAlign: 'center', gap: 4, marginBottom: 12}}>
                    <Text style={{fontSize: 20}}>Direct Deposit Authorization Form</Text>
                    <Text style={{fontSize: 12}}>{dd.employerName}</Text>
                </View>

                <View style={{borderLeft: '1px solid black', borderTop: '1px solid black', width: '100%', fontSize: 8, color: 'gray'}}>
                    <View style={{borderBottom: '1px solid black', width: '100%', flexDirection: 'row', height: 30}}>
                        <View style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                            <Text>Employee Name</Text>
                        </View>
                        <View style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                            <Text>Email Address</Text>
                        </View>
                        <View style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                            <Text>Phone Number</Text>
                        </View>
                    </View>
                    <View style={{borderBottom: '1px solid black', width: '100%', flexDirection: 'row', height: 30}}>
                        <View style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                            <Text>Financial Institution Name</Text>
                        </View>
                        <View style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                            <Text>ABA Routing Number</Text>
                        </View>
                        <View style={{borderRight: '1px solid black', width: '33.33%', paddingLeft: 4}}>
                            <Text>Account Number</Text>
                        </View>
                    </View>
                </View>
                <View style={{border: '1px solid black', borderTop: 'none', width: '100%', flexDirection: 'row', alignItems: 'center', fontSize: 12, gap: 12, padding: 2}}>
                    <Text>Account Type</Text>
                    <View style={{borderBottom: '2px dotted gray', flex: 1}}/>

                    <View style={{flexDirection: 'row', alignItems: 'center', gap: 6}}>
                        <Text>Checking</Text>
                        <View style={{width: 10, height: 10, border: '1px solid black'}}/>
                    </View>
                    <View style={{flexDirection: 'row', alignItems: 'center', gap: 6}}>
                        <Text>Savings</Text>
                        <View style={{width: 10, height: 10, border: '1px solid black'}}/>
                    </View>
                </View>
                <View style={{gap: 12, marginTop: 12}}>
                    <View style={{display: 'flex', flexDirection: 'column'}}>
                        <Text style={{fontSize: 11, textAlign: 'center', lineHeight: 1}}>A PRINTED BANK DOCUMENT, INCLUDING YOUR NAME, BANK NAME, ROUTING NUMBER</Text>
                        <Text style={{fontSize: 11, textAlign: 'center', lineHeight: 1}}>AND ACCOUNT NUMBER IS REQUIRED FOR VERIFICATION. ACCEPTED BANK DOCS</Text>
                        <Text style={{fontSize: 11, textAlign: 'center', lineHeight: 1}}>INCLUDE VOIDED CHECK, DIRECT DEPOSIT FORM OR BANK APP SCREENSHOT; TWO</Text>
                        <Text style={{fontSize: 11, textAlign: 'center', lineHeight: 1}}>SCREENSHOTS MAY BE NECESSARY TO PROVIDE REQUIRED INFORMATION. FAILURE TO</Text>
                        <Text style={{fontSize: 11, textAlign: 'center', lineHeight: 1}}>PROVIDE REQUIRED BANK DOCUMENT MAY CAUSE DELAY OF PAYROLL PROCESSING</Text>
                    </View>
                    {/* <Text style={{fontSize: 12, textAlign: 'center'}}>A PRINTED BANK DOCUMENT, INCLUDING YOUR NAME, BANK NAME, ROUTING NUMBER AND ACCOUNT NUMBER IS REQUIRED FOR VERIFICATION. ACCEPTED BANK DOCS INCLUDE VOIDED CHECK, DIRECT DEPOSIT FORM OR BANK APP SCREENSHOT; TWO SCREENSHOTS MAY BE NECESSARY TO PROVIDE REQUIRED INFORMATION. FAILURE TO PROVIDE REQUIRED BANK DOCUMENT MAY CAUSE DELAY OF PAYROLL PROCESSING</Text> */}
                    <View>
                        <Text style={{fontSize: 11, textAlign: 'center'}}>NO HAND WRITTEN DOCUMENTS ARE ACCEPTED</Text>
                        <Text style={{fontSize: 11, textAlign: 'center'}}>DO NOT USE A DEPOSIT SLIP TO OBTAIN YOUR ACCOUNT NUMBER!</Text>
                    </View>
                    <View style={{gap: 12, marginTop: 24}}>
                        <Text style={{fontSize: 10}}>{`I hereby authorize ${dd.employerName} to direct electronic deposits to the account noted above for my periodic payroll checks and I authorize the financial institution designated herein to accept such electronic funds transfers. I have indicated the account at the financial institution to which deposits are to be made and understand that this decision can only be changed by me upon written instruction delivered to ${dd.employerName}. I understand that ${dd.employerName} may cancel this direct deposit program at any time.`}</Text>
                        <Text style={{fontSize: 10}}>{`I understand that it is my responsibility to confirm the accuracy of all transfers to my designated account and acknowledge that ${dd.employerName} has received a written termination notification from me in such time to afford ${dd.employerName} a reasonable opportunity to act on it.`}</Text>
                    </View>

                </View>

                <View style={{display: 'flex', flexDirection: 'row', gap: 50, fontSize: 11.5, marginTop: 48}}>
                    <Text style={{borderTop: '1px solid black', paddingLeft: 12, paddingRight: 50, paddingTop: 4}}>Employee's Signature</Text>
                    <Text style={{borderTop: '1px solid black', paddingLeft: 12, paddingRight: 50, paddingTop: 4}}>Date</Text>
                </View>

                <AbsoluteText fontSize={10} y={118} x={56} text={dd.employeeName}/>
                <AbsoluteText fontSize={10} y={118} x={226} text={dd.email}/>
                <AbsoluteText fontSize={10} y={118} x={396} text={formatPhoneNumber(dd.phoneNumber)}/>
                <AbsoluteText fontSize={10} y={148} x={56} text={dd.fiName}/>
                <AbsoluteText fontSize={10} y={148} x={226} text={dd.routingNumber}/>
                <AbsoluteText fontSize={10} y={148} x={396} text={dd.accountNumber}/>
                <AbsoluteText y={164} x={dd.accountType == 0 ? 480 : 550} text={'X'} fontSize={11}/>
                { signature && 
                    <>
                        <Image src={signature.image} style={{position: 'absolute', top: 425, left: 55, width: 118, height: 40}}/>
                        <AbsoluteText y={453} x={280} text={moment().format('MM/DD/YYYY')} fontSize={12}/>
                    </>
                }
            </Page>
            <Page size='LETTER' style={{position: 'relative', padding: 50}}>
                <Text style={{textAlign: 'center', fontSize: 15, marginBottom: 12}}>Uploaded Bank Document</Text>
                <Image src={dd.attachment} style={{objectFit: 'contain', maxHeight: 600}}/>
            </Page>
        </Document>
    )
}