/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useReducer, useState } from 'react';
import Form from 'react-bootstrap/Form';
import OptionalCol from '../OptionalCol';
import ValidationFeedback from '../ValidationFeedback';

export default function CustomCheck({selection, setSelection, items, title, inline, breakpoints, stateProps}) {
    const [showValidation, setShowValidation] = useState(false);
    const [_, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        stateProps?.subscribe((showValidation) => {
            if (showValidation) {
                setShowValidation(true);
            }
            forceUpdate();
        });
    }, [stateProps]);

    const handleSelect = (value) => {
        let newArray = Array.from(stateProps?.getValue() ?? selection ?? []);
        if (newArray.includes(value)) {
            newArray = newArray.filter(r => r !== value);
        } else {
            newArray.push(value);
        }

        stateProps?.setValue(newArray);
        if (setSelection) {
            setSelection(newArray);
        }
    }



    const checks = Object.entries(items).map(([value, label]) => {
        return (
            <Form.Check onBlur={() => {setShowValidation(true)}} label={label} key={value} type='checkbox' checked={(stateProps?.getValue() ?? selection ?? []).includes(value)} onChange={() => {handleSelect(value)}} inline={inline}/>
        )
    });

    return (
        <OptionalCol breakpoints={breakpoints}>
            <div className={'form-control ' + (stateProps?.getIsInvalid() && showValidation ? 'is-invalid' : stateProps?.getIsValid() && showValidation ? 'is-valid' : '')} style={{position: 'relative', minHeight: 64, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '6px 12px 3px 12px'}}>
                <div style={{marginBottom: 0, opacity: 0.65, fontSize: 14}}>{title}<span style={{color: 'red'}}>{(stateProps?.hasValidation()) ? '*' : ''}</span></div>
                <div>
                    {checks}
                </div>
                <ValidationFeedback stateProps={stateProps} showValidation={showValidation}/>
            </div>
        </OptionalCol>
    )
}