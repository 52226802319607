/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ControlStateProps from '../../../../models/State/ControlStateProps';
import { Validation } from '../../../../validation';
import moment from 'moment';
import SubscriberWrapper from '../../../../components/SubscriberWrapper';
import DocumentWizard from '../../DocumentWizard';
import DocumentSignatureButton from '../../DocumentSignatureButton';
import OfferLetterPdf from './OfferLetterPdf';
import { reactPdfToBase64 } from '../../../../tools';
import { submitDocument } from '../../../../services/EmployeeOnboardingService';
import { AppGlobals } from '../../../../App';
import OfferLetterFormBackground from './OfferLetterFormBackground';
import OfferLetter from './OfferLetter';

export default function OfferLetterForm({document, userData, companyData, signature, hideModal, onboardingToken, updateDocumentToCompleted}) {
    const [zoom, setZoom] = useState(1);

    const [offerLetter, _] = useState(OfferLetter.initFromData());

    const fieldRefs = [useRef()];

    const handleSubmit = async () => {
        const pdf = <OfferLetterPdf signature={signature} document={document} userData={userData} companyData={companyData}/>;
        const base64 = await reactPdfToBase64(pdf);
        const response = await submitDocument(offerLetter.encode(document, base64), onboardingToken);
        if (response.status === 200) {
            updateDocumentToCompleted('offerLetter', base64);
            hideModal();
            AppGlobals.alert('success', response.message);
        } else {
            AppGlobals.alert('danger', response.message);
        }
    }

    return (
        <>
            <Modal.Header closeButton/>
            <Modal.Body style={{padding: 0, overflowX: 'scroll', backgroundColor: 'lightgray'}}>
                <div style={{transform: `scale(${zoom})`, display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 12, height: (1100 + 16) * zoom}}>
                    <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                        <OfferLetterFormBackground document={document} userData={userData} companyData={companyData}/>
                        
                        <DocumentSignatureButton innerRef={fieldRefs[0]} signature={signature} stateProps={new ControlStateProps('signed', offerLetter, Validation.true)} width={177} height={60} x={90} y={936}/>
                        <SubscriberWrapper stateProps={new ControlStateProps('signed', offerLetter, null, 'wrapper for date')} render={() => {
                            return (!offerLetter.signed ? '' : 
                                <span style={{position: 'absolute', left: 415, top: 978}}>{moment().format('MM/DD/YYYY')}</span>
                            )
                        }}/>

                    </div>
                </div>
            </Modal.Body>
            <DocumentWizard document={offerLetter} fieldRefs={fieldRefs} refCount={1} zoom={zoom} setZoom={setZoom} onSubmit={handleSubmit}/>
        </>
    )
}
