/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Validation } from "../../../validation";
import Address from "../../../models/Address";
import Education from "../../../models/JobApplication/Education";
import EmploymentHistory from "../../../models/JobApplication/EmploymentHistory";
import MilitaryService from "../../../models/JobApplication/MilitaryService";
import CustomQuestionAnswer from "../../../models/JobApplication/CustomQuestionAnswer";
import moment from "moment";
import StateObject from "../../../models/State/StateObject";
import Decoder from "../../../decoding";
import JobApplicationNote from "../../../models/JobApplication/JobApplicationNote";
import Interview from "../../../models/JobApplication/Interview";

export default class JobApplication extends StateObject {
    uid; //: number;
    jobPostIdentifier; //: number;
    dateApplied; //: string | undefined;
    firstName; //: string;
    middleName; //: string;
    lastName; //: string;
    phoneNumber; //: string;
    email; //: string;
    dateOfBirth; //: string;
    address; //: Address;
    dateAvailable; //: string;
    eligibleToWork; //: boolean | undefined;
    convictedOfFelony; //: boolean | undefined;
    isVeteran; //: boolean | undefined;
    education; //: Education[];
    employmentHistory; //: EmploymentHistory[];
    customQuestionAnswers; //: CustomQuestionAnswer[];
    backgroundCheckConsent; //: boolean | undefined;
    militaryService; //: MilitaryService | undefined;
    felonyConvictionNotes; //?: string;
    terminalIdentifier; // number
    source; // string | null

    constructor(
        uid,
        jobPostIdentifier,
        dateApplied,
        firstName,
        middleName,
        lastName,
        phoneNumber,
        email,
        dateOfBirth,
        address,
        dateAvailable,
        eligibleToWork,
        convictedOfFelony,
        isVeteran,
        education,
        employmentHistory,
        customQuestionAnswers,
        backgroundCheckConsent,
        militaryService,
        felonyConvictionNotes,
        terminalIdentifier,
        source
    ) {
        super();
        this.uid = uid;
        this.jobPostIdentifier = jobPostIdentifier;
        this.dateApplied = dateApplied;
        this.firstName = firstName;
        this.middleName = middleName;
        this.lastName = lastName;
        this.phoneNumber = phoneNumber;
        this.email = email;
        this.dateOfBirth = dateOfBirth;
        this.address = address;
        this.dateAvailable = dateAvailable;
        this.eligibleToWork = eligibleToWork;
        this.convictedOfFelony = convictedOfFelony;
        this.isVeteran = isVeteran;
        this.education = education;
        this.employmentHistory = employmentHistory;
        this.customQuestionAnswers = customQuestionAnswers;
        this.backgroundCheckConsent = backgroundCheckConsent;
        this.militaryService = militaryService;
        this.felonyConvictionNotes = felonyConvictionNotes;
        this.terminalIdentifier = terminalIdentifier;
        this.source = source;
        this.initChildren();
    }

    static initDefault() {
        const jobApplication =  new JobApplication(
            -1, 
            -1, 
            moment().format('YYYY-MM-DD'),
            '', 
            '',
            '', 
            '', 
            '', 
            '', 
            undefined,
            '', 
            undefined, 
            undefined, 
            undefined, 
            [], 
            [], 
            [], 
            undefined, 
            undefined, 
            undefined,
            -1,
            null
        );
        jobApplication.address = Address.initDefault('address', jobApplication);
        return jobApplication; 
    }

    static initFromJobPost(jobPost) {
        const jobApplication = this.initDefault();
        jobApplication.jobPostIdentifier = jobPost.uid;
        jobApplication.terminalIdentifier = jobPost.terminal?.uid;
        const questionAnswers = jobPost.questions.map(q => CustomQuestionAnswer.initFromQuestion(q));
        jobApplication.customQuestionAnswers = questionAnswers;
        jobApplication.initChildren();
        return jobApplication;
    }

    // static decode(json) {
    //     const decoder = new Decoder(json);
    //     const uid = decoder.decode('uid', Decoder.Uid);
    //     const jobPostIdentifier = decoder.decode('jobPostIdentifier', Decoder.Uid);
    //     const dateApplied = decoder.decode('dateApplied', Decoder.Date, {defaultValue: undefined, warn: false});
    //     const firstName = decoder.decode('firstName', Decoder.StringStrict);
    //     const middleName = decoder.decode('middleName', Decoder.StringStrict);
    //     const lastName = decoder.decode('lastName', Decoder.StringStrict);
    //     const phoneNumber = decoder.decode('phoneNumber', Decoder.StringStrict);
    //     const email = decoder.decode('email', Decoder.StringStrict);
    //     const dateOfBirth = decoder.decode('dateOfBirth', Decoder.Date);
    //     const address = Address.decode(json.address);
    //     const dateAvailable = decoder.decode('dateAvailable', Decoder.Date);
    //     const eligibleToWork = decoder.decode('eligibleToWork', Decoder.Boolean);
    //     const convictedOfFelony = decoder.decode('convictedOfFelony', Decoder.Boolean);
    //     const isVeteran = decoder.decode('isVeteran', Decoder.Boolean);
    //     const education = (decoder.decode('education', Decoder.Array) ?? []).map(e => Education.decode(e));
    //     const employmentHistory = (decoder.decode('employmentHistory', Decoder.Array)).map(e => EmploymentHistory.decode(e));
    //     const customQuestionAnswers = (decoder.decode('customQuestionAnswers', Decoder.Array)).map(qa => CustomQuestionAnswer.decode(qa));
    //     const backgroundCheckConsent = decoder.decode('backgroundCheckConsent', Decoder.Boolean);
    //     const militaryService = json.militaryService === null ? null : MilitaryService.decode(json.militaryService);
    //     const notes = (decoder.decode('notes', Decoder.Array) ?? []).map(n => JobApplicationNote.decode(n));
    //     const felonyConvictionNotes = decoder.decode('felonyConvictionNotes', Decoder.String, {defaultValue: undefined, warn: false});
    //     const resume = decoder.decode('resume', Decoder.String, {defaultValue: '', warn: false});
        
    //     if (decoder.checkForErrors()) {
    //         return new JobApplication(uid, jobPostIdentifier, dateApplied, passedDrugScreen, passedBackground, hired, clientIdentifier, rejected, firstName, middleName, lastName,
    //             phoneNumber, email, dateOfBirth, ssn, address, dateAvailable, eligibleToWork, convictedOfFelony, isVeteran, education, employmentHistory, customQuestionAnswers, backgroundCheckConsent, militaryService, notes, felonyConvictionNotes, resume);
    //     } else {
    //         return JobApplication.initDefault();
    //     }
    // }

    encode() {
        return {
            uid: this.uid,
            jobPostIdentifier: this.jobPostIdentifier,
            dateApplied: this.dateApplied,
            firstName: this.firstName,
            middleName: this.middleName,
            lastName: this.lastName,
            phoneNumber: this.phoneNumber,
            email: this.email,
            dateOfBirth: this.dateOfBirth,
            address: this.address.encode(),
            dateAvailable: this.dateAvailable,
            eligibleToWork: this.eligibleToWork,
            convictedOfFelony: this.convictedOfFelony,
            isVeteran: this.isVeteran,
            education: this.education.map(e => e.encode()),
            employmentHistory: this.employmentHistory.map(eh => eh.encode()),
            customQuestionAnswers: this.customQuestionAnswers.map(a => a.encode()),
            backgroundCheckConsent: this.backgroundCheckConsent ? true : false,
            militaryService: this.militaryService?.encode(),
            felonyConvictionNotes: this.felonyConvictionNotes,
            terminalIdentifier: this.terminalIdentifier,
            source: this.source,
        }
    }

    duplicate() {
        return new JobApplication(
            this.uid, 
            this.jobPostIdentifier, 
            this.dateApplied, 
            this.firstName, 
            this.middleName, 
            this.lastName, 
            this.phoneNumber, 
            this.email, 
            this.dateOfBirth,
            this.address,
            this.dateAvailable,
            this.eligibleToWork,
            this.convictedOfFelony,
            this.isVeteran,
            this.education,
            this.employmentHistory,
            this.customQuestionAnswers,
            this.backgroundCheckConsent,
            this.militaryService,
            this.felonyConvictionNotes,
            this.terminalIdentifier,
            this.source,
        )
    }
}

