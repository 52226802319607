/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useRef } from 'react';
import { Button,  } from 'react-bootstrap';
import SignatureCanvas from 'react-signature-canvas';
import Signature from './Signature';


export default function SignatureControl({initialValue, setValue}) {
    const signatureRef = useRef();

    useEffect(() => {
        if (initialValue) {
            signatureRef.current?.fromData(initialValue.toPointGroupArray());
        }
    }, [])


    const handleSetSignature = () => {
        if (signatureRef.current) {
            setValue(Signature.initFromCanvas(signatureRef.current.toData(), signatureRef.current.toDataURL()));
        }
    }

    const handleClearSignature = () => {
        signatureRef.current?.clear();
        setValue(undefined);
    }


    return (
        <div style={{display: 'flex', flexDirection: 'column', width: 'fit-content', gap: 4}}>
            <div style={{width: 'fit-content', border: '1px solid lightgray'}}>    
                <SignatureCanvas dotSize={() => 4} throttle={16} minDistance={3} onEnd={handleSetSignature} ref={signatureRef} canvasProps={{width: 296, height: 100, className: 'sigCanvas'}}/>
            </div>
            <Button style={{padding: '0px 6px 0px 6px', marginTop: 6}} onClick={handleClearSignature}>Clear</Button>
        </div>
    )
}