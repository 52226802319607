/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../../../decoding";
import Address from "../../../../../models/Address";
import StateObject from "../../../../../models/State/StateObject";
import { base64PdfToBase64Images, validateDecimal, validateInteger } from "../../../../../tools";

export default class A4 extends StateObject {
    employeeSubmission;
    employeeName;
    ssn;
    streetAddress;
    city;
    state;
    zipCode;
    noPersonalExemptions;
    singleOrMarriedFilingSeparate;
    marriedOrSingleClaimingHead;
    numberOfDependents;
    additionalWitholding;
    signed;
    totalExemptions;
    employerName;
    ein;
    employerAddress;
    employerCity;
    employerState;
    employerZipCode;

    constructor(
        employeeSubmission,
        employeeName,
        ssn,
        streetAddress,
        city,
        state,
        zipCode,
        noPersonalExemptions,
        singleOrMarriedFilingSeparate,
        marriedOrSingleClaimingHead,
        numberOfDependents,
        additionalWitholding,
        signed,
        totalExemptions,
        employerName,
        ein,
        employerAddress,
        employerCity,
        employerState,
        employerZipCode,
    ){
        super();
        this.employeeSubmission = employeeSubmission;
        this.employeeName = employeeName;
        this.ssn = ssn;
        this.streetAddress = streetAddress;
        this.city = city;
        this.state = state;
        this.zipCode = zipCode;
        this.noPersonalExemptions = noPersonalExemptions;
        this.singleOrMarriedFilingSeparate = singleOrMarriedFilingSeparate;
        this.marriedOrSingleClaimingHead = marriedOrSingleClaimingHead;
        this.numberOfDependents = numberOfDependents;
        this.additionalWitholding = additionalWitholding;
        this.signed = signed;
        this.totalExemptions = totalExemptions;
        this.employerName = employerName;
        this.ein = ein;
        this.employerAddress = employerAddress;
        this.employerCity = employerCity;
        this.employerState = employerState;
        this.employerZipCode = employerZipCode;
    }

    static initDefault() {
        return new A4(
            [],
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            false,
            '',
            '',
            '',
            '',
            '',
            '',
            ''
        )
    }

    static async initFromData(document, userData, companyData, isEmployer) {
        const firstName = userData.firstName ?? '';
        const middleName = userData.middleName ?? '';
        const lastName = userData.lastName ?? '';
        const ssn = userData.ssn ?? '';
        const userAddress = Address.decodeNonStrict(userData.address);

        const ein = companyData.ein ?? '';
        const companyName = companyData.companyName ?? companyData.name ?? '';
        const companyAddress = Address.decodeNonStrict(companyData.address);

        let employeeSubmission = [];
        if (isEmployer) {
            employeeSubmission = await base64PdfToBase64Images(document.employeeSubmission, 1173);
        }

        return new A4(
            employeeSubmission,
            document.employeeName ?? `${firstName} ${middleName} ${lastName}`,
            document.ssn ?? ssn,
            document.streetAddress ?? `${userAddress.thoroughfare} ${userAddress?.premise}`,
            document.city ?? userAddress.locality,
            document.state ?? userAddress.administrativeArea,
            document.zipCode ?? userAddress.postalCode,
            document.noPersonalExemptions ?? '',
            document.singleOrMarriedFilingSeparate ?? '',
            document.marriedOrSingleClaimingHead ?? '',
            document.numberOfDependents ?? '',
            document.additionalWitholding ?? '',
            false,
            document.totalExemptions ?? '',
            document.employerName ?? companyName,
            document.ein ?? ein,
            document.employerAddress ?? `${companyAddress.thoroughfare} ${companyAddress.premise}`,
            document.employerCity ?? companyAddress.locality,
            document.employerState ?? companyAddress.administrativeArea,
            document.employerZipCode ?? companyAddress.postalCode,
        )
    }

    encode(document, base64) {
        return {
            ...document,
            base64: base64,
            ssn: this.ssn,
            employeeName: this.employeeName,
            streetAddress: this.streetAddress,
            city: this.city,
            state: this.state,
            zipCode: validateInteger(this.zipCode),
            noPersonalExemptions: this.noPersonalExemptions ? validateInteger(this.noPersonalExemptions) : null,
            singleOrMarriedFilingSeparate: this.singleOrMarriedFilingSeparate ? this.singleOrMarriedFilingSeparate : null,
            marriedOrSingleClaimingHead: this.marriedOrSingleClaimingHead ? this.marriedOrSingleClaimingHead : null,
            numberOfDependents: this.numberOfDependents ? validateInteger(this.numberOfDependents) : null,
            additionalWitholding: this.additionalWitholding ? validateDecimal(this.additionalWitholding) : null,
            totalExemptions: this.totalExemptions,
            employerName: this.employerName,
            ein: validateInteger(this.ein),
            employerAddress: this.employerAddress,
            employerCity: this.employerCity,
            employerState: this.employerState,
            employerZipCode: validateInteger(this.employerZipCode),
        }
    }

}