/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import CustomControl from "../../components/controls/CustomControl";
import CustomTextArea from "../../components/controls/CustomTextArea";
import SwitchControl from "../../components/SwitchControl";
import CustomButton from "../../components/CustomButton";
import { AppGlobals } from "../../App";
import { getRejectionMessage } from "../../services/ClientService";
import { rejectApplicant } from "../../services/JobApplicationService";
import EmailTemplate from "../../components/EmailTemplate";
import Cookies from "universal-cookie";
import { getAutomaticRejectionMessage } from "../../services/MyCompany";
import { useHistory } from "react-router-dom";

export default function RejectModal({jobApplication, hideModal, clientIdentifier, handleUpdateReject, loadApplicantData}){

    const [isLoading, setIsLoading] = useState(false);
    const [isRejecting, setIsRejecting] = useState(false);
    const [sendRejection, setSendRejection] = useState(true);
    const [rejectionMessage, setRejectionMessage] = useState('');
    
    useEffect(()=>{
        loadData();
    }, [])

    async function loadData(){
        setIsLoading(true)
        const response = clientIdentifier ? await getRejectionMessage(clientIdentifier) : await getAutomaticRejectionMessage() ;
        if(response.status === 200){
            setRejectionMessage(response.rejectionMessage);
        } else{
            AppGlobals.alert('danger', response.message)
        }
        setIsLoading(false)
    }

    async function handleRejectApplicant(){
        setIsRejecting(true);
        const response = await rejectApplicant(jobApplication.uid, sendRejection, rejectionMessage.replaceAll('\n', '<br/>'));
        if(response.status === 200){
            AppGlobals.alert('success', response.message)
            handleUpdateReject(jobApplication.uid);
            loadApplicantData(jobApplication.uid);
            hideModal();
        } else{
            AppGlobals.alert('danger', response.message)
        }
        setIsRejecting(false);
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Rejecting Applicant</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SwitchControl style={{marginBottom: 24}} title={'Send Rejection Email'} value={sendRejection} setValue={()=>setSendRejection(cur=>!cur)}/>
                {sendRejection && 
                    <div style={{overflow: 'auto'}}>
                        <EmailTemplate message={rejectionMessage} setRejectionMessage={setRejectionMessage} name={`${jobApplication.firstName} ${jobApplication.lastName}`}/>
                    </div>
                }   
            </Modal.Body>
            <Modal.Footer>
                <CustomButton isLoading={isRejecting} onClick={handleRejectApplicant} variant={'danger'}>
                    Reject {jobApplication.firstName} {jobApplication.lastName}
                </CustomButton>
            </Modal.Footer>
        </>
    )
}