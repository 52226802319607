/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/


import WH4Page1 from '../../../../../assets/WH-4-1.png';
import WH4Page2 from '../../../../../assets/WH-4-2.png';
import {Document, Page, Image, } from '@react-pdf/renderer';
import moment from 'moment';
import Signature from "../../../Signature/Signature"
import WH4 from "./WH4"
import AbsoluteText from '../../AbsoluteText';
import { formatSsn } from '../../../../../tools';

type WH4PdfProps = {
    wh4: WH4,
    signature: Signature
}

export default function WH4Pdf({wh4, signature}: WH4PdfProps) {
    return (
        <Document>
            <Page size='LETTER' style={{fontSize: 8}}>
                <Image src={WH4Page1}/>
                <AbsoluteText y={127} x={123} text={wh4.fullName}/>
                <AbsoluteText y={127} x={453} text={formatSsn(wh4.ssnOrITIN)}/>
                <AbsoluteText y={141} x={126} text={wh4.homeAddress}/>
                <AbsoluteText y={141} x={286} text={wh4.city}/>
                <AbsoluteText y={141} x={392} text={wh4.state}/>
                <AbsoluteText y={141} x={463} text={wh4.zipCode}/>
                <AbsoluteText y={154} x={280} text={wh4.countyOfResidence}/>
                <AbsoluteText y={168} x={300} text={wh4.countyOfEmployment}/>

                <AbsoluteText y={180} x={425} text={wh4.countiesEffectiveNextYear ? 'X' : ''} fontSize={8}/>

                <AbsoluteText y={205} x={506} text={wh4.entitledExemption}/>
                <AbsoluteText y={227} x={506} text={wh4.marriedExemption}/>
                <AbsoluteText y={238} x={506} text={wh4.dependentExemptions}/>

                <AbsoluteText y={267} x={281} text={wh4.olderThan65 ? 'X' : ''} fontSize={8}/>
                <AbsoluteText y={267} x={316.6} text={wh4.legallyBlind ? 'X' : ''} fontSize={8}/>
                <AbsoluteText y={267} x={394} text={wh4.spouseOlderThan65 ? 'X' : ''} fontSize={8}/>
                <AbsoluteText y={267} x={429} text={wh4.spouseLegallyBlind ? 'X' : ''} fontSize={8}/>

                <AbsoluteText y={278} x={506} text={`${wh4.subtotalAgeBlind()}`}/>
                <AbsoluteText y={291} x={506} text={`${wh4.totalExemptions()}`}/>
                <AbsoluteText y={302.5} x={506} text={wh4.entitledExemptionForDependents}/>
                <AbsoluteText y={315} x={506} text={wh4.entitledExemptionForDependentsFirstTime}/>
                <AbsoluteText y={328} x={506} text={wh4.entitledExemptionForAdoptedDependents}/>
                <AbsoluteText y={338} x={495} text={wh4.additionalStateWitholding}/>
                <AbsoluteText y={349.2} x={495} text={wh4.additionalCountyWitholding}/>

                <Image src={signature.image} style={{position: 'absolute', top: 369, left: 130, width: 75, height: 10}}/>
                <AbsoluteText y={372} x={455} text={moment().format('MM/DD/YYYY')}/>
            </Page>
            <Page size='LETTER' style={{fontSize: 10}}>
                <Image src={WH4Page2}/>
            </Page>
        </Document>
    )
}