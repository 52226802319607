/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useState } from 'react';
import React from 'react';
import Cookies from 'universal-cookie';
import Modal from 'react-bootstrap/Modal';
import CustomControl from '../../components/controls/CustomControl';
import logo from '../../assets/TTA-Long-white.png';
import CustomButton from '../../components/CustomButton';
import { login } from '../../services/AuthenticationService';
import { AppGlobals } from '../../App';
import MFA from './MFA';
import { Validation } from '../../validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import ForgotPassword from './ForgotPassword';

export default function Login({}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [tokenIdentifier, setTokenIdentifier] = useState(undefined);
    const [showMfa, setShowMfa] = useState(false);

    const [error, setError] = useState(undefined);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showForgotPassword, setShowForgotPassword] = useState(false);

    const handleSubmit = async (event) => {
        event?.preventDefault();
        setIsSubmitting(true);
        setError(undefined);

        const response = await login(email, password);
        if (response.status === 200) {
            if (response.token) {
                AppGlobals.CookieMonster.set('token', response.token);
                AppGlobals.checkAuthentication();
            } else if (response.tokenIdentifier) {
                setTokenIdentifier(response.tokenIdentifier);
                setShowMfa(true);
            } else {
                setError('Something went wrong. Please contact IT');
            }
        } else {
            setError(response.message);
        }

        setIsSubmitting(false);
    }
    

    return (
        <div style={{position: 'relative', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 12}}>
            <Card style={{maxWidth: '100%', display: 'flex', flexDirection: 'row', width: 800, minHeight: 600, borderRadius: 8}}>
                <Container fluid>
                    <Row style={{height: '100%'}}>
                        <Col md={6} style={{padding: 6}}>
                            <Card.Body className='login-lhs-card' style={{padding: 32, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: 'var(--bs-primary)', textAlign: 'center', borderRadius: 6}}>
                                <div>
                                    <img src={logo} style={{padding: 8, maxWidth: '100%'}}/>
                                    <h2 style={{color: 'white', fontWeight: 'bolder'}}>Recruiter</h2>
                                </div>
                                {/* <FontAwesomeIcon icon={faTruck} size='5x' style={{color: 'white'}}/> */}
                                <span style={{color: 'white', opacity: 0.9, fontWeight: 'bold'}}>A comprehensive solution to your recruiting needs</span>
                            </Card.Body>
                        </Col>
                        <Col md={6} style={{padding: 0}}> 
                            <Card.Body style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', gap: 12, height: '100%', padding: 32}}>
                                <div style={{textAlign: 'center'}}>
                                    <h1>Hello!</h1>
                                    <span style={{color: 'gray'}}>Please login below.</span>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                                    <CustomControl noAsterisk floatingLabel type='text' title='Email Address' value={email} setValue={setEmail} validation={Validation.nonEmptyString}/>
                                    <CustomControl noAsterisk floatingLabel type='password' title='Password' value={password} setValue={setPassword} validation={Validation.nonEmptyString}/>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', gap: 4, }}>
                                    <CustomButton onClick={handleSubmit} isLoading={isSubmitting} disabled={!email || !password}>
                                        Login
                                    </CustomButton>
                                    { error &&
                                        <span style={{color: 'var(--bs-danger)'}}>{error}</span>
                                    }
                                    <Button style={{background:'none', backgroundColor:'none', boxShadow:'none', border:'none', color: 'var(--bs-primary)', marginTop: 36}} onClick={()=>setShowForgotPassword(true)}>Forgot Password?</Button>
                                </div>
                            </Card.Body>
                        </Col>

                    </Row>
                </Container>
            </Card>
            <Modal show={showMfa} backdrop='static' centered size='lg'>
                <MFA tokenIdentifier={tokenIdentifier}/>
            </Modal>
            <Modal show={showForgotPassword} onHide={()=>{setShowForgotPassword(false)}}>
                <ForgotPassword hideModal={()=>setShowForgotPassword(false)}/>
            </Modal>
        </div>
    )
    


}
