/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Address from "../Address";
import { Validation } from "../../validation";
import { UidGenerator, validateDecimalFixed } from "../../tools";
import StateObject from "../State/StateObject";
import Decoder from "../../decoding";

export default class EmploymentHistory extends StateObject {
    uid; //: number;
    employerName; //: string;
    startingPayAmount; //: number;
    startingPayType; //: string;
    endingPayAmount; //: number;
    endingPayType; //: string;
    jobTitle; //: string;
    responsibilities; //: string;
    reasonForLeaving; //: string;
    startDate; //: string;
    endDate; //: string;
    employerPhoneNumber; //: string | undefined;
    employerEmail; //: string | undefined;
    employerAddress; //: Address | undefined;

    constructor(
        employerName,
        startingPayAmount,
        startingPayType,
        endingPayAmount,
        endingPayType,
        jobTitle,
        responsibilities,
        reasonForLeaving,
        startDate,
        endDate,
        employerPhoneNumber,
        employerEmail,
        employerAddress,
    ) {
        super();
        this.uid = UidGenerator.getUid();
        this.employerName = employerName;
        this.startingPayAmount = startingPayAmount;
        this.startingPayType = startingPayType;
        this.endingPayAmount = endingPayAmount;
        this.endingPayType = endingPayType;
        this.jobTitle = jobTitle;
        this.responsibilities = responsibilities;
        this.reasonForLeaving = reasonForLeaving;
        this.startDate = startDate;
        this.endDate = endDate;
        this.employerPhoneNumber = employerPhoneNumber;
        this.employerEmail = employerEmail;
        this.employerAddress = employerAddress;
        this.initChildren();
    }

    static initDefault() {
        return new EmploymentHistory(
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            Address.initDefault(),
        );
    }

    static decode(json) {
        const decoder = new Decoder(json);
        const employerName = decoder.decode('employerName', Decoder.StringStrict);
        const startingPayAmount = decoder.decode('startingPayAmount', Decoder.Decimal);
        const startingPayType = decoder.decode('startingPayType', Decoder.String);
        const endingPayAmount = decoder.decode('endingPayAmount', Decoder.Decimal);
        const endingPayType = decoder.decode('endingPayType', Decoder.StringStrict);
        const jobTitle = decoder.decode('jobTitle', Decoder.StringStrict);
        const responsibilities = decoder.decode('responsibilities', Decoder.StringStrict);
        const reasonForLeaving = decoder.decode('reasonForLeaving', Decoder.StringStrict);
        const startDate = decoder.decode('startDate', Decoder.Date);
        const endDate = decoder.decode('endDate', Decoder.Date, {defaultValue: null, warn: false} );
        const employerPhoneNumber = decoder.decode('employerPhoneNumber', Decoder.StringStrict, {defaultValue: undefined, warn: false});
        const employerEmail = decoder.decode('employerEmail', Decoder.StringStrict, {defaultValue: undefined, warn: false});
        const employerAddress = Address.decode(json.employerAddress, true);

        if (decoder.checkForErrors()) {
            return new EmploymentHistory(employerName, startingPayAmount, startingPayType, endingPayAmount, endingPayType, jobTitle, responsibilities, reasonForLeaving, startDate, endDate, employerPhoneNumber, employerEmail, employerAddress);
        } else {
            return EmploymentHistory.initDefault();
        }
    }

    encode() {
        return {
            employerName: this.employerName,
            startingPayAmount: validateDecimalFixed(this.startingPayAmount, 2),
            startingPayType: this.startingPayType,
            endingPayAmount: validateDecimalFixed(this.endingPayAmount, 2),
            endingPayType: this.endingPayType,
            jobTitle: this.jobTitle,
            responsibilities: this.responsibilities,
            reasonForLeaving: this.reasonForLeaving,
            startDate: this.startDate,
            endDate: this.endDate,
            employerPhoneNumber: this.employerPhoneNumber,
            employerEmail: this.employerEmail,
            employerAddress: this.employerAddress.encode()
        }
    }

    
}