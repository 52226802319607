/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { CSSProperties, ReactNode, useState } from 'react';
//import logo from './logo.svg';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import NavigationBar from './components/NavigationBar/NavigationBar';
import { Button, CloseButton, Modal, Table, Toast } from 'react-bootstrap';
import QuickTable, { QuickTableRow } from './components/QuickTable';
// import JobPost from './models/JobPost';
import TTABackground from './components/TTABackground';
import { checkAuthentication } from './services/AuthenticationService';
import Login from './views/Login/Login';
import LoadingWrapper from './components/LoadingWrapper';
import Cookies from 'universal-cookie';
// import JobApplicationForm from './views/JobApplication/JobApplicationForm';
import Apply from './views/Apply/Apply';
import './App.css';
import 'draft-js/dist/Draft.css';
import JobCreator from './views/JobCreator/JobCreator';
import JobPosts from './views/JobPosts/JobPosts';
import Clients from './views/Clients/Clients';
import Dashboard from './views/Dashboard/Dashboard';
import CustomAlert from './components/CustomAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faInfoCircle, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import ClientOnboarding from './views/ClientOnboarding/ClientOnboarding';
import JobPostPage from './views/JobPost/JobPostPage';
import ErrorPage from './views/ErrorPage';
import Settings from './views/Settings/Settings';
import Recruiters from './views/ClientViews/Recruiters';
import Applicants from './views/ClientViews/Applicants/Applicants';
import ApplyListings from './views/Apply/ApplyListings';
import EmployeeOnboarding from './views/EmployeeOnboarding/EmployeeOnboarding';
import MyCompany from './views/MyCompany/MyCompany';
import Admin from './views/Admin/Admin';
import PasswordReset from './views/PasswordReset/PasswordReset';

type AppState = {
    alertType: 'warning' | 'danger' | 'info' | 'success';
    alertTitle: string;
    showAlert: boolean;
    auth: Auth;
    isLoading: boolean;
    notifications: any[];
    initializedHelloMedian: boolean;
}

type Auth = {
    userIdentifier: number | undefined;
    clientUserIdentifier: number | undefined;
    adminUserIdentifier: number | undefined;
}

export default class App extends React.Component<{}, AppState> {

    constructor(props: any) {
        super(props);
        this.state = {
            alertType: 'warning',
            alertTitle: '',
            showAlert: false,
            auth: {userIdentifier: undefined, clientUserIdentifier: undefined, adminUserIdentifier: undefined},
            isLoading: true,
            notifications: [],
            initializedHelloMedian: false,
        }

        this.alert = this.alert.bind(this);
        this.dismissAlert = this.dismissAlert.bind(this);
        this.checkAuthentication = this.checkAuthentication.bind(this);

        AppGlobals.alert = this.alert;
        AppGlobals.checkAuthentication = this.checkAuthentication
    }

    componentDidMount(): void {
        this.checkAuthentication();
    }

    alert(type: 'warning' | 'danger' | 'info' | 'success', title: string) {
        this.setState({alertType: type, alertTitle: title, showAlert: true});
    }

    dismissAlert() {
        this.setState({showAlert: false});
    }

    async checkAuthentication() {
        this.setState({isLoading: true});
        const response = await checkAuthentication();
        if (response.status === 200) {
            if (response.userData) {
                AppGlobals.CookieMonster.set('userData', response.userData);
            }
            if (!this.state.initializedHelloMedian) {
                this.initHelloMedian(response.userData);
            }
            AppGlobals.CookieMonster.set('companyName', response.companyName);
            AppGlobals.CookieMonster.set('auth', response.auth);
            this.setState({notifications: response.notifications ?? [], auth: response.auth});
        } else if (response.status === 401) {
            this.setState({auth: {userIdentifier: undefined, clientUserIdentifier: undefined, adminUserIdentifier: undefined}});
        }
        this.setState({isLoading: false});
    }

    initHelloMedian(userData: any) {
        this.setState({initializedHelloMedian: true});
        const text = `My Tactical Recruiting - ${userData.firstName} ${userData.lastName}`;

        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = `! function () {
                var e = window.Median = window.Median || [];
                if (!e._initialized)
                if (e._snippet_loaded) console.warn("Median Snippet loaded twice.");
                else {
                    e._snippet_loaded = !0, e._snippet_version = 3, e.methods = ["init", "identify", "endSession",
                    "on"], e.factory = function (n) {
                        return function () {
                        var t = Array.prototype.slice.call(arguments);
                        e.push([n, t])
                        }
                    };
                    for (var n = 0; n < e.methods.length; n++) {
                    var t = e.methods[n];
                    e[t] = e.factory(t)
                    }
                    var i = document.createElement("script");
                    i.type = "text/javascript", i.async = !0, i.src =
                    "https://js.hellomedian.com/v1/mdn-cobrowse-screenshare.js";
                    var a = document.getElementsByTagName("script")[0];
                    a.parentNode.insertBefore(i, a)
                }
                }();
            Median.init("f2a996e2-70ec-4c5c-90c0-944c7f5abb6b");
            Median.identify('${text}');`
        ;
        document.body.appendChild(s);
    }
    

    render() {

        return (
            <Router>
                <div style={{display: 'flex', flexDirection: 'column', height: '100vh', textAlign: 'center', position: 'relative', backgroundColor: 'var(--bs-primary)'}}>
                    <TTABackground style={undefined}/>
                    <div style={{position: 'absolute', height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column', textAlign: 'start'}}>
                        <LoadingWrapper isLoading={this.state.isLoading}>
                            { (this.state.auth.userIdentifier || this.state.auth.clientUserIdentifier) && 
                                <NavigationBar auth={this.state.auth} notifications={this.state.notifications} setNotifications={(value: any) => {this.setState({notifications: value})}}/>
                            }
                            <div className='hidden-scrollbar' style={{overflowY: 'scroll', flex: 1, position: 'relative'}}>
                                { this.state.auth.userIdentifier ?
                                    <Switch>
                                        <Route path='/home' component={Dashboard}/>
                                        <Route path='/settings' component={Settings}/>
                                        <Route path='/post' key={1} component={JobCreator}/>
                                        <Route path='/edit/:jobPost' key={2} component={JobCreator}/>
                                        <Route path='/jobs' component={JobPosts}/>
                                        <Route path='/job/:jobPost' component={JobPostPage}/>
                                        <Route path='/clients' component={Clients}/>
                                        <Route path='/my-company' component={MyCompany}/>
                                        <Route path='/apply/:jobPostIdentifier/:terminalIdentifier' component={Apply}/>
                                        <Route path='/apply' component={ApplyListings}/>
                                        <Route path='/client-onboarding/:onboardingToken' component={ClientOnboarding}/>
                                        <Route path='/employee-onboarding/:onboardingToken' component={EmployeeOnboarding}/>
                                        <Route exact path='/'>
                                            <Redirect to={'/home'}/>
                                        </Route>
                                        <Route path='/' component={ErrorPage}/>
                                    </Switch>
                                : this.state.auth.clientUserIdentifier ?
                                    <Switch>
                                        <Route path='/settings' component={Settings}/>
                                        <Route path='/recruiters' component={Recruiters}/>
                                        <Route path='/applicants' component={Applicants}/>
                                        <Route path='/apply/:jobPostIdentifier/:terminalIdentifier' component={Apply}/>
                                        <Route path='/client-onboarding/:onboardingToken' component={ClientOnboarding}/>
                                        <Route path='/employee-onboarding/:onboardingToken' component={EmployeeOnboarding}/>
                                        <Route exact path='/'>
                                            <Redirect to={'/recruiters'}/>
                                        </Route>
                                        <Route path='/' component={ErrorPage}/>
                                    </Switch>
                                : this.state.auth.adminUserIdentifier ?
                                    <Switch>
                                        <Route path='/' exact component={Admin}/>
                                        <Route path='/'>
                                            <Redirect to={'/'}/>
                                        </Route>
                                    </Switch>
                                :
                                    <Switch>
                                        <Route path='/apply/:jobPostIdentifier/:terminalIdentifier' component={Apply}/>
                                        <Route path='/apply' component={ApplyListings}/>
                                        <Route path='/client-onboarding/:onboardingToken' component={ClientOnboarding}/>
                                        <Route path='/employee-onboarding/:onboardingToken' component={EmployeeOnboarding}/>
                                        <Route path='/password-reset/:passwordToken' component={PasswordReset}/>
                                        <Route path='/' component={Login}/>
                                    </Switch>
                                }
                            </div>
                        </LoadingWrapper>
                    </div>
                </div>
                <Modal show={this.state.showAlert} onHide={this.dismissAlert}>
                    <Modal.Body style={{padding: 8}}>
                        {
                            <div style={{display: 'flex', gap: 12, alignItems: 'center'}}>
                                <FontAwesomeIcon 
                                    style={{color: this.state.alertType == 'danger' ? 'red' : this.state.alertType == 'warning' ? 'gold' : this.state.alertType == 'info' ? 'var(--bs-primary)' : 'green'}} 
                                    icon={this.state.alertType == 'info' ? faInfoCircle : this.state.alertType === 'success' ? faCheck : faTriangleExclamation}
                                />
                                <div style={{flex: 1, overflow: 'hidden'}}>
                                    <span style={{overflowWrap: 'break-word', wordWrap: 'break-word'}}>{this.state.alertTitle}</span>
                                </div>
                            </div>
                        }
                        
                    </Modal.Body>
                </Modal>
            </Router>
        );
    }

}

export class AppGlobals {
    static alert: (type: 'warning' | 'danger' | 'info' | 'success', title: string) => void;
    static checkAuthentication: () => void;
    static CookieMonster: Cookies = new Cookies();
}
