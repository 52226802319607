/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import CustomControl from '../../components/controls/CustomControl';
import { Validation } from '../../validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import CustomButton from '../../components/CustomButton';
import { createClient, onboardClient } from '../../services/ClientService';
import { AppGlobals } from '../../App';
import StateObject from '../../models/State/StateObject';
import ControlStateProps from '../../models/State/ControlStateProps';
import Client from './models/Client';
import { validateInteger } from '../../tools';
import AddressAutoFill from '../../components/controls/AddressAutoFill';
import Address from '../../models/Address';
import ClientUser from './models/ClientUser';
import SubscriberWrapper from '../../components/SubscriberWrapper';
import CustomToolTip from '../../components/CustomToolTip';

export default function ClientOnboardModal({hideModal, handleAddClient}) {
    const [ein, setEin] = useState('');

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [companyExists, setCompanyExists] = useState(true);

    const [formObject, newFormObject] = useState(() => new StateObject({
        client: Client.initDefault(),
        clientUser: ClientUser.initDefault(),
    }))

    const handleCheckEin = async () => {
        setIsSubmitting(true);
        const response = await onboardClient(ein);
        if (response.status === 200) {
            setCompanyExists(response.clientExists);
            if(response.clientExists){
                hideModal();
                AppGlobals.alert('success', response.message)
            }
            formObject.client.setState('ein', validateInteger(ein));
        } else {
            AppGlobals.alert('danger', response.message);
        }
        setIsSubmitting(false);
    }

    const handleSubmitNewClient = async () => {
        setIsSubmitting(true);
        const response = await createClient(formObject.client.encode(), formObject.clientUser.encode());
        if (response.status === 200) {
            AppGlobals.alert('success', response.message);
            formObject.client.uid = response.uid;
            handleAddClient(formObject.client);
            hideModal();
        } else if(response.status === 400){
            AppGlobals.alert('danger', response.message);
        } else {
            AppGlobals.alert('danger', response.message);
        }
        setIsSubmitting(false);
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Onboard New Client</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <Row style={{marginBottom: 18}}>
                    <h5>Client Details:</h5>
                    <CustomControl disabled={!companyExists} floatingLabel title='EIN' maxLength={9} type='number' value={ein} setValue={(value)=>setEin(value)} breakpoints={{sm: 12}} />
                    {!companyExists &&
                    <>
                        <CustomControl floatingLabel title='Client Name' maxLength={50} stateProps={new ControlStateProps('name', formObject.client, Validation.nonEmptyString)} breakpoints={{sm: 12}}/>
                        <CustomControl floatingLabel title='Client Email' maxLength={50} stateProps={new ControlStateProps('email', formObject.client, Validation.email)} breakpoints={{sm: 12}}/>
                        <div style={{display: 'flex', alignItems: 'center', gap: 24}}>
                            <CustomControl floatingLabel title='First Advantage Account ID' maxLength={50} stateProps={new ControlStateProps('faAccountId', formObject.client)} breakpoints={{sm: 11}}/>
                            <CustomToolTip content={`This is the account ID associated with the client\'s First Advantage Account. This ID must be accurate to allow automated background screening. This ID can be added later.`} placement={"right"}/>
                        </div>
                        <AddressAutoFill title='Client Address' stateProps={new ControlStateProps('address', formObject.client, Validation.notUndefined)}/>
                        {/* <div>
                            <FontAwesomeIcon icon={faInfoCircle} style={{color: 'var(--bs-primary)', marginRight: 8}}/>
                            <span>Upon submission, the client will recieve an email with an invitation to add themselves to your recruiting company. If the client accepts the request, your company will be notified and the client will automatically appear on this page.</span>
                        </div> */}
                    </>
                    }
                </Row>
                {!companyExists &&
                    <Row style={{marginBottom: 12}}>
                        <h5>Client AO:</h5>
                        <CustomControl floatingLabel title='AO First Name' maxLength={20} stateProps={new ControlStateProps('firstName', formObject.clientUser, Validation.nonEmptyString)} breakpoints={{sm: 6}}/>
                        <CustomControl floatingLabel title='AO Last Name' maxLength={20} stateProps={new ControlStateProps('lastName', formObject.clientUser, Validation.nonEmptyString)} breakpoints={{sm: 6}}/>
                        <CustomControl floatingLabel title='AO Phone Number' maxLength={10} stateProps={new ControlStateProps('phoneNumber', formObject.clientUser, Validation.phoneNumber)} breakpoints={{lg: 6}} type='number'/>
                        <CustomControl floatingLabel title='AO Email' maxLength={50} stateProps={new ControlStateProps('email', formObject.clientUser, Validation.email)} breakpoints={{lg: 6}}/>
                    </Row>
                }
            </Modal.Body>
            <Modal.Footer>
                {companyExists 
                    ? <CustomButton disabled={Validation.ein(ein)}  onClick={handleCheckEin} isLoading={isSubmitting}>Submit</CustomButton>
                    : <CustomButton stateObject={formObject} onClick={handleSubmitNewClient} isLoading={isSubmitting}>Submit new client</CustomButton>
                }
            </Modal.Footer>

        </>
    )
}