/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/types';

type CustomToolTipProps = {
    style?: React.CSSProperties
    text?: string;
    content?: ReactNode;
    placement?: Placement
}

export default function CustomToolTip({text, style, content, placement}: CustomToolTipProps) {
    return (
        <OverlayTrigger placement={placement} overlay={
            <Popover>
                <Popover.Body>
                    { content ?
                        content
                        :
                        <span style={{whiteSpace: 'pre-line'}}>{text}</span>
                    }
                </Popover.Body>
            </Popover>
        }>
            <FontAwesomeIcon style={{color: 'var(--bs-primary)', ...style}} icon={faQuestionCircle}/>
        </OverlayTrigger>
    )
}