/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import StateObject from "./StateObject";

export default class ControlStateProps {
    key; //string;
    stateKey; //string;
    stateObject; //StateObject;

    constructor(stateKey: string, stateObject: StateObject, validation = undefined, uniqueKey = undefined) {
        this.key = stateKey + uniqueKey;
        this.stateKey = stateKey;
        this.stateObject = stateObject;

        if (validation) {
            stateObject.setValidation(stateKey, validation);
        }
    }

    getValue() {
        return this.stateObject[this.stateKey as keyof StateObject];
    }

    setValue(value: any) {
        this.stateObject.setState(this.stateKey, value);
    }

    getIsValid() {
        return this.stateObject.getIsValid(this.stateKey);
    }

    getIsInvalid() {
        return this.stateObject.getIsInvalid(this.stateKey);
    }

    hasValidation(){
        return this.stateObject.hasValidation(this.stateKey);
    }

    getValidationMessage() {
        return this.stateObject.getValidationMessage(this.stateKey);
    }

    subscribe(callback: (showValidationMessages?: boolean) => void) {
        this.stateObject.subscribe(this.key, this.stateKey, callback);
    }
}

