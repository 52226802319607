/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useState } from "react";
import { Accordion, Card, ListGroup, Modal } from "react-bootstrap";
import KeyValueRow from "../../../components/KeyValueRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingWrapper from "../../../components/LoadingWrapper";
import moment from "moment";
import RichTextEditor from "../../../components/RichText/RichTextEditor";
import ControlStateProps from "../../../models/State/ControlStateProps";
import Cookies from "universal-cookie";
import JobApplicationForJobPost from "../../JobPosts/models/JobApplicationForJobPost";
import { getJobApplication } from "../../../services/JobApplicationService";
import { DegreeLevels, EducationLevels, MilitaryBranches, PayTypes } from "../../../enums";
import { addressToString, cleanUsd, customQuestionAnswerToString, formatPhoneNumber, formatSsn, pastTimestampToString } from "../../../tools";
import JobApplicationTimeline from "../../JobPost/JobApplicationTimeline";
import SubscriberWrapper from "../../../components/SubscriberWrapper";
import { getApplication } from "../../../services/ClientUserService";


export default function JobApplicationViewClient({selectedJobApplication}){

    const [isLoading, setIsLoading] = useState(false);
    const [jobApplication, setJobApplication] = useState(JobApplicationForJobPost.initDefault());
    const [clients, setClients] = useState([]);

    useEffect(()=>{
        loadData()
    }, [selectedJobApplication])


    async function loadData() {
        setIsLoading(true);

        const response = await getApplication(selectedJobApplication.uid);
        if (response.status === 200) {
            setJobApplication(JobApplicationForJobPost.decode(response.jobApplication));
            setClients(response.clients);
        }
        setIsLoading(false);
    }

        const educationElements = jobApplication.education.map((ed, index) => {
            return (
                <ListGroup.Item key={index}>
                    <p>{'Education #' + (index + 1)}</p>
                    <ListGroup>
                        <KeyValueRow isListItem title='Name of Institution' value={ed.name}/>
                        <KeyValueRow isListItem title='Level of Education' value={EducationLevels[ed.level]}/>
                        <KeyValueRow isListItem title='Location' value={ed.city + ', ' + ed.state}/>
                        <KeyValueRow isListItem title='Attended' value={moment(ed.startDate).format('MMM YYYY') + ' - ' + moment(ed.endDate).format('MMM YYYY')}/>
                        <KeyValueRow isListItem title='Graduated?' value={ed.graduated ? 'Yes' : 'No'}/>
                        { ed.fieldOfStudy &&
                            <KeyValueRow isListItem title='Field of Study' value={ed.fieldOfStudy}/>
                        }
                        { ed.degreeLevel &&
                            <KeyValueRow isListItem title='Degree Level' value={DegreeLevels[ed.degreeLevel]}/>
                        }
                    </ListGroup>
                </ListGroup.Item>
            )
        })
        const employmentHistoryElements = jobApplication.employmentHistory.map((eh, index) => {
            return (
                <ListGroup.Item key={index}>
                    <p>{'Previous Employment #' + (index + 1)}</p>
                    <ListGroup>
                        <KeyValueRow isListItem title='Name of Employer' value={eh.employerName}/>
                        <KeyValueRow isListItem title='Job Title' value={eh.jobTitle}/>
                        <KeyValueRow isListItem title='Start & End Date' value={moment(eh.startDate).format('MMM YYYY') + ' - ' + moment(eh.endDate).format('MMM YYYY')}/>
                        <KeyValueRow isListItem title='Starting Pay' value={cleanUsd(eh.startingPayAmount) + ' ' + PayTypes[eh.startingPayType]}/>
                        <KeyValueRow isListItem title='Ending Pay' value={cleanUsd(eh.endingPayAmount) + ' ' + PayTypes[eh.endingPayType]}/>
                        <KeyValueRow isListItem title='Responsibilities' value={eh.responsibilities}/>
                        <KeyValueRow isListItem title='Reason for Leaving' value={eh.reasonForLeaving}/>
                        { eh.employerPhoneNumber &&
                            <KeyValueRow isListItem title='Employer Phone Number' value={formatPhoneNumber(eh.employerPhoneNumber)}/>
                        }
                        { eh.employerEmail && 
                            <KeyValueRow isListItem title='Employer Email' value={eh.employerEmail}/>
                        }
                        { eh.employerAddress &&
                            <KeyValueRow isListItem title='Employer Address' value={addressToString(eh.employerAddress)}/>
                        }
                    </ListGroup>
                </ListGroup.Item>
            )
        })

        const resume = !jobApplication ? undefined : (
            <ListGroup>
                <KeyValueRow isListItem title='First Name' value={jobApplication.firstName}/>
                <KeyValueRow isListItem title='Middle Name' value={jobApplication.middleName}/>
                <KeyValueRow isListItem title='Last Name' value={jobApplication.lastName}/>
                <KeyValueRow isListItem title='Phone Number' value={formatPhoneNumber(jobApplication.phoneNumber)}/>
                <KeyValueRow isListItem title='Email Address' value={jobApplication.email}/>
                <KeyValueRow isListItem title='Date of Birth' value={moment(jobApplication.dateOfBirth).format('MMM D, YYYY') + ' (age ' + moment().diff(moment(jobApplication.dateOfBirth), 'years') + ')'}/>
                <KeyValueRow isListItem title='First Date Available to Work' value={moment(jobApplication.dateAvailable).format('MMM D, YYYY')}/>
                <KeyValueRow isListItem title='Address' value={addressToString(jobApplication.address)}/>
                <KeyValueRow isListItem title='Legally Eligible to Work in the U.S.?' value={jobApplication.eligibleToWork ? 'Yes' : 'No'}/>
                <KeyValueRow isListItem title='Ever Convicted of a Felony?' value={jobApplication.convictedOfFelony ? 'Yes' : 'No'}/>
                { jobApplication.convictedOfFelony ?
                    <KeyValueRow isListItem title='Felony Explanation' value={jobApplication.felonyConvictionNotes}/>: <></>
                }
                <KeyValueRow isListItem title='Veteran?' value={jobApplication.isVeteran ? 'Yes' : 'No'}/>
                { jobApplication.militaryService &&
                    <ListGroup.Item>
                        <p>Military Service</p>
                        <ListGroup>
                            <KeyValueRow isListItem title='Branch' value={MilitaryBranches[jobApplication.militaryService.branch]}/>
                            <KeyValueRow isListItem title='Start Date' value={moment(jobApplication.militaryService.startDate).format('MMM D, YYYY')}/>
                            <KeyValueRow isListItem title='End Date' value={moment(jobApplication.militaryService.endDate).format('MMM D, YYYY')}/>
                            <KeyValueRow isListItem title='Type of Discharge' value={jobApplication.militaryService.dischargeType}/>
                            { jobApplication.militaryService.dishonorableDischargeExplanation &&
                                <KeyValueRow isListItem title='Reason for Dishonorable Discharge' value={jobApplication.militaryService.dishonorableDischargeExplanation}/>
                            }
                            <KeyValueRow isListItem title='Rank at Discharge' value={jobApplication.militaryService.rankAtDischarge}/>
                        </ListGroup>
                    </ListGroup.Item>
                }
                <KeyValueRow isListItem title='Provided background check consent?' value={jobApplication.backgroundCheckConsent ? 'Yes' : 'No'}/>
                {educationElements}
                {employmentHistoryElements}
            </ListGroup>
        )


        const customQuestionAnswers = jobApplication.customQuestionAnswers.map((qa, index) => {

            if(qa.question.type === 'text' || qa.question.type === 'textArea'){
                return (
                    <ListGroup.Item key={index}>
                        <h6 style={{fontWeight: 400}}>{qa.question.title}</h6>
                        <p style={{opacity: .6}}>{customQuestionAnswerToString(qa)}</p>
                    </ListGroup.Item>
                )
            }
            return (
                <KeyValueRow key={index} isListItem title={qa.question.title} value={customQuestionAnswerToString(qa)}/>
            )
        })

        const noteElements = jobApplication.notes.map((note, index)=>{
            return (
                <div key={note.uid}>
                    { (index === 0 || jobApplication.notes[index - 1].author.uid !== note.author.uid) &&
                        <span style={{fontWeight: 'bold', fontSize: 13}}>{note.author.firstName  + ' ' + note.author.lastName}</span>
                    }
                    <div style={{display: 'flex', justifyContent: 'space-between', gap: 8, alignItems: 'center'}}>
                        <div style={{display: 'flex', gap: 4, alignItems: 'end'}}>
                            <div style={{backgroundColor: 'white', border: '1px solid lightgray', padding: '2px 8px 2px 8px', borderRadius: 12, padding: 12, wordBreak: 'break-word'}}>
                                <RichTextEditor readOnly editorState={note.richText}/>
                            </div>
                        </div>
                        <div style={{display: 'flex', gap: 4, flexWrap: 'wrap', justifyContent: 'flex-end'}}>
                            <span style={{whiteSpace: 'nowrap'}}>{pastTimestampToString(note.dateTime)}</span>
                            {note.lastEdited && <span style={{opacity: .5}}>&nbsp;(edited)</span>}
                        </div>
                    </div>
                </div>         
            )
        })

        return (
            <>
                <LoadingWrapper isLoading={isLoading} color='var(--bs-primary)'>
                    <Modal.Header closeButton>
                        <Modal.Title>({selectedJobApplication.jobPostTitle}) Viewing applicant {jobApplication.firstName} {jobApplication.lastName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            <Accordion>
                                <Accordion.Item eventKey='1'>
                                    <Accordion.Header><span style={{fontWeight: 'bold'}}>Resume</span></Accordion.Header>
                                    <Accordion.Body>{resume}</Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey='2'>
                                    <Accordion.Header><span style={{fontWeight: 'bold'}}>Custom Question Answers</span></Accordion.Header>
                                    <Accordion.Body>
                                        <ListGroup>
                                            {customQuestionAnswers}
                                        </ListGroup>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey='0'>
                                    <Accordion.Header><span style={{fontWeight: 'bold'}}>Timeline</span></Accordion.Header>
                                    <Accordion.Body>
                                        <JobApplicationTimeline jobApplication={jobApplication}/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                { jobApplication.clientIdentifier &&
                                <Accordion.Item eventKey='4'>
                                    <Accordion.Header><span style={{fontWeight: 'bold'}}>Interviews</span></Accordion.Header>
                                    <Accordion.Body>
                                        <SubscriberWrapper stateProps={new ControlStateProps('interviews', jobApplication)} render={()=>{
                                            return (
                                                <>
                                                    {jobApplication.interviews?.map((i, index)=>    
                                                    <Card key={index} style={{marginTop: 60}}>
                                                            <Card.Header style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}} >
                                                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bolder', margin: 0, height: 30, 
                                                                width: 30, marginRight: 16, color: 'white', borderRadius: '100%', padding: 12, backgroundColor: 'rgb(45, 105, 160)'}}>{index+1}</div>
                                                                <h6 style={{margin: 0, fontSize: 15}}>{moment(i.dateTime).format('MMM Do YYYY, h:mm a')} &nbsp;&nbsp; </h6> 
                                                            </Card.Header>
                                                        <Card.Body>
                                                            <RichTextEditor readOnly editorState={i.richText}/>
                                                        </Card.Body>
                                                        <Card.Footer>
                                                            <h6 style={{textAlign: 'right', fontSize: 15}}>&nbsp;Conducted By: {i.user ? `${i.user.firstName} ${i.user.lastName}` : `${clients.find(c=>jobApplication.clientIdentifier == c.uid)?.name}`}</h6>
                                                        </Card.Footer>
                                                    </Card>)}
                                                </>
                                            )
                                        }}/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                }
                                <Accordion.Item eventKey='3'>
                                    <Accordion.Header><span style={{fontWeight: 'bold'}}>Notes</span></Accordion.Header>
                                    <Accordion.Body>
                                        <div style={{display: 'flex', flexDirection: 'column', gap: 6, marginTop: 12}}>
                                           {noteElements}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Modal.Body>
                </LoadingWrapper>
            </>

        )
}