/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useRef, useState } from 'react';

import { Button, Modal } from 'react-bootstrap';
import ControlStateProps from '../../../../models/State/ControlStateProps';
import { Validation } from '../../../../validation';
import moment from 'moment';
import SubscriberWrapper from '../../../../components/SubscriberWrapper';
import DocumentWizard from '../../DocumentWizard';
import DocumentSignatureButton from '../../DocumentSignatureButton';
import { pdfjs } from 'react-pdf';
import DocumentField from '../../DocumentField';
import DocumentFileControl from '../../DocumentFileControl';
import DocumentRadioCheck from '../../DocumentRadioCheck';
import { AppGlobals } from '../../../../App';
import { reactPdfToBase64, validateDecimalFixed } from '../../../../tools';
import { submitDocument } from '../../../../services/EmployeeOnboardingService';
import MDD from './MDD';
import MDDFormBackground from './MDDFormBackground';
import MDDPdf from './MDDPdf';
import { PDFViewer } from '@react-pdf/renderer';
import LoadingWrapper from '../../../../components/LoadingWrapper';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function MDDForm({document, userData, companyData, signature, hideModal, onboardingToken, updateDocumentToCompleted}) {
    const [zoom, setZoom] = useState(1);

    const [mdd, _] = useState(MDD.initFromData(document, userData, companyData));

    const fieldRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

    const handleSubmit = async () => {
        const pdf = <MDDPdf signature={signature} mdd={mdd}/>;
        const base64 = await reactPdfToBase64(pdf);
        const response = await submitDocument(mdd.encode(document, base64), onboardingToken);
        if (response.status === 200) {
            updateDocumentToCompleted('mdd', base64);
            hideModal();
            AppGlobals.alert('success', response.message);
        } else {
            AppGlobals.alert('danger', response.message);
        }
    }

    return (
        <>
            <Modal.Header closeButton/>
            <Modal.Body style={{padding: 0, overflowX: 'scroll', backgroundColor: 'lightgray'}}>
                <div style={{transform: `scale(${zoom})`, display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 12, height: (1100 + 16) * zoom}}>
                    <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                        <MDDFormBackground mdd={mdd}/>
                        <DocumentField maxLength={30} innerRef={fieldRefs[0]} stateProps={new ControlStateProps('employeeName', mdd, Validation.nonEmptyString)} x={77} y={182} width={228}/>
                        <DocumentField maxLength={30} innerRef={fieldRefs[1]} stateProps={new ControlStateProps('email', mdd, Validation.nonEmptyString)} x={310} y={182} width={228}/>
                        <DocumentField maxLength={10} innerRef={fieldRefs[2]} stateProps={new ControlStateProps('phoneNumber', mdd, Validation.nonEmptyString)} x={543} y={182} width={228}/>
                        <DocumentField maxLength={30} innerRef={fieldRefs[3]} stateProps={new ControlStateProps('fiName1', mdd, Validation.nonEmptyString)} x={77} y={264} width={228}/>
                        <DocumentField maxLength={9} innerRef={fieldRefs[4]} stateProps={new ControlStateProps('routingNumber1', mdd, Validation.nonEmptyString)} x={310} y={264} width={228} type='number'/>
                        <DocumentField maxLength={17} innerRef={fieldRefs[5]} stateProps={new ControlStateProps('accountNumber1', mdd, Validation.nonEmptyString)} x={543} y={264} width={228} type='number'/>
                        <DocumentRadioCheck rhs innerRef={fieldRefs[6]} value={0} stateProps={new ControlStateProps('accountType1', mdd, Validation.notUndefined, 'accountType1-0')} x={150} y={310} width={15} height={15}/>
                        <DocumentRadioCheck rhs innerRef={fieldRefs[7]} value={1} stateProps={new ControlStateProps('accountType1', mdd, Validation.notUndefined, 'accountType1-1')} x={252} y={310} width={15} height={15}/>
                        <SubscriberWrapper stateProps={new ControlStateProps('depositType', mdd, null, 'wrapper1 for depositType')} render={() => {
                            return (
                                <DocumentField onChange={(value) => {if (mdd.depositType == 1) {mdd.setState('depositAmount2', validateDecimalFixed(100 - value, 2))}}} minDecimal={0} maxDecimal={mdd.depositType == 1 ? 100 : 999999.99} innerRef={fieldRefs[8]} stateProps={new ControlStateProps('depositAmount1', mdd, Validation.greaterThanZero)} x={310} y={309} width={228} type='number'/>
                            )
                        }}/>
                        <DocumentRadioCheck rhs innerRef={fieldRefs[9]} value={0} stateProps={new ControlStateProps('depositType', mdd, Validation.notUndefined, '1')} x={634} y={310} width={15} height={15}/>
                        <DocumentRadioCheck onChange={() => {mdd.setState('depositAmount2', validateDecimalFixed(100 - Math.min(mdd.depositAmount1, 100), 2)); mdd.setState('depositAmount1', Math.min(mdd.depositAmount1, 100))}} rhs innerRef={fieldRefs[10]} value={1} stateProps={new ControlStateProps('depositType', mdd, Validation.notUndefined, '2')} x={744} y={310} width={15} height={15}/>

                        <DocumentField maxLength={30} innerRef={fieldRefs[11]} stateProps={new ControlStateProps('fiName2', mdd, Validation.nonEmptyString)} x={77} y={264 + 127} width={228}/>
                        <DocumentField maxLength={9} innerRef={fieldRefs[12]} stateProps={new ControlStateProps('routingNumber2', mdd, Validation.nonEmptyString)} x={310} y={264 + 127} width={228} type='number'/>
                        <DocumentField maxLength={17} innerRef={fieldRefs[13]} stateProps={new ControlStateProps('accountNumber2', mdd, Validation.nonEmptyString)} x={543} y={264 + 127} width={228} type='number'/>
                        <DocumentRadioCheck rhs innerRef={fieldRefs[14]} value={0} stateProps={new ControlStateProps('accountType2', mdd, Validation.notUndefined, 'accountType2-0')} x={150} y={310 + 127} width={15} height={15}/>
                        <DocumentRadioCheck rhs innerRef={fieldRefs[15]} value={1} stateProps={new ControlStateProps('accountType2', mdd, Validation.notUndefined, 'accountType2-1')} x={252} y={310 + 127} width={15} height={15}/>
                        <SubscriberWrapper stateProps={new ControlStateProps('depositType', mdd, null, 'wrapper2 for depositType')} render={() => {
                            return (
                                <DocumentField disabled={mdd.depositType == 1} minDecimal={0} maxDecimal={999999.99} innerRef={fieldRefs[16]} stateProps={new ControlStateProps('depositAmount2', mdd, Validation.greaterThanZero)} x={310} y={309 + 127} width={461} type='number'/>
                            )
                        }}/>
                        
                        <DocumentSignatureButton innerRef={fieldRefs[17]} signature={signature} stateProps={new ControlStateProps('signed', mdd, Validation.true)} x={85} y={834}/>
                        <SubscriberWrapper stateProps={new ControlStateProps('signed', mdd, null, 'wrapper for date')} render={() => {
                            return (!mdd.signed ? '' : 
                                <span style={{position: 'absolute', left: 424, top: 854}}>{moment().format('MM/DD/YYYY')}</span>
                            )
                        }}/>


                        <span style={{position: 'absolute', left: 75, right: 375 + 75, top: 950, textAlign: 'center', fontWeight: 'bold'}}>Account #1 Bank Document</span>
                        <DocumentFileControl innerRef={fieldRefs[18]} defaultLabel='Submitted Bank Document' stateProps={new ControlStateProps('attachment1', mdd, Validation.notUndefined)} x={75} y={990} width={350 - 5} type='file'/>
                        <span style={{position: 'absolute', left: 375 + 75, right: 75, top: 950, textAlign: 'center', fontWeight: 'bold'}}>Account #2 Bank Document</span>
                        <DocumentFileControl innerRef={fieldRefs[19]} defaultLabel='Submitted Bank Document' stateProps={new ControlStateProps('attachment2', mdd, Validation.notUndefined)} x={850 - 75 - 350 + 5} y={990} width={350 - 5} type='file'/>
                    </div>
                </div>
                        {/*      
                <PDFViewer style={{width: '100%', height: 1200}}>
                    <MDDPdf mdd={mdd} signature={signature}/>
                </PDFViewer> */}
            </Modal.Body>
            <DocumentWizard document={mdd} fieldRefs={fieldRefs} refCount={20} zoom={zoom} setZoom={setZoom} onSubmit={handleSubmit}/>
        </>
    )
}
