/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import SignatureControl from './SignatureControl';
import CustomControl from '../../../components/controls/CustomControl';
import { Validation } from '../../../validation';
import { AppGlobals } from '../../../App';
import ControlStateProps from '../../../models/State/ControlStateProps';
import CustomButton from '../../../components/CustomButton';
import StateObject from '../../../models/State/StateObject';
import DocumentFileControl from '../DocumentFileControl';
import AddressAutoFill from '../../../components/controls/AddressAutoFill';
import { updateTcUserDataEmployeeOnboarding, updateLhUserDataEmployeeOnboarding, updateMTBUserDataEmployeeOnboarding } from '../../../services/EmployeeOnboardingService.tsx'
import EmergencyContactLh from '../../../models/EmergencyContactLh';
import Address from '../../../models/Address';
import TCEmergencyContacts from '../../../models/TCEmergencyContacts';

export default function SignatureForm({hideModal, signature, setSignature, userData, companyData, documentRequest, isEmployer, onboardingToken}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [localSignature, setLocalSignature] = useState(signature);
    const [emergencyContacts, _setEmergencyContacts] = useState(TCEmergencyContacts.initFromUserData(userData));
    const [emergencyContactsLh, _setEmergencyContactsLh] = useState(((documentRequest.lhCompanyUserIdentifier || documentRequest.mtbUserIdentifier) && userData.emergencyContacts.length) ? userData.emergencyContacts.map(ec => new EmergencyContactLh(ec.uid, ec.userIdentifier, ec.firstName, ec.lastName, ec.phoneNumber ?? '', ec.email ?? '', ec.address ? Address.decodeValidated(ec.address, true) : undefined, ec.relationship)) : [EmergencyContactLh.initDefault()]);
    const [parentStateObject, _setParentStateObject] = useState(() => new StateObject({userData, emergencyContacts: (documentRequest.lhCompanyUserIdentifier || documentRequest.mtbUserIdentifier) ? emergencyContactsLh : emergencyContacts, dl: null, ssn: null}));
    const [_, forceUpdate] = useReducer(x => x + 1, 0);

    const fieldRefs = [
        useRef(), useRef()
    ]

    async function updateUserData(){
        setIsSubmitting(true);
        if(documentRequest.tcCompanyUserIdentifier){
            const response = await updateTcUserDataEmployeeOnboarding(onboardingToken, userData.ssn, userData.dateOfBirth, userData.address.encode(), emergencyContacts.encode(), parentStateObject.dl, parentStateObject.ssn);
            if(response.status === 200){
                confirmSignature();
            }else{
                AppGlobals.alert('danger', response.message);
            }
        }else if(documentRequest.lhCompanyUserIdentifier){
            const response = await updateLhUserDataEmployeeOnboarding(onboardingToken, userData.ssn, userData.dateOfBirth, userData.address.encode(), parentStateObject.emergencyContacts.map(ec => ec.encode()), parentStateObject.dl, parentStateObject.ssn);
            if(response.status === 200){
                confirmSignature();
            }else{
                AppGlobals.alert('danger', response.message);
            }
        }else if(documentRequest.mtbUserIdentifier){
            const response = await updateMTBUserDataEmployeeOnboarding(onboardingToken, userData.ssn, userData.dateOfBirth, userData.address.encode(), parentStateObject.emergencyContacts.map(ec => ec.encode()), parentStateObject.dl, parentStateObject.ssn);
            if(response.status === 200){
                confirmSignature();
            }else{
                AppGlobals.alert('danger', response.message);
            }
        } else {
            confirmSignature();
        }
        setIsSubmitting(false);
    }

    async function confirmSignature(){
        setSignature(localSignature);
        hideModal();
    }

    function handleAddEmergencyContact(){
        const newEmergencyContact = EmergencyContactLh.initDefault();
        newEmergencyContact['userIdentifier'] = userData.uid;
        parentStateObject.setState('emergencyContacts', [...parentStateObject.emergencyContacts, newEmergencyContact])
        forceUpdate();
    }

    function handleDeleteEmergencyContact(index){
        parentStateObject.setState('emergencyContacts', parentStateObject.emergencyContacts.toSpliced(index, 1));
        forceUpdate();
    }


    const hasi9 = documentRequest.documents.reduce((acc, el)=>{
        if(el.documentType === 'i9')
            return true;
        return acc;
    }, false)

    const renderEContacts = documentRequest.includeEContacts == 1 && !documentRequest.submittedEContacts;
    
    return (
        <>
            <Modal.Header>
                <Modal.Title>{isEmployer ? 'Create Signature' : 'Enter Information'}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{padding: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 12, textAlign: 'center'}}>
                {!isEmployer && 
                    <Container style={{padding: 0}}>
                        <Row style={{marginBottom: 18, marginLeft: 0}}>
                            <Col xxl={12}>
                                <h5 style={{marginBottom: 12}}>Personal Information</h5>
                            </Col>
                            <CustomControl breakpoints={{lg: 6}} floatingLabel title={'Date of Birth'} type='date' stateProps={new ControlStateProps('dateOfBirth', userData, Validation.date)}/>
                            <CustomControl breakpoints={{lg: 6}} floatingLabel title={'Social Security Number'} type='number' maxLength={9}  stateProps={new ControlStateProps('ssn', userData, Validation.ssn)}/>
                            <AddressAutoFill label='Home Address' stateProps={new ControlStateProps('address', userData, Validation.notUndefined)}/>
                            <hr style={{margin: 18}}/>
                            { renderEContacts && documentRequest.tcCompanyUserIdentifier &&
                                <>
                                    <Col xxl={12}>
                                        <h5 style={{marginBottom: 12}}>Emergency Contact #1</h5>
                                    </Col>
                                    <CustomControl breakpoints={{lg: 4}} floatingLabel title={'First Name'} maxLength={20} stateProps={new ControlStateProps('eContact1FirstName', emergencyContacts, companyData.require1EContact ? Validation.nonEmptyString : null)}/>
                                    <CustomControl breakpoints={{lg: 4}} floatingLabel title={'Last Name'} maxLength={20} stateProps={new ControlStateProps('eContact1LastName', emergencyContacts, companyData.require1EContact ? Validation.nonEmptyString : null)}/>
                                    <CustomControl breakpoints={{lg: 4}} floatingLabel title={'Relationship'} maxLength={20} stateProps={new ControlStateProps('eContact1Relationship', emergencyContacts, companyData.require1EContact ? Validation.nonEmptyString : null)}/>
                                    <CustomControl breakpoints={{lg: 6}} floatingLabel title={'Phone Number'} maxLength={10} stateProps={new ControlStateProps('eContact1PhoneNumber', emergencyContacts, companyData.require1EContact ? Validation.nonEmptyString : null)}/>
                                    <CustomControl breakpoints={{lg: 6}} floatingLabel title={'Email'} maxLength={50} stateProps={new ControlStateProps('eContact1Email', emergencyContacts, companyData.require1EContact ? Validation.nonEmptyString : null)}/>
                                    <AddressAutoFill stateProps={new ControlStateProps('eContact1Address', emergencyContacts, Validation.notUndefined)} label='Emergency Contact Address #1'/>
                                    <Col xxl={12} style={{marginTop: 30}}>
                                        <h5 style={{marginBottom: 12}}>Emergency Contact #2</h5>
                                    </Col>
                                    <CustomControl breakpoints={{lg: 4}} floatingLabel title={'First Name'} maxLength={20} stateProps={new ControlStateProps('eContact2FirstName', emergencyContacts)}/>
                                    <CustomControl breakpoints={{lg: 4}} floatingLabel title={'Last Name'} maxLength={20} stateProps={new ControlStateProps('eContact2LastName', emergencyContacts)}/>
                                    <CustomControl breakpoints={{lg: 4}} floatingLabel title={'Relationship'} maxLength={20} stateProps={new ControlStateProps('eContact2Relationship', emergencyContacts)}/>
                                    <CustomControl breakpoints={{lg: 6}} floatingLabel title={'Phone Number'}  maxLength={10} stateProps={new ControlStateProps('eContact2PhoneNumber', emergencyContacts)}/>
                                    <CustomControl breakpoints={{lg: 6}} floatingLabel title={'Email'} maxLength={50} stateProps={new ControlStateProps('eContact2Email', emergencyContacts)}/>
                                    <AddressAutoFill stateProps={new ControlStateProps('eContact2Address', emergencyContacts, null)} label='Emergency Contact Address #2'/>
                                </>

                            }
                            { renderEContacts && (documentRequest.lhCompanyUserIdentifier || documentRequest.mtbUserIdentifier) &&
                                <Col xxl={12} style={{marginBottom: 24}}>
                                    <h5>Emergency Contacts</h5>
                                    {parentStateObject.emergencyContacts.map((contact, index) => {
                                        return <EmergencyContactForm key={index} contact={contact} index={index} totalContacts={parentStateObject.emergencyContacts.length} handleDeleteEmergencyContact={handleDeleteEmergencyContact}/>
                                    })}
                                    <Button onClick={handleAddEmergencyContact} style={{marginTop: 10, marginLeft: 'auto', display: 'block'}} variant={'outline-primary'}>Add Emergency Contact</Button>
                                </Col>
                            }
                            <>
                                { !documentRequest.dlDataIdentifier && hasi9 &&
                                    <Col xl={6} style={{flex: 1}}>
                                        <p style={{textAlign: 'center', fontWeight: 'bold', marginTop: 12, marginBottom: 8}}>Please Upload Your Driver's License</p>
                                        <DocumentFileControl stateProps={new ControlStateProps('dl', parentStateObject, Validation.notUndefined)} innerRef={fieldRefs[0]} width={'100%'} type='file'/>
                                    </Col>
                                }
                                { !documentRequest.ssnDataIdentifier && hasi9 &&
                                    <Col xl={6} style={{flex: 1}}>
                                        <p style={{textAlign: 'center', fontWeight: 'bold', marginTop: 12, marginBottom: 8}}>Please Upload Your Social Security Card</p>
                                        <DocumentFileControl stateProps={new ControlStateProps('ssn', parentStateObject, Validation.notUndefined)} innerRef={fieldRefs[1]} width={'100%'} type='file'/>
                                    </Col>
                                }
                            </>
                        </Row>
                    </Container>
                }
                <span>Please use the canvas below to draw your signature. This signature will be used to sign all of your employment documents.</span>
                <SignatureControl initialValue={localSignature} setValue={setLocalSignature}/>
            </Modal.Body>
            <Modal.Footer>
                {!isEmployer ? <CustomButton isLoading={isSubmitting} disabled={!localSignature} stateObject={parentStateObject} onClick={() => updateUserData()}>Continue</CustomButton>
                : <Button disabled={!localSignature} onClick={confirmSignature}>Use This Signature</Button>}
            </Modal.Footer>
        </>
    )
}

export function EmergencyContactForm({contact, index, handleDeleteEmergencyContact, totalContacts}) {
    return (
        <Container>
            <Row>
                <Col xxl={12}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 18, marginTop: 8}}>
                        <h6>Emergency Contact #{index+1}</h6>
                        {totalContacts > 1 &&  <Button variant='outline-danger' onClick={() => handleDeleteEmergencyContact(index)}>Delete Contact</Button>}
                    </div>
                </Col>
                <CustomControl breakpoints={{lg: 6}} maxLength={30} floatingLabel type='text' title='First Name' stateProps={new ControlStateProps('firstName', contact, Validation.nonEmptyString)}/>
                <CustomControl breakpoints={{lg: 6}} maxLength={30} floatingLabel type='text' title='Last Name' stateProps={new ControlStateProps('lastName', contact, Validation.nonEmptyString)}/>
                <CustomControl breakpoints={{lg: 6}} maxLength={20} floatingLabel type='text' title='Relationship' stateProps={new ControlStateProps('relationship', contact, Validation.nonEmptyString)}/>
                <CustomControl breakpoints={{lg: 6}} maxLength={10} floatingLabel type='number' title='Phone Number' stateProps={new ControlStateProps('phoneNumber', contact, Validation.none)} noAsterisk/>
                <CustomControl breakpoints={{lg: 12}} maxLength={50} floatingLabel type='text' title='Email Address' stateProps={new ControlStateProps('email', contact, Validation.none)} noAsterisk/>
                <div style={{marginBottom: 10}}></div>
                <AddressAutoFill stateProps={new ControlStateProps('address', contact, null)} label='Emergency Contact Address'/>
            </Row>
            <hr/>
        </Container>
    )
}
