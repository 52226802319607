/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useEffect, useReducer} from 'react';
import Form from 'react-bootstrap/Form';
import { faPlus, faTrash, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, InputGroup } from 'react-bootstrap';
import OptionalCol from '../OptionalCol';

export default function ChildSupportControl({value, setValue, stateProps, breakpoints}) {
    const [_, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        stateProps?.subscribe((_) => {
            forceUpdate();
        });
    }, [stateProps]);

    const handleGetValue = () => {
        return value ?? stateProps.getValue();
    }

    const handleSetValue = (value) => {
        if (setValue) {
            setValue(value);
        }
        if (stateProps) {
            stateProps.setValue(value);
        }
    }

    const handleSetChildSupport = (index, newValue) => {
        const newChildSupport = Array.from(handleGetValue());
        newChildSupport[index] = newValue;
        handleSetValue(newChildSupport);
    }

    const handleAddChildSupport = () => {
        const newChildSupport = Array.from(handleGetValue());
        newChildSupport.push('');
        handleSetValue(newChildSupport);
    }

    const handleRemoveChildSupport = (index) => {
        handleSetValue(handleGetValue().toSpliced(index, 1));
    }



    const csElements = handleGetValue().map((cs, index) => {
        return (
            <InputGroup key={index} style={{flexWrap: 'nowrap'}}>
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control type='number' placeholder={`Child Support #${index + 1}`} value={cs} onChange={(event) => {handleSetChildSupport(index, event.target.value)}} style={{borderRadius: 0}}/>
                <Button variant='outline-primary'  onClick={() => {handleRemoveChildSupport(index)}}>
                    <FontAwesomeIcon icon={faTrash}/>
                </Button>
            </InputGroup>

        )
    })

    return (
        <OptionalCol breakpoints={breakpoints}>
            <div style={{border: '1px solid lightgray', borderRadius: 6, padding: 12}}>
                <p style={{marginBottom: 4, fontSize: 14, opacity: 0.65}}>Child Support</p>
                <div style={{display: 'flex', flexWrap: 'wrap', gap: 12}}>
                    {csElements}
                    <Button variant='outline-primary' onClick={handleAddChildSupport}><FontAwesomeIcon icon={faPlus}/></Button>
                </div>
            </div>
        </OptionalCol>
    )
}