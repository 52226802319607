/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import { Modal} from "react-bootstrap";
import { JobSites } from "../../enums";
import CustomRadio from "../../components/controls/CustomRadio";
import CustomButton from "../../components/CustomButton";
import SubscriberWrapper from "../../components/SubscriberWrapper";
import CustomCheck from "../../components/controls/CustomCheck";
import JobStatusToolTip from "../../components/JobStatusToolTip";
import ControlStateProps from "../../models/State/ControlStateProps";

import JobPostTerminalEditor from "./JobPostTerminalEditor";
import SwitchControl from "../../components/SwitchControl";

export default class JobCreatorSubmissionModal extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            search: '',
            showDropdown: false,
        }

        props.jobPost.subscribe('terminalChangeCreatorSubmissionModal', null , () => {
            this.forceUpdate();
        })
    }
    

    render() {

        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>One Last Thing...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{display: 'flex', alignItems: 'center', gap: 8, marginBottom: 8}}>
                        <SwitchControl style={{width: '100%', height: 58}} title='Activate Upon Submission' stateProps={new ControlStateProps('active', this.props.jobPost)}/>
                        <JobStatusToolTip statuses={['active', 'inactive']}/>
                    </div>
                    <SubscriberWrapper stateProps={new ControlStateProps('active', this.props.jobPost, null, 'postToShowWhenStatusActiveWrapper')} render={()=> (
                        <>
                            {this.props.jobPost.active == 1 &&
                                <CustomCheck title='Post to:' items={JobSites} stateProps={new ControlStateProps('jobSites',this.props.jobPost)} inline={true} breakpoints={{xxl: 12}}/>
                            }
                        </>
                    )}/>
                    <JobPostTerminalEditor terminals={this.props.terminals} jobPost={this.props.jobPost} handleAddTerminal={this.props.handleAddTerminal} handleDeleteTerminal={this.props.handleDeleteTerminal}/>
                </Modal.Body>
                <Modal.Footer>
                    {!this.props.jobPost.terminals.length > 0 && <p style={{marginRight: 12}}>Please select at least one terminal</p>}
                    <CustomButton disabled={!this.props.jobPost.terminals.length>0} isLoading={this.props.isSubmitting} stateObject={this.props.jobPost} onClick={this.props.handleSubmit}>
                        Submit
                    </CustomButton>
                </Modal.Footer>
            </>
        )
    }
}