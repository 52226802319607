/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import JobPostsForNotificationSettings from "../views/Settings/models/JobPostsForNotificationSettings";
import NotificationSetting from "../views/Settings/models/NotificationSetting";
import { getResponse, getToken} from "./serviceTools";

export async function getNotificationSettings(){
    const data = {
        type: 'user',
        target: 'getNotificationSettings',
        token: getToken(),
    }

    return await getResponse(data);
}

export async function updateNotificationSettings(notificationSettings: NotificationSetting[], jobPosts: JobPostsForNotificationSettings[],){
    const data = {
        type: 'user',
        target: 'updateNotificationSettings',
        token: getToken(),
        notificationSettings,
        jobPosts
    }

    return await getResponse(data);
}

export async function markNotificationRead(uid: number) {
    const data = {
        type: 'user',
        target: 'markNotificationRead',
        token: getToken(),
        uid: uid
    }
    return await getResponse(data);
}

export async function changeUserPassword(currentPassword: string, newPassword: string){
    const data = {
        type: 'user',
        target: 'changePassword',
        token: getToken(),
        currentPassword,
        newPassword
    }

    return await getResponse(data);
}