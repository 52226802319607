/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../decoding";
import Address from "../../../models/Address";
import StateObject from "../../../models/State/StateObject";


export default class TerminalForApplication extends StateObject {
    uid: number;
    name: string;
    address: Address;
    availablePositionsRemaining: number;
    lat: number;
    lng: number;

    constructor(uid: number, name: string, address: Address, availablePositionsRemaining: number, lat: number, lng: number) {
        super();
        this.uid = uid;
        this.name = name;
        this.address = address;
        this.availablePositionsRemaining = availablePositionsRemaining;
        this.lat = lat;
        this.lng = lng;
        this.initChildren();
    }

    static initDefault(): TerminalForApplication {
        return new TerminalForApplication(-1, '', Address.initDefault(), 0, 0, 0);
    }

    static decode(json: any): TerminalForApplication {
        const decoder = new Decoder(json);
        const uid: number = decoder.decode('uid', Decoder.Uid);
        const name: string = decoder.decode('name', Decoder.StringStrict);
        const address: Address = Address.decode(json.address) as Address;
        const availablePositionsRemaining: number = decoder.decode('availablePositionsRemaining', Decoder.Integer, {defaultValue: 0, warn: false})
        const lat: number = decoder.decode('lat', Decoder.Decimal);
        const lng: number = decoder.decode('lng', Decoder.Decimal);
        
        if (decoder.checkForErrors()) {
            return new TerminalForApplication(uid, name, address, availablePositionsRemaining, lat, lng);
        } else {
            return TerminalForApplication.initDefault();
        }

    }

    static initFromTerminalCreation(obj: any) {
        return new TerminalForApplication(-1, obj.name, obj.address, 0, obj.address.lat, obj.address.lng);
    }

    encode(){
        return{
            uid: this.uid,
            name: this.name,
            address: this.address.encode(),
            availablePositionsRemaining: this.availablePositionsRemaining,
            lat: this.lat,
            lng: this.lng
        }
    }
}