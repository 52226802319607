/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import CustomButton from "./CustomButton";
import { AppGlobals } from "../App";

export default function DeleteConfirmation({uid, api, onSuccess, title, message, buttonLabel}) {

    const [isLoading, setIsLoading] = useState(false);

    const handleApi = async () => {
        setIsLoading(true);
        const response = await api(uid);
        if (response.status === 200) {
            if (onSuccess) {
                onSuccess();
            }
        } else {
            AppGlobals.alert("danger", response.message);
        }
        setIsLoading(false);
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            { message &&
                <Modal.Body>
                    {message}
                </Modal.Body>
            }
            <Modal.Footer>
                <CustomButton variant={'danger'} isLoading={isLoading} onClick={handleApi}>
                    {buttonLabel}
                </CustomButton>
            </Modal.Footer>
        </>
    )
}