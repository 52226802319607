/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from 'moment'
import VA4Page1 from '../../../../../assets/VA-4-1.png'
import VA4Page2 from '../../../../../assets/VA-4-2.png'
import Signature from '../../../Signature/Signature'
import AbsoluteText from '../../AbsoluteText'
import VA4 from './VA4'
import { Document, Image, Page, Text } from '@react-pdf/renderer'
import { formatSsn, usdFormatter, validateDecimal } from '../../../../../tools'

type VA4PdfProps = {
    va4: VA4
    signature: Signature
}

export default function VA4Pdf({va4, signature}: VA4PdfProps) {

    return (
        <Document>
            <Page size='LETTER' style={{fontSize: 12}}>
                <Image src={VA4Page1}/>
                <AbsoluteText y={103} x={435} text={va4.claimYourself}/>
                <AbsoluteText y={128} x={435} text={va4.marriedSpouseNotClaimed}/>
                <AbsoluteText y={151} x={435} text={va4.numberOfDependents}/>
                <AbsoluteText y={170} x={435} text={`${va4.subtotalPersonalExemptions()}`}/>
                <AbsoluteText y={200} x={435} text={va4.olderThan65}/>
                <AbsoluteText y={223} x={435} text={va4.spouseOlderThan65}/>
                <AbsoluteText y={247} x={435} text={va4.legallyBlind}/>
                <AbsoluteText y={271} x={435} text={va4.spouseLegallyBlind}/>
                <AbsoluteText y={292} x={529} text={`${va4.subtotalAgeBlind()}`}/>
                <AbsoluteText y={314} x={529} text={`${va4.totalExemptions()}`}/>

                <AbsoluteText y={418} x={45} text={formatSsn(va4.ssn)}/>
                <AbsoluteText y={418} x={180} text={va4.name}/>
                <AbsoluteText y={448} x={45} text={va4.streetAddress}/>
                <AbsoluteText y={476} x={45} text={va4.city}/>
                <AbsoluteText y={476} x={315} text={va4.state}/>
                <AbsoluteText y={476} x={448} text={va4.zipCode}/>

                <AbsoluteText y={526} x={526} text={`${va4.subtotalPersonalExemptions()}`}/>
                <AbsoluteText y={556} x={526} text={`${va4.subtotalAgeBlind()}`}/>
                <AbsoluteText y={576} x={526} text={`${va4.totalExemptions()}`}/>
                <AbsoluteText y={596} x={493} text={va4.additionalWitholdings ? usdFormatter.format(va4.additionalWitholdings) : ''}/>

                <AbsoluteText y={624} x={490} text={va4.notSubjectToWitholdingFromInstructions ? 'X' : ''} fontSize={17}/>
                <AbsoluteText y={669} x={490} text={va4.notSubjectToWitholdingServiceMember ? 'X' : ''} fontSize={17}/>
                
                <Text style={{position: 'absolute', left: 554, top: 608, backgroundColor: 'white', height: 2, width: 2}}></Text>

                <Image src={signature.image} style={{position: 'absolute', top: 690, left: 50, width: 130, height: 22}}/>
                <AbsoluteText y={695} x={480} text={moment().format('MM/DD/YYYY')}/>
            </Page>
            <Page size='LETTER' style={{fontSize: 10}}>
                <Image src={VA4Page2}/>
            </Page>
        </Document>
    )
}