/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { acceptOnboardingRequest } from "../../services/ClientOnboardingService";
import LoadingWrapper from "../../components/LoadingWrapper";
import { Card } from "react-bootstrap";

export default function ClientOnboarding({}) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const [message, setMessage] = useState(undefined);

    const { onboardingToken } = useParams();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        const response = await acceptOnboardingRequest(onboardingToken);
        if (response.status === 200) {
            setMessage(response.message);
        } else {
            setError(response.message);
        }
        setIsLoading(false);
    }


    return (
        <LoadingWrapper isLoading={isLoading}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                <Card>
                    <Card.Body>
                        <Card.Title style={{color: message ? 'green' : 'red'}}>{message ?? error}</Card.Title>
                    </Card.Body>
                </Card>
            </div>
        </LoadingWrapper>
    )
}