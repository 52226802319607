/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Button, ButtonGroup, Card, Dropdown, Form, InputGroup, ListGroup, ListGroupItem, Modal, Pagination } from "react-bootstrap";
import LoadingWrapper from "../../components/LoadingWrapper";
import QuickTable from "../../components/QuickTable";
import { useEffect, useState } from "react";
import { AppGlobals } from "../../App";
import JobPostMinimal from "./models/JobPostMinimal";
import Sort from "../../sorting";
import { EmploymentTypes, PayTypes } from "../../enums";
import { timeSinceDateToString, usdFormatter, validateDecimal } from "../../tools";
import { useHistory } from "react-router-dom";
import { getJobPostsForApplicants } from "../../services/ApplicantService";
import SearchBar from "../../components/SearchBar";
import moment from "moment";
import './ApplyListings.css';
import CustomControl from "../../components/controls/CustomControl";
import developmentMode from "../../developmentMode";
import CustomButton from "../../components/CustomButton";
import { DistanceMatrixService } from "@react-google-maps/api";
import { within } from "@testing-library/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCab, faLocationDot } from "@fortawesome/free-solid-svg-icons";

function getDistanceFromLatlngInMiles(lat1, lng1, lat2, lng2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2-lat1);  // deg2rad below
  var dLng = deg2rad(lng2-lng1); 
  var a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLng/2) * Math.sin(dLng/2)
    ; 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c; // Distance in km
  return d*0.621371;
}

function deg2rad(deg) {
  return deg * (Math.PI/180)
}

const apiKey = developmentMode ? 'AIzaSyAWI7AlWJrd7l_ARor6Hgo0C4TYxcru_Fw' : 'AIzaSyBO6pLOjJq8W63VtQ3TPkpUW1mF7gdfJgo';

export default function ApplyListings({}){

    const [jobs, setJobs] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [jobSearch, setJobSearch] = useState('');
    const [selectedJob, setSelectedJob] = useState(null);

    const [allEmployeeTypes, setAllEmployeeTypes] = useState([]);
    const [employeeTypeFilter, setEmployeeTypeFilter] = useState([]);
    const [zipCode, setZipCode] = useState('');
    const [maxDistance, setMaxDistance] = useState('');
    const [distanceIsFiltered, setDistanceIsFiltered] = useState(false);
    const [isSettingWithinDistance, setIsSettingWithinDistance] = useState(false);

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1);

    const history = useHistory();

    async function loadData(){
        setIsLoading(true);
        const response = await getJobPostsForApplicants(zipCode, maxDistance);
        if(response.status === 200){
            setJobs(response.jobPosts.map(j=>JobPostMinimal.decode(j)));
            setAllEmployeeTypes([...new Set(response.jobPosts.flatMap(j=>EmploymentTypes[j.employmentType]))])
            setEmployeeTypeFilter([...new Set(response.jobPosts.flatMap(j=>EmploymentTypes[j.employmentType]))])
        }else{
            AppGlobals.alert('danger', response.message)
        }
        setIsLoading(false);
    }

    useEffect(()=>{
        loadData(zipCode, maxDistance);
    }, [distanceIsFiltered])

    function handleClick(job){
        if(job.terminals.length === 1){
            history.push(`/apply/${job.uid}/${job.terminals[0].uid}`);
        } else{
            setSelectedJob(job);
            setShowModal(true);
        }
    }

    function handleDistanceFilter(){
        setZipCode(zipCode)
        setMaxDistance(maxDistance);
        setDistanceIsFiltered(!distanceIsFiltered);
    }
    
    // async function handleDistanceFilter(){
    //     setIsSettingWithinDistance(true);

    //     const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode},USA&key=${apiKey}`);
    //     const data = await response.json();

    //     const zipCodeCoordinates = {
    //         lat: data.results[0].geometry.location.lat,
    //         lng: data.results[0].geometry.location.lng
    //     }

    //     const newJobs = Array.from(jobs);

    //     for(let i = 0; i < newJobs.length; i++){
    //         let withinDistance = newJobs[i].terminals.reduce((acc, el)=>{
    //             if(acc){
    //                 return true;
    //             }
    //             let distance = getDistanceFromLatlngInMiles(zipCodeCoordinates.lat, zipCodeCoordinates.lng, el.lat, el.lng);
    //             return distance < maxDistance;
    //         }, false)

    //         if(data.results[0].address_components.length < 2){
    //             withinDistance = false;
    //         }

    //         newJobs[i].withinDistance = withinDistance;
    //     }
    //     setJobs(newJobs)
    //     setDistanceIsFiltered(true);
    //     setIsSettingWithinDistance(false);
    // }

    ///////////////////////////////
    /// Filter Drop Down Items
    //////////////////////////////
    const employeeTypeFilterDropdownItems = allEmployeeTypes.map((employeeType, index) => {
        return (
            <Dropdown.Item key={index} active={employeeTypeFilter.includes(employeeType)} onClick={() => {
                if (employeeTypeFilter.includes(employeeType)) {
                    setEmployeeTypeFilter(employeeTypeFilter.filter(et => et !== employeeType));
                } else {
                    setEmployeeTypeFilter(employeeTypeFilter.concat([employeeType]));
                }
                setCurrentPage(1)
            }}>
                {employeeType}
            </Dropdown.Item>
        )
    })

    ///////////////////////////
    ///ROWS FOR QUICKTABLE 
    ///////////////////////////
    let filteredJobsLength = 0;
    const jobRows = jobs.filter((job, index)=>{

        /// Search Filter
        if(!job.title.toLowerCase().includes(jobSearch.toLowerCase())){
            return;
        }

        /// Employment Type Filter
        if(!employeeTypeFilter.includes(EmploymentTypes[job.employmentType])){
            return;
        }

        filteredJobsLength++;
        ///////////// NO FILTERS PAST THIS POINT OTHER THAN PAGINATION
        

        if(filteredJobsLength-1 >= currentPage * itemsPerPage)
            return;


        if(filteredJobsLength-1< (currentPage-1) * itemsPerPage)
            return;

        return true;
    }).map((job) => {

        const availablePositionsRemaining = job.terminals.reduce((acc, t)=>{return acc+=t.availablePositionsRemaining}, 0)
        return {
            onClick: () => handleClick(job),
            columns: [
                {value: job.title, sortMethod: Sort.alphaNumeric},
                {value: `${usdFormatter.format(job.compensationMin)} - ${usdFormatter.format(job.compensationMax)}\u00A0\u00A0${PayTypes[job.payType]}`},
                {value: EmploymentTypes[job.employmentType], sortMethod: Sort.alphaNumeric},
                {value: `${job.terminals[0]?.address.locality} (${job.terminals[0]?.address.administrativeArea}) ${job.terminals.length > 1 ? ` + ${job.terminals.length - 1}` : ''}`},
                {value: availablePositionsRemaining > 0 ? availablePositionsRemaining : ''},
                // {value: job.dateLastModified, sortMethod: Sort.date, content: timeSinceDateToString(job.dateLastModified, 30)}
            ]
        } 
    });


    ///////////////////////////
    ///LIST FOR LISTGROUP
    ///////////////////////////
    const jobList = jobs.filter((job, index)=>{

        if(!job.title.toLowerCase().includes(jobSearch.toLowerCase())){
            return;
        }

        if(index >= currentPage * itemsPerPage)
            return;
    

        if(index < (currentPage-1) * itemsPerPage)
            return;

        return employeeTypeFilter.includes(EmploymentTypes[job.employmentType])
    }).map((job, index) => {

        const availablePositionsRemaining = job.terminals.reduce((acc, t)=>{return acc+=t.availablePositionsRemaining}, 0);
        return (
            <ListGroup.Item action className='cursor-pointer' key={job.uid} onClick={() => handleClick(job)}>
                <div style={{display: 'flex', flexDirection: 'column', padding: 8}}>
                        <h4 style={{fontWeight: 'bold', marginBottom: 12}}>{job.title}</h4>
                        <h6 style={{marginBottom: 6}}>{`${usdFormatter.format(job.compensationMin)} - ${usdFormatter.format(job.compensationMax)} ${PayTypes[job.payType]}`}</h6>
                        <h6 style={{marginBottom: 6}}><strong>Employment: </strong> {EmploymentTypes[job.employmentType]}</h6>
                        {availablePositionsRemaining > 0 && <h6 style={{marginBottom: 16}}><strong>Available Positions: </strong> {availablePositionsRemaining}</h6>}
                        {/* <p style={{textAlign: 'right', margin: 0, fontSize: 13}}><strong>Last Updated: </strong> {timeSinceDateToString(job.dateLastModified, 30)}</p> */}
                        <p style={{textAlign: 'right', margin: 0, fontSize: 13}}><strong>{job.terminals.length>1 ? 'Locations' : 'Location'}: </strong> {`${job.terminals[0]?.address.locality} (${job.terminals[0]?.address.administrativeArea}) ${job.terminals.length > 1 ? ` + ${job.terminals.length - 1}` : ''}`}</p>
                </div>
            </ListGroup.Item>
        )
    });


    const pages = Math.ceil(filteredJobsLength / itemsPerPage);
    let paginationItems = [];
    for(let i = 0; i < pages; i++){
        paginationItems.push(i+1);
    }

    return (
        <LoadingWrapper isLoading={isLoading}>
        <div style={{backgroundColor: 'var(--bs-primary)', height: '100%', padding: 12, textAlign: 'start', display: 'flex', flexDirection: 'column', gap: 12}}>
            <Card style={{flex: 1, display: 'flex', flexDirection: 'column', overflowY: 'hidden'}}>
                <Card.Body style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                    <Card.Title style={{flex: 0, fontWeight: "bold", fontSize: 30, textAlign: 'center', marginRight: 24}}>Open Job Listings</Card.Title>
                    <div style={{flex: 0, maxWidth: 400, marginBottom: 12}}></div>
                    <div style={{flex: 1, overflowY: 'auto', paddingRight: 12}}>
                        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', gap: 8, marginBottom: 12}}>
                            <Dropdown autoClose='outside'>
                                <Dropdown.Toggle style={{minWidth: 240}} variant='outline-primary'>Filter By Employment Type</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {employeeTypeFilterDropdownItems}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <InputGroup className="desktop-distancefilter" style={{maxWidth: 500, margin: 'auto', marginBottom: 12}}>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faLocationDot}/> 
                            </InputGroup.Text>
                            <CustomControl
                                floatingLabel
                                title="Enter zipcode"
                                type="number"
                                value={zipCode}
                                setValue={(value)=>setZipCode(value)}
                                maxInt={99999}
                                noMaxLabel
                            />
                            <CustomControl
                                floatingLabel
                                title="Max distance (miles)"
                                type="number"
                                value={maxDistance}
                                setValue={(value)=>setMaxDistance(value)}
                                maxInt={99999}
                                noMaxLabel
                            />
                            <CustomButton isLoading={isSettingWithinDistance} onClick={handleDistanceFilter}>Filter</CustomButton>
                            <Button variant="danger" onClick={()=>{setDistanceIsFiltered(false); setZipCode(''); setMaxDistance(''); }}>Clear</Button>
                        </InputGroup>
                        <div className="mobile-distancefilter" style={{flexDirection: 'column', border: '1px solid lightgray', gap: 4, padding: 12, borderRadius: 6, marginBottom: 12}}>
                            <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon width={16} icon={faLocationDot}/> 
                            </InputGroup.Text>
                                <CustomControl
                                    floatingLabel
                                    title="Enter zipcode"
                                    type="number"
                                    value={zipCode}
                                    setValue={(value)=>setZipCode(value)}
                                    maxInt={99999}
                                    noMaxLabel
                                    />
                            </InputGroup>
                            <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faCab}/> 
                            </InputGroup.Text>
                            <CustomControl
                                floatingLabel
                                title="Max distance (miles)"
                                type="number"
                                value={maxDistance}
                                setValue={(value)=>setMaxDistance(value)}
                                maxInt={99999}
                                noMaxLabel
                            />
                            </InputGroup>
                            <CustomButton style={{width: '100%'}} isLoading={isSettingWithinDistance} onClick={handleDistanceFilter}>Filter</CustomButton>
                            <Button variant="danger" onClick={()=>{setDistanceIsFiltered(false); setZipCode('');setMaxDistance('');}}>Clear</Button>
                        </div>
                        <div style={{marginBottom: 16}}>
                            <SearchBar label='Search by job title' value={jobSearch} setValue={(value) => setJobSearch(value)}/>
                        </div>
                        <div className="desktop-listings">
                            <QuickTable
                                responsive={false}
                                hover 
                                headers={[
                                    {label: 'Job Title', sortable: true},
                                    {label: 'Compensation', sortable: false},
                                    {label: 'Employment Type', sortable: true},
                                    {label: 'Location(s)', sortable: false},
                                    {label: 'Available Positions', sortable: false},
                                ]}
                                rows={jobRows}
                            />
                        </div>
                        <div className="mobile-listings">
                            <ListGroup>
                                {jobList}
                            </ListGroup>
                        </div>
                        <div className="pagination" >
                            <div className="results-row">
                                <div>
                                    Showing results: {filteredJobsLength === 0 ? 'No results' : `${(itemsPerPage * (currentPage-1)) + 1} - ${(currentPage * itemsPerPage) < filteredJobsLength ? (currentPage * itemsPerPage) : filteredJobsLength} of ${filteredJobsLength}`}
                                </div>
                                <div className="itemsperpage-btns">
                                    <p className="perpage-text">Items per page: </p>
                                    <ButtonGroup>
                                        <Button onClick={()=>{setItemsPerPage(10); setCurrentPage(1)}} variant={itemsPerPage === 10 ? 'primary' : 'light'}>10</Button>
                                        <Button onClick={()=>{setItemsPerPage(25); setCurrentPage(1)}} variant={itemsPerPage === 25 ? 'primary' : 'light'}>25</Button>
                                        <Button onClick={()=>{setItemsPerPage(50); setCurrentPage(1)}} variant={itemsPerPage === 50 ? 'primary' : 'light'}>50</Button>
                                        <Button onClick={()=>{setItemsPerPage(100); setCurrentPage(1)}} variant={itemsPerPage === 100 ? 'primary' : 'light'}>100</Button>
                                    </ButtonGroup>
                                </div>
                            </div>
                            <Pagination style={{marginTop: 16, display: 'flex', flexDirection: 'row', padding: 0}}>
                                <div className="desktop-btn">
                                    <Pagination.First onClick={() => setCurrentPage(1)}/>
                                </div>
                                <Pagination.Prev onClick={() => {if(currentPage !== 1){setCurrentPage(cur=>cur-1)}}} />
                                {paginationItems.map((p, index) => {
                                        if(index < currentPage - 2 || index > currentPage )
                                        return;
                                    return <Pagination.Item key={index} active={currentPage === p} onClick={()=>setCurrentPage(p)}>{p}</Pagination.Item>
                                })}
                                <Pagination.Next onClick={() => {if(currentPage !== pages){setCurrentPage(cur=>cur+1)}}}/>
                                <div className="desktop-btn">
                                    <Pagination.Last onClick={() => setCurrentPage(pages)}/>
                                </div>
                            </Pagination>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
        <Modal show={showModal} onHide={()=>{setShowModal(false); setSelectedJob(null)}}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Select Location
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 style={{textAlign: 'center', marginBottom: 12}}>{selectedJob?.title}</h4>
                <ListGroup>
                    {selectedJob?.terminals.map(t=>{
                        return (
                            <ListGroupItem k={t.uid} style={{display: 'flex', justifyContent: 'space-between'}} action onClick={()=> history.push(`/apply/${selectedJob.uid}/${t.uid}`)}>
                                <p>{`${t.address.locality} (${t.address.administrativeArea})`}</p>
                                {t.availablePositionsRemaining > 0 && <p>{`Available Positions: ${t.availablePositionsRemaining}`}</p>}
                            </ListGroupItem>
                        )
                    })}
                </ListGroup>
            </Modal.Body>
        </Modal>
    </LoadingWrapper>
    )
}