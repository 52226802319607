/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../decoding";
import { UidGenerator, validateDecimal, validateInteger } from "../../tools";
import { Validation } from "../../validation";
import CustomQuestion from "../CustomQuestion";
import StateObject from "../State/StateObject";

export default class CustomQuestionAnswer extends StateObject {
    uid; //: number;
    question; //: CustomQuestion;
    answer; //: string | number[] | boolean | undefined;

    constructor(question, answer) {
        super();
        this.uid = UidGenerator.getUid();
        this.question = question;
        this.answer = answer;
        this.initChildren();
    }

    static initDefault() {
        return new CustomQuestionAnswer(CustomQuestion.initDefault('text'), undefined);
    }

    static initFromQuestion(question) {
        return new CustomQuestionAnswer(question, question.type === 'multiselect' ? [] : ['toggle', 'picker', 'knockout'].includes(question.type) ? undefined : '');
    }

    static decode(json) {
        const decoder = new Decoder(json);
        const question = CustomQuestion.decode(json);
        let answer;
        if (question.type === 'toggle') {
            answer = decoder.decode('answer', Decoder.Boolean);
        } else if (question.type === 'multiselect') {
            answer = JSON.parse(json.answer);
        } else if (question.type === 'date') {
            answer = decoder.decode('answer', Decoder.Date);    
        } else if (question.type === 'number') {
            answer = decoder.decode('answer', Decoder.Decimal);   
        } else {
            answer = decoder.decode('answer', Decoder.NonEmptyString);
        }
        if (decoder.checkForErrors()) {
            return new CustomQuestionAnswer(question, answer);
        } else {
            return CustomQuestionAnswer.initDefault();
        }
    }

    encode() {
        return {
            question: this.question.encode(),
            answer: this.question.type === 'multiselect' ? JSON.stringify(this.answer.map(a => validateInteger(a))) 
                : this.question.type === 'number' ? validateDecimal(this.answer) 
                : this.answer
        }
    }


}