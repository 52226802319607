/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ControlStateProps from '../../../../models/State/ControlStateProps';
import { Validation } from '../../../../validation';
import moment from 'moment';
import SubscriberWrapper from '../../../../components/SubscriberWrapper';
import { pdf } from '@react-pdf/renderer';
import W4Page1 from '../../../../../src/assets/fw4-1.png';
import W4Page2 from '../../../../../src/assets/fw4-2.png';
import W4Page3 from '../../../../../src/assets/fw4-3.png';
import W4Page4 from '../../../../../src/assets/fw4-4.png';
import DocumentWizard from '../../DocumentWizard';
import DocumentSignatureButton from '../../DocumentSignatureButton';
import DocumentField from '../../DocumentField';
import DocumentRadioCheck from '../../DocumentRadioCheck';
import DocumentCheckbox from '../../DocumentCheckbox';
import { submitDocument } from '../../../../services/EmployeeOnboardingService';
import W4Pdf from './W4Pdf';
import { reactPdfToBase64 } from '../../../../tools';
import { AppGlobals } from '../../../../App';
import LoadingWrapper from '../../../../components/LoadingWrapper';
import W4 from './W4';

export default function W4Form({document, userData, companyData, startDate, signature, onboardingToken, hideModal, updateDocumentToCompleted}) {
    const [w4, _] = useState(W4.initFromData(document, userData, companyData, startDate));
    const [zoom, setZoom] = useState(1);

    const fieldRefs = [
        useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef()
    ]

    const handleSubmit = async () => {
        const pdf = <W4Pdf signature={signature} w4={w4}/>;
        const base64 = await reactPdfToBase64(pdf);
        const response = await submitDocument(w4.encode(document, base64), onboardingToken);
        if (response.status === 200) {
            updateDocumentToCompleted('w4', base64);
            hideModal();
            AppGlobals.alert('success', response.message);
        } else {
            AppGlobals.alert('danger', response.message);
        }
    }

    return (
        <>
            <Modal.Header closeButton/>
            <Modal.Body style={{padding: 0, overflowX: 'scroll', backgroundColor: 'lightgray'}}>
                <div style={{transform: `scale(${zoom})`, display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 12, height: ((1100 * 4) + (12 * 3) + 16) * zoom}}>
                    <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                        <img src={W4Page1} style={{width: 850, height: 1100}}/>
                        <DocumentField innerRef={fieldRefs[0]} stateProps={new ControlStateProps('firstNameMiddleInitial', w4, Validation.nonEmptyString)} maxLength={20} x={134} y={128} width={240}/>
                        <DocumentField innerRef={fieldRefs[1]} stateProps={new ControlStateProps('lastName', w4, Validation.nonEmptyString)} maxLength={20} x={385} y={128} width={270}/>
                        <DocumentField innerRef={fieldRefs[2]} stateProps={new ControlStateProps('ssn', w4, Validation.ssn)} x={666} y={128} width={128} maxLength={9} type='number'/>
                        <DocumentField innerRef={fieldRefs[3]} stateProps={new ControlStateProps('address', w4, Validation.nonEmptyString)} maxLength={30} x={134} y={162} width={400}/>
                        <DocumentField innerRef={fieldRefs[4]} stateProps={new ControlStateProps('cityStateZip', w4, Validation.nonEmptyString)} maxLength={50} x={134} y={195} width={400}/>

                        <DocumentRadioCheck innerRef={fieldRefs[5]} value={0} stateProps={new ControlStateProps('filingStatus', w4, Validation.notUndefined, 'filingStatus0')} x={159} y={219}/>
                        <DocumentRadioCheck innerRef={fieldRefs[6]} value={1} stateProps={new ControlStateProps('filingStatus', w4, Validation.notUndefined, 'filingStatus1')} x={159} y={235}/>
                        <DocumentRadioCheck innerRef={fieldRefs[7]} value={2} stateProps={new ControlStateProps('filingStatus', w4, Validation.notUndefined, 'filingStatus2')} x={159} y={251}/>

                        <DocumentCheckbox innerRef={fieldRefs[8]} value={2} stateProps={new ControlStateProps('has2Jobs', w4)} x={782} y={461}/>

                        <DocumentField type='number' minDecimal={0} maxDecimal={999999.99} innerRef={fieldRefs[9]} stateProps={new ControlStateProps('step3Children', w4)} x={570} y={553} width={90}/>
                        <DocumentField type='number' minDecimal={0} maxDecimal={999999.99}  innerRef={fieldRefs[10]} stateProps={new ControlStateProps('step3OtherDependents', w4)} x={570} y={578} width={90}/>
                        <DocumentField type='number' minDecimal={0} maxDecimal={999999.99}  innerRef={fieldRefs[11]} stateProps={new ControlStateProps('step3Total', w4)} x={710} y={620} width={90}/>

                        <DocumentField type='number' minDecimal={0} maxDecimal={999999.99}  innerRef={fieldRefs[12]} stateProps={new ControlStateProps('step4OtherIncome', w4)} x={710} y={670} width={90}/>
                        <DocumentField type='number' minDecimal={0} maxDecimal={999999.99}  innerRef={fieldRefs[13]} stateProps={new ControlStateProps('step4Deductions', w4)} x={710} y={728} width={90}/>
                        <DocumentField type='number' minDecimal={0} maxDecimal={9999.99}  innerRef={fieldRefs[14]} stateProps={new ControlStateProps('step4ExtraWitholding', w4)} x={710} y={762} width={90}/>

                        <DocumentSignatureButton innerRef={fieldRefs[15]} signature={signature} stateProps={new ControlStateProps('step5Signed', w4, Validation.true)} x={134} y={836} width={118 * 0.9} height={40 * 0.9}/>
                        <SubscriberWrapper stateProps={new ControlStateProps('step5Signed', w4, null, 'wrapper for date')} render={() => {
                            return (!w4.step5Signed ? '' : 
                                <span style={{position: 'absolute', left: 650, top: 852}}>{moment().format('MM/DD/YYYY')}</span>
                            )
                        }}/>

                    </div>
                    <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto', }}>
                        <img src={W4Page2} style={{width: 850, height: 1100}}/>
                    </div>
                    <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto', }}>
                        <img src={W4Page3} style={{width: 850, height: 1100}}/>
                        <DocumentField type='number' stateProps={new ControlStateProps('step2b1', w4)} x={709} y={237} width={90}/>
                        <DocumentField type='number' stateProps={new ControlStateProps('step2b2a', w4)} x={709} y={345} width={90}/>
                        <DocumentField type='number' stateProps={new ControlStateProps('step2b2b', w4)} x={709} y={420} width={90}/>
                        <DocumentField type='number' stateProps={new ControlStateProps('step2b3', w4)} x={709} y={487} width={90}/>

                        <DocumentField type='number' stateProps={new ControlStateProps('step4b1', w4)} x={709} y={645} width={90}/>
                        <DocumentField type='number' stateProps={new ControlStateProps('step4b2', w4)} x={709} y={687} width={90}/>
                        <DocumentField type='number' stateProps={new ControlStateProps('step4b4', w4)} x={709} y={787} width={90}/>

                        <SubscriberWrapper stateProps={new ControlStateProps(null, w4, null, 'step2b2c')} render={() => {
                            return (
                                <>
                                    <span style={{position: 'absolute', left: 709, top: 446}}>{w4.step2b2c()}</span>
                                    <span style={{position: 'absolute', left: 709, top: 546}}>{w4.step2b4()?.toFixed(2)}</span>
                                    <span style={{position: 'absolute', left: 709, top: 746}}>{w4.step4b3()}</span>
                                    <span style={{position: 'absolute', left: 709, top: 813}}>{w4.step4b5()}</span>
                                </>
                            )
                        }}/>
                    </div>
                    <div style={{position: 'relative', marginLeft: 'auto', marginRight: 'auto', }}>
                        <img src={W4Page4} style={{width: 850, height: 1100}}/>
                    </div>
                </div>
            </Modal.Body>
            <DocumentWizard document={w4} fieldRefs={fieldRefs} refCount={16} zoom={zoom} setZoom={setZoom} onSubmit={handleSubmit}/>
        </>
    )
}
