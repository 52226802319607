/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useEffect, useReducer, useState} from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { validateDecimal, validateInteger } from '../../tools';
import OptionalCol from '../OptionalCol';
import ValidationFeedback from '../ValidationFeedback';

function CustomControl({type, title, originalValue, value, setValue, disabled, stateProps, maxLength, maxInt, maxDecimal, floatingLabel, style, wrapperStyle, min, max, breakpoints, validation, noMaxLabel, noAsterisk = false, innerRef}) {
    const [showValidation, setShowValidation] = useState(false);
    const [_, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        stateProps?.subscribe((showValidation) => {
            if (showValidation) {
                setShowValidation(true);
            }
            forceUpdate();
        });
    }, [stateProps]);

    const handleSetValue = (value) => {

        if (maxLength) {
            value = value.slice(0, maxLength);
        } else if (maxInt && validateInteger(value) > maxInt) {
            value = maxInt.toString();
        } else if (maxDecimal && validateDecimal(value) > maxDecimal) {
            value = maxDecimal.toString();
        }
        if (setValue) {
            setValue(value);
        } else if (stateProps) {
            stateProps.stateObject.setState(stateProps.stateKey, value);
        }
    }

    const changedFromOriginal = () => {
        return originalValue !== undefined && originalValue !== null && (stateProps ? stateProps.getValue() != originalValue : value != originalValue);
    }

    const content = (
        <>
            <Form.Control 
                onBlur={() => {setShowValidation(true)}}
                ref={innerRef}
                isInvalid={showValidation ? validation ? validation(value) : stateProps?.getIsInvalid() : undefined} 
                isValid={showValidation ? validation ? !validation(value) : stateProps?.getIsValid() : undefined} 
                disabled={disabled}
                type={type} 
                min={min}
                max={max}
                placeholder={title} 
                value={value ?? stateProps?.getValue()} 
                onChange={(event) => {handleSetValue(event.target.value)}} 
                style={changedFromOriginal() ? {backgroundColor: '#faffe3', ...style} : style}
            />
            {!noMaxLabel && <ValidationFeedback validation={validation} value={value} stateProps={stateProps} maxLength={maxLength} maxDecimal={maxDecimal} maxInt={maxInt} showValidation={showValidation}/>}
        </>
    )

    return (
        <OptionalCol breakpoints={breakpoints}>
            { floatingLabel ? 
                <FloatingLabel label={<p>{title}<span style={{color: 'red'}}>{(!noAsterisk && (validation || stateProps?.hasValidation())) ? '*' : ''}</span></p>} style={{position: 'relative', flex: 1,  ...wrapperStyle}}>
                    {content}
                </FloatingLabel> 
                : 
                <div style={{position: 'relative', flex: 1,  ...wrapperStyle}}>{content}</div>
            }
        </OptionalCol>
    )
}

export default CustomControl;