/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useState } from "react";
import { Button, Card, ListGroup, Modal, Row } from "react-bootstrap";
import Cookies from "universal-cookie";
import { createUser, deleteUser, getCompanyInfo, updateCompanySettings, updateUser } from "../../services/MyCompany";
import QuickTable from "../../components/QuickTable";
import { formatPhoneNumber } from "../../tools";
import './MyCompany.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPencil, faPhone, faPlus, faShareAltSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import SwitchControl from "../../components/SwitchControl";
import CustomTextArea from "../../components/controls/CustomTextArea";
import LoadingWrapper from "../../components/LoadingWrapper";
import CustomButton from "../../components/CustomButton";
import { AppGlobals } from "../../App";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import CustomControl from "../../components/controls/CustomControl";
import UserForCompany from "./models/UserForCompany";
import ControlStateProps from "../../models/State/ControlStateProps";
import { Validation } from "../../validation";
import CustomCheck from "../../components/controls/CustomCheck";
import EmailTemplate from "../../components/EmailTemplate";

const cookies = new Cookies();
const companyName = cookies.get('companyName');
const userData = cookies.get('userData');

export default function MyCompany({ }) {

    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false)
    const [showModal, setShowModal] = useState(false);

    const [isEdit, setIsEdit] = useState(false);

    const [selectedUser, setSelectedUser] = useState(null);
    const [users, setUsers] = useState([]);
    const [sendAutomaticRejection, setSendAutomaticRejection] = useState(false);
    const [automaticRejectionMessage, setAutomaticRejectionMessage] = useState('');

    useEffect(() => {
        loadData();
    }, [])

    async function loadData() {
        setIsLoading(true);
        const response = await getCompanyInfo();
        if (response.status === 200) {
            setUsers(response.users.map(user=>UserForCompany.decode(user)));
            setSendAutomaticRejection(response.company.sendAutomaticRejectionEmail ? true : false);
            setAutomaticRejectionMessage(response.company.automaticRejectionMessage ?? '');
        }
        setIsLoading(false);
    }

    async function handleUpdateSettings() {
        setIsUpdating(true);
        const response = await updateCompanySettings(sendAutomaticRejection, automaticRejectionMessage);
        if (response.status === 200) {
            AppGlobals.alert('success', response.message);
        } else {
            AppGlobals.alert('danger', response.message);
        }
        setIsUpdating(false);
    }

    function handleUserCrud(type, uid, user = null){
        let newUsers = Array.from(users);

        switch (type){
            case 'remove':
                newUsers = newUsers.filter(u=>u.uid !== uid);
                break;
            case 'add':
                user.uid = uid;
                newUsers.push(user);
                break;
        }

        setUsers(newUsers);
    }

    //////////////////
    /// User Rows
    //////////////////

    const userRows = users.map((user, index) => {
        return {
            onClick: userData['canEditRecruiters'] == 1 ? ()=>{setSelectedUser(user); setShowModal(true); setIsEdit(true)} : null,
            columns: [
                { value: `${user.firstName} ${user.lastName}`},
                { value: formatPhoneNumber(user.phoneNumber) },
                { value: user.email }
            ]
        }
    })

    //////////////////
    /// User List
    //////////////////

    const userList = users.map(user => {
        return (
            <ListGroup.Item action={userData['canEditRecruiters'] == 1 ? true : false} key={user.uid} onClick={userData['canEditRecruiters'] == 1 ? ()=>{setSelectedUser(user); setShowModal(true); setIsEdit(true)} : null}>
                <div style={{ display: 'flex', flexDirection: 'column', padding: 2 }}>
                    <h4 style={{ textAlign: 'center', margin: 0, marginBottom: 10 }}>{`${user.firstName} ${user.lastName}`}</h4>
                    <div className="user-info">
                        <p style={{ margin: 0 }}> <FontAwesomeIcon style={{ marginRight: 7, color: 'var(--bs-primary)' }} icon={faPhone} />{formatPhoneNumber(user.phoneNumber)}</p>
                        <p style={{ margin: 0 }}><FontAwesomeIcon style={{ marginRight: 7, color: 'var(--bs-primary)' }} icon={faEnvelope} />{user.email}</p>
                    </div>
                </div>
            </ListGroup.Item>
        )
    })

    return (
        <LoadingWrapper isLoading={isLoading}>
            <div style={{ backgroundColor: 'var(--bs-primary)', padding: 12, textAlign: 'start', display: 'flex', flexDirection: 'column', gap: 12 }}>
                <Card style={{ flex: 1, display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}>
                    <Card.Body style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <Card.Title style={{ flex: 0, fontWeight: "bold", fontSize: 30, textAlign: 'center', marginRight: 24 }}>{companyName}</Card.Title>
                        {userData['canEditRecruiters'] == 1 &&
                            <Button style={{ display: 'block', marginLeft: 'auto', marginBottom: 12, maxWidth: 150 }} onClick={() => {setShowModal(true); setSelectedUser(UserForCompany.initDefault())}}>
                                <FontAwesomeIcon style={{ marginRight: 4 }} icon={faPlus} />Add Recruiter
                            </Button>
                        }
                        <div className="desktop-users">
                            <QuickTable
                                responsive={false}
                                hover={userData['canEditRecruiters'] == 1}
                                headers={[
                                    { label: 'Name', sortable: true },
                                    { label: 'Phone Number', sortable: false },
                                    { label: 'Email Address', sortable: true },
                                ]}
                                rows={userRows}
                            />
                        </div>
                        <div className="mobile-users">
                            <ListGroup>
                                {userList}
                            </ListGroup>
                        </div>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title style={{ marginBottom: 12 }}>Email Settings</Card.Title>
                        <SwitchControl style={{ marginBottom: 18 }} title='Send automatic rejection email' value={sendAutomaticRejection} setValue={() => setSendAutomaticRejection(cur => !cur)} />
                        {sendAutomaticRejection &&
                            <div style={{ overflow: 'auto', margin: '24px 0' }}>
                                <EmailTemplate message={automaticRejectionMessage} setRejectionMessage={setAutomaticRejectionMessage}/>
                            </div>
                        }
                    </Card.Body>
                    <Card.Footer>
                        <CustomButton isLoading={isUpdating} style={{ display: 'block', marginLeft: 'auto' }} onClick={handleUpdateSettings}>Update Settings</CustomButton>
                    </Card.Footer>
                </Card>
            </div>
            <Modal size='lg' show={showModal} onHide={() => { setShowModal(false); setIsEdit(false)}}>
                <AddEditRecruiterModal isEdit={isEdit} selectedUser={selectedUser} handleUserCrud={handleUserCrud} setShowModal={setShowModal} />
            </Modal>
        </LoadingWrapper>
    )
}

function AddEditRecruiterModal({ selectedUser, isEdit, handleUserCrud, setShowModal }) {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    async function handleCreateUser() {
        setIsSubmitting(true);
        const response = await createUser(selectedUser.encode());
        if (response.status === 200) {
            AppGlobals.alert('success', response.message);
            handleUserCrud('add', response.uid, selectedUser);
            setShowModal(false);
        } else {
            AppGlobals.alert('danger', response.message);
        }
        setIsSubmitting(false);
    }

    async function handleUpdateUser() {
        setIsSubmitting(true);
        const response = await updateUser(selectedUser.encode());
        if (response.status === 200) {
            AppGlobals.alert('success', response.message);
            setShowModal(false);
        } else {
            AppGlobals.alert('danger', response.message);
        }
        setIsSubmitting(false);
    }

    return (
        <>
            <>
                <Modal.Header closeButton>
                    <Modal.Title>{isEdit ? 'Edit ' : 'Add '}Recruiter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ marginBottom: 24 }}>
                        <h6 style={{ marginLeft: 4 }}>User Details</h6>
                        <CustomControl maxLength={30} floatingLabel title='First Name' stateProps={new ControlStateProps('firstName', selectedUser, Validation.nonEmptyString)} breakpoints={{ sm: 6 }} />
                        <CustomControl maxLength={30} floatingLabel title='Last Name' stateProps={new ControlStateProps('lastName', selectedUser, Validation.nonEmptyString)} breakpoints={{ sm: 6 }} />
                        <CustomControl maxLength={10} floatingLabel title='Phone Number' stateProps={new ControlStateProps('phoneNumber', selectedUser, Validation.phoneNumber)} breakpoints={{ sm: 6 }} />
                        <CustomControl maxLength={50} floatingLabel title='Email' stateProps={new ControlStateProps('email', selectedUser, Validation.email)} breakpoints={{ sm: 6 }} />
                    </Row>
                    <Row>
                        <h6 style={{ marginLeft: 4 }}>User Permissions</h6>
                        <SwitchControl style={{height: 58}} sm={6} title='Enable billing' stateProps={new ControlStateProps('hasBillingPermissions', selectedUser)} breakpoints={{ sm: 6 }} />
                        <SwitchControl style={{height: 58}} sm={6} title='Enable editing recruiters' stateProps={new ControlStateProps('canEditRecruiters', selectedUser)} breakpoints={{ sm: 6 }} />
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {isEdit && userData.uid !== selectedUser.uid && <button onClick={()=>setShowDeleteModal(true)} style={{marginLeft: 18, border: 'none', boxShadow: 'none', color: 'red', backgroundColor: 'transparent'}} >Delete Recruiter</button>}
                    <CustomButton isLoading={isSubmitting} stateObject={selectedUser} onClick={isEdit ? handleUpdateUser : handleCreateUser}>{isEdit ? 'Save Changes' : 'Submit'}</CustomButton>
                </Modal.Footer>
            </>
            { isEdit &&
                <Modal show={showDeleteModal} onHide={() => { setShowDeleteModal(false)}}>
                    <DeleteConfirmation uid={selectedUser?.uid} onSuccess={() =>  {handleUserCrud('remove', selectedUser?.uid); setShowDeleteModal(false); setShowModal(false)}} api={deleteUser}
                    buttonLabel='Delete' title={selectedUser ? `Delete ${selectedUser?.firstName} ${selectedUser?.lastName}` : 'Delete User'} message='Are you sure you want to delete this user?' />
                </Modal>
            }
        </>
    )
}