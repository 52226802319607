/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import Decoder from "../../../../decoding";
import Address from "../../../../models/Address";
import StateObject from "../../../../models/State/StateObject"
import { base64PdfToBase64Images, validateInteger } from "../../../../tools";

export default class F8850 extends StateObject {
    employeeSubmission;
    name;
    ssn;
    streetAddress;
    cityStateZipCode;
    county;
    phoneNumber;
    dateOfBirthIfUnder40;
    checkbox1;
    checkbox2;
    checkbox3;
    checkbox4;
    checkbox5;
    checkbox6;
    checkbox7;
    signed;
    //Employer
    employerName;
    employerPhoneNumber;
    ein;
    employerStreetAddress;
    employerCityStateZipCode;
    contactPerson;
    contactPhoneNumber;
    contactStreetAddress;
    contactCityStateZipCode;
    groupNumber;
    dateGaveInformation;
    dateOfferedJob;
    dateWasHired;
    dateStartedJob;
    employerTitle;

    constructor(
        employeeSubmission,
        name,
        ssn,
        streetAddress,
        cityStateZipCode,
        county,
        phoneNumber,
        dateOfBirthIfUnder40,
        checkbox1,
        checkbox2,
        checkbox3,
        checkbox4,
        checkbox5,
        checkbox6,
        checkbox7,
        signed,
        employerName,
        employerPhoneNumber,
        ein,
        employerStreetAddress,
        employerCityStateZipCode,
        contactPerson,
        contactPhoneNumber,
        contactStreetAddress,
        contactCityStateZipCode,
        groupNumber,
        dateGaveInformation,
        dateOfferedJob,
        dateWasHired,
        dateStartedJob,
        employerTitle,
    ){
        super();
        this.employeeSubmission = employeeSubmission;
        this.name = name;
        this.ssn = ssn;
        this.streetAddress = streetAddress;
        this.cityStateZipCode = cityStateZipCode;
        this.county = county;
        this.phoneNumber = phoneNumber;
        this.dateOfBirthIfUnder40 = dateOfBirthIfUnder40;
        this.checkbox1 = checkbox1;
        this.checkbox2 = checkbox2;
        this.checkbox3 = checkbox3;
        this.checkbox4 = checkbox4;
        this.checkbox5 = checkbox5;
        this.checkbox6 = checkbox6;
        this.checkbox7 = checkbox7;
        this.signed = signed;
        this.employerName = employerName;
        this.employerPhoneNumber = employerPhoneNumber;
        this.ein = ein;
        this.employerStreetAddress = employerStreetAddress;
        this.employerCityStateZipCode = employerCityStateZipCode;
        this.contactPerson = contactPerson;
        this.contactPhoneNumber = contactPhoneNumber;
        this.contactStreetAddress = contactStreetAddress;
        this.contactCityStateZipCode = contactCityStateZipCode;
        this.groupNumber = groupNumber;
        this.dateGaveInformation = dateGaveInformation;
        this.dateOfferedJob = dateOfferedJob;
        this.dateWasHired = dateWasHired;
        this.dateStartedJob = dateStartedJob;
        this.employerTitle = employerTitle;
    }

    static initDefault(base64){
        return new F8850(
            [],
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
        )
    }

    static async initFromData(document, userData, companyData, isEmployer){
        const firstName = userData.firstName ?? '';
        const middleName = userData.middleName ?? '';
        const lastName = userData.lastName ?? '';
        const userAddress = Address.decodeNonStrict(userData.address);
        const companyAddress = Address.decodeNonStrict(companyData.address);

        let employeeSubmission = [];
        if (isEmployer) {
            employeeSubmission = await base64PdfToBase64Images(document.employeeSubmission, 1173);
        }


        return new F8850(
            employeeSubmission,
            document.name ?? `${firstName} ${middleName} ${lastName}`,
            document.ssn ?? userData.ssn ?? '',
            document.streetAddress ?? `${userAddress.thoroughfare} ${userAddress.premise}`,
            document.cityStateZipCode ?? `${userAddress.locality}, ${userAddress.administrativeArea}, ${userAddress.postalCode}`,
            document.county ?? '',
            document.phoneNumber ?? userData.phoneNumber ?? '',
            document.dateOfBirthIfUnder40 ?? ((userData.dateOfBirth && moment(userData.dateOfBirth).diff(moment(), 'years') < 40) ? userData.dateOfBirth : ''),
            document.checkbox1 ?? false,
            document.checkbox2 ?? false,
            document.checkbox3 ?? false,
            document.checkbox4 ?? false,
            document.checkbox5 ?? false,
            document.checkbox6 ?? false,
            document.checkbox7 ?? false,
            false,
            document.employerName ?? companyData.companyName ?? companyData.name ?? '',
            document.employerPhoneNumber ?? companyData.phoneNumber ?? '',
            document.ein ?? companyData.ein ?? '',
            document.employerCityStateZipCode ?? `${companyAddress.thoroughfare} ${companyAddress.premise}`,
            document.employerCityStateZipCode ??`${companyAddress.locality}, ${companyAddress.administrativeArea}, ${companyAddress.postalCode}`,
            document.contactPerson ?? '',
            document.contactPhoneNumber ?? '',
            document.contactStreetAddress ?? '',
            document.contactCityStateZipCode ?? '',
            document.groupNumber ?? '',
            document.dateGaveInformation ?? '',
            document.dateOfferedJob ?? '',
            document.dateWasHired ?? '',
            document.dateStartedJob ?? '',
            document.employerTitle ?? ''
        )
    }

    encode(document, base64){
        return {
            ...document,
            base64: base64,
            name: this.name,
            ssn: validateInteger(this.ssn),
            streetAddress: this.streetAddress,
            cityStateZipCode: this.cityStateZipCode,
            county: this.county,
            phoneNumber: this.phoneNumber,
            dateOfBirthIfUnder40: this.dateOfBirthIfUnder40 ? this.dateOfBirthIfUnder40 : null,
            checkbox1: this.checkbox1,
            checkbox2: this.checkbox2,
            checkbox3: this.checkbox3,
            checkbox4: this.checkbox4,
            checkbox5: this.checkbox5,
            checkbox6: this.checkbox6,
            checkbox7: this.checkbox7,
            employerName: this.employerName,
            employerPhoneNumber: this.employerPhoneNumber,
            ein: validateInteger(this.ein),
            employerStreetAddress: this.employerStreetAddress,
            employerCityStateZipCode: this.employerCityStateZipCode,
            contactPerson: this.contactPerson ? this.contactPerson : null,
            contactPhoneNumber: this.contactPhoneNumber ? this.contactPhoneNumber : null,
            contactStreetAddress: this.contactStreetAddress ? this.contactStreetAddress : null,
            contactCityStateZipCode: this.contactCityStateZipCode ? this.contactCityStateZipCode : null,
            groupNumber: this.groupNumber ? this.groupNumber : null,
            dateGaveInformation: this.dateGaveInformation ? this.dateGaveInformation : null,
            dateOfferedJob: this.dateOfferedJob ? this.dateOfferedJob : null,
            dateWasHired: this.dateWasHired ? this.dateWasHired : null,
            dateStartedJob: this.dateStartedJob ? this.dateStartedJob : null,
            employerTitle: this.employerTitle
        }
    }
}

