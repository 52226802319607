/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useReducer, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

export default function SwitchControl({value, setValue, stateProps, originalValue, title, disabled, xs, sm, md, lg, xl, xxl, style, noWrap = true, reverse}) {

    const [_, forceUpdate] = useReducer(x => x + 1, 0);

    const handleSetValue = () => {
        if(stateProps){
            stateProps.stateObject.setState(stateProps.stateKey, !stateProps.getValue());
            forceUpdate();
            return;
        }

        if (value === '1') {
            setValue('0');
        } else if (value === '0') {
            setValue('1');
        } else if (value === 1) {
            setValue(0);
        } else if (value === 0) {
            setValue(1);
        } else {
            setValue(!value);
        }
    }

    const content = (
        <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', gap: 12, border: '1px solid lightgray', borderRadius: 6, padding: '6px 12px 6px 12px', backgroundColor: originalValue !== undefined && originalValue != value ? '#faffe3' : 'white', ...style}}>
            { title && <span style={{whiteSpace: noWrap ? 'nowrap' : undefined}}>{title}</span>}
            <Form.Check style={{marginRight: -6}} 
                type='switch' 
                checked={stateProps ? stateProps?.getValue() : value === '0' ? false : value === '1' ? true : value} 
                onChange={handleSetValue} 
                reverse={reverse} 
                disabled={disabled}
            />
        </div>
    );

    const contentWrapped = xs || sm || md || lg || xl || xxl ? (
        <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} style={{marginBottom: 8}}>
            {content}
        </Col>
    ) : '';
    return (
        <>
            {xs || sm || md || lg || xl || xxl ? contentWrapped : content}
        </>
    )
}