/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { EditorState } from "draft-js";
import Interview from "../models/JobApplication/Interview";
import { getResponse, getToken } from "./serviceTools";

export async function getJobApplication(uid: number) {
    const data = {
        type: 'jobApplication',
        target: 'get',
        token: getToken(),
        uid
    }
    return await getResponse(data);
}

export async function assignClient(uid: number, clientIdentifier: number) {
    const data = {
        type: 'jobApplication',
        target: 'assignClient',
        token: getToken(),
        uid,
        clientIdentifier
    }
    return await getResponse(data);
}

export async function rejectApplicant(jobApplicationIdentifier: number, sendRejection: boolean, rejectionMessage: string) {
    const data = {
        type: 'jobApplication',
        target: 'reject',
        token: getToken(),
        jobApplicationIdentifier,
        sendRejection,
        rejectionMessage,
    }
    return await getResponse(data);
}

export async function getOfferLetterData(jobApplicationIdentifier: number) {
    const data = {
        type: 'jobApplication',
        target: 'getOfferLetterData',
        token: getToken(),
        jobApplicationIdentifier: jobApplicationIdentifier,
    }
    return await getResponse(data);
}

export async function hireApplicant(jobApplicationIdentifier: number, hireData: any, documents: object, includeEContacts: boolean, employerSigner: string) {
    const data = {
        type: 'jobApplication',
        target: 'hireApplicant',
        token: getToken(),
        jobApplicationIdentifier: jobApplicationIdentifier,
        hireData: hireData,
        documents: documents,
        includeEContacts: includeEContacts,
        employerEmail: employerSigner,
    }
    return await getResponse(data);
}

// JOB APPLICATION NOTES
export async function createJobApplicationNote(jobApplicationIdentifier: number, applicantFirstName: string, applicantLastName: string, richText: EditorState){
    const data = {
        type: 'jobApplication',
        target: 'createNote',
        token: getToken(),
        jobApplicationIdentifier,
        applicantFirstName,
        applicantLastName,
        richText
    }

    return await getResponse(data);
}

export async function updateJobApplicationNote(uid: number, richText: EditorState){
    const data = {
        type: 'jobApplication',
        target: 'updateNote',
        token: getToken(),
        uid,
        richText
    }
    return await getResponse(data);
}

export async function deleteJobApplicationNote(uid: number){
    const data = {
        type: 'jobApplication',
        target: 'deleteNote',
        token: getToken(),
        uid,
    }

    return await getResponse(data);
}

// INTERVIEWS
export async function createInterview(interview: Interview, isContractor: boolean){
    const data = {
        type: 'jobApplication',
        target: 'createInterview',
        token: getToken(),
        interview,
        isContractor
    }

    return await getResponse(data);
}

export async function updateInterview(interview: Interview, isContractor: boolean){
    const data = {
        type: 'jobApplication',
        target: 'updateInterview',
        token: getToken(),
        interview,
        isContractor
    }
    return await getResponse(data);
}

export async function deleteInterview(interviewIdentifier: number){
    const data = {
        type: 'jobApplication',
        target: 'deleteInterview',
        token: getToken(),
        interviewIdentifier,
    }

    return await getResponse(data);
}