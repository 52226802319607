/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../decoding";

export default class Company {
    uid: number;
    companyName: string;
    isEnabled: boolean;

    constructor(uid: number, companyName: string, isEnabled: boolean) {
        this.uid = uid;
        this.companyName = companyName;
        this.isEnabled = isEnabled;
    }

    static initDefault(): Company {
        return new Company(-1, 'ERR', true);
    }

    static decode(json: any): Company {
        const decoder = new Decoder(json);
        const uid = decoder.decode('uid', Decoder.Uid);
        const companyName = decoder.decode('name', Decoder.StringStrict);
        const isEnabled = decoder.decode('isEnabled', Decoder.Boolean);
        if (decoder.checkForErrors()) {
            return new Company(uid, companyName, isEnabled);
        } else {
            return Company.initDefault();
        }

    }

}