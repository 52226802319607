/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment"
import CustomQuestionAnswer from "./models/JobApplication/CustomQuestionAnswer"
import Address from "./models/Address"
import { pdf } from "@react-pdf/renderer"
import { pdfjs } from "react-pdf"

export type Breakpoints = {
    xs?: number
    sm?: number
    md?: number
    lg?: number
    xl?: number
    xxl?: number
}

export function cleanNumber(num: any): number {
    const result = Number(num);
    return Number.isNaN(result) ? 0 : result;
}

export function cleanInt(num: any, allowEmptyString: boolean = false): number {
    if (num === '') {
        return num;
    }
    const result = parseInt(num);
    return Number.isNaN(result) ? 0 : result;
}

export function cleanUsd(num: any): string {
    const result = parseFloat(num);
    return Number.isNaN(result) ? '$0.00' : '$' + result.toFixed(2);
}

export const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export class UidGenerator {
    static uid: number = 0;

    static getUid(): number {
        this.uid += 1;
        return this.uid;
    }

    static getNextUniqueUid(arr: number[]) {
        let uid: number = 1;
        while (arr.includes(uid)) {
            uid++;
        }
        return uid;
    }
}

export function customQuestionAnswerToString(qa: CustomQuestionAnswer): string {
    switch (qa.question.type) {
        case 'toggle':
            return qa.answer ? 'Yes' : 'No';
        case 'multiselect':
            return (qa.answer as number[]).map(a => qa.question.options[a].label).join(', ');
        case 'date':
            return qa.answer ? moment(qa.answer as string).format('MMM D, YYYY') : '';
        case 'picker':
            return qa.question.options[parseInt(qa.answer)].label;
        case 'knockout':
            return qa.question.options[parseInt(qa.answer)].label;
        default:
            return qa.answer?.toString() ?? 'ERR';
    }
}

export function formatPhoneNumber(str: string): string {
    return '(' + str.slice(0, 3) + ') ' + str.slice(3, 6) + '-' + str.slice(6);
}

export function formatSsn(ssn: any): string {
    const str: string = ssn.toString();
    return str.slice(0, 3) + '-' + str.slice(3, 5) + '-' + str.slice(5);
}

export function formatEin(ein: number): string {
    return ein.toString().slice(0, 2) + '-' + ein.toString().slice(2);
}

export function addressToString(address: Address) {
    
    if (address) {
        let str = '';

        if (address.thoroughfare) {
            str += address.thoroughfare;
        }
        if (address.premise) {
            if (str.length > 0) {
                str += ' ';
            }
            str += address.premise;
        }
        if (address.locality) {
            if (str.length > 0) {
                str += ', ';
            }
            str += address.locality;
        }
        if (address.administrativeArea) {
            if (str.length > 0) {
                str += ', ';
            }
            str += address.administrativeArea;
        }
        if (address.postalCode) {
            if (str.length > 0) {
                str += ' ';
            }
            str += address.postalCode
        }
        return str;
    } else {
        return '';
    }
}

export function availabilityToString(availability: boolean[]) {
    const days = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    const array = [];

    for (let i = 0; i < 7; i++) {
        if (availability[i]) {
            array.push(days[i]);
        }
    }
    return array.join(', ');
}

export function availabilityToBinaryString(availability: string) {
    let str = '';
    ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].forEach((dayOfWeek) => {
        str += (availability.includes(dayOfWeek) ? '1' : '0');
    });
    return str;
}

export function availabilityFromBinaryString(availability: string) {
    let arr: string[] = [];
    ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].forEach((dayOfWeek, index) => {
        if (availability[index] == '1') {
            arr.push(dayOfWeek);
        }
    });
    return arr.join(', ');
}

export function encodeAvailability(availability: boolean[]) {
    const days = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    const array = [];

    for (let i = 0; i < 7; i++) {
        if (availability[i]) {
            array.push(days[i]);
        }
    }
    return array.join(',');
}

export function yearRangeToString(lhs: string, rhs: string) {
    if (moment(lhs).format('YYYY') === moment(rhs).format('YYYY')) {
        return moment(lhs).format('YYYY');
    } else {
        return moment(lhs).format('YYYY') + ' - ' + moment(rhs).format('YYYY');
    }
}

export function timeUntilDateToString(date: string, threshold: number, formatting: string = 'MMM D, YYYY') {
    const days = moment().diff(moment(date), 'days');
    if (days === 0) {
        return 'Today';
    } if (days >= threshold) {
        return moment(date).format(formatting);
    } else {
        return timeDurationToString(moment().format('YYYY-MM-DD'), date) ? 'In ' + timeDurationToString(moment().format('YYYY-MM-DD'), date) : 'Expired';
    }
}

export function timeSinceDateToString(date: string, threshold: number, formatting: string = 'MMM D, YYYY') {
    const days = moment().diff(moment(date), 'days');
    if (days === 0) {
        return 'Today';
    } else if (days >= threshold) {
        return moment(date).format(formatting);
    } else {
        return timeDurationToString(date) + ' ago';
    }
}

export function timeDurationToString(lhs: string, rhs: string = moment().format('YYYY-MM-DD')) {
    const amounts = {
        years: 0,
        months: 0,
        weeks: 0,
        days: moment(rhs).diff(moment(lhs), 'days'),
    }
    if (amounts.days >= 365) {
        const amount = Math.floor(amounts.days / 365)
        amounts.days -= amount * 365;
        amounts.years += amount;
    }
    if (amounts.days >= 30) {
        const amount = Math.floor(amounts.days / 30);
        amounts.days -= amount * 30;
        amounts.months += amount;
    }
    if (amounts.days >= 7) {
        const amount = Math.floor(amounts.days / 7);
        amounts.days -= amount * 7;
        amounts.weeks += amount;
    }

    const array = Object.entries(amounts).filter(([key, value]) => value > 0).map(([key, value]) => {return value === 1 ? ('1 ' + key.substring(0 ,key.length - 1)) : (value + ' ' + key)});

    return array.join(', ');
}

export function pastTimestampToString(date: string) {
    const momentDate = moment(date);

    let str = '';
    
    if (!moment().isSame(momentDate, 'day')) {
        if (moment().isSame(momentDate, 'week')) {
            str += momentDate.format('dddd');
        } else {
            str += momentDate.format('MMM D');
        }
    }

    if (!moment().isSame(momentDate, 'year')) {
        str += `, ${momentDate.format('YYYY')}`
    }
    if (str !== '') {
        str += ' at ';
    }
    str += momentDate.format('h:mm A');

    return str;
}

export function validateDecimal(value: any) {
    return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
}

export function validateDecimalFixed(value: any, decimalPlaces: number) {
    return parseFloat(validateDecimal(value).toFixed(decimalPlaces));
}

export function validateInteger(value: any) {
    return isNaN(parseInt(value)) ? 0 : parseInt(value);
}

export function arraysToObject(array1: any[], array2: any[]) {
    
    const obj: Object = {}

    for (let i = 0; i < array1.length; i++) {
        obj[array1[i] as keyof Object] = array2[i];
    }

    return obj;
}

export function formatTime(str: string) {
    if (!str) {
        return '';
    }
    const hours = parseInt(str.substring(0, 2));
    const minutes = parseInt(str.substring(3));
    const pm = hours > 12;
    const addLeadingZero = minutes < 10;

    return `${pm ? hours - 12 : hours}:${addLeadingZero ? '0' : ''}${minutes} ${pm ? 'PM' : 'AM'}`;
}

export async function downloadBase64(base64: string, title: string) {
    const response = await fetch(base64);
    const blob = await response.blob();
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = title;        
    document.body.appendChild(elem);
    elem.click();        
    document.body.removeChild(elem);
}


export async function reactPdfToBase64(doc: JSX.Element) {
    const blob = await pdf(doc).toBlob();
    const thing = await new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        }
        reader.readAsDataURL(blob); 
    })
    return thing;
}

export async function base64PdfToBase64Images(base64: string, height: number | undefined) {
    const pdf = await pdfjs.getDocument(base64).promise;

    const pages = [];
    for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1);
        const viewport = page.getViewport({scale: 1.5});
        const canvas = document.createElement('canvas');
        const canvasContext = canvas.getContext('2d') as CanvasRenderingContext2D;
        canvas.height = height ?? viewport.height; /* viewport.height is NaN */
        canvas.width = viewport.width;  /* viewport.width is also NaN */
        await page.render({canvasContext, viewport}).promise;
        pages.push(canvas.toDataURL());
    };
    return pages;
}

export async function downloadReactPdf(doc: JSX.Element, title: string = '') {
    const blob = await pdf(doc).toBlob();
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.target = '_blank';
    // elem.download = `${onboardingData.employerName} - ${title}`;        
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
}

export function filterArrayByObjectProperty(array: any[], key: string, value: string) {
    return array.filter(a => a[key]?.toLowerCase().includes(value?.toLowerCase()));
} 