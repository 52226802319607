/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useEffect, useReducer, useState} from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { validateDecimal, validateInteger } from '../../tools';
import OptionalCol from '../OptionalCol';
import ValidationFeedback from '../ValidationFeedback';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

export default function AvailabilityControl({title, value, setValue, disabled, stateProps, style, wrapperStyle, breakpoints, validation}) {
    const [showValidation, setShowValidation] = useState(false);
    const [_, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        stateProps?.subscribe((showValidation) => {
            if (showValidation) {
                setShowValidation(true);
            }
            forceUpdate();
        });
    }, [stateProps]);

    const handleToggleAvilability = (index) => {
        if (setValue) {
            const newArr = Array.from(value);
            newArr[index] = !newArr[index];
            setValue(newArr);
        } else if (stateProps) {
            const newArr = Array.from(stateProps.getValue());
            newArr[index] = !newArr[index];
            stateProps.setValue(newArr);
        }
    }

    const checkboxes = ['Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri'].map((day, index) => {
        return (
            <Form.Check 
                onBlur={() => {setShowValidation(true)}}
                disabled={disabled}
                key={day} 
                inline
                type='checkbox' 
                label={day} 
                checked={value?.[index] ?? stateProps.getValue()[index]} 
                onChange={() => {handleToggleAvilability(index)}}
            />
        )
    });

    const isInvalid = showValidation ? validation ? validation(value) : stateProps?.getIsInvalid() : false;
    const isValid = showValidation ? validation ? !validation(value) : stateProps?.getIsValid() : false;

    const validationMessage = validation ? validation(value) : stateProps?.getValidationMessage();

    const content = (
        <div style={{
            position: 'relative',
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between', 
            border: '1px solid ' + (isValid ? 'green' : isInvalid  ? 'red' : 'lightgray'), 
            borderRadius: 6, 
            padding: '0px 12px 0px 12px', 
            minHeight: 58, 
        }}>
            <div style={{width: '100%'}}>
                <span style={{opacity: 0.65, fontSize: 15}}>{title}</span>
                <div style={{display: 'flex', width: '100%', flexWrap: 'wrap'}}>
                    {checkboxes}
                    <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'end', paddingBottom: 2}}>
                        { validationMessage && showValidation &&
                            <span style={{color: 'var(--bs-form-invalid-color)', fontSize: 11, whiteSpace: 'nowrap'}}>{validationMessage}</span>
                        }
                    </div>
                </div>
            </div>
            { isValid &&
                <FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/>
            }
            {/* { isInvalid && 
                <FontAwesomeIcon icon={faExclamationCircle} style={{color: 'rgb(209, 30, 54)'}}/>
            } */}

        </div>
    );

    return (
        <OptionalCol breakpoints={breakpoints}>
            {content}
        </OptionalCol>
    )
}