/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import { Badge, Button, Card, Col, Container, Dropdown, Form, ListGroup, Modal, ModalBody, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import JobPost from '../JobPosts/models/JobPost';
import KeyValueRow from '../../components/KeyValueRow';
import { EmploymentTypes, JobSites, PayTypes, ShiftDurations, ShiftTypes } from '../../enums';
import { cleanUsd, usdFormatter, validateInteger } from '../../tools';
import CustomTabs from '../../components/CustomTabs';
import CustomToolTip from '../../components/CustomToolTip';
import JobApplicationView from './JobApplicationView.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faBuilding, faClipboardQuestion, faExclamationTriangle, faHandshake, faPencil, faTrash, faTriangleExclamation, faVialCircleCheck, faWrench, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Link, Redirect } from 'react-router-dom';
import CustomButton from '../../components/CustomButton';
import CustomRadio from '../../components/controls/CustomRadio';
import { activateJobPost, deactivateJobPost, deleteJobPost, getJobPost, updateJobPostStatus, updateJobPostTerminals } from '../../services/JobPostService';
import JobStatusToolTip from '../../components/JobStatusToolTip';
import { AppGlobals } from '../../App';
import JobPostOverview from '../JobPosts/models/JobPostOverview';
import './JobPostPage.css';
import LoadingWrapper from '../../components/LoadingWrapper';
import CustomDropDown from '../../components/controls/CustomDropDown';
import CustomControl from '../../components/controls/CustomControl';
import JobPostTerminalEditor from '../JobCreator/JobPostTerminalEditor';
import JobPostTerminal from '../JobCreator/models/JobPostTerminal';
import SubscriberWrapper from '../../components/SubscriberWrapper';
import ControlStateProps from '../../models/State/ControlStateProps';
import SwitchControl from '../../components/SwitchControl';
import moment from 'moment';
import CustomCheck from '../../components/controls/CustomCheck';



export default class JobPostPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            jobPost: JobPostOverview.initDefault(),
            selectedJobApplication: undefined,
            markedAsViewed: false,
            showStatusModal: false,
            isSubmittingStatus: false,
            showDeleteModal: false,
            isLoading: true,
            isDeleting: false,
            terminalFilter: [],
            selectedTerminal: null,
            allTerminals: [],
            jobPostTerminals: [],
            isUpdatingTerminals: false,
            cloneJobPost: false,
            continueDelete: false,
            jobPostNotFound: false
        }
        this.handleSelectApplicant = this.handleSelectApplicant.bind(this);
        this.handleSetStatus = this.handleSetStatus.bind(this);
        this.handleDeleteJobPost = this.handleDeleteJobPost.bind(this);    
        this.handleUpdateReject = this.handleUpdateReject.bind(this);  
        this.handleAddTerminal = this.handleAddTerminal.bind(this);
        this.handleDeleteTerminal = this.handleDeleteTerminal.bind(this);
        this.handleUpdateTerminals = this.handleUpdateTerminals.bind(this);
        this.loadData = this.loadData.bind(this);
        this.handleSetApplicant = this.handleSetApplicant.bind(this);
    }

    componentDidMount(){
        window.addEventListener("beforeunload", this.handleBeforeUnload);
        const jobUid = parseInt(this.props.match.params.jobPost);
        if (!isNaN(jobUid)) {
            this.loadData(jobUid);
        }
    }

    async loadData(jobUid){
        this.setState({isLoading: true});
        const response = await getJobPost(jobUid);
        if(response.status === 200){
            const jobPost = JobPostOverview.decode(response.jobPost)
            jobPost.subscribe('JobPostPage', undefined, () => this.forceUpdate());
            this.setState({jobPost: jobPost, terminalFilter: jobPost.terminals.map(t=>t.uid), 
            allTerminals: response.terminals, jobPostTerminals: jobPost.terminals, newJobStatus: jobPost.status}, ()=>{
                const searchParams = new URLSearchParams(this.props.location.search).get('applicant');
                if(searchParams !== null){
                    this.handleSelectApplicant(validateInteger(searchParams));
                }
            })
        } else if (response.status === 400){
            this.setState({jobPostNotFound: true});
        } else { 
            AppGlobals.alert('danger', response.message)
        }
        this.setState({isLoading: false});
    }

    handleSetApplicant(uid, key, value) {
        const applicant = this.state.jobPost.applicants.find(a => a.uid == uid);
        applicant.setState(key, value);
    }

    handleSelectApplicant(uid) {
        this.setState({selectedJobApplication: this.state.jobPost?.applicants.find(a => a.uid === uid)});
    }

    async handleSetStatus() {
        if (this.state.jobPost) {
            this.setState({isSubmittingStatus: true});
            const response = this.state.jobPost.active ? await deactivateJobPost(this.state.jobPost?.uid) : await activateJobPost(this.state.jobPost?.uid, this.state.cloneJobPost, JSON.stringify(this.state.jobPost.jobSites));
            if (response.status === 200) {
                if(response.uid){
                    this.props.history.push(`/job/${response.uid}`);
                    this.loadData(response.uid);
                }else{
                    this.loadData(this.state.jobPost.uid)
                }
                this.state.jobPost.setState('active', !this.state.jobPost.active);
                AppGlobals.alert('success', response.message)
            }
            this.setState({isSubmittingStatus: false});
        }
    }

    async handleDeleteJobPost() {
        if (this.state.jobPost) {
            this.setState({isDeleting: true});
            const response = await deleteJobPost(this.state.jobPost.uid);
            if (response.status === 200) {
                this.setState({showDeleteModal: false});
                this.props.history.push(`/jobs`);
                AppGlobals.alert('success', response.message)
            } else {
                AppGlobals.alert('danger', response.message)
            }
            this.setState({isDeleting: false});
        }
    }

    async handleUpdateTerminals(){
        this.setState({isUpdatingTerminals: true});
        const response = await updateJobPostTerminals(this.state.jobPost.terminals.map(t=>t.encode()), this.state.jobPost.uid);
        if(response.status === 200){
            this.setState({terminalFilter: this.state.jobPost.terminals.map(t=>t.uid)})
            this.setState({showTerminalModal: false})
            AppGlobals.alert('success', response.message)
        } else{
            AppGlobals.alert('danger', response.message)
        }
        this.setState({isUpdatingTerminals: false});
    }

    handleAddTerminal(terminal, availablePositions, active, autoInactivate){
        this.state.jobPost.setState('terminals', [...this.state.jobPost.terminals, JobPostTerminal.initFromTerminal(terminal, availablePositions, active, autoInactivate)] )
    }

    handleDeleteTerminal(uid){
        this.state.jobPost.setState('terminals', this.state.jobPost.terminals.filter(t=>t.uid !== uid))
    }

    handleUpdateReject(uid){
        const newApplicants = Array.from(this.state.jobPost.applicants)
        const index = this.state.jobPost.applicants.findIndex(a=>a.uid === uid);
        newApplicants[index] = {...newApplicants[index], rejected: 1}
        this.setState({jobPost: {...this.state.jobPost, applicants: newApplicants, showRejectModal: false}});
    }

    
    render() {
        const applied = [];
        const passed = [];
        const interviewed = [];
        const hired = [];
        const rejected = [];

        const terminalFilterDropdownItems = this.state.jobPost?.terminals?.map((terminal) => {
            return (
                <Dropdown.Item key={terminal.uid} active={this.state.terminalFilter.includes(terminal.uid)} onClick={() => {
                    if (this.state.terminalFilter.includes(terminal.uid)) {
                        this.setState({terminalFilter: this.state.terminalFilter.filter(t=>t!== terminal.uid)})
                    } else {
                        this.setState({terminalFilter: this.state.terminalFilter.concat(terminal.uid)})
                    }
                }}>
                    {terminal.name}
                </Dropdown.Item>
            )
        })

        const availablePositionsFiltered = this.state.jobPost.terminals.reduce((prev, curr)=>{
            if(this.state.terminalFilter.includes(curr.uid)){
                return prev += curr.availablePositions;
            }
            return prev;
        }, 0)


        this.state.jobPost.applicants.filter(applicant=>{
            if(this.state.jobPost?.terminals.map(t=>t.uid).includes(applicant.terminalIdentifier)){
                return this.state.terminalFilter.includes(applicant.terminalIdentifier);
            }
            return true;
        }).forEach((app) => {
            if (app.rejected) {
                rejected.push(app);
            } else if (app.hired) {
                hired.push(app);
            } else if (app.interviews.length > 0) {
                interviewed.push(app);
            } else if (app.passedBackground && app.passedDrugScreen) {
                passed.push(app);
            } else {
                // for(let i =0; i < 100; i++){
                    applied.push(app);
                // }
            }
        })

        return (
            <LoadingWrapper isLoading={this.state.isLoading}>
                    {!this.state.jobPostNotFound ?
                    <div style={{padding: 12, display: 'flex', flexDirection: 'column', gap: 12}}>
                    <Card>
                        <Card.Header style={{display: 'flex', justifyContent: 'end', alignItems: 'center', gap: 8, flexWrap: 'wrap'}} >
                            <CustomButton narrow onClick={() => {}}>
                                <Link style={{color: 'white', textDecoration: 'none'}} to={`/edit/${this.state.jobPost.uid}`}>
                                    <FontAwesomeIcon icon={faPencil} style={{marginRight: 8}}/>
                                    Edit
                                </Link>
                            </CustomButton>
                            <>
                                <CustomButton onClick={() => this.setState({showTerminalModal: true})} narrow>
                                <FontAwesomeIcon icon={faBuilding}/>
                                Change Terminals
                                </CustomButton>
                                <CustomButton narrow onClick={() => {this.setState({showStatusModal: true})}}>
                                    <FontAwesomeIcon icon={faWrench}/>
                                    {this.state.jobPost.active ? 'Deactivate' : 'Activate'}
                                </CustomButton>
                            </>
                            { !this.state.jobPost.active &&
                                <CustomButton variant='danger' narrow onClick={() => {this.setState({showDeleteModal: true})}}>
                                    <FontAwesomeIcon icon={faTrashCan}/>
                                    Delete
                                </CustomButton>
                            }
                        </Card.Header>
                        <Card.Header style={{display: 'block', textAlign: 'center', position: 'relative'}}>
                            <h2>{this.state.jobPost.title + `${this.state.jobPost.isDuplicate ? ` (Activated ${moment(this.state.jobPost.dateActivated).format('MMM D, YYYY')})` : ''}`}</h2>
                            {/* <div style={{display: 'flex', gap: 12, justifyContent: 'center', fontWeight: 'bold', marginBottom: 12}}>
                                <h6 >{EmploymentTypes[this.state.jobPost.employmentType]}</h6>
                                <span>|</span>
                                <h6>{usdFormatter.format(this.state.jobPost.compensationMin) + ' - ' + usdFormatter.format(this.state.jobPost.compensationMax) + ' ' + PayTypes[this.state.jobPost.payType]}</h6>
                            </div> */}
                            <div style={{display: 'block', width: '80%', margin: 'auto'}}>
                                <p style={{whiteSpace: 'break-spaces', textAlign: 'left'}}>{this.state.jobPost.description}</p>
                            </div>
                            <ListGroup>
                                <KeyValueRow isListItem title={EmploymentTypes[this.state.jobPost.employmentType]} value={usdFormatter.format(this.state.jobPost.compensationMin) + ' - ' + usdFormatter.format(this.state.jobPost.compensationMax) + ' ' + PayTypes[this.state.jobPost.payType]}/>
                                <KeyValueRow isListItem title='Shift Type(s)' value={this.state.jobPost.shiftType === 'both' ? 'Weekdays & Weekends' : ShiftTypes[this.state.jobPost.shiftType]}/>
                                <KeyValueRow isListItem title='Shift Durations' value={this.state.jobPost.shiftDurations.map((r) => ShiftDurations[r]).join(', ')}/>
                                { this.state.jobPost.benefits &&
                                    <KeyValueRow isListItem title='Benefits' value={this.state.jobPost.benefits}/>
                                }
                                { this.state.jobPost.location &&
                                    <KeyValueRow isListItem title='Location' value={this.state.jobPost.location}/>
                                }
                            </ListGroup>
                            <div style={{margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '16px 0', gap: 12, flexWrap: 'wrap'}}>
                                <div className='filled-positions'>
                                    {this.state.terminalFilter.length === 0 ? 'No Terminals Selected' :`Filled ${this.state.jobPost.applicants.reduce((prev, curr) => {return prev + (curr.hired === undefined ? 0 : 1)}, 0)}/${availablePositionsFiltered} Available Positions`} 
                                    <div>
                                        {this.state.terminalFilter.length === 0 ? '' : ` ( ${this.state.jobPost.terminals.find(t=>t.uid == this.state.terminalFilter[0])?.name} ${this.state.terminalFilter.length === 1 ? ')' :''}`}
                                        {this.state.terminalFilter.length > 1 &&  ` + ${this.state.terminalFilter.length - 1} )`}
                                    </div>
                                </div>
                                <Dropdown autoClose='outside'>
                                    <Dropdown.Toggle variant='primary'>Filter Applicants by Terminal</Dropdown.Toggle>
                                    <Dropdown.Menu style={{overflowY: 'scroll', maxHeight: 300}}>
                                        {terminalFilterDropdownItems}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Card.Header>
                        <Card.Body className='mobile' style={{overflowY: 'scroll', flex: 0, paddingLeft: 6, paddingRight: 6, maxHeight: 400}}>
                            <CustomTabs 
                            automaticDefaultTab
                            iconBreakpoint={500} 
                            disableBadgesWhen0
                            tabs={[
                                {
                                    title: 'Applied', 
                                    icon: faFileLines,
                                    badge: applied.length,
                                    content: <JobPostModalAppList selectedJobApplication={this.state.selectedJobApplication} jobPost={this.state.jobPost} handleSelectApplicant={this.handleSelectApplicant} apps={applied}/>
                                },
                                {
                                    title: 'Screened', 
                                    icon: faVialCircleCheck,
                                    badge: passed.length,
                                    content: <JobPostModalAppList selectedJobApplication={this.state.selectedJobApplication} jobPost={this.state.jobPost} handleSelectApplicant={this.handleSelectApplicant} apps={passed}/>
                                },
                                {
                                    title: 'Interviewed', 
                                    icon: faClipboardQuestion,
                                    badge: interviewed.length,
                                    content: <JobPostModalAppList selectedJobApplication={this.state.selectedJobApplication} jobPost={this.state.jobPost} handleSelectApplicant={this.handleSelectApplicant} apps={interviewed}/>
                                },
                                {
                                    title: 'Hired', 
                                    icon: faHandshake,
                                    badge: hired.length,
                                    content: <JobPostModalAppList selectedJobApplication={this.state.selectedJobApplication} jobPost={this.state.jobPost} handleSelectApplicant={this.handleSelectApplicant} apps={hired}/>
                                },
                                {
                                    title: 'Rejected', 
                                    icon: faTrash,
                                    badge: rejected.length,
                                    content: <JobPostModalAppList selectedJobApplication={this.state.selectedJobApplication} jobPost={this.state.jobPost} handleSelectApplicant={this.handleSelectApplicant} apps={rejected}/>
                                }
                            ]}
                            />
                        </Card.Body>
                        <Card.Body className='desktop' style={{ padding: 0}}>
                            <div style={{flex: 1, padding: 6, textAlign: 'center', maxHeight: 400, overflowY: 'auto'}}>
                                <p style={{fontWeight: 'bold'}}>Applied</p>
                                <JobPostModalAppList selectedJobApplication={this.state.selectedJobApplication} jobPost={this.state.jobPost} handleSelectApplicant={this.handleSelectApplicant} apps={applied}/>
                            </div>
                            <div style={{backgroundColor: 'lightgray', width: 1}}/>
                            <div style={{flex: 1, padding: 6, textAlign: 'center', maxHeight: 400, overflowY: 'auto'}}>
                                <p style={{fontWeight: 'bold'}}>
                                    Screened
                                    <CustomToolTip style={{marginLeft: 4}} text='Applicants who have passed both the background check and drug screen'/>
                                </p>
                                <JobPostModalAppList selectedJobApplication={this.state.selectedJobApplication} jobPost={this.state.jobPost}  handleSelectApplicant={this.handleSelectApplicant} apps={passed}/>
                            </div>
                            <div style={{backgroundColor: 'lightgray', width: 1}}/>
                            <div style={{flex: 1, padding: 6, textAlign: 'center', maxHeight: 400, overflowY: 'auto'}}>
                                <p style={{fontWeight: 'bold'}}>
                                    Interviewed
                                    <CustomToolTip style={{marginLeft: 4}} text='Applicants who have been interviewed at least once'/>
                                </p>
                                <JobPostModalAppList selectedJobApplication={this.state.selectedJobApplication} jobPost={this.state.jobPost}  handleSelectApplicant={this.handleSelectApplicant} apps={interviewed}/>
                            </div>
                            <div style={{backgroundColor: 'lightgray', width: 1}}/>
                            <div style={{flex: 1, padding: 6, textAlign: 'center', maxHeight: 400, overflowY: 'auto'}}>
                                <p style={{fontWeight: 'bold'}}>Hired</p>
                                <JobPostModalAppList selectedJobApplication={this.state.selectedJobApplication} jobPost={this.state.jobPost}  handleSelectApplicant={this.handleSelectApplicant} apps={hired}/>
                            </div>
                            <div style={{backgroundColor: 'lightgray', width: 1}}/>
                            <div style={{flex: 1, padding: 6, textAlign: 'center', maxHeight: 400, overflowY: 'auto'}}>
                                <p style={{fontWeight: 'bold'}}>
                                    Rejected
                                    <CustomToolTip style={{marginLeft: 4}} text={`Auto = Automatically rejected\nApplicants are automatically rejected if they: \n- Did not consent to a background check\n- Were uneligible to work in the US\n- Failed to correctly answer a Knockout question`}/>
                                </p>
                                <JobPostModalAppList selectedJobApplication={this.state.selectedJobApplication} jobPost={this.state.jobPost} handleSelectApplicant={this.handleSelectApplicant} apps={rejected}/>
                            </div>
                        </Card.Body>
                        <div style={{height: 1, backgroundColor: 'lightgray', flexShrink: 0}}/>
            
                        <Modal size='md' show={this.state.showStatusModal} onHide={() => {this.setState({showStatusModal: false})}}>
                            <Modal.Header closeButton>
                                <Modal.Title>{this.state.jobPost?.active ? 'Deactivate Job Post' : 'Activate Job Post'}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div style={{}}>
                                        {!this.state.jobPost?.active 
                                        ?   
                                            <>
                                                <ul>
                                                    <li>Posted and visible on all selected job search websites</li>
                                                    <li>Appears on Job Posts page under active jobs</li>
                                                    <li>Notifies recruiters of new applicants</li>
                                                </ul>
                                                <CustomCheck title='Post to:' items={JobSites} stateProps={new ControlStateProps('jobSites', this.state.jobPost)} inline={true} breakpoints={{xxl: 12}}/>
                                                <div style={{display: 'flex', alignItems: "center", gap: 12}}>
                                                    <SwitchControl style={{width: '100%'}} title='Start From Scratch' value={this.state.cloneJobPost} setValue={()=>this.setState({cloneJobPost: !this.state.cloneJobPost})}/>
                                                    <CustomToolTip content='Toggling this option will create a new version of this job post without the original applicants. You will still have access to the original job post and its applicants.'/>
                                                </div>
                                            </>
                                        :
                                            <ul>
                                                <li>Removed from all job search websites</li>
                                                <li>Unlisted by default on Job Posts page</li>
                                                <li>Able to be permanently deleted</li>
                                            </ul>
                                        }

                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div style={{display: 'flex'}}>
                                    <p style={{fontSize: 11, display: 'block', margin: 'auto'}}>
                                            Please allow up to 12 hours for the change to take effect on third-party job search websites.
                                    </p>
                                    <CustomButton isLoading={this.state.isSubmittingStatus} onClick={()=>{this.handleSetStatus(); this.setState({showStatusModal: false})}}>
                                        {this.state.jobPost?.active ? 'Deactivate' : 'Activate'}
                                    </CustomButton>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.showDeleteModal} onHide={() => {this.setState({showDeleteModal: false, continueDelete: false})}}>
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Job Post?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <h6 style={{textAlign: 'center'}}>WARNING: This action cannot be undone!</h6>
                                <p style={{marginBottom: 12, textAlign: 'center'}}>Deleting this job post will also delete all applicant data associated with this job post.</p>
                                <p style={{marginBottom: 4, textAlign: 'center', fontStyle: 'italic', fontSize: 11}}>Accurate statistics for dashboard will be retained.</p>
                            </Modal.Body>
                            <Modal.Footer>
                                { !this.state.continueDelete && 
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 8}}>
                                        <p style={{margin: 0}}>Are you sure you want to continue?</p>
                                        <Button onClick={()=>setTimeout(()=>{this.setState({continueDelete: true})}, 500)}>
                                            Continue
                                        </Button>
                                    </div>
                                }
                                {this.state.continueDelete && 
                                    <CustomButton variant='danger' isLoading={this.state.isDeleting} onClick={this.handleDeleteJobPost}>
                                        Delete Job Post
                                    </CustomButton>
                                }
                            </Modal.Footer>
                        </Modal>
                        <Modal size='xl' show={this.state.showTerminalModal} onHide={()=>this.setState({showTerminalModal: false})}>
                            <Modal.Header closeButton>
                                <Modal.Title>Job Post Terminals Settings</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <SubscriberWrapper stateProps={new ControlStateProps('terminals', this.state.jobPost, undefined, 'terminalUpdateJobPost')} render={()=> {
                                    return <JobPostTerminalEditor jobPost={this.state.jobPost} terminals={this.state.allTerminals} handleAddTerminal={this.handleAddTerminal} handleDeleteTerminal={this.handleDeleteTerminal}/>
                                }}/>
                            </Modal.Body>
                            <Modal.Footer>
                                <SubscriberWrapper stateProps={new ControlStateProps('terminals', this.state.jobPost, null, 'Disable savechanges button to terminals')} render={()=>{
                                    return(
                                        <>
                                            {!this.state.jobPost.terminals.length > 0 && <p style={{marginRight: 12}}>Please select at least one terminal</p>}
                                            <CustomButton disabled={!this.state.jobPost.terminals.length > 0} isLoading={this.state.isUpdatingTerminals} onClick={this.handleUpdateTerminals}>Save Changes</CustomButton>
                                        </> 
                                    )
                                }}/>
                            </Modal.Footer>
                        </Modal>
                    </Card>
                    {this.state.selectedJobApplication &&
                        <Card>
                            <Card.Body style={{overflowY: 'unset', paddingLeft: this.props.mobileView ? 6 : undefined, paddingRight: this.props.mobileView ? 6 : undefined}}>
                                <JobApplicationView accordionDefaultOpen key={this.state.selectedJobApplication.uid} selectedJobApplication={this.state.selectedJobApplication.uid} terminals={this.state.allTerminals} handleUpdateReject={this.handleUpdateReject} loadData={this.loadData} handleSetApplicant={this.handleSetApplicant}/>
                            </Card.Body>
                        </Card>
                    }
                    </div>
                    :
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                        <Card>
                            <Card.Header>
                                <Card.Title style={{textAlign: 'center'}}>Oops!</Card.Title>
                            </Card.Header>
                            <Card.Body style={{display: 'flex', alignItems: 'center', gap: 12}}>
                                <span style={{margin: 'auto', textAlign: 'center'}}>
                                    <FontAwesomeIcon size="1x" icon={faExclamationTriangle} style={{color: 'var(--bs-danger)', marginRight: 8}}/>
                                    The Job Post you are looking for does not exist or was removed
                                </span>
                            </Card.Body>
                        </Card>
                    </div>
                }
            </LoadingWrapper>
        )
    }
}

function JobPostModalAppList({apps, handleSelectApplicant, selectedJobApplication, jobPost}) {
    

    const appElements = apps.map((app) => {
        const isActive = selectedJobApplication?.uid === app.uid;
        return (
            <ListGroup.Item active={isActive} action onClick={() => {handleSelectApplicant(app.uid)}} key={app.uid} style={{display: 'flex', justifyContent: 'space-between', gap: 2, alignItems: 'center' }}>
                <span>
                    {app.firstName + ' ' + app.lastName}
                    
                    {!jobPost?.terminals.map(t=>t.uid).includes(app.terminalIdentifier) && 
                    <OverlayTrigger overlay={
                        <Popover>
                            <Popover.Header>Warning</Popover.Header>
                            <Popover.Body>This applicant is associated with a terminal no longer associated with this job post.</Popover.Body>
                        </Popover>
                    }>
                        <FontAwesomeIcon style={{marginLeft: 6}} color='gold' icon={faTriangleExclamation}/>
                    </OverlayTrigger>
                    
                    }
                </span>
                    { !app.clientIdentifier && !app.rejected 
                        ? <Badge bg={isActive ? 'light' : 'primary'} style={isActive ? {color: 'var(--bs-primary)'} : {}}>New!</Badge>
                        : app.autoRejected === 1 && <Badge bg={isActive ? 'light' : 'primary'} style={isActive ? {color: 'var(--bs-primary)'} : {}}>Auto</Badge>
                    }
            </ListGroup.Item>
        )
    })

    return (
        <ListGroup style={{textAlign: 'start'}}>
            {appElements}
        </ListGroup>
    )
}


