/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { faExclamation, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useReducer, useState } from 'react';

export default function DocumentRadioCheck({stateProps, x, y, value, onChange, innerRef, width = 13, height = 13, rhs, style}) {
    const [showValidation, setShowValidation] = useState(false);
    const [_, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        stateProps?.subscribe((showValidation) => {
            if (showValidation) {
                setShowValidation(true);
            }
            forceUpdate();
        });
    }, [stateProps]);

    const handleSetValue = () => {
        stateProps.stateObject.setState(stateProps.stateKey, value);
        if (onChange) {
            onChange(value);
        }
    }

    return (
        <>
            <input ref={innerRef} type='checkbox' checked={stateProps.getValue() === value} onChange={handleSetValue} style={{position: 'absolute', top: y, left: x, width, height, ...style}}/>
            { stateProps.getIsInvalid() && 
                <>
                    <div style={{position: 'absolute', top: y, left: x, width: width, height: height, border: '1px solid red', borderRadius: 2, pointerEvents: 'none'}}/>
                    <FontAwesomeIcon icon={faExclamation} size='2xs' style={{position: 'absolute', top: y + (height / 2) - 6, left: x + (rhs ? width + 2 : -(width + 2)), color: 'red', border: '1px solid red', backgroundColor: 'white', borderRadius: 100, padding: '0px 5px 0px 5px'}}/>
                </>

            }
        </>
    )
}