/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import { Accordion, Button, ButtonGroup, Card, Col, Dropdown, Form, InputGroup, ListGroup, Modal, Row } from 'react-bootstrap';
import KeyValueRow from '../../components/KeyValueRow';
import { DegreeLevels, EducationLevels, JobSites, MilitaryBranches, PayTypes, States } from '../../enums';
import moment from 'moment';
import { addressToString, cleanUsd, customQuestionAnswerToString, formatPhoneNumber, formatSsn, pastTimestampToString } from '../../tools';
import JobApplicationTimeline from './JobApplicationTimeline';
import JobApplication from '../JobApplication/models/JobApplication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight, faClipboardCheck, faFile, faPencil, faPlus, faScrewdriverWrench, faSquareCheck, faTrash, faWrench, faX } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane, faSquare } from '@fortawesome/free-regular-svg-icons';
import { assignClient, createJobApplicationNote, deleteJobApplicationNote, getJobApplication, rejectApplicant, updateJobApplicationNote } from '../../services/JobApplicationService';
import LoadingWrapper from '../../components/LoadingWrapper';
import SubscriberWrapper from '../../components/SubscriberWrapper';
import CustomButton from '../../components/CustomButton';
import CustomControl from '../../components/controls/CustomControl';
import JobApplicationNote from '../../models/JobApplication/JobApplicationNote';
import Address from '../../models/Address';
import CustomQuestionAnswer from '../../models/JobApplication/CustomQuestionAnswer';
import { AppGlobals } from '../../App';
import CustomDropDown from '../../components/controls/CustomDropDown';
import ControlStateProps from '../../models/State/ControlStateProps';
import { Validation } from '../../validation';
import RichText from '../../components/RichText/RichText';
import { Editor, EditorState } from 'draft-js';
import RichTextEditor from '../../components/RichText/RichTextEditor';
import InterviewEditor from './InterviewEditor';
import JobApplicationForJobPost from '../JobPosts/models/JobApplicationForJobPost';
import Cookies from 'universal-cookie';
import DeleteConfirmation from '../../components/DeleteConfirmation';
import RejectModal from './RejectModal';
import HireModal from './HireModal';
import './JobApplicationView.css'

export default class JobApplicationView extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            jobApplication: undefined,
            buttonIsLoading: false,
            newNote: '',
            clients: [],
            isSubmittingNote: false,
            isSubmittingClient: false,
            showModal: false,
            showInterviewModal: false,
            showNoteDeleteModal: false,
            showRejectModal: false,
            showHireModal: false,
            clientIdentifierForApplicant: null,
            selectedInterview: null,
            selectedNote: null,
            editorState: EditorState.createEmpty(),
        }

        this.loadData = this.loadData.bind(this);
        this.handleAddNote = this.handleAddNote.bind(this);
        this.handleAssignClient = this.handleAssignClient.bind(this);
        this.handleInterviewCrud = this.handleInterviewCrud.bind(this);
        this.handleDeleteNote = this.handleDeleteNote.bind(this);
        this.handleSetApplicantHired = this.handleSetApplicantHired.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        this.setState({isLoading: true});
        const response = await getJobApplication(this.props.selectedJobApplication);
        if (response.status === 200) {
            this.setState({jobApplication: JobApplicationForJobPost.decode(response.jobApplication)});
            this.setState({clients: response.clients})
        }
        this.setState({isLoading: false});
    }
    
    async handleAssignClient(){
        this.setState({isSubmittingClient: true});
        const response = await assignClient(this.state.jobApplication.uid, parseInt(this.state.clientIdentifierForApplicant));
        if(response.status === 200){
            AppGlobals.alert('success', response.message);
            this.state.jobApplication.setState('clientIdentifier', parseInt(this.state.clientIdentifierForApplicant));
            if (this.props.handleSetApplicant) {
                this.props.handleSetApplicant(this.state.jobApplication.uid, 'clientIdentifier', parseInt(this.state.clientIdentifierForApplicant));
            }
        } else{
            AppGlobals.alert('danger', response.message);
        }
        this.setState({isSubmittingClient: false});
        this.setState({showModal: false});
    }
    
    async handleAddNote() {
        this.setState({isSubmittingNote: true});

        const response = await createJobApplicationNote(this.state.jobApplication.uid, this.state.jobApplication.firstName, this.state.jobApplication.lastName, RichText.encode(this.state.editorState));
        if (response.status === 200) {
            const notes = Array.from(this.state.jobApplication['notes']);
            notes.push(JobApplicationNote.decode(response.note));
            this.state.jobApplication.setState('notes', notes);
            this.setState({editorState: EditorState.createEmpty()});
        } else {
            AppGlobals.alert('danger', response.message);
        }
        this.setState({isSubmittingNote: false});

    }

    async handleUpdateNote(note){
        this.setState({isSubmittingNote: true});
        const response = await updateJobApplicationNote(note.uid, RichText.encode(this.state.editorState));
        if (response.status === 200) {
            let newNotes = Array.from(this.state.jobApplication.notes);
            const index = this.state.jobApplication.notes.findIndex(n=>n.uid === note.uid);
            newNotes[index] = {
                ...note,
                richText: this.state.editorState
            }
            this.state.jobApplication.setState('notes', newNotes);
        } else {
            AppGlobals.alert('danger', response.message);
        }
        this.setState({isSubmittingNote: false, selectedNote: null, editorState: EditorState.createEmpty()});
    }

    handleDeleteNote(uid){
        let newNotes = Array.from(this.state.jobApplication.notes);
        newNotes = newNotes.filter(n=>n.uid !== uid);
        this.state.jobApplication.setState('notes', newNotes)
        this.setState({selectedNote: null, showNoteDeleteModal: false});
    }

    handleInterviewCrud(type, interview){
        let newInterviews = Array.from(this.state.jobApplication.interviews);
        const cookies = new Cookies();
        const userData = cookies.get('userData');
        const user = {
            uid: userData.uid,
            firstName: userData.firstName,
            lastName: userData.lastName,
        }

        switch(type){
            case 'create':
                newInterviews.push({
                    ...interview,
                    richText: RichText.decode(interview.richText),
                    user: interview.isContractor ? null : user
                });
                break;
            case 'delete':
                newInterviews = newInterviews.filter(i=>i.uid !== interview.uid);
                break;
            case 'update':
                const index = this.state.jobApplication.interviews.findIndex(i=>i.uid === interview.uid);
                newInterviews[index] = {
                    ...interview,
                    richText: RichText.decode(interview.richText),
                    user: interview.isContractor ? null : user
                }
                break;
            default:
                break;
        }
        newInterviews.sort(function(a,b){return new Date(a.dateTime) - new Date(b.dateTime)});
        this.state.jobApplication.setState('interviews', newInterviews);
        if (this.props.handleSetApplicant) {
            this.props.handleSetApplicant(this.state.jobApplication.uid, 'interviewCount', newInterviews.length);
        }
    }

    handleSetApplicantHired() {
        this.state.jobApplication.setState('hired', moment().format('YYYY-MM-DD'));
        if (this.props.handleSetApplicant) {
            this.props.handleSetApplicant(this.state.jobApplication.uid, 'hired', moment().format('YYYY-MM-DD'));
        }
    }

    render() {
      
        const cookies = new Cookies();
        const userData = cookies.get('userData');

        const clientItems = {};
        this.state.clients.forEach(c=>{
            clientItems[c.uid] = c.name;
        })

        const educationElements = this.state.jobApplication?.education.map((ed, index) => {
            return (
                <ListGroup.Item key={index}>
                    <p>{'Education #' + (index + 1)}</p>
                    <ListGroup>
                        <KeyValueRow vertical={this.props.mobileView} isListItem title='Name of Institution' value={ed.name}/>
                        <KeyValueRow vertical={this.props.mobileView} isListItem title='Level of Education' value={EducationLevels[ed.level]}/>
                        <KeyValueRow vertical={this.props.mobileView} isListItem title='Location' value={ed.city + ', ' + ed.state}/>
                        {ed.startDate && ed.endDate && <KeyValueRow vertical={this.props.mobileView} isListItem title='Attended' value={moment(ed.startDate).format('MMM YYYY') + ' - ' + moment(ed.endDate).format('MMM YYYY')}/>}
                        <KeyValueRow vertical={this.props.mobileView} isListItem title='Graduated?' value={ed.graduated ? 'Yes' : 'No'}/>
                        {ed.endDate && ed.graduated && <KeyValueRow isListItem title='Graduation Date' value={moment(ed.endDate).format('MMMM YYYY')}/>}
                        { ed.fieldOfStudy &&
                            <KeyValueRow vertical={this.props.mobileView} isListItem title='Field of Study' value={ed.fieldOfStudy}/>
                        }
                        { ed.degreeLevel &&
                            <KeyValueRow vertical={this.props.mobileView} isListItem title='Degree Level' value={DegreeLevels[ed.degreeLevel]}/>
                        }
                    </ListGroup>
                </ListGroup.Item>
            )
        })
        const employmentHistoryElements = this.state.jobApplication?.employmentHistory.map((eh, index) => {
            return (
                <ListGroup.Item key={index}>
                    <p>{'Previous Employment #' + (index + 1)}</p>
                    <ListGroup>
                        <KeyValueRow vertical={this.props.mobileView} isListItem title='Name of Employer' value={eh.employerName}/>
                        <KeyValueRow vertical={this.props.mobileView} isListItem title='Job Title' value={eh.jobTitle}/>
                        <KeyValueRow vertical={this.props.mobileView} isListItem title='Start & End Date' value={moment(eh.startDate).format('MMM YYYY') + ' - ' +  (eh.endDate ? moment(eh.endDate).format('MMM YYYY') : 'Present')}/>
                        <KeyValueRow vertical={this.props.mobileView} isListItem title='Starting Pay' value={cleanUsd(eh.startingPayAmount) + ' ' + PayTypes[eh.startingPayType]}/>
                        <KeyValueRow vertical={this.props.mobileView} isListItem title='Ending Pay' value={cleanUsd(eh.endingPayAmount) + ' ' + PayTypes[eh.endingPayType]}/>
                        <KeyValueRow vertical={this.props.mobileView} isListItem title='Responsibilities' value={eh.responsibilities}/>
                        <KeyValueRow vertical={this.props.mobileView} isListItem title='Reason for Leaving' value={eh.reasonForLeaving}/>
                        { eh.employerPhoneNumber &&
                            <KeyValueRow vertical={this.props.mobileView} isListItem title='Employer Phone Number' value={formatPhoneNumber(eh.employerPhoneNumber)}/>
                        }
                        { eh.employerEmail && 
                            <KeyValueRow vertical={this.props.mobileView} isListItem title='Employer Email' value={eh.employerEmail}/>
                        }
                        { eh.employerAddress &&
                            <KeyValueRow vertical={this.props.mobileView} isListItem title='Employer Address' value={addressToString(eh.employerAddress)}/>
                        }
                    </ListGroup>
                </ListGroup.Item>
            )
        })

        const resume = !this.state.jobApplication ? undefined : (
            <ListGroup>
                <KeyValueRow vertical={this.props.mobileView} isListItem title='First Name' value={this.state.jobApplication.firstName}/>
                <KeyValueRow vertical={this.props.mobileView} isListItem title='Middle Name' value={this.state.jobApplication.middleName}/>
                <KeyValueRow vertical={this.props.mobileView} isListItem title='Last Name' value={this.state.jobApplication.lastName}/>
                <KeyValueRow vertical={this.props.mobileView} isListItem title='Phone Number' value={formatPhoneNumber(this.state.jobApplication.phoneNumber)}/>
                <KeyValueRow vertical={this.props.mobileView} isListItem title='Email Address' value={this.state.jobApplication.email}/>
                <KeyValueRow vertical={this.props.mobileView} isListItem title='Date of Birth' value={moment(this.state.jobApplication.dateOfBirth).format('MMM D, YYYY') + ' (age ' + moment().diff(moment(this.state.jobApplication.dateOfBirth), 'years') + ')'}/>
                <KeyValueRow vertical={this.props.mobileView} isListItem title='First Date Available to Work' value={moment(this.state.jobApplication.dateAvailable).format('MMM D, YYYY')}/>
                <KeyValueRow vertical={this.props.mobileView} isListItem title='Address' value={addressToString(this.state.jobApplication.address)}/>
                <KeyValueRow vertical={this.props.mobileView} isListItem title='Legally Eligible to Work in the U.S.?' value={this.state.jobApplication.eligibleToWork ? 'Yes' : 'No'}/>
                <KeyValueRow vertical={this.props.mobileView} isListItem title='Ever Convicted of a Felony?' value={this.state.jobApplication.convictedOfFelony ? 'Yes' : 'No'}/>
                { this.state.jobApplication.convictedOfFelony ?
                    <KeyValueRow vertical={this.props.mobileView} isListItem title='Felony Explanation' value={this.state.jobApplication.felonyConvictionNotes}/>: <></>
                }
                <KeyValueRow vertical={this.props.mobileView} isListItem title='Veteran?' value={this.state.jobApplication.isVeteran ? 'Yes' : 'No'}/>
                { this.state.jobApplication.militaryService &&
                    <ListGroup.Item>
                        <p>Military Service</p>
                        <ListGroup>
                            <KeyValueRow vertical={this.props.mobileView} isListItem title='Branch' value={MilitaryBranches[this.state.jobApplication.militaryService.branch]}/>
                            <KeyValueRow vertical={this.props.mobileView} isListItem title='Start Date' value={moment(this.state.jobApplication.militaryService.startDate).format('MMM D, YYYY')}/>
                            {this.state.jobApplication.militaryService.endDate && <KeyValueRow vertical={this.props.mobileView} isListItem title='End Date' value={moment(this.state.jobApplication.militaryService.endDate).format('MMM D, YYYY')}/>}
                            <KeyValueRow vertical={this.props.mobileView} isListItem title='Type of Discharge' value={this.state.jobApplication.militaryService.dischargeType}/>
                            { this.state.jobApplication.militaryService.dishonorableDischargeExplanation &&
                                <KeyValueRow vertical={this.props.mobileView} isListItem title='Reason for Dishonorable Discharge' value={this.state.jobApplication.militaryService.dishonorableDischargeExplanation}/>
                            }
                            <KeyValueRow vertical={this.props.mobileView} isListItem title='Rank at Discharge' value={this.state.jobApplication.militaryService.rankAtDischarge}/>
                        </ListGroup>
                    </ListGroup.Item>
                }
                <KeyValueRow vertical={this.props.mobileView} isListItem title='Provided background check consent?' value={this.state.jobApplication.backgroundCheckConsent ? 'Yes' : 'No'}/>
                {educationElements}
                {employmentHistoryElements}
            </ListGroup>
        )


        const customQuestionAnswers = this.state.jobApplication?.customQuestionAnswers.map((qa, index) => {

            if(qa.question.type === 'text' || qa.question.type === 'textArea'){
                return (
                    <ListGroup.Item key={index}>
                        <h6 style={{fontWeight: 400}}>{qa.question.title}</h6>
                        <p style={{opacity: .6}}>{customQuestionAnswerToString(qa)}</p>
                    </ListGroup.Item>
                )
            }
            return (
                <KeyValueRow vertical={this.props.mobileView} key={index} isListItem title={qa.question.title} value={customQuestionAnswerToString(qa)}/>
            )
        })

        const noteElements = this.state.jobApplication?.notes.map((note, index)=>{
            return (
                <div key={note.uid}>
                    { (index === 0 || this.state.jobApplication?.notes[index - 1].author.uid !== note.author.uid) &&
                        <span style={{fontWeight: 'bold', fontSize: 13}}>{note.author.firstName  + ' ' + note.author.lastName}</span>
                    }
                    <div style={{display: 'flex', justifyContent: 'space-between', gap: 8, alignItems: 'center'}}>
                        <div style={{display: 'flex', gap: 4, alignItems: 'end'}}>
                            <div style={{backgroundColor: 'white', border: '1px solid lightgray', padding: '2px 8px 2px 8px', borderRadius: 12, padding: 12, wordBreak: 'break-word'}}>
                                <RichTextEditor readOnly editorState={note.richText}/>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'end', gap: 8, marginBottom: 4}}>
                                {userData.uid === note.author.uid && <FontAwesomeIcon onClick={()=> {this.setState({selectedNote: note, editorState: note.richText})}} style={{cursor: 'pointer'}} color='rgb(45, 105, 160)' icon={faPencil}/>}
                                <FontAwesomeIcon style={{cursor: 'pointer'}} onClick={()=>this.setState({selectedNote: note, showNoteDeleteModal: true})} color='red' icon={faTrash}/>
                            </div>
                        </div>
                        <div style={{display: 'flex', gap: 4, flexWrap: 'wrap', justifyContent: 'flex-end'}}>
                            <span style={{whiteSpace: 'nowrap'}}>{pastTimestampToString(note.dateTime)}</span>
                            {note.lastEdited && <span style={{opacity: .5}}>&nbsp;(edited)</span>}
                        </div>
                    </div>
                </div>         
            )
        })

        return (
            <>
                <LoadingWrapper isLoading={this.state.isLoading} color='var(--bs-primary)'>
                    { this.state.jobApplication &&
                        <div>
                            <div className='source-buttons'>
                                <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                                    <h5 style={{margin: 0}}><strong>Source: &nbsp;</strong>{JobSites[this.state.jobApplication.source] ? JobSites[this.state.jobApplication.source] : 'MyTacticalRecruiter'}</h5>
                                    <h5 style={{margin: 0}}><strong>Terminal: &nbsp;</strong>{this.state.jobApplication?.terminal.name}</h5>
                                </div>
                                {!this.state.jobApplication?.rejected && !this.state.jobApplication.hired &&
                                    <div style={{display: 'flex', justifyContent: 'end', alignItems: 'center', gap: 8}}>
                                        {this.state.jobApplication.clientIdentifier  && 
                                            <Button style={{minWidth:80}} onClick={() => {this.setState({showHireModal: true})}}>
                                                <FontAwesomeIcon style={{marginRight: 4}} icon={faClipboardCheck}/> Hire
                                            </Button>
                                        }
                                        <Button style={{minWidth:80}} variant='danger' onClick={()=>this.setState({showRejectModal: true})}><FontAwesomeIcon style={{marginRight: 4}} icon={faTrash}/> Reject</Button>
                                </div>
                                }
                            </div>
                            <div style={{textAlign: 'center', marginBottom: 8}}>
                                <h3 style={{marginTop: 16}}>{this.state.jobApplication.firstName + ' ' + this.state.jobApplication.lastName}</h3>
                                {this.state.jobApplication.clientIdentifier && <h6 style={{marginBottom: 20}}>Assigned to: {this.state.clients.find(c=>c.uid == this.state.jobApplication.clientIdentifier)?.name}</h6>}
                                {!this.state.jobApplication.clientIdentifier && !this.state.jobApplication.rejected &&
                                    <div style={{display: 'flex', padding: 12}}>
                                        <CustomDropDown items={clientItems} title='Choose Client' selection={this.state.clientIdentifierForApplicant} setSelection={(value=>this.setState({clientIdentifierForApplicant: value}))} breakpoints={{s:10}}/>
                                        <CustomButton disabled={!this.state.clientIdentifierForApplicant}  style={{height: 58}} onClick={()=>this.setState({showModal: true})}>
                                            Assign to Client
                                        </CustomButton>
                                    </div>
                                }
                            </div>
                            <Accordion alwaysOpen defaultActiveKey={this.props.accordionDefaultOpen ? ['0', '1', '2', '3', '4'] : []}>
                                <Accordion.Item eventKey='0'>
                                    <Accordion.Header><span style={{fontWeight: 'bold'}}>Resume</span></Accordion.Header>
                                    <Accordion.Body style={this.props.mobileView ? {paddingLeft: 6, paddingRight: 6} : {}}>{resume}</Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey='1'>
                                    <Accordion.Header><span style={{fontWeight: 'bold'}}>Custom Question Answers</span></Accordion.Header>
                                    <Accordion.Body style={this.props.mobileView ? {paddingLeft: 6, paddingRight: 6} : {}}>
                                        <ListGroup>
                                            {customQuestionAnswers}
                                        </ListGroup>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey='2'>
                                    <Accordion.Header><span style={{fontWeight: 'bold'}}>Timeline</span></Accordion.Header>
                                    <Accordion.Body style={this.props.mobileView ? {paddingLeft: 6, paddingRight: 6} : {}}>
                                        <JobApplicationTimeline jobApplication={this.state.jobApplication} vertical={false}/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                { this.state.jobApplication.clientIdentifier &&
                                <Accordion.Item eventKey='3'>
                                    <Accordion.Header><span style={{fontWeight: 'bold'}}>Interviews</span></Accordion.Header>
                                    <Accordion.Body style={this.props.mobileView ? {paddingLeft: 6, paddingRight: 6} : {}}>
                                        <Button onClick={()=>this.setState({showInterviewModal: true, selectedInterview: null})} style={{float: 'right', marginBottom: 16}}>
                                            <FontAwesomeIcon icon={faPlus} style={{marginRight: 6}}/>Add Interview
                                            </Button>
                                        <SubscriberWrapper stateProps={new ControlStateProps('interviews', this.state.jobApplication)} render={()=>{
                                            return (
                                                <>
                                                    {this.state.jobApplication.interviews?.map((i, index)=>    
                                                    <Card key={index} style={{marginTop: 60}}>
                                                            <Card.Header style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}} >
                                                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bolder', margin: 0, height: 30, 
                                                                width: 30, marginRight: 16, color: 'white', borderRadius: '100%', padding: 12, backgroundColor: 'rgb(45, 105, 160)'}}>{index+1}</div>
                                                                <h6 style={{margin: 0, fontSize: 15}}>{moment(i.dateTime).format('MMM Do YYYY, h:mm a')} &nbsp;&nbsp; </h6> 
                                                                <Button style={{marginLeft: 'auto'}} onClick={()=>this.setState({showInterviewModal: true, selectedInterview: i})}>
                                                                    <FontAwesomeIcon icon={faPencil} style={{}}/>
                                                                </Button>
                                                            </Card.Header>
                                                        <Card.Body>
                                                            <RichTextEditor readOnly editorState={i.richText}/>
                                                        </Card.Body>
                                                        <Card.Footer>
                                                            <h6 style={{textAlign: 'right', fontSize: 15}}>&nbsp;Conducted By: {i.user ? `${i.user.firstName} ${i.user.lastName}` : `${this.state.clients.find(c=>this.state.jobApplication.clientIdentifier == c.uid).name}`}</h6>
                                                        </Card.Footer>
                                                    </Card>)}
                                                </>
                                            )
                                        }}/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                }
                                <Accordion.Item eventKey='4'>
                                    <Accordion.Header><span style={{fontWeight: 'bold'}}>Notes</span></Accordion.Header>
                                    <Accordion.Body style={this.props.mobileView ? {paddingLeft: 6, paddingRight: 6} : {}}>
                                        <Card>
                                            <Card.Body>
                                                <RichTextEditor editorState={this.state.editorState} setEditorState={(value)=>this.setState({editorState: value})}/>
                                                <div style={{display: 'flex', margin: '12px 0', justifyContent: 'end'}}>
                                                {this.state.selectedNote && !this.state.showNoteDeleteModal && <Button style={{marginRight: 8}} onClick={()=>this.setState({selectedNote: null, editorState: EditorState.createEmpty()})} variant='danger'>Cancel Edit</Button>}
                                                <CustomButton isLoading={this.state.isSubmittingNote} onClick={()=> {
                                                    if(this.state.selectedNote){
                                                        this.handleUpdateNote(this.state.selectedNote)
                                                    }else{
                                                        this.handleAddNote(); 
                                                    }}}>
                                                   {this.state.selectedNote && !this.state.showNoteDeleteModal ? 'Edit' : 'Send'}<FontAwesomeIcon icon={faCircleArrowRight}/>
                                                </CustomButton>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                        <div style={{display: 'flex', flexDirection: 'column', gap: 6, marginTop: 12}}>
                                           {noteElements}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    }
                </LoadingWrapper>
                <Modal show={this.state.showModal} onHide={()=>this.setState({showModal: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Assigning To Client</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                       Warning: This cannot be changed or undone!
                    </Modal.Body>
                    <Modal.Footer>
                        <CustomButton variant={'danger'} isLoading={this.state.isSubmittingClient} stateObject={this.jobApplication} uniqueKey={'AssignApplicantToCLient'} onClick={this.handleAssignClient}>
                            Assign
                        </CustomButton>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showNoteDeleteModal} onHide={()=>this.setState({showNoteDeleteModal: false, selectedNote: null})}>
                    <DeleteConfirmation uid={this.state.selectedNote?.uid} onSuccess={()=>this.handleDeleteNote(this.state.selectedNote?.uid)} api={deleteJobApplicationNote} buttonLabel='Delete' title='Delete Note' message='Are you sure you want to delete this note?'/>
                </Modal>
                <Modal size='xl' show={this.state.showRejectModal} onHide={()=>this.setState({showRejectModal: false})}>
                    <RejectModal jobApplication={this.state.jobApplication} hideModal={()=>this.setState({showRejectModal: false})} clientIdentifier={this.state.jobApplication?.clientIdentifier} handleUpdateReject={this.props.handleUpdateReject} loadApplicantData={this.loadData}/>
                </Modal>
                <Modal show={this.state.showHireModal} onHide={() => {this.setState({showHireModal: false})}} size='xl' backdrop='static'>
                    <HireModal jobApplication={this.state.jobApplication} hideModal={() => {this.setState({showHireModal: false})}} handleSetApplicantHired={this.handleSetApplicantHired}/>
                </Modal>
                <Modal size='xl' show={this.state.showInterviewModal} onHide={()=>{this.setState({showInterviewModal: false})}} backdrop='static'>
                    <InterviewEditor interview={this.state.selectedInterview} hideModal={()=>this.setState({showInterviewModal: false})} jobApplicationIdentifier={this.state.jobApplication?.uid} handleInterviewCrud={this.handleInterviewCrud}/>
                </Modal>
            </>

        )
    }
}