/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import CustomQuestion from '../../../models/CustomQuestion';
import { Button, ButtonGroup, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTurnDown, faArrowTurnUp, faX } from '@fortawesome/free-solid-svg-icons';
import { CustomQuestionTypes } from '../../../enums';
import CustomControl from '../../../components/controls/CustomControl';
import ControlStateProps from '../../../models/State/ControlStateProps';
import { Validation } from '../../../validation';

export default class CustomQuestionFieldView extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
                <div>
                    <ButtonGroup vertical>
                        <Button style={{padding: 2}} onClick={() => {this.props.handleMoveBack(this.props.question.uid)}}>
                            <FontAwesomeIcon icon={faArrowTurnUp} flip='horizontal'/>
                        </Button>
                        <Button style={{padding: 2}} onClick={() => {this.props.handleMoveForward(this.props.question.uid)}}>
                            <FontAwesomeIcon icon={faArrowTurnDown} flip='horizontal'/>
                        </Button>
                    </ButtonGroup>
                </div>
                <div style={{border: '1px solid lightgray', backgroundColor: 'var(--background-secondary)', padding: 6, borderRadius: 6, width:'100%', display: 'flex', flexDirection: 'column', gap: 6}}>
                    <div style={{display: 'flex', gap: 12, width: '100%', justifyContent: 'space-between'}}>
                        <div style={{display: 'flex', flexShrink: 0, justifyContent: 'center', alignItems: 'center', width: 24, height: 24, borderRadius: 12, backgroundColor: 'var(--bs-primary)'}}>
                            <span style={{color: 'white'}}>{this.props.index + 1}</span>
                        </div>
                        <span style={{fontWeight: 'bold'}}>{CustomQuestionTypes[this.props.question.type]}</span>
                        <Button onClick={() => {this.props.handleDelete(this.props.question.uid)}} className='btn-danger' style={{width: 24, height: 24, padding: 0, borderRadius: 12, display: 'flex', justifyContent: 'center', alignItems: 'center', flexShrink: 0}}>
                            <FontAwesomeIcon icon={faX}/>
                        </Button>
                    </div>
                    <CustomControl floatingLabel title='Question' stateProps={new ControlStateProps('title', this.props.question, Validation.nonEmptyString)}/>
                </div>
            </div>
        )
    }
}