/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState, useEffect} from 'react';
import RichTextEditor from '../../components/RichText/RichTextEditor';
import { EditorState } from 'draft-js';
import RichText from '../../components/RichText/RichText';
// import { createinterview, getAllinterviews, getinterview, updateinterview, deleteinterview, sendinterview } from '../../services/interviewService';
import CustomButton from '../../components/CustomButton';
import { Form, Modal, ListGroup, Alert, Row, Col, ModalBody } from 'react-bootstrap';
import { createInterview, deleteInterview, updateInterview } from '../../services/JobApplicationService';
import CustomControl from '../../components/controls/CustomControl';
import moment from 'moment';
import { AppGlobals } from '../../App';
import DeleteConfirmation from '../../components/DeleteConfirmation';


export default function InterviewEditor({interview, hideModal, handleInterviewCrud, jobApplicationIdentifier}) {

    const [editorState, setEditorState] = useState(interview ? interview.richText : EditorState.createEmpty());
    const [interviewName, setinterviewName] = useState(interview ? interview.name : '');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isContractor, setIsContractor] = useState(false);
    const [interviewDateTime, setInterviewDateTime] = useState(interview ? interview.dateTime : moment().format('YYYY-MM-DD HH:mm:ss'));

    async function handleSubmit(){
        setIsSubmitting(true);
        const interviewToSave = {
            uid: interview?.uid,
            jobApplicationIdentifier,
            dateTime: interviewDateTime,
            richText: RichText.encode(editorState),
        }

        if (interviewToSave.uid) {
            const response = await updateInterview(interviewToSave, isContractor);
            if (response.status === 200) {
                handleInterviewCrud('update', {...interviewToSave, isContractor: isContractor})
                hideModal();
            }
        } else {
            const response = await createInterview(interviewToSave, isContractor);
            if (response.status === 200) {
                interviewToSave.uid = response.uid;
                handleInterviewCrud('create', {...interviewToSave, isContractor: isContractor});
                hideModal();
            }
        }
        setIsSubmitting(false);
    }

    async function handleDelete(){
        setIsDeleting(true);
        const response = await deleteInterview(interview.uid);
        if(response.status === 200){
            AppGlobals.alert('success', response.message);
            handleInterviewCrud('delete', interview)
            hideModal();
        } else{
            AppGlobals.alert('success', response.message);
        }
        setIsDeleting(false);
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{interview?.uid ? 'Editing Interview' : 'Creating Interview'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                    <div style={{display:'flex', gap:10}}>
                        <RichTextEditor editorState={editorState} setEditorState={setEditorState}/>
                    </div>
                </div>
                <Row style={{marginTop: 30}}>
                    {!interview?.uid &&
                        <Col sm={6}>
                            <Form.Check 
                                type='switch' 
                                label='Conducted by Client' 
                                style={{border: '1px solid lightgray', borderRadius: 6, height: 57, paddingTop: 16, paddingBottom: 6, paddingLeft: 54}}
                                checked={isContractor}
                                value={isContractor}
                                onChange={()=>setIsContractor(!isContractor)}
                            />
                    </Col>
                    }
                    <CustomControl breakpoints={{sm: 6}} max={moment().format('YYYY-MM-DD HH:mm:ss')} floatingLabel disabled={isSubmitting} title='Date/Time' type='datetime-local' value={interviewDateTime} setValue={(value)=>setInterviewDateTime(!value ? undefined : moment(value).format('YYYY-MM-DD HH:mm:ss'))}/>
                </Row>
             </Modal.Body>
             <Modal.Footer>           
                    <div style={{display:'flex', width:'100%', justifyContent:'flex-end', padding: 5, gap: 6}}>
                    {interview?.uid && <button onClick={()=>setShowDeleteModal(true)} style={{marginLeft: 18, border: 'none', boxShadow: 'none', color: 'red', backgroundColor: 'transparent'}} >Delete Interview</button>}    
                        <CustomButton isLoading={isSubmitting} onClick={handleSubmit}>
                            {interview?.uid ? 'Save Changes' : 'Create Interview'}
                        </CustomButton>
                    </div>
             </Modal.Footer>
             <Modal show={showDeleteModal} onHide={() => { setShowDeleteModal(false)}}>
               <Modal.Header>
                    <Modal.Title> Delete Inteview</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                    Are you sure you want to delete this interview? 
               </Modal.Body>
               <Modal.Footer>
                    <CustomButton onClick={handleDelete} variant={'danger'} isLoading={isDeleting}>Delete Interview</CustomButton>
               </Modal.Footer>
            </Modal>
         </>
    )
}