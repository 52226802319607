/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { ReactNode } from 'react';
import { Button, Card, Container, Modal, Row, Toast } from 'react-bootstrap';
import { Prompt } from 'react-router-dom';
import JobPost from './models/JobPost';
import { EmploymentTypes, PayTypes, ShiftDurations, ShiftTypes } from '../../enums';
import CustomButton from '../../components/CustomButton';
import JobApplicationForm from '../JobApplication/JobApplicationForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEye } from '@fortawesome/free-solid-svg-icons';
import { createJobPost, getJobPostForEdit, getJobPostTerminals, updateJobPost } from '../../services/JobPostService';
import LoadingWrapper from '../../components/LoadingWrapper';
import CustomControl from '../../components/controls/CustomControl';
import CustomPicker from '../../components/controls/CustomPicker';
import CustomCheck from '../../components/controls/CustomCheck';
import CustomTextArea from '../../components/controls/CustomTextArea';
import CustomQuestionsView from './CustomQuestions/CustomQuestionsView';
import { AppGlobals } from '../../App';
import JobCreatorSubmissionModal from './JobCreatorSubmissionModal';
import ControlStateProps from '../../models/State/ControlStateProps';
import { Validation } from '../../validation';
import Terminal from '../../models/Terminal';
import JobPostTerminal from './models/JobPostTerminal';
import moment from 'moment';

export default class JobCreator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            splitView: window.innerWidth >= 992,
            showPreview: false,
            jobPost: JobPost.initDefault(),
            isEditing: false,
            isSubmitting: false,
            showSubmitModal: false,
            modalSwitch: '',
            terminals: [],
            
        }
        this.handleWindowResize = this.handleWindowResize.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleAddTerminal = this.handleAddTerminal.bind(this);
        this.handleDeleteTerminal = this.handleDeleteTerminal.bind(this);
    }

    breakpoints = {xxl: 4, xl: 6, lg: 6, md: 4, sm: 6}
    formKey = 0;

    handleBeforeUnload(event) {
        event.preventDefault();
    }
    componentDidMount(){
        window.addEventListener("beforeunload", this.handleBeforeUnload);
        window.addEventListener('resize', this.handleWindowResize);
        const jobUidToEdit = parseInt(this.props.match.params.jobPost);
        if (!isNaN(jobUidToEdit)) {
            this.loadJobPostData(jobUidToEdit);
        } else{
            this.loadData()
        }
        
    }
    componentWillUnmount(){
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
        window.removeEventListener('resize', this.handleWindowResize);
    }

    handleWindowResize() {
        const newSplitView = window.innerWidth >= 992;
        if (this.state.splitView !== newSplitView) {
            this.setState({splitView: newSplitView});
        }
    }

    async loadJobPostData(uid) {
        this.setState({isLoading: true});
        const response = await getJobPostForEdit(uid);
        if (response.status === 200) {
            const jobPost = JobPost.decode(response.jobPost);
            this.setState({jobPost: jobPost, selectedTerminals: jobPost.terminals, terminals: response.terminals.map(t=>Terminal.decode(t)), isEditing: true});
        } else if(response.status === 400){
            AppGlobals.alert('warning', response.message)
        } else {
            AppGlobals.alert('danger', response.message)
        }
        this.setState({isLoading: false});
    }

    async loadData() {
        const response = await getJobPostTerminals();
        if (response.status === 200) {
            this.setState({terminals: response.terminals});
        } else {
            AppGlobals.alert('danger', response.message)
        }
    }

    async handleSubmit() {
        this.setState({isSubmitting: true});
        if (this.state.isEditing) {
            const response = await updateJobPost(this.state.jobPost.encode());
            if (response.status === 200) {
                AppGlobals.alert('success', response.message)
            } else {
                AppGlobals.alert('danger', response.message)
            }
        } else {
            const response = await createJobPost(this.state.jobPost.encode());
            if (response.status === 200) {
                AppGlobals.alert('success', response.message);
                this.state.jobPost.uid = response.uid;
                this.setState({isEditing: true})
            } else {
                AppGlobals.alert('danger', response.message);
            }
        }
        this.setState({isSubmitting: false, showSubmitModal: false});
    }

    hideModal() {
        this.setState({modalSwitch: ''});
    }

    handleAddTerminal(terminal, availablePositions, active, autoInactivate){
        this.state.jobPost.setState('terminals', [...this.state.jobPost.terminals, JobPostTerminal.initFromTerminal(terminal, availablePositions, active, autoInactivate)] )
    }

    handleDeleteTerminal(uid){
        this.state.jobPost.setState('terminals', this.state.jobPost.terminals.filter(t=>t.uid !== uid))
    }

    render() {

        return (
            <LoadingWrapper isLoading={this.state.isLoading}>
                <div style={{position: 'relative', padding: 12, height: '100%', display: 'flex', flexDirection: 'column', gap: 12, textAlign: 'start'}}>
                    <div key={this.formKey} style={{display: 'flex', flex: 1, flexDirection: this.state.splitView ? 'row' : 'column', gap: 12, overflowY: 'hidden'}}>
                        <div style={{width: this.state.splitView ? '50%' : '100%', display: 'flex', height: '100%', flexDirection: 'column', gap: 12}}>
                            <div className='hidden-scrollbar' style={{width: '100%', display: 'flex', flex: 1, overflowY: 'scroll', flexDirection: 'column', gap: 12}}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Job Information</Card.Title>
                                        <Container fluid style={{paddingTop: 12}}>
                                            <Row>
                                                <CustomControl floatingLabel maxLength={50} title='Job Title' stateProps={new ControlStateProps('title', this.state.jobPost, Validation.nonEmptyString)} breakpoints={this.breakpoints}/>
                                                <CustomPicker items={EmploymentTypes} title='Employment Type' stateProps={new ControlStateProps('employmentType',this.state.jobPost, Validation.nonEmptyString)} breakpoints={this.breakpoints}/>
                                                <CustomPicker items={PayTypes} title='Pay Type' stateProps={new ControlStateProps('payType',this.state.jobPost, Validation.nonEmptyString)} breakpoints={this.breakpoints}/>
                                                <CustomControl maxDecimal={999999.99} floatingLabel title='Minimum Compensation' type='number' stateProps={new ControlStateProps('compensationMin', this.state.jobPost, Validation.nonEmptyString)} breakpoints={this.breakpoints}/>
                                                <CustomControl maxDecimal={999999.99} floatingLabel title='Maximum Compensation' type='number' stateProps={new ControlStateProps('compensationMax', this.state.jobPost, Validation.nonEmptyString)} breakpoints={this.breakpoints}/>
                                                <CustomPicker items={ShiftTypes} title='Shift Type' stateProps={new ControlStateProps('shiftType',this.state.jobPost, Validation.nonEmptyString)} breakpoints={this.breakpoints}/>
                                                <CustomCheck title='Shift Durations' items={ShiftDurations} stateProps={new ControlStateProps('shiftDurations',this.state.jobPost, Validation.nonEmptyArray)} inline={true} breakpoints={{sm: 12}}/>
                                                <CustomControl floatingLabel title='Benefits' stateProps={new ControlStateProps('benefits', this.state.jobPost)} breakpoints={{sm: 8}}/>
                                                <CustomControl floatingLabel max={'9999-12-31'} min={moment().format('YYYY-MM-DD')} title='Expiration Date' type='date' stateProps={new ControlStateProps('expirationDate', this.state.jobPost)} breakpoints={{sm: 4}}/>
                                            </Row>
                                            <Row>
                                                <CustomTextArea max={2000} title='Job Description' stateProps={new ControlStateProps('description',this.state.jobPost, Validation.nonEmptyString)} breakpoints={{xxl: 12}} rows={4}/>
                                            </Row>
                                            <Prompt when={true} message={() => {return 'Leaving the page will discard any unsubmitted data'}}/>
                                        </Container>
                                    </Card.Body>
                                </Card>
                                <CustomQuestionsView jobPost={this.state.jobPost}/>
                            </div>
                            <Card style={{textAlign: 'center'}}>
                                <Card.Body style={{padding: 8, display: 'flex', flexDirection: 'column'}}>
                                    <CustomButton isLoading={this.state.isSubmitting} stateObject={this.state.jobPost} uniqueKey={'Submit Button'} onClick={this.state.isEditing ? this.handleSubmit : () => {this.setState({showSubmitModal: true})}}>
                                        {this.state.isEditing ? 'Save Changes ' : 'Submit'}
                                    </CustomButton>
                                </Card.Body>
                            </Card>
                        </div>
                        { this.state.splitView &&
                            <Card className='hidden-scrollbar' style={{width: this.state.splitView ? '50%' : '100%', height: '100%', overflowY: 'scroll'}}>
                                <Card.Body style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                                    <Card.Title>Application Preview</Card.Title>
                                    <JobApplicationForm isPreview jobPost={this.state.jobPost} breakpoints={{xxl: 6, xl: 12}} breakpointsForYesNo={{xxl: 12}}/>
                                </Card.Body>
                            </Card>
                        }
                    </div>
                    { !this.state.splitView &&
                        <div style={{position: 'absolute', right: 6, top: 6, backgroundColor: 'white', padding: 3, borderRadius: 6, border: '2px solid lightgray', zIndex: 1001}}>
                            <Button onClick={() => {this.setState({showPreview: true})}}>
                                View Preview
                                <FontAwesomeIcon style={{marginLeft: 6}} icon={faEye}/>
                            </Button>
                        </div>

                    }
                    <Modal show={this.state.showPreview} onHide={() => {this.setState({showPreview: false})}} fullscreen>
                        <Modal.Header closeButton>
                            <Modal.Title>Application Preview</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <JobApplicationForm isPreview jobPost={this.state.jobPost} breakpoints={{xxl: 6, xl: 12}} breakpointsForYesNo={{xxl: 12}}/>
                        </Modal.Body>
                    </Modal>
                    <Modal size='xl' show={this.state.showSubmitModal} onHide={() => {this.setState({showSubmitModal: false})}} centered>
                        <JobCreatorSubmissionModal terminals={this.state.terminals} jobPost={this.state.jobPost} 
                        handleAddTerminal={this.handleAddTerminal} handleDeleteTerminal={this.handleDeleteTerminal} handleSubmit={this.handleSubmit} isSubmitting={this.state.isSubmitting}/>
                    </Modal>
                </div>
            </LoadingWrapper>
        )
    }
}