/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from 'moment'
import A4Page1 from '../../../../../assets/A4-1.png';
import Signature from '../../../Signature/Signature'
import AbsoluteText from '../../AbsoluteText'
import { Document, Image, Page, Text } from '@react-pdf/renderer'
import { formatEin, formatSsn, usdFormatter, validateDecimal } from '../../../../../tools'
import A4 from './A4'

type A4PdfProps = {
    a4: A4,
    signature: Signature,
    isEmployer: boolean
}

export default function A4Pdf({a4, signature, isEmployer}: A4PdfProps) {

    return (
        <Document>
            <Page size='LETTER' style={{fontSize: 10}}>
                <Image src={isEmployer ? a4.employeeSubmission[0] ?? A4Page1 : A4Page1}/>
                { !isEmployer ?
                        ///EMPLOYEE
                    <>
                        <AbsoluteText y={187} x={45} text={a4.employeeName}/>
                        <AbsoluteText y={187} x={475} text={formatSsn(a4.ssn)}/>
                        
                        <AbsoluteText y={212} x={45} text={a4.streetAddress}/>
                        <AbsoluteText y={212} x={325} text={a4.city}/>
                        <AbsoluteText y={212} x={459} text={a4.state}/>
                        <AbsoluteText y={212} x={510} text={a4.zipCode}/>
                        
                        <AbsoluteText y={265} x={535} text={a4.noPersonalExemptions} fontSize={14}/>
                        <AbsoluteText y={288} x={535} text={a4.singleOrMarriedFilingSeparate} fontSize={14}/>
                        <AbsoluteText y={323} x={535} text={a4.marriedOrSingleClaimingHead} fontSize={14}/>
                        <AbsoluteText y={346} x={535} text={a4.numberOfDependents} fontSize={14}/>
                        <AbsoluteText y={371} x={509} text={a4.additionalWitholding} fontSize={12}/>

                        <Image src={signature.image} style={{position: 'absolute', top: 457, left: 150, width: 110, height: 20}}/>
                        <AbsoluteText y={468} x={489} text={moment().format('MM/DD/YYYY')}/>
                    </>
                :   
                        ///EMPLOYER
                    <>
                    <AbsoluteText y={396} x={528} text={a4.totalExemptions} fontSize={14}/>
                        <AbsoluteText x={38} y={516} text={a4.employerName}/>
                        <AbsoluteText x={448} y={516} text={formatEin(a4.ein)}/>
                        <AbsoluteText x={38} y={541} text={a4.employerAddress}/>
                        <AbsoluteText x={325} y={541} text={a4.employerCity}/>
                        <AbsoluteText x={458} y={541} text={a4.employerState}/>
                        <AbsoluteText x={508} y={541} text={a4.employerZipCode}/>
                    </>
                }   
            </Page>
        </Document>
    )
}