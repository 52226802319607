/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Validation } from "../../validation";
import { UidGenerator } from "../../tools";
import StateObject from "../State/StateObject";
import Decoder from "../../decoding";
import moment from "moment";

export default class Education extends StateObject {
    uid; //: number;
    name; //: string;
    level; //: string;
    city; //: string;
    state; //: string;
    startDate; //: string | null;
    endDate; //: string | null;
    graduated; //: boolean | undefined;
    fieldOfStudy; //: string | undefined;
    degreeLevel; //: string | undefined;

    constructor(
        name,
        level,
        city,
        state,
        startDate,
        endDate,
        graduated,
        fieldOfStudy,
        degreeLevel,
        key,
        parent
    ) {
        super(key, parent);
        this.uid = UidGenerator.getUid();
        this.name = name;
        this.level = level;
        this.city = city;
        this.state = state;
        this.startDate = startDate;
        this.endDate = endDate;
        this.graduated = graduated;
        this.fieldOfStudy = fieldOfStudy;
        this.degreeLevel = degreeLevel;
    }

    static initDefault(key = undefined, parent = undefined) {
        return new Education(
            '',
            '',
            '',
            '',
            '',
            '',
            undefined,
            undefined,
            undefined,
            key,
            parent
        )
    };

    static decode(json) {
        const decoder = new Decoder(json);
        const name = decoder.decode('name', Decoder.String);
        const level = decoder.decode('level', Decoder.String);
        const city = decoder.decode('city', Decoder.String);
        const state = decoder.decode('state', Decoder.String);
        const startDate = decoder.decode('startDate', Decoder.Date, {defaultValue: null, warn: false});
        const endDate = decoder.decode('endDate', Decoder.Date, {defaultValue: null, warn: false});
        const graduated = decoder.decode('isGraduated', Decoder.Boolean);
        const fieldOfStudy = decoder.decode('fieldOfStudy', Decoder.String, {defaultValue: undefined, warn: false});
        const degreeLevel = decoder.decode('degreeLevel', Decoder.String, {defaultValue: undefined, warn: false});
        if (decoder.checkForErrors()) {
            return new Education(name, level, city, state, startDate, endDate, graduated, fieldOfStudy, degreeLevel);
        } else {
            return Education.initDefault();
        }
    }

    encode() {
        return {
            name: this.name,
            level: this.level,
            city: this.city,
            state: this.state,
            startDate: this.startDate? this.startDate : null,
            endDate:  this.endDate ? this.endDate : null,
            graduated: this.graduated,
            fieldOfStudy: this.fieldOfStudy,
            degreeLevel: this.degreeLevel
        }
    }

}