/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "./serviceTools";

export async function applyToJob(application) {
    const data = {
        type: 'applicant',
        target: 'apply',
        token: getToken(),
        application
    }
    return await getResponse(data);
}

export async function getJobPostForApplication(jobPostIdentifier, terminalIdentifier) {
    const data = {
        type: 'applicant',
        target: 'getJobPostForApplication',
        token: getToken(),
        jobPostIdentifier: jobPostIdentifier, 
        terminalIdentifier: terminalIdentifier,
    }
    return await getResponse(data);
}

export async function getJobPostsForApplicants(zipCode, distance) {
    const data = {
        type: 'applicant',
        target: 'getAllForApplicants',
        token: getToken(),
        zipCode, 
        distance
    }

    return await getResponse(data);
}