/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import Decoder from "../../decoding";
import { UidGenerator } from "../../tools";
import { Validation } from "../../validation";
import CustomQuestion from "../CustomQuestion";
import StateObject from "../State/StateObject";
import JobApplication from "../../views/JobApplication/models/JobApplication";
import RichText from '../../components/RichText/RichText';
import { EditorState } from "draft-js";
import UserForInterview from "../../views/JobPosts/models/UserForInterview";

export default class Interview extends StateObject {
    uid: number;
    jobApplicationIdentifier: number;
    user: UserForInterview | null;
    dateTime: string;
    richText: EditorState;

    constructor(
        uid: number,
        jobApplicationIdentifier: number,
        user: UserForInterview | null,
        dateTime: string,
        richText: EditorState
        ) {
        super();
        this.uid = uid;
        this.jobApplicationIdentifier = jobApplicationIdentifier;
        this.user = user;
        this.dateTime = dateTime;
        this.richText = richText;
        this.initChildren();
    }

    static initDefault(): Interview {
            return new Interview(
                -1,
                -1,
                null,
                moment().format('YYYY-MM-DD HH:mm:ss'),
                EditorState.createEmpty()
            )
    }

    static decode(json: any): Interview {
        const decoder = new Decoder(json);
        const uid: number = decoder.decode('uid', Decoder.Uid);
        const jobApplicationIdentifier: number = decoder.decode('jobApplicationIdentifier', Decoder.Uid);
        const user: UserForInterview | null = json.user === null ? null : UserForInterview.decode(json.user);
        const dateTime: string = decoder.decode('dateTime', Decoder.DateTime);
        const richText: EditorState = RichText.decode(json.richText);

        if (decoder.checkForErrors()) {
            return new Interview(uid, jobApplicationIdentifier, user, dateTime, richText);
        } else {
            return Interview.initDefault();
        }
    }

    encode() {
        return {
            uid: this.uid,
            jobApplicationIdentifier: this.jobApplicationIdentifier,
            user: this.user,
            dateTime: this.dateTime,
            richText: RichText.encode(this.richText)
            
        }
    }


}