/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment"
import Address from "../../../../models/Address"
import StateObject from "../../../../models/State/StateObject"
import Decoder from "../../../../decoding";

export default class DirectDeposit extends StateObject {
    employerName;
    employeeName;
    email;
    phoneNumber;
    fiName;
    routingNumber;
    accountNumber;
    accountType;
    attachment;
    signed = false;
    completed = false;

    constructor(employerName, employeeName, email, phoneNumber, fiName, routingNumber, accountNumber, accountType, attachment) {
        super();
        this.employerName = employerName;
        this.employeeName = employeeName;
        this.email = email;
        this.phoneNumber = phoneNumber;
        this.fiName = fiName;
        this.routingNumber = routingNumber;
        this.accountNumber = accountNumber;
        this.accountType = accountType;
        this.attachment = attachment;
    }

    static initDefault() {
        return new DirectDeposit('', '', '', '', '', '', '', undefined);
    }

    static initFromData(document, userData, companyData) {
        const firstName = userData.firstName ?? '';
        const lastName = userData.lastName ?? '';

        return new DirectDeposit(
            companyData.companyName ?? companyData.name ?? '',
            document.employeeName ?? `${firstName} ${lastName}`,
            document.email ?? userData.email ?? '',
            document.phoneNumber ?? userData.phoneNumber ?? '',
            document.fiName ?? '',
            document.routingNumber ?? '',
            document.accountNumber ?? '',
            document.accountType,
            document.attachment
        )
    }

    encode(document, base64) {
        return {
            ...document,
            base64: base64,
            employerName: this.employerName,
            employeeName: this.employeeName,
            email: this.email,
            phoneNumber: this.phoneNumber,
            fiName: this.fiName,
            routingNumber: this.routingNumber,
            accountNumber: this.accountNumber,
            accountType: this.accountType,
            attachment: this.attachment
        }
    }
}