/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../decoding";
import StateObject from "../../../models/State/StateObject";

export default class NotificationSetting extends StateObject {
    name: string;
    textEnabled: boolean;
    emailEnabled: boolean;

    constructor(name: string, textEnabled: boolean, emailEnabled: boolean) {
        super();
        this.name = name;
        this.textEnabled = textEnabled;
        this.emailEnabled = emailEnabled;
    }

    static initDefault(): NotificationSetting {
        return new NotificationSetting(
          '',
          false,
          false
        );
    }

    static decode(json: {[key: string]: any}): NotificationSetting {
        const decoder = new Decoder(json);
        const name: string = decoder.decode('name', Decoder.String);
        const textEnabled: boolean = decoder.decode('textEnabled', Decoder.Boolean);
        const emailEnabled: boolean = decoder.decode('emailEnabled', Decoder.Boolean);

        if (decoder.checkForErrors()) {
            return new NotificationSetting(name, textEnabled, emailEnabled);
        } else {
            return NotificationSetting.initDefault();
        }
    }

    convertToArrayOfStrings(): string[]{
        let array: string[] = [];
        if(this.textEnabled)
            array.push('textEnabled')
        if(this.emailEnabled)
            array.push('emailEnabled')
        
        return array;
    }
}