/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, createRef } from 'react';
import { Badge, Button, ButtonGroup } from 'react-bootstrap';

type CustomTabsProps = {
    tabs: CustomTab[]
    defaultTab?: string | undefined
    fontSizeMult?: number;
    disableBadgesWhen0: boolean;
    iconBreakpoint?: number;
    automaticDefaultTab?: boolean
}
type CustomTabsState = {
    activeTab: string | undefined;
    width: number;
}

export default class CustomTabs extends React.Component<CustomTabsProps, CustomTabsState> {
    constructor(props: CustomTabsProps) {
        super(props);

        this.state = {
            activeTab: props.defaultTab ?? props.automaticDefaultTab ? props.tabs.find(t => (t.badge ?? 0) > 0)?.title : undefined,
            width: 800
        }
        this.handleWindowResize = this.handleWindowResize.bind(this);
    }

    ref = createRef<HTMLDivElement>();

    componentDidMount() {
        this.handleWindowResize();
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize)
    }

    handleWindowResize() {
        const newWidth = this.ref.current?.clientWidth;
        if (newWidth) {
            this.setState({width: newWidth});
        }
    }

    maxFontSize = 17.0;
    widthMult = 0.06;


    render() {

        const fontSize = Math.min(this.maxFontSize, (this.state.width * this.widthMult) * (this.props.fontSizeMult ?? 1.0));
        const paddingHorizontal = (fontSize / this.maxFontSize) * 6.0;
        const paddingVertical = (fontSize / this.maxFontSize) * 6.0;
        const badgepadding = (fontSize / this.maxFontSize) * 4.0;


        const buttons = this.props.tabs.map((tab) => {
            const isActive = this.state.activeTab === tab.title

            return (
                <Button disabled={this.props.disableBadgesWhen0 && tab.badge === 0} key={tab.title} style={{display: 'flex', gap: 8, alignItems: 'center', justifyContent: 'center', fontSize: fontSize, paddingLeft: paddingHorizontal, paddingRight: paddingHorizontal, paddingTop: paddingVertical, paddingBottom: paddingVertical, backgroundColor: this.state.activeTab !== tab.title ? 'white' : 'var(--bs-primary)', color: this.state.activeTab !== tab.title ? 'var(--bs-primary)' : 'white'}} onClick={() => {this.setState({activeTab: tab.title})}}>
                    {tab.icon !== undefined && this.state.width <= (this.props.iconBreakpoint ?? 999999) &&
                        <FontAwesomeIcon icon={tab.icon}/>
                    }
                    { tab.title && (this.props.iconBreakpoint === undefined || this.state.width > this.props.iconBreakpoint) &&
                        <span>{tab.title}</span>
                    }
                    
                    { (tab.badge ?? 0) > 0 &&
                        <Badge bg={isActive ? 'light' : 'primary'} style={{color: isActive ? 'var(--bs-primary)' : undefined, display: 'inline', padding: badgepadding}}>
                            {tab.badge}
                        </Badge>
                    }
                </Button>
            )
        });

        const activeTabContent = this.props.tabs.find(t => t.title === this.state.activeTab)?.content;

        return (
            <div ref={this.ref} style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <ButtonGroup>
                    {buttons}
                </ButtonGroup>
                {<p style={{textAlign: 'center', fontWeight: 'bold'}}>{this.state.activeTab}</p>}
                {activeTabContent}
            </div>
        )
    }
}

export type CustomTab = {
    title?: string;
    icon?: IconDefinition;
    badge?: number;
    content: ReactNode | undefined;
}