/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { faCheck, faChevronRight, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useReducer } from "react";
import { ListGroup } from "react-bootstrap";
import CustomButton from "../../components/CustomButton";
import { downloadBase64 } from "../../tools";

export default function CompanyDocumentListItem({document, showModal}) {

    return (
        <ListGroup.Item style={{display: 'flex', color: 'var(--bs-primary)', justifyContent: 'space-between', alignItems: 'center'}} action onClick={showModal}>
            <span>{document.documentName ?? document.name}</span>
            <FontAwesomeIcon icon={faChevronRight}/>
        </ListGroup.Item>
    )
}