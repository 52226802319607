/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useReducer } from 'react';

export default function DocumentCheckbox({disabled, stateProps, x, y, height, width, innerRef, style}) {
    const [_, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        stateProps?.subscribe((_) => {
            forceUpdate();
        });
    }, [stateProps]);

    const handleSetValue = () => {
        stateProps.stateObject.setState(stateProps.stateKey, !stateProps.getValue());
    }

    return (
        <input ref={innerRef} type='checkbox' checked={stateProps.getValue()} onChange={handleSetValue} style={{position: 'absolute', top: y, left: x, height, width, ...style}}/>
    )
}