/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "./serviceTools";

export async function getAllUsers() {
    const data = {
        type: 'admin',
        target: 'getAllUsers',
        token: getToken(),
    }
    return await getResponse(data);
}

export async function getAllClientUsers() {
    const data = {
        type: 'admin',
        target: 'getAllClientUsers',
        token: getToken(),
    }
    return await getResponse(data);
}

export async function shadow(userIdentifier, clientUserIdentifier) {
    const data = {
        type: 'admin',
        target: 'shadow',
        token: getToken(),
        userIdentifier: userIdentifier,
        clientUserIdentifier: clientUserIdentifier,
    }
    return await getResponse(data);
}

export async function stopShadowing() {
    const data = {
        type: 'admin',
        target: 'stopShadowing',
        token: getToken(),
    }
    return await getResponse(data);
}