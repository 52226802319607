/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Spinner from 'react-bootstrap/Spinner';
import CustomButton from "./CustomButton";
import { AppGlobals } from "../App";

export default function ApiButton({style, api, onSuccess, disabled, icon, stateObject, uniqueKey, narrow, variant, children}) {
    const [isLoading, setIsLoading] = useState(false);

    const handleApi = async () => {
        setIsLoading(true);
        const response = await api();
        if (response.status === 200) {
            if (onSuccess) {
                onSuccess();
            }
        } else {
            AppGlobals.alert('danger', response.message);
        }
        setIsLoading(false);
    }
    

    return (
        <CustomButton isLoading={isLoading} onClick={handleApi} style={style} disabled={disabled} icon={icon} stateObject={stateObject} uniqueKey={uniqueKey} narrow={narrow} variant={variant}>
            {children}
        </CustomButton>
    );
    
}