/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import { JSType } from "./enums"
import { validateInteger } from "./tools";


export default class Decoder {
    object: {[key: string]: any}
    errors: DecodingError[] = [];
    silent: boolean;


    constructor(object: Object, silent: boolean = false) {
        this.object = object;
        this.silent = silent;
    }


    decode(key: string, test: (key: string, value: any) => any, fallback?: DecodingFallback): any {
        if (fallback) {
            try {
                return test(key, this.object[key]);
            } catch {
                if (fallback.warn) {
                    console.log(`Warning: Falling back to default value for ${key} with value ${this.object[key]}`);
                }
                return fallback.defaultValue;
            }
        } else {
            try {
                return test(key, this.object[key]);
            } catch(e) {
                this.errors.push(e as DecodingError);
            }
        }
    }
    checkForErrors(): boolean {
        if (this.errors.length === 0) {
            return true;
        } else {
            if (!this.silent) {
                this.errors.forEach(e => console.log(e.message));
            }
            return false;
        }
    }


    static Decode(obj: {[key: string]: any}, key: string, test: (key: string, value: any) => any, fallback?: DecodingFallback) {
        if (fallback) {
            try {
                return test(key, obj[key]);
            } catch {
                if (fallback.warn) {
                    console.log(`Warning: Falling back to default value for ${key} with value ${obj[key]}`);
                }
                return fallback.defaultValue;
            }
        } else {
            try {
                return test(key, obj[key]);
            } catch(e) {
                throw e;
            }
        }
    }

    static String(key: string, value: any): string {
        if (typeof value === JSType.Object) {
            throw new DecodingError(key, value, 'String');
        } else {
            return value.toString();
        }
    }
    static StringStrict(key: string, value: any): string {
        if (typeof value !== JSType.String) {
            throw new DecodingError(key, value, 'StringStrict');
        } else {
            return value;
        }
    }
    static NonEmptyString(key: string, value: any) {
        try {
            if (Decoder.StringStrict(key, value) === '') {
                throw new DecodingError(key, value, 'NonEmptyString');
            } else {
                return value;
            }
        } catch {
            throw new DecodingError(key, value, 'NonEmptyString');
        }
    }
    static Date(key: string, value: any) {
        try {
            // if (!/^([123]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/g.test(Decoder.NonEmptyString(key, value))) {
            if(!moment(value).isValid()){
                throw new DecodingError(key, value, 'Date');
            } else {
                return value;
            }
        } catch {
            throw new DecodingError(key, value, 'Date');
        }
    }
    static DateTime(key: string, value: any) {
        try {
            if (!/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/g.test(Decoder.NonEmptyString(key, value))) {
                throw new DecodingError(key, value, 'DateTime');
            } else {
                return value;
            }
        } catch {
            throw new DecodingError(key, value, 'DateTime');
        }
    }
    static Integer(key: string, value: any) {
        if (typeof value === JSType.Number && Math.floor(value) === value) {
            return value;
        } else {
            throw new DecodingError(key, value, 'Integer');
        }
    }
    static Decimal(key: string, value: any) {
        if (typeof parseFloat(value) === JSType.Number) {
            return value;
        } else {
            throw new DecodingError(key, value, 'Decimal');
        }
    }
    static Boolean(key: string, value: any) {
        if (typeof value === JSType.Boolean || value == 1 || value == 0) {
            return value;
        } else {
            throw new DecodingError(key, value, 'Boolean');
        }
    }
    static Uid(key: string, value: any) {
        try {
            if (Decoder.Integer(key, value) > 0) {
                return value;
            } else {
                throw new DecodingError(key, value, 'Uid');
            }
        } catch {
            throw new DecodingError(key, value, 'Uid');
        }
    }
    static NonEmptyArray(key: string, value: any) {
        if (Array.isArray(value) && value.length > 0) {
            return value;
        } else {
            throw new DecodingError(key, value, 'NonEmptyArray');
        }
    }
    static Array(key: string, value: any) {
        if (Array.isArray(value)) {
            return value;
        } else {
            throw new DecodingError(key, value, 'Array');
        }
        
    }

    static NonEmptyArrayFromString(key: string, value: any) {
        try{
            if (Array.isArray(JSON.parse(value)) && JSON.parse(value).length > 0) {
                return JSON.parse(value);
            } else {
                throw new DecodingError(key, value, 'NonEmptyArrayFromString');
            }
        } catch {
            throw new DecodingError(key, value, 'JSON.ParseError')
        }
    }

    static ArrayFromString(key: string, value: any) {
        try{
            if (Array.isArray(JSON.parse(value))) {
                return JSON.parse(value);
            } else {
                throw new DecodingError(key, value, 'ArrayFromString');
            }
        } catch {
            throw new DecodingError(key, value, 'JSON.ParseError')
        }
    }
    
    static StringArray(key: string, value: any) {
        try {
            if (Decoder.Array(key, value) && !(value as any[]).find(e => typeof e !== JSType.String)) {
                return value;
            } else {
                throw new DecodingError(key, value, 'StringArray');
            }
        } catch {
            throw new DecodingError(key, value, 'StringArray');
        }
    }
    static NonEmptyStringArray(key: string, value: any) {
        try {
            if (Decoder.NonEmptyArray(key, value) && !(value as any[]).find(e => typeof e !== JSType.String)) {
                return value;
            } else {
                throw new DecodingError(key, value, 'NonEmptyStringArray');
            }
        } catch {
            throw new DecodingError(key, value, 'NonEmptyStringArray');
        }
    }
}

export class DecodingError extends Error {
    constructor(key: string, value: any, method: string) {
        super(`Failed to decode ${key} with value ${value} using method ${method}`);
        this.name = 'DecodingError';
    }
}

export type DecodingFallback = {
    defaultValue: any;
    warn: boolean;
}
