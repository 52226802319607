/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Card, ListGroup } from "react-bootstrap";
import { AppGlobals } from "../../App";
import LoadingWrapper from "../../components/LoadingWrapper";
import { getAllRecruiters } from "../../services/ClientUserService"
import { useEffect, useState } from "react";
import { formatPhoneNumber } from "../../tools";
import SortableTable, { SortableTableCol, SortableTableRow } from "../../components/SortableTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

export default function Recruiters() {
    const [isLoading, setIsLoading] = useState(false);
    const [recruiters, setRecruiters] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        const response = await getAllRecruiters();
        if (response.status === 200) {
            setRecruiters(response.recruiters);
        } else {
            AppGlobals.alert('danger', response.message);
        }
        setIsLoading(false);
    }

    const recruiterElements = recruiters.map((r) => {

        const userElementsMobile = r.users.map((user) => {
            return (
                <ListGroup.Item key={user.uid} className="recruiters-mobile">
                    <h5 style={{marginBottom: 8}}>{`${user.firstName} ${user.lastName}`}</h5>
                    <p style={{ margin: 0}}><FontAwesomeIcon style={{marginRight: 4}} icon={faPhone}/> {formatPhoneNumber(user.phoneNumber)}</p>
                    <p style={{ margin: 0}}><FontAwesomeIcon style={{marginRight: 8}} icon={faEnvelope}/>{user.email}</p>
                </ListGroup.Item>
            )
        });


        const columns = [
            new SortableTableCol('name', 'Name', null, SortableTableCol.sortAsc),
            new SortableTableCol('phoneNumber', 'Phone Number', null, SortableTableCol.sortAsc),
            new SortableTableCol('email', 'Email Address', null, SortableTableCol.sortAsc),
        ]

        const userElementsDesktop = r.users.map((user) => {
            return new SortableTableRow(
                {name: `${user.firstName} ${user.lastName}`, phoneNumber: user.phoneNumber, email: user.email},
                <tr key={user.uid}>
                    <td>{`${user.firstName} ${user.lastName}`}</td>
                    <td>{formatPhoneNumber(user.phoneNumber)}</td>
                    <td>{user.email}</td>
                </tr>
            )
        });

        return (
            <Card key={r.uid}>
                <Card.Header>
                    <Card.Title>{r.name}</Card.Title>
                </Card.Header>
                <Card.Body>
                    <h6 style={{marginBottom: 12}}>Total Applicants Hired: {r.totalApplicantsHired}</h6>
                    <ListGroup className="mobile-block">
                        {userElementsMobile}
                    </ListGroup>
                    <div className="desktop-block">
                        <SortableTable columns={columns} rows={userElementsDesktop}/>
                    </div>
                </Card.Body>

            </Card>
        )
    });


    return (
        <LoadingWrapper isLoading={isLoading}>
            <div style={{padding: 12, display: 'flex', flexDirection: 'column', gap: 12}}>
                {recruiterElements}
            </div>
        </LoadingWrapper>
    )
}