/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../decoding";
import { Validation } from "../validation";
import StateObject from "./State/StateObject";

export default class Address extends StateObject {
    uid; //: number;
    thoroughfare; //: string;
    premise; //: string;
    locality; //: string;
    administrativeArea; //: string;
    postalCode; //: string;
    lat;
    lng;

    constructor(
        uid,
        thoroughfare,
        premise,
        locality,
        administrativeArea,
        postalCode,
        lat,
        lng
    ) {
        super();
        this.uid = uid;
        this.thoroughfare = thoroughfare;
        this.premise = premise;
        this.locality = locality;
        this.administrativeArea = administrativeArea;
        this.postalCode = postalCode;
        this.lat = lat;
        this.lng = lng;
        this.initChildren();
    }

    static initDefault() {
        const address = new Address(
            -1,
            '',
            '',
            '',
            '',
            '',
            null,
            null
        );
        return address;
    }

    static decode(json, optional = false) {
        const decoder = new Decoder(json, optional);
        const uid = decoder.decode('uid', Decoder.Uid);
        const thoroughfare = decoder.decode('thoroughfare', Decoder.String);
        const premise = decoder.decode('premise', Decoder.String, {defaultValue: '', warn: false});
        const locality = decoder.decode('locality', Decoder.String);
        const administrativeArea = decoder.decode('administrativeArea', Decoder.String);
        const postalCode = decoder.decode('postalCode', Decoder.String);
        if (decoder.checkForErrors()) {
            return new Address(uid, thoroughfare, premise, locality, administrativeArea, postalCode, null, null);
        } else if (optional) {
            return undefined;
        } else {
            return Address.initDefault();
        }
    }

    static decodeValidated(json, optional = false) {
        const decoder = new Decoder(json, optional);
        const thoroughfare = decoder.decode('thoroughfare', Decoder.StringStrict);
        const premise = decoder.decode('premise', Decoder.StringStrict, {defaultValue: '', warn: false});
        const locality = decoder.decode('locality', Decoder.StringStrict);
        const administrativeArea = decoder.decode('administrativeArea', Decoder.StringStrict);
        const postalCode = decoder.decode('postalCode', Decoder.StringStrict);
        const lat = decoder.decode('lat', Decoder.Decimal);
        const lng = decoder.decode('lng', Decoder.Decimal);
        if (decoder.checkForErrors()) {
            return new Address(-1, thoroughfare, premise, locality, administrativeArea, postalCode, lat, lng);
        } else if (optional) {
            return undefined;
        } else {
            return Address.initDefault();
        }
    }

    checkIfValid(){
        if(!this.thoroughfare || !this.locality || !this.administrativeArea || !this.postalCode)
            return undefined
        else{
            return this;
        }
    }

    static decodeNonStrict(address = {}){
        return new Address(address.uid ?? -1, address.thoroughfare ?? '', address.premise ?? '', address.locality ?? '', address.administrativeArea ?? '', address.postalCode ?? '', null, null);
    }

    encode() {
        return {
            uid: this.uid,
            thoroughfare: this.thoroughfare,
            premise: this.premise,
            locality: this.locality,
            administrativeArea: this.administrativeArea,
            postalCode: this.postalCode,
            latitude: this.lat,
            longitude: this.lng,
        }
    }
}