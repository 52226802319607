/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../../../decoding";
import Address from "../../../../../models/Address";
import StateObject from "../../../../../models/State/StateObject";
import { validateDecimal, validateInteger } from "../../../../../tools";
import { Validation } from "../../../../../validation";

export default class K4 extends StateObject {
    ssnFirst3;
    ssnMiddle2;
    ssnLast4
    name;
    mailingAddress;
    city;
    state;
    zipCode;
    exemptBox1;
    exemptBox2;
    exemptBox3;
    exemptBox4;
    residentOfState;
    additionalWitholding;
    signed;
    spouseMilitary;
    notMilitary;
    spouseMilitaryInKentucky;
    liveSameAddress;
    spouseDifferentDomicileState;
    differentDomicileStateCode;
    inKentuckyWithSpouse;
    resideInDifferentState;

    constructor(
        ssnFirst3,
        ssnMiddle2,
        ssnLast4,
        name,
        mailingAddress,
        city,
        state,
        zipCode,
        exemptBox1,
        exemptBox2,
        exemptBox3,
        exemptBox4,
        residentOfState,
        additionalWitholding,
        signed,
        spouseMilitary,
        notMilitary,
        spouseMilitaryInKentucky,
        liveSameAddress,
        spouseDifferentDomicileState,
        differentDomicileStateCode,
        inKentuckyWithSpouse,
        resideInDifferentState,
    ){
        super();
        this.ssnFirst3 = ssnFirst3;
        this.ssnMiddle2 = ssnMiddle2;
        this.ssnLast4 = ssnLast4;
        this.name = name;
        this.mailingAddress = mailingAddress;
        this.city = city;
        this.state = state;
        this.zipCode = zipCode;
        this.exemptBox1 = exemptBox1;
        this.exemptBox2 = exemptBox2;
        this.exemptBox3 = exemptBox3;
        this.exemptBox4 = exemptBox4;
        this.residentOfState = residentOfState;
        this.additionalWitholding = additionalWitholding;
        this.signed = signed;
        this.spouseMilitary = spouseMilitary;
        this.notMilitary = notMilitary;
        this.spouseMilitaryInKentucky = spouseMilitaryInKentucky;
        this.liveSameAddress = liveSameAddress;
        this.spouseDifferentDomicileState = spouseDifferentDomicileState;
        this.differentDomicileStateCode = differentDomicileStateCode;
        this.inKentuckyWithSpouse = inKentuckyWithSpouse;
        this.resideInDifferentState = resideInDifferentState;
    }

    static initDefault(){
        return new K4(
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            false,
            false,
            false,
            false,
            '',
            '',
            false,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            ''
        )
    }

    static initFromData(document, userData){
        const firstName = userData.firstName ?? '';
        const middleInitial = userData.middleName?.[0] ?? '';
        const lastName = userData.lastName ?? '';
        const ssn = userData.ssn ?? '';
        const first3 = ssn.substring(0, 3) ?? '';
        const middle2 = ssn.substring(3, 5) ?? '';
        const last4 = ssn.substring(6) ?? '';
        const userAddress = Address.decodeNonStrict(userData.address);

        return new K4(
            document.ssnFirst3 ?? first3,
            document.ssnMiddle2 ?? middle2,
            document.ssnLast4 ?? last4,
            document.name ?? `${lastName}, ${firstName}, ${middleInitial}`,
            document.mailingAddress ??`${userAddress.thoroughfare} ${userAddress.premise}`,
            document.city ?? userAddress.locality,
            document.state ?? userAddress.administrativeArea,
            document.zipCode ?? userAddress.postalCode,
            document.exemptBox1 ?? false,
            document.exemptBox2 ?? false,
            document.exemptBox3 ?? false,
            document.exemptBox4 ?? false,
            document.residentOfState ?? '',
            document.additionalWitholding ?? '',
            false,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            ''
        )
    }

    encode(document, base64){
        return {
            ...document,
            base64: base64,
            ssnFirst3: validateInteger(this.ssnFirst3),
            ssnMiddle2: validateInteger(this.ssnMiddle2),
            ssnLast4: validateInteger(this.ssnLast4),
            name: this.name,
            mailingAddress: this.mailingAddress,
            city: this.city,
            state: this.state,
            zipCode: this.zipCode,
            exemptBox1: this.exemptBox1,
            exemptBox2: this.exemptBox2,
            exemptBox3: this.exemptBox3,
            exemptBox4: this.exemptBox4,
            residentOfState: this.residentOfState ? this.residentOfState : null,
            additionalWitholding: this.additionalWitholding ? validateDecimal(this.additionalWitholding) : null,
        }
    }
}